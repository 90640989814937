import { Theme} from "@material-ui/core";
// Customizable Area Start
        // Customizable Area End

export const styles = (theme:Theme) => ({
  // Customizable Area Start
  mobileHomeCureContainer: {
    display: "flex",
    alignItems: "center",
    margin: "20px",
  },
  mobileHomecureLogo: {
    width: "200px",
    height:"70px",
    margin: 20,
    [theme.breakpoints.down('sm')]: {
      width: 180,
      margin: 10
    }
  },
  mobileBackButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "20px",
    height: "20px",
    backgroundColor: "rgba(255, 255, 255, 0.3)",
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
    padding: "10px",
    margin: "35px",
    borderRadius: "5px",
  },
  mobileTransparentCard: {
    backgroundColor: "rgba(255, 255, 255, 0.3)",
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
    width: "380px",
    margin: "50px 120px",
    borderRadius: "25px",
    padding: "25px 35px",
    [theme.breakpoints.down("sm")]: {
      width: "380px !important",
      margin: "100px auto",
      padding: "30px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "300px !important",
      margin: "80px auto",
      padding: "15px",
    }
  },
  mobileCardContent: {
    display: "flex",
    padding: theme.spacing(2),
  },
  mobileTextBold: {
    caretColor:"transparent",
    color:"#292929",
    fontSize: "28px",
    fontFamily:"SF Pro Text Bold !important",
  },
  mobileTitleWelcome: {
    caretColor:"transparent",
    color:"#292929",
    fontSize: "28px",
    fontFamily:"SF Pro Text Medium",
    marginVertical: 8,
    marginTop:"30px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px"
    }
  },
  mobileSubtitle: {
    caretColor:"transparent",
    fontFamily:"SF Pro Text Regular",
    fontSize: "14px",
    color:"#292929",
    padding: 5,
  },
  mobileInputLabel: {
    marginTop: "30px",
    marginBottom: "10px",
    fontSize: "16px",
    fontFamily:"SF Pro Text Regular",
    color:"#292929",
    caretColor:"transparent",
  },
  mobileInputField: {
    width: "100%",
    marginBottom: "15px",
    "& .MuiInputBase-root":{
      color:"#292929",
      fontSize: "14px",
      borderRadius: "10px",
      fontFamily:"SF Pro Text Regular",
    },
    "& input": {
      color:"#292929",
      fontSize: "14px",
      fontFamily:"SF Pro Text Regular",
      "&::placeholder": {
        opacity: 1
      }
    },
  },
  mobileLoginButton: {
    width: "100%",
    backgroundColor: "#014866",
    color: "#F2F6F7",
    border: "none",
    borderRadius: "10px",
    padding: "16px",
    cursor: "pointer",
    fontFamily:"SF Pro Text Bold",
    fontSize: "18px",
    margin: "10px auto",
    "&:hover": {
      backgroundColor: "#014866",
      color: "#F2F6F7",
    },
  },
  mobileInputError: {
    display:"flex",
    justifyContent: "end",
    color: "#FF0000",
    fontSize: "12px",
    fontFamily:"SF Pro Text Regular",
  },
  mobileLink: {
    caretColor:"transparent",
    display: "flex",
    justifyContent: "center",
    alignIten: "center",
    marginTop:"10px",
    color: "#292929",
    fontSize: "14px",
    fontFamily:"SF Pro Text Regular",
    marginBottom:"15px"
  },
  mobileRegisterLink: {
    cursor: "pointer",
    color: "#014866",
    caretColor:"transparent",
    marginLeft: "5px",
    fontSize: "14px",
    fontFamily:"SF Pro Text Medium",
  },
  mobileNotchedOutline: {
    borderWidth: "1px",
    borderColor: "white !important",
    borderRadius: "10px",
    fontSize: "16px",
    "& .MuiInputBase-input": {
      fontSize: "16px",
      color: "black",
    },
    "& .MuiFormLabel-root": {
      color: "black",
    },
    "& .MuiInputLabel-formControl": {
      color: "black",
    },
  },
  mobileErrorOutline: {
    borderWidth: "1px",
    borderColor: "#FF0000 !important",
    borderRadius: "10px",
    fontSize: "16px",
    "& .MuiInputBase-input": {
      fontSize: "16px",
      color: "black",
    },
    "& .MuiFormLabel-root": {
      color: "black",
    },
    "& .MuiInputLabel-formControl": {
      color: "black",
    },
  },
  arrowIcon: {
    width: "15px",
    height: "15px"
  },
  mobileErrorInputField: {
    width: "100%",
    color: '#292929 !important',
    marginBottom: "10px",
    "& .MuiInputBase-root":{
      color:"#292929",
      fontSize: "14px",
      fontFamily:"SF Pro Text Regular",
      background: "white !important",
      borderRadius: "10px"
    },
    "& input": {
      color:"#292929",
      fontSize: "14px",
      fontFamily:"SF Pro Text Regular",
      "&::placeholder": {
        opacity: 1
      }
    },
  },
  // Customizable Area End
});