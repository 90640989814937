import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible ,UserProfile} from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
const {baseURL} = require('../../../framework/src/config');
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any
  docType?: any
  openDialog?: any
  handelDialog?: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  profileImage: string;
  userName: string;
  fileDetails: any;
  documentURL:string;
  openPreview:boolean;
  date:string;
  fileType:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class FileattachmentController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  documentApiCallId: any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      profileImage: UserProfile,
      userName: "",
      fileDetails: [],
      documentURL:"",
      openPreview:false,
      date:"",
      fileType:"",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.documentApiCallId &&
      this.documentApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (!responseJson.errors && !responseJson.error) {
          this.setDocAPIResponse(responseJson.data)
      } else {
        this.showAlert("Error", responseJson.errors ? responseJson.errors.message : responseJson.error.message);
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Startitem.attributesfile_details
  closeDialog = () => {
    const { handelDialog } = this.props;
    handelDialog();
  }

  closePerview = () => {
    this.closeDialog()
    this.setState({ openPreview:false});
  }
  //Fetch document details
  getdocumentList = async () => {
    const token = await getStorageData("authToken");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.documentApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.fetchDocuments
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  setDocAPIResponse = async(responseJson:any) => {
    const docType = this.props.docType ==="upload" ? "prescription":"report"
          const updateFileDetails = (data:any) => {
            return data
                .filter((item:any )=> item.attributes.document_type === docType)
                .map((item:any) => {
    
                return {
                    ...item.attributes,
                    userName: item.attributes.name,
                    profileImage: item.attributes?.profile_details?.url || this.state.profileImage,
                    days: item.attributes.create_day,
                    formattedDate: item.attributes.create_date,
                    formattedDateWithTime: item.attributes.create_time
                };
            });
        };
        
        const fileDetails = await updateFileDetails(responseJson);
        this.setState({fileDetails})
  }

  fetchDoc = async (documentURL:any, date:string) => {
    const urlLink = `${baseURL}${documentURL}`;
    if(urlLink){
      const fileName = urlLink.split('/').pop() || '';
      const fileType = fileName.split('.').pop() || '';
      this.setState({ documentURL:urlLink, openPreview:true,date,fileType});
    }
};
  // Customizable Area End
}
