import * as React from "react";
// Customizable Area Start
import { Box, Typography, Card, CardContent, TextField, Button, FormHelperText, withStyles } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { homecureTransparent, physicianMedical } from "./assets";
import OTPInputAuthController, {
  Props,
} from "./OTPInputAuthController";
import { styles } from "./styles/OTPInputAuth";
import CountDownTimer from "../../../components/src/CountDownTimer.web";
// Customizable Area End

export class OTPInputAuth extends OTPInputAuthController {
  constructor(props: Props) {
    super(props);
  }

  // Customizable Area Start
  async componentDidMount() {
    const phoneNumber = await this.getPhoneNumber();
    if(phoneNumber){
      this.setState({phoneNumber:phoneNumber});
    }
  }

  generateResendComponent = (text: string, testId: string) => (
    <Typography
      variant="h6"
      data-test-id={testId}
      style={{
        color: this.state.resendCounter === 5 || !this.state.isResendActive ? "#201d1d" : "#014866",
        fontSize: "16px",
        textTransform: "none",
        cursor: this.state.resendCounter === 5 || !this.state.isResendActive ? "not-allowed" : "pointer",
        opacity: this.state.resendCounter === 5 || !this.state.isResendActive ? 0.5 : 1,
        caretColor:"transparent",
        fontFamily:"SF Pro Text Medium"
      }}
      onClick={() => {
        console.log(text);
      }}
    >
      {text}
    </Typography>
  );
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const astrikPhoneNo = `******${this.state.phoneNumber.toString().slice(6, 10)}`;
    const inputField = this.state.isError? classes.inputErrorField : classes.inputField;
    // Customizable Area End
    return (
      // Customizable Area Start
      <Box
        component="div"
        sx={{
          width: "100%",
          minHeight:"100vh",
          height:"100%"
        }}
        style={{
          backgroundImage: `url(${physicianMedical})`,
          backgroundPosition: "top",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Box className={classes.homeCureContainer}>
          <img
            src={homecureTransparent}
            alt='HomeCure Logo'
            className={classes.homecureLogo}
            style={{ objectFit: 'contain' }}
          />
        </Box>
        <Box className={classes.backButton} onClick={this.handleBackButton}>
          <ArrowBackIosIcon data-test-id="go-back" style={{ width: "15px", height: "15px" }} />
        </Box>
        <Box>
          <Card className={classes.otpTransparentCard}>
            <CardContent className={classes.otpCardContent} style={{ flexDirection: "column" }}>
              <Typography variant="h6" gutterBottom className={classes.otpTitleWelcome} style={{ textAlign: "center" }}>
                <span className={classes.otpTextBold}>OTP</span> Verification
              </Typography>
              <Typography variant="h6" gutterBottom className={classes.subtitle} style={{ textAlign: "center" }}>
                Enter OTP
              </Typography>
              <Typography variant="h6" className={classes.inputLabel} style={{ textAlign: "center" }}>
                An 4- Digit code has been sent to {"\n"}+91{""}
                {astrikPhoneNo}
              </Typography>
              <Box className={classes.otpContainer}>
                {this.state.phoneOTP.map((value: any, index: any) => (
                  <TextField
                    key={index}
                    className={inputField}
                    variant="outlined"
                    size="small"
                    id={`otpInput${index}`}
                    data-test-id={`otpInput${index}`}                   
                    value={value}
                    onChange={(event) => this.handleChange(index, event)}
                    style={{ margin: "5px auto 0px", width: "52px", height:"52px" }}
                    inputProps={{
                      maxLength: 1,
                      style: {
                        textAlign: "center"
                      }
                    }}
                  />
                ))}
              </Box>
              <Box className={classes.errorMessage}>
                {this.state.isError && (
                  <FormHelperText className={classes.inputError}>
                    {this.state.errorMessage}
                  </FormHelperText>
                )}
              </Box>
              <CountDownTimer
                times={this.state.resendCounter}
                isResendActive={this.state.isResendActive}
                toggleResend={this.toggleResend}
              />
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                padding="8px 0px"
              >
                {this.generateResendComponent("Resend OTP", "resendOTP")}

                {this.generateResendComponent("Get OTP by Call", "resendOTPByCall")}
              </Box>
              <Button
                variant="contained"
                color="primary"
                data-test-id="verifyOtpBtn"
                className={classes.verifyOTP}
                onClick={() => this.verifyOTP()}
                style={{ textTransform: "capitalize" }}
              >
                Verify
              </Button>
            </CardContent>
          </Card>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(styles)(OTPInputAuth);
// Customizable Area End
