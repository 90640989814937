import * as React from "react";
// Customizable Area Start
import { withStyles, Box, Typography, Grid, Button, ThemeProvider, createTheme } from "@material-ui/core";
import CustomisableUserProfilesController, {
    Props
} from "./CustomisableUserProfilesController";
import { styles } from "./styles/CustomisableUserProfiles";
import { Notification, SOSImage, Setting, Edit } from "./assets"
import Sidebar from "../../../components/src/Sidebar.web";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff"
        }
    }
});
// Customizable Area End

export class CustomisableViewUserProfile extends CustomisableUserProfilesController {
    constructor(props: Props) {
        super(props);
    }

    // Customizable Area Start
    async componentDidMount() {
        await this.fetchUserProfileDatas();
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { address, city, district, postalCode } = this.state;
        const addressComponents = [address, city, district, postalCode];
        const formattedAddress = addressComponents.filter(component => component).join(', ');
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <ThemeProvider theme={theme}>
                    {!this.state.showHealthDetails ? (
                        <Grid container className={classes.leftWrapper}>
                            <Grid item xs={1}>
                                <Sidebar />
                            </Grid>
                            <Grid item xs={11} className={classes.rightWrapper}>
                                <Box className={classes.root}>
                                    <Box>
                                        <Typography variant="h6" className={classes.profileHeading}>
                                            Profile
                                        </Typography>
                                    </Box>
                                    <Grid container>
                                        <Grid item xl={4} md={6} sm={6} xs={12}>
                                            <Box className={classes.topPatientLeftContainer}>
                                                <Box className={classes.profileContainer}>
                                                    <img
                                                        className={classes.avatar}
                                                        src={this.state.profilePicture}
                                                    />
                                                </Box>
                                                <Box className={classes.profileDetailsContainer}>
                                                    <Typography variant="h6" className={classes.userName}>
                                                        {this.state.user_name}
                                                    </Typography>
                                                    <Typography variant="h6" className={classes.userPhoneNumber}>
                                                        {this.state.city} | +91{this.state.phoneNumber}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Box className={classes.boxBottomBorder} />
                                        </Grid>
                                        <Grid item xl={8} md={6} sm={6} xs={12}>
                                            <Box className={classes.topRightContainer}>
                                                <Box>
                                                    <img
                                                        alt="SOS"
                                                        className={classes.sosImage}
                                                        src={SOSImage}
                                                    />
                                                </Box>
                                                <Box data-test-id="editProfile" onClick={() => this.openEditProfiles()}>
                                                    <img
                                                        className={classes.editImage}
                                                        src={Edit}
                                                    />
                                                </Box>
                                                <Box>
                                                    <img
                                                        className={classes.notificationImage}
                                                        src={Notification}
                                                    />
                                                </Box>
                                                <Box>
                                                    <img
                                                        className={classes.settingImage}
                                                        src={Setting}
                                                    />
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xl={6} md={8} sm={8} xs={12}>
                                            <Box>
                                                <Box className={classes.subContainer}>
                                                    <Typography className={classes.userDetailsLabel}>
                                                        Age
                                                    </Typography>
                                                    <Typography className={classes.userDetailsColon}>
                                                        :
                                                    </Typography>
                                                    <Typography className={classes.userDetails}>
                                                        {this.state.userAge} y/o
                                                    </Typography>
                                                </Box>
                                                <Box className={classes.subContainer}>
                                                    <Typography className={classes.userDetailsLabel}>
                                                        Gender
                                                    </Typography>
                                                    <Typography className={classes.userDetailsColon}>
                                                        :
                                                    </Typography>
                                                    <Typography className={classes.userDetailsCaps}>
                                                        {this.state.gender}
                                                    </Typography>
                                                </Box>
                                                <Box className={classes.subContainer}>
                                                    <Typography className={classes.userDetailsLabel}>
                                                        Languages Known
                                                    </Typography>
                                                    <Typography className={classes.userDetailsColon}>
                                                        :
                                                    </Typography>
                                                    <Typography className={classes.userDetailsCaps}>
                                                        {this.state.languages}
                                                    </Typography>
                                                </Box>
                                                <Box className={classes.subContainer}>
                                                    <Typography className={classes.userDetailsLabel}>
                                                        Email Address
                                                    </Typography>
                                                    <Typography className={classes.userDetailsColon}>
                                                        :
                                                    </Typography>
                                                    <Typography className={classes.userDetails}>
                                                        {this.state.email}
                                                    </Typography>
                                                </Box>
                                                <Box className={classes.addressSubConatiner}>
                                                    <Typography className={classes.userDetailsLabel}>
                                                        Address
                                                    </Typography>
                                                    <Typography className={classes.userDetailsColon}>
                                                        :
                                                    </Typography>
                                                    <Typography className={classes.userDetailsCaps}>
                                                        {formattedAddress}
                                                    </Typography>
                                                </Box>
                                                <Box className={classes.boxTopBorder} />
                                                <Box className={classes.healthDetailsNameSubContainer}>
                                                    <Typography className={classes.userDetailsLabel}>
                                                        Health Details
                                                    </Typography>
                                                    <Typography className={classes.userDetailsColon}>
                                                        :
                                                    </Typography>
                                                    <Typography className={classes.userDetails}>
                                                        The symptoms and full details of the condition of the patient
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Box>
                                                <Button
                                                    data-test-id="HealthDetails"
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.healthDetailsButtons}
                                                    onClick={this.handleEditPatientHealthDetail}
                                                >
                                                    Health Details
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    ) : (
                        <Box>
                            {this.callHealthDetails}
                        </Box>
                    )}
                </ThemeProvider>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export default withStyles(styles)(CustomisableViewUserProfile);
// Customizable Area End