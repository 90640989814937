import * as React from "react";

// Customizable Area Start
import { Box, Typography, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

interface CountDownTimerProps {
  times: number;
  isResendActive?: boolean;
  toggleResend?: (arg0: boolean) => void;
}

const useStyles = makeStyles((theme:Theme) => ({
  counterStyle: {
    fontFamily:"SF Pro Text Regular",
    color:"#292929",
    textAlign: "center",
    fontSize: "18px",
    margin: "8px auto", 
    caretColor:"transparent"
  },
}));
// Customizable Area End

const CountDownTimer: React.FC<CountDownTimerProps> = ({
  // Customizable Area Start
  times,
  toggleResend,
  isResendActive,
  // Customizable Area End
}) => {
  // Customizable Area Start
  const initialSeconds = 59;
  const [seconds, setSeconds] = React.useState(59);
  const classes = useStyles();

  React.useEffect(() => {
    const timer = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        toggleResend && toggleResend(true);
        clearInterval(timer);
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [seconds, toggleResend]);

  const resetTimer = () => {
    if (seconds === 0) {
      setSeconds(initialSeconds);
    }
  };

  React.useEffect(() => {
    resetTimer();
  }, [times]);
  // Customizable Area End
  return (
    // Customizable Area Start
    <Box>
      <Typography data-test-id="counter" className={classes.counterStyle}>
        00:{seconds < 10 ? `0${seconds}`:seconds}
      </Typography>
    </Box>
    // Customizable Area End
  );
};

export default CountDownTimer;
