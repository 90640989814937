import React, { useState } from 'react';
// Customizable Area Start
import { Button, Grid, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { HomeScreen, HomeCureLogo, pexelsCottonbroStudio } from './assets';
import { setStorageData } from '../../../framework/src/Utilities';
// Customizable Area End

const UserRole = (props: { navigation: any }) => {
  // Customizable Area Start
  const classes = useStyles();
  const [buttonSelection, setButtonSelection] = React.useState<any>(
    'patient'
  );
  const [showForm, setShowForm] = useState(false);
  const { navigation } = props;

  setTimeout(() => {
    setShowForm(true);
  }, 3000);

  const handleButtonClick = async () => {
    setStorageData('userType', buttonSelection)
    navigation.navigate('MobileAccountLoginBlock')
  };
  // Customizable Area End

  return (
    // Customizable Area Start
    <Box className={classes.container}>
      {!showForm ? (<Box className={classes.homeCureContainer}>
        <img src={HomeScreen} alt='HomeCure Logo' className={classes.imageStyle} />
      </Box>
      ) : (
        <Grid container >
          <Grid item lg={6} md={6} sm={6} xs={12} className={classes.gridHeight}>
            <img
              src={HomeCureLogo}
              alt='HomeCure Logo'
              className={classes.homecureLogo}
            />
            <Box className={classes.cardContainer}>
              <Box className={classes.heading}>
                <Box data-testid="selectUserType" className={classes.userType}>
                  <span className={classes.textBold}>Hi,</span> Choose your user type
                </Box>
              </Box>
              <Button
                data-testid="patient"
                key="patient"
                variant='outlined'
                className={`${classes.selectionButton} ${buttonSelection === "patient" ? classes.selectedButton : ''}`}
                onClick={() => setButtonSelection("patient")}
              >
                Patient
              </Button>
              <Button
                data-testid="hospital"
                key="hospital"
                variant='outlined'
                className={`${classes.selectionButton} ${buttonSelection === "hospital" ? classes.selectedButton : ''}`}
                onClick={() => setButtonSelection("hospital")}
              >
                Hospital
              </Button>
              <Button
                data-testid="healthcare-personnel"
                key="healthcare_personnel"
                variant='outlined'
                className={`${classes.selectionButton} ${buttonSelection === "healthcare_personnel" ? classes.selectedButton : ''}`}
                onClick={() => setButtonSelection("healthcare_personnel")}
              >
                Healthcare Personnel
              </Button>
              <Button
                data-testid="typeBtn"
                variant='contained'
                color='primary'
                className={classes.customButton}
                onClick={handleButtonClick}
              >
                CONTINUE
              </Button>
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12} className={classes.gridHeight}>
            <Box className={classes.rightContainer} >
              <Box
                className={classes.rightContainerBackground}
              ></Box>
              <Typography variant='h6' data-testid="contactUs" className={classes.contactUs}>
                Contact Us
              </Typography>
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
    // Customizable Area End
  );
};

const useStyles = makeStyles((theme) => ({
  // Customizable Area Start
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  homeCureContainer: {
    backgroundColor: '#014866',
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  banner: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    objectPosition: "top"
  },
  rightContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    backgroundImage: `url(${pexelsCottonbroStudio})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    minHeight:"100vh",
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    }
  },
  rightContainerBackground:{
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.2)', 
    zIndex: 1,
    minHeight:"100vh",
  },
  heading: {
    marginBottom: '15px'
  },
  textOverImage: {
    position: 'absolute',
    top: '85px',
    right: '0%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1,
    color: '#fff',
    fontWeight: 'bold',
    fontSize: '20px',
  },
  imageStyle: {
    height: "100%",
    width: "100%",
    marginBottom: 8,
    objectFit: 'contain',
  },
  homecureLogo: {
    width: "200px",
    height:"70px",
    margin: 20,
    objectFit: 'contain',
    [theme.breakpoints.down('xs')]: {
      width: 180,
      margin: 10
    }
  },
  logoTitle: {
    marginBottom: 16,
    fontSize: "20px",
    color: '#fff',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  cardContainer: {
    display: 'flex',
    width: '100%',
    margin: 'auto',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    paddingTop: '150px',
    [theme.breakpoints.down('md')]: {
      paddingTop: '150px'
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: '100px'
    }
  },
  gridHeight:{
    height:"1080px",
    [theme.breakpoints.down('xs')]: {
      height: '800px'
    }
  },
  selectionButton: {
    color:"#000000",
    backgroundColor: '#F2F6F7',
    border: '1px solid #D9D9D9',
    borderRadius: "8px",
    padding: '10px 20px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    width: '320px',
    height:'60px',
    margin: '10px auto',
    textTransform: 'capitalize',
    fontSize: '18px',
    fontFamily:"SF Pro Text Semibold",
    [theme.breakpoints.down('xs')]: {
      width: '70%',
    }
  },
  selectedButton: {
    backgroundColor: '#AC8E68',
    color: '#FFFFFF',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    fontSize: '18px',
    fontFamily:"SF Pro Text Semibold",
    '&:hover': {
      backgroundColor: '#AC8E68',
      color: '#FFFFFF',
      fontFamily:"SF Pro Text Semibold",
    },
  },
  customButton: {
    backgroundColor: '#014866',
    color: '#FFFFFF',
    border: 'none',
    borderRadius: "8px",
    padding: '10px 20px',
    cursor: 'pointer',
    fontSize: '18px',
    fontFamily:"SF Pro Text Semibold",
    width: '320px',
    height:"60px",
    margin: '10px auto',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#014866',
      color: '#fff',
    },
    [theme.breakpoints.down('xs')]: {
      width: '70%',
    }
  },
  userType: {
      fontFamily: 'SF Pro Text Regular',
      color:"#292929",
      fontSize:"24px",
      textAlign: 'center',
      marginVertical: 16,
      caretColor:"transparent"
  },
  textBold: {
    caretColor:"transparent",
    fontFamily: 'SF Pro Text Bold !important',
    color:"#292929",
    fontSize:"24px"
  },
  contactUs: {
    top: "35px",
    right: "16px",
    color: "#FFFFFF",
    position: "absolute",
    zIndex: 1,
    fontSize:"16px",
    fontFamily:"SF Pro Text Semibold",
  }
  // Customizable Area End
}));

export default UserRole;

