import React from "react";
// Customizable Area Start
import Reservation2Controller, { Props } from "./Reservations2Controller";

import {
  Box,
  Grid,
  createTheme,
  InputAdornment,
  TextField,
  ThemeProvider,
  Typography,
  Button,
  Chip,
  withStyles,
  CardContent,
} from "@material-ui/core";
import Sidebar from "../../../components/src/Sidebar.web";
import { searchIcons, NoData, DownArrow, UserProfile, filterIcons, ProfilePicOne, ProfilePicTwo, NurseBanner, AyahBannner } from "./assets";
import { PositionValue, floatType, objectFitType, textTransform, FlexDirectionProperty} from "./types";
import CloseIcon from '@material-ui/icons/Close';
import '../../../web/src/fonts/stylesheet.css';
import StarIcon from '@material-ui/icons/Star';
import Filter from "../../filteritems/src/Filter.web"
import { getStorageData } from "../../../framework/src/Utilities";

const theme = createTheme({
  palette: {
    primary: {
      contrastText: "#fff",
      main: "#0000ff",
    }
  }
});


// Customizable Area End
export class Reservations2NurseBooking extends Reservation2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  async componentDidUpdate(prevProps: Props, prevState: any) {
    const { currentPage } = this.state;
    if (prevState.currentPage !== currentPage) {
        try {
          if (!this.state.isDataEmpty && !this.state.loading) { await this.getNursesList(); }
        } catch (error) {
            this.showAlert("Error fetching hospital data:", "Something went wrong");
        }
    }
  }

  onScroll = () => {
    const element = document.getElementById("test");

    if (element && !this.state.loading) {
      const { scrollTop, scrollHeight, clientHeight } = element;
      if (scrollTop > this.state.prevScrollTop && scrollHeight - scrollTop === clientHeight && this.state.nurseList?.length > 0) {
        this.setState(prevState => ({
          perPage: Math.max(10, prevState.perPage),
          currentPage: Math.max(1, prevState.currentPage + 1)
        }));
      }
      this.setState({ prevScrollTop: scrollTop });
    }
  };

  async componentDidMount() {
    try {
      await Promise.all([this.getNursesList()]);
    } catch (errors) {
      this.showAlert("Error fetching data:", "Something went wrong");
    }
    const serviceType = await getStorageData("serviceType");
    this.setState({ serviceType: serviceType === "Nurses" ? serviceType : `${serviceType}s` })
  }

  async componentWillUnmount() {
    const element = document.getElementById("test");
    if (element) {
      element.removeEventListener('scroll', this.onScroll);
    }
  }

  RenderBannerImage = (serviceType: string) => {
    return (
      <img src={serviceType === "Nurses" ? NurseBanner : AyahBannner} style={webStyles.bannerImg} alt="Banner" />
    )
  }

  RenderBannerDesc = (classes: any) => {
    const checkIfNurse = this.state.serviceType === "Nurses";
    return (
      <div className={classes.bannerTextDiv}>
        <p style={webStyles.bannerTitle}>{checkIfNurse ? "Nurses" : "Ayahs"}</p>
        <p className={classes.bannerDescription}>
          {checkIfNurse
            ? "Educated, trained and empathetic nurses for providing complete patient care."
            : "There are only two sorts of doctors those who practice with their brain."
          }
        </p>
      </div>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
         <ThemeProvider theme={theme}>
          <Grid container style={webStyles.container}>
            <Grid item xs={1}>
              <Sidebar />
            </Grid>
            <Grid item xs={11}>
              <Box style={webStyles.availbleContainer}>
                <Box style={webStyles.searchBarHolder}>
                  <Box style={{ width: "100%" }}>
                    <TextField
                      data-test-id="searchBarHP"
                      variant="outlined"
                      placeholder={`Search ${this.state.serviceType}...`}
                      className={classes.searchNurses}
                      onChange={(e)=>this.setState({searchNurse:e.target.value})}
                      onKeyPress={(event)=>this.searchNurseByName(event)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              src={searchIcons}
                              alt="search"
                              style={webStyles.searchingIcon}
                            />
                          </InputAdornment>
                        ),
                        style: {
                          borderRadius: "8px",
                          color: "#999999 !important",
                          fontFamily: "SF Pro Text Regular !important",
                          fontSize: "16px !important",
                        }
                      }}
                    />
                  </Box>
                  <Box>
                    <Box
                      style={this.state.showPopup?webStyles.hpFilterIconSelected:webStyles.hpFilterIcon}
                    >
                      <img data-test-id="hpToggleButton" src={filterIcons} onClick={this.togglePopup} />
                    </Box>
                    <Box>
                      {this.state.showPopup && (
                        <Filter navigation={this.props.navigation} id={""}/>
                      )}
                    </Box>
                  </Box>
                </Box>
                <Box className={classes.bannerHolder}>                  
                  {this.RenderBannerImage(this.state.serviceType)}
                  {this.RenderBannerDesc(classes)}
                </Box>
                <Box  style={{ width: "100%" }}>
                  <Typography variant="h6" style={webStyles.upcommingBookingText}>
                    Available {this.state.serviceType}
                  </Typography>
                  <Typography variant="h6" style={webStyles.subDescText}>
                    Please choose date and time slots to see available {this.state.serviceType?.toLowerCase()}
                  </Typography>
                  <Box style={webStyles.selectFilterHolder}>
                    <Box style={webStyles.selectParentContainer}>
                      <Typography style={webStyles.selectMenu}>Select Date
                        <img
                          src={DownArrow}
                          alt="DownArrow"
                          style={webStyles.hpDownArrowIcon}
                        /></Typography>
                      <Box
                        style={{ ...(this.state.dateAnchorEl ? webStyles.dateDropdownVisible : webStyles.dropdown) }}
                      >
                      </Box>
                    </Box>
                    <Box style={webStyles.selectParentContainer}>
                      <Typography style={webStyles.selectMenu}>Select Shift
                        <img
                          src={DownArrow}
                          alt="DownArrow"
                          style={webStyles.hpDownArrowIcon}
                        /></Typography>
                      <Box
                        style={{ ...(this.state.shiftsAnchorEl ? webStyles.shiftsDropdownVisible : webStyles.dropdown) }}
                      >
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    {this.state.selectedFilter?.map((name: string, index: number) => (
                      <Chip
                        key={index}
                        data-test-id={`deleteFilter${index}`}
                        label={name}
                        onDelete={() => { }}
                        deleteIcon={<CloseIcon style={{ color: "white", width: "20px" }} fontSize="small" />}
                        style={webStyles.chip}
                      />
                    ))}
                  </Box>
                  <Box className={classes.scrollContainer} style={{ height: "300px", overflow: this.state.nurseList?.length > 0 ? "auto" : "hidden", marginTop: "45px" }} id="test" onScroll={this.onScroll}>
                    {this.state.nurseList?.length > 0 ? (
                      <div id="nurseListBox" style={webStyles.availableNurseList}>
                        {this.state.nurseList?.map((user: any, index: number) => (
                          <Box key={index} className={classes.nurseRoot}>
                            <div data-test-id="navigateToNurseProfileDiv" onClick={() => this.navigateToNurseProfile(user.id)} style={webStyles.flexBox}>
                              <img style={webStyles.nurseCover} src={user.attributes?.profile_image_url?.url || UserProfile} title={user.name} />
                              <div style={webStyles.nurseDetails}>
                                <CardContent style={webStyles.nurseContent}>
                                  <div style={webStyles.nurseBox}>
                                    <div>
                                      <Typography style={webStyles.nurseName} component="h5" variant="h5">
                                        {user.attributes?.name}
                                      </Typography>
                                      <div style={webStyles.nursePriceShift}>
                                        <Typography style={webStyles.nurseFees} variant="h6">
                                          ₹{user.attributes?.price}
                                        </Typography>
                                        <Typography style={webStyles.nurseShift} variant="subtitle1" color="textSecondary">
                                          / {user?.shift || "Morning Shift"}
                                        </Typography>
                                      </div>
                                      <Typography variant="body2" style={webStyles.nurseExperience}>
                                        <span style={webStyles.experienceLabel}>Experience: </span> <span style={webStyles.experienceValue}>{user.attributes?.experience} years </span>
                                      </Typography>

                                    </div>
                                    <div style={webStyles.nurseRating}>
                                      <StarIcon />
                                      <Typography variant="body2" style={{ marginLeft: '4px' }}>
                                        <span style={webStyles.userRating}>{user?.rating || "4.2"}</span><span style={webStyles.userRatingOutOf}> / 5 </span>
                                      </Typography>
                                    </div>
                                  </div>
                                </CardContent>
                              </div>
                            </div>
                            <Box style={webStyles.btnContainer}>
                              <Button
                                variant="contained"
                                data-test-id={`bookNow`}
                                color="primary"
                                style={webStyles.BookNowBtn}
                                onClick={() => this.goToBookNurseNow(user.id)}
                              >
                                Book Now
                              </Button>
                            </Box>
                          </Box>
                        ))}
                      </div>
                    )
                      :
                      (
                        <>
                          {
                            this.state.loading ? (
                              <>
                                <Box data-test-id="nurseListLoaderTest" id="nurseListLoader" style={webStyles.loadingText}>Loading...</Box>
                              </>) : (
                              <>
                                <Box data-test-id="noDataBoxNurse" id="noDataBox" style={webStyles.noDataContainer}>
                                  <img
                                    src={NoData}
                                    width={60}
                                    height={60}
                                    style={webStyles.noDataIcons}
                                  />
                                  <Box style={webStyles.noDataText}>No results found</Box>
                                  <Box style={webStyles.subText} >Try adjusting or clearing your filtes to</Box>
                                  <Box style={webStyles.subText} >display better results.</Box>
                                </Box>
                              </>)
                          }</>
                      )}

                  </Box>
                      {this.state.nurseList?.length > 0 && this.state.loading && (
                         <Box id="nurseListLoader" style={webStyles.loadingText}>Loading...</Box>
                      )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </ThemeProvider>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyles = {
  container: {
    display: "flex",
  },
  availbleContainer: {
      margin: "40px 50px",
      width: "70%",
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column" as FlexDirectionProperty,
      justifyContent: "flex-start",
      alignItems: "flex-start",
      gap: "20px",
  },
  bannerHolder: {
    margin: "0px 0 20px",
    width: "100%",
    borderRadius: "14px",
    position: "relative" as PositionValue,
    top: "-25px",
    left: 0,
    bottom: 0,
    right: 0,
    objectFit: "contain" as "contain",
    '@media (min-width: 1051px)': {
      height: "250px",
    },
    '@media (max-width: 1050px)': {
      height: "220px",
      marginBottom: 0,
    },
  },
  bannerImg: {
    position: 'absolute' as PositionValue,
    left: 0,
    bottom: 0,
    right: 0,
    width: "100%",
    height: "100%",
    borderRadius: "8px"
  },
  bannerTextDiv: {
    position: 'absolute' as PositionValue,
    left: '22px',
    color: 'white',
    zIndex: 1,
    '@media (min-width: 1050px)': {
      top: '52%',
      },
    '@media (max-width: 1050px)': {
      top: "48%",
      },
  },
   bannerTitle: {
    fontFamily: 'SF Pro Text Bold',
    fontSize: '22px',
    fontWeight: 600,
    lineHeight: '26.25px',
    marginTop: 0,
    marginBottom: "10px",
    letterSpacing: '-0.6285714507102966px',
  },
  bannerDescription: {
    fontFamily: 'SF Pro Text Regular',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '24px',
    margin: 0,
    letterSpacing: '-0.5142855048179626px',
    textAlign: 'left' as 'left',
    maxWidth: "80%",
    '@media (max-width: 1050px)': {
      maxWidth: "60%",
      },
  },
  availableNurseList: {
    display: "flex",
    flexDirection: "column" as FlexDirectionProperty,
    gap: "25px",
    minHeight: "300px",
    maxHeight: "500px",
    '@media (min-width: 1051px)': {
      width: "calc(100% - 100px) !important",
      },
      '@media (max-width: 1050px)': {
        width: '100% !important',
      },
  },
  searchBarHolder: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%"
  },
  searchNurses: {
    width: "93.5%",
    margin: "15px 0px",
    "& .MuiInputBase-root": {
      fontFamily: "SF Pro Text Regular !important",
      fontSize: "16px !important",
      color: "#292929 !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "8px !important",
    },
    '&::placeholder': {
      fontFamily: "SF Pro Text Regular !important",
      fontSize: "16px !important",
      color: '#999999 !important',
      opacity: 1,
    },
    '&::-webkit-input-placeholder': {
      fontFamily: "SF Pro Text Regular !important",
      fontSize: "16px !important",
      color: '#999999 !important',
      opacity: 1,
    },
  },
  searchingIcon: {
    width: "25px",
    height: "25px",
    objectFit: "cover" as objectFitType,
    display: "flex",
    justifyContent: "center",
    margin: "0px 15px 0px 0px"
  },
  selectedIcon: {
    width: "18px",
    height: "18px",
    display: "flex",
    justifyContent: "center",
    margin: "20px auto 0px -45px",
    backgroundColor: "white",
    zIndex: 1000,
    padding: "13px",
    borderRadius: "8px",
    position:"absolute" as PositionValue,
    caretColor:"white"
  },
  settingIcon: {
    width: "18px",
    height: "18px",
    display: "flex",
    justifyContent: "center",
    margin: "20px auto 0px -45px",
    backgroundColor: "white",
    zIndex: 1000,
    padding: "13px",
    borderRadius: "8px",
    caretColor:"white"
  },
  upcommingBookingText: {
    fontSize: "20px",
    color: "#4C4C4C",
    fontFamily: "SF Pro Text Semibold",
    fontWeight: 600,
    marginBottom: "12px",
    caretColor:"transparent"
  },
  subDescText: {
    fontFamily: 'SF Pro Text Regular',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '19.09px',
    color: '#808080',
  },
  BookNowBtn: { 
    caretColor:"transparent",
    backgroundColor: "#014866",
    color: "#fff",
    border: "none",
    borderRadius: "8px",
    width:"170px",
    height:"52px",
    cursor: "pointer",
    fontSize: "18px",
    fontFamily:"SF Pro Text Bold",
    marginRight: "20px",
    "&:hover": {
      backgroundColor: "#014866",
      color: "#fff"
    },
    textTransform: "capitalize" as textTransform,
    float:"right" as floatType,
    '@media (max-width:1250px)': {
      width: "160px !important",
    },
  },
  chip: 
  {
    "& .MuiChip-label":{
      paddingLeft: "8px !important",
      paddingRight: "8px !important"
    },
    margin: "2px 25px 5px 0px",
    alignItems: "center",
    padding: "20px 12px",
    fontSize: "14px",
    fontWeight: 400,
    fontFamily:"SF Pro Text Regular",
    background: "#FF0000",
    color: "white",
    borderRadius: "7px",
    height:"34px",
    minWidth: "124px",
    caretColor:"transparent",
    lineHeight: "16.71px",
    "& .MuiChip-deletable:focus":{
        color: "white",
        borderRadius: "7px",
        caretColor:"transparent",
        backgroundColor: "#FF0000",
    },
    "& .MuiChip-deleteIcon":{
      width:"20px",
      height:"20px",
      fontFamily:"SF Pro Text Regular",
    },
  },
  noDataContainer:{
    width:"362px",
    minHeight: "200px",
    display:"block",
    margin:"40px auto 0px"
  },
  noDataIcons:{
    display:"flex",
    margin:"auto"
  },
  noDataText:{
    fontSize:"20px",
    fontFamily:"SF Pro Text Medium",
    fontWeight: 500,
    color:"#808080", 
    width:"100%",
    display:"flex",
    justifyContent:"center",
    margin:"8px auto",
  },
  loadingText: {
    fontSize:"20px",
    fontFamily:"SF Pro Text Medium",
    fontWeight: 500,
    color:"#808080", 
    width:"100%",
    display:"flex",
    justifyContent:"center",
    margin:"10px auto 0",
  },
  subText:{
    fontSize:"20px",
    fontFamily:"SF Pro Text Medium",
    color:"#808080", 
    width:"100%",
    display:"flex",
    justifyContent:"center", 
    margin:"auto",
    opacity: "0.29"
  },
  btnContainer:{
    marginLeft:"40px",
  },
  hpDownArrowIcon: {
    width: "18px",
    height: "14px",
    objectFit: "cover" as objectFitType,
    cursor: "pointer",
    transform: 'translate(-6px, -1px)'
  },
  selectMenu: {
    width: '140px',
    height: '52px',
    border: '1px solid #808080',
    color: '#808080',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 15px',
    fontFamily: 'SF Pro Text Regular',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '21.48px',
    cursor: 'pointer'
  },
  selectParentContainer: {
    position: 'relative' as PositionValue,
  },
  selectFilterHolder: {
    lineHeight: '26.8px',
    margin: "25px 0px 10px",
    display: 'flex',
    alignItems: 'center',
    gap: '30px',
    padding: "10px 0px"
  },
  flexBox: {
    display: 'flex',
    width: '100%',
    boxShadow: '0px 0px 40px #B3B3B31F',
    border: '1px solid #80808021',
    borderRadius: "10px",
    alignItems: 'center',
    padding: "5px 24px",
  },
  nurseRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    boxShadow: 'none',
    maxWidth: '758px',
    cursor: 'pointer',
  },
  nurseDetails: {
    display: 'flex',
    flexDirection: 'column' as FlexDirectionProperty,
    width: "100%",
    justifyContent: 'center',
  },
  nurseContent: {
    display: 'flex',
    width: "100%"
  },
  nurseCover: {
    width: "85px",
    height: "85px",
    borderRadius: '50%',
    margin: theme.spacing(1),
  },
  nurseName: {
    fontFamily: 'SF Pro Text Bold',
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '23.87px',
    letterSpacing: '-0.48235294222831726px',
    color: '#292929',
    textTransform: "capitalize" as "capitalize",
  },
  nurseFees: {
    fontFamily: 'SF Pro Text Bold',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '19.09px',
    letterSpacing: '-0.38588234782218933px',
    color: '#191B1A',
    marginRight: "5px"
  },
  nurseShift: {
    fontFamily: 'SF Pro Text Regular',
    fontSize: '16px',
    fontWeight: 300,
    lineHeight: '19.09px',
    letterSpacing: '-0.38588234782218933px',
    color: '#191B1A',
    opacity: 0.5
  },
  experienceLabel: {
    fontFamily: 'SF Pro Text Regular',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16.71px',
    letterSpacing: '-0.34px',
    color: '#191B1A',
  },
  experienceValue: {
    fontFamily: 'SF Pro Text Medium',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16.71px',
    letterSpacing: '-0.34px',
    color: '#191B1A',
  },
  nurseRating: {
    display: 'flex',
    alignItems: 'center',
    color: '#4CAF50',
    fontWeight: 600,
  },
  nurseExperience: {
    marginTop: "15px",
    color: '#808080',
  },
  dropdown: {
    display: 'none',
  },
  shiftsDropdownVisible: {
    display: 'block',
    position: 'absolute' as PositionValue,
    top: '60px',
    left: '0',
    width: '180px',
    height: '200px',
    background: 'white',
    zIndex: 10,
    boxShadow: '0px 0px 40px rgba(0,0,0,0.2)',
    border: '1px solid #80808021',
    borderRadius: "10px",
  },
  dateDropdownVisible: {
    display: 'block',
    position: 'absolute' as PositionValue,
    top: '60px',
    left: '0',
    width: '170px',
    height: '200px',
    background: 'white',
    zIndex: 10,
    boxShadow: '0px 0px 40px rgba(0,0,0,0.2)',
    border: '1px solid #80808021',
    borderRadius: "10px",
  },
  nurseButton: {
    alignSelf: 'center',
    backgroundColor: '#00678F',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#00506F',
    },
  },
  nursePriceShift: {
    display: 'flex',
    alignItems: 'center',
    marginTop: "15px",
  },
  nurseBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: "calc(100% - 25px)",
  },
  userRating: {
    fontFamily: 'SF Pro Text Medium',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '16.71px',
    letterSpacing: '-0.02800000086426735px',
    color: '#014866',
    marginLeft: '8px',
  },
  userRatingOutOf: {
    fontFamily: 'SF Pro Text Medium',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '16.71px',
    letterSpacing: '-0.02800000086426735px',
    color: '#3780E0',
  },
  hpFilterIconSelected: {
    width: "18px",
    height: "18px",
    display: "flex",
    justifyContent: "center",
    margin: "20px auto 0px -45px",
    backgroundColor: "white",
    zIndex: 1000,
    padding: "13px",
    borderRadius: "8px",
    position:"absolute" as PositionValue,
    caretColor:"white"
  },
  hpFilterIcon: {
    width: "18px",
    height: "18px",
    display: "flex",
    justifyContent: "center",
    margin: "20px auto 0px -45px",
    backgroundColor: "white",
    zIndex: 1000,
    padding: "13px",
    borderRadius: "8px",
    caretColor:"white"
  },
  scrollContainer: {
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
    "&& ::-webkit-scrollbar": {
      display: "none"
    },
  }
};

export default withStyles(webStyles)(Reservations2NurseBooking);
// Customizable Area End

