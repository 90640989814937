import * as React from "react";
// Customizable Area Start
import { Box, Typography, Card, CardContent, TextField, Button, FormHelperText, withStyles } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { homecureTransparent, physicianMedical } from "./assets";
import MobileAccountLoginController, {
    Props,
} from "./MobileAccountLoginController";
import { styles } from "./styles/MobileAccountLoginBlock";
// Customizable Area End

export class MobileAccountLoginBlock extends MobileAccountLoginController {
    constructor(props: Props) {
        super(props);
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const InputField = this.state.isError ? classes.mobileErrorInputField : classes.mobileInputField;
        // Customizable Area End
        return (
            // Customizable Area Start
            <Box
                component="div"
                sx={{
                    width: "100%",
                    minHeight:"100vh",
                    height:"100%"
                }}
                style={{
                    backgroundImage: `url(${physicianMedical})`,
                    backgroundPosition: "top",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                }}
            >
                <Box className={classes.mobileHomeCureContainer}>
                    <img
                        src={homecureTransparent}
                        alt='HomeCure Logo'
                        className={classes.mobileHomecureLogo}
                        style={{ objectFit: 'contain' }}
                    />
                </Box>
                <Box className={classes.mobileBackButton} data-test-id="go-back" onClick={this.handleBackButton}>
                    <ArrowBackIosIcon data-test-id="go-back" className={classes.arrowIcon} onClick={this.handleBackButton} />
                </Box>
                <Box>
                    <Card className={classes.mobileTransparentCard}>
                        <CardContent className={classes.mobileCardContent} style={{ flexDirection: "column" }}>
                            <Box className={classes.mobileTitleWelcome} style={{ flexDirection: "column", textAlign: "center" }}>
                                <span className={classes.mobileTextBold}>Hello!</span> Welcome Back
                            </Box>
                            <Typography variant="h6" gutterBottom className={classes.mobileSubtitle} style={{ textAlign: "center" }}>
                                Login to your account
                            </Typography>
                            <Typography variant="h6" className={classes.mobileInputLabel} style={{ textAlign: "left" }}>
                                Phone Number
                            </Typography>
                            <TextField
                                data-test-id="phone-login"
                                variant="outlined"
                                className={InputField}
                                placeholder="Enter your phone number"
                                value={this.state.mobileNo}
                                onChange={this.handelChangePhoneNumber}
                                InputProps={{
                                    classes: {
                                        notchedOutline: this.state.errorMessage ? classes.mobileErrorOutline : classes.mobileNotchedOutline
                                    }
                                }}
                            />
                            {this.state.isError && (
                                <FormHelperText className={classes.mobileInputError}>
                                    {this.state.errorMessage}
                                </FormHelperText>
                            )}
                            <Button
                                data-test-id="login-btn"
                                variant="contained"
                                color="primary"
                                className={classes.mobileLoginButton}
                                onClick={() => this.signupViaContactNumber()}
                                style={{ textTransform: "none" }}
                            >
                                Log in
                            </Button>
                            <Typography variant="body1" gutterBottom className={classes.mobileLink} style={{ textAlign: "center" }}>
                                Don't have an account?{" "}
                                <Typography data-test-id="switch-to-register" className={classes.mobileRegisterLink}
                                    style={{ textDecoration: "none" }} onClick={this.openRegistrationPage}>
                                    Register
                                </Typography>
                            </Typography>
                        </CardContent>
                    </Card>
                </Box>
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export default withStyles(styles)(MobileAccountLoginBlock);
// Customizable Area End