// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from "firebase"
import { connect } from "react-firebase"

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from "../../blocks/info-page/src/InfoPageBlock";
import AlertBlock from "../../blocks/alert/src/AlertBlock.web";
import UserRole from "../../blocks/rolesandpermissions/src/UserRole.web";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock.web";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth.web";
import OTPVerified from "../../blocks/otp-input-confirmation/src/OTPVerified.web";
import PhoneNumberRegistration from "../../blocks/email-account-registration/src/PhoneNumberRegistration.web";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration.web";
import EmailVerified from "../../blocks/email-account-registration/src/EmailVerified.web";
import PatientRegisterDetails from "../../blocks/email-account-registration/src/PatientRegisterDetails.web";
import PatientHealthDetails from "../../blocks/email-account-registration/src/PatientHealthDetails.web";
import RegistrationSuccess from "../../blocks/email-account-registration/src/RegistrationSuccess.web";
import HospitalRegisterDetails from "../../blocks/email-account-registration/src/HospitalRegisterDetails.web";
import HospitalRegistrationSuccess from "../../blocks/email-account-registration/src/HospitalRegistrationSuccess";
import ConfirmEmail from "../../blocks/email-account-registration/src/ConfirmEmail.web";
import HealthCarePersonalRegistrationType from "../../blocks/email-account-registration/src/HealthCarePersonalRegistrationType.web";
import HealthCarePersonalRegistration from "../../blocks/email-account-registration/src/HealthCarePersonalRegistration.web";
import HealthCarePersonnelAyahRegistration from "../../blocks/email-account-registration/src/HealthCarePersonnelAyahRegistration.web";
import CustomisableViewUserProfile from "../../blocks/customisableuserprofiles/src/CustomisableViewUserProfile.web";
import CustomisableEditUserProfile from "../../blocks/customisableuserprofiles/src/CustomisableEditUserProfile.web";
import CustomisableHealthDetailsUserProfiles from "../../blocks/customisableuserprofiles/src/CustomisableHealthDetailsUserProfiles.web";
import HealthCarePersonnelRegistration from "../../blocks/email-account-registration/src/HealthCarePersonnelRegistration.web";
import Dashboard from "../../blocks/dashboard/src/Dashboard.web";
import CustomisableHPViewUserProfile from "../../blocks/customisableuserprofiles/src/CustomisableHPViewUserProfile.web";
import CustomisableViewHospitalProfile from "../../blocks/customisableuserprofiles/src/CustomisableViewHospitalProfile.web";
import CustomisableEditHospitalProfile from "../../blocks/customisableuserprofiles/src/CustomisableEditHospitalProfile.web";
import CustomisableEditHealthcarePersonnel from "../../blocks/customisableuserprofiles/src/CustomisableEditHealthcarePersonnel.web";
import Reservations2Booking from '../../blocks/reservations2/src/Reservations2Booking.web';
import Reservations2ViewHospitalBooking from '../../blocks/reservations2/src/Reservations2ViewHospitalBooking.web';
import Reservations2HospitalBooking from "../../blocks/reservations2/src/Reservations2HospitalBooking.web";
import Reservations2NurseBooking from '../../blocks/reservations2/src/Reservations2NurseBooking.web';
import Scheduling from "../../blocks/scheduling/src/Scheduling.web";
import Reservations2NurseProfile from '../../blocks/reservations2/src/Reservations2NurseProfile.web';
import Reservations2NurseProfileReviews from '../../blocks/reservations2/src/Reservations2NurseProfileReviews.web';
import Reservations2ViewNurseBooking from '../../blocks/reservations2/src/Reservations2ViewNurseBooking.web';
import LocationView from "../../blocks/location/src/LocationView.web"
import Filter from "../../blocks/filteritems/src/Filter.web";
import SchedulingCalendar from "../../blocks/scheduling/src/SchedulingCalendar.web";

const routeMap = {
  Home: {
    component: HomeScreen,
    path: "/",
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: "/InfoPage"
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },

  UserRole: {
    component: UserRole,
    path: "/UserRole",
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: "/MobileAccountLogin",
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
  },
  OTPVerified: {
    component: OTPVerified,
    path: "/OTPVerified"
  },
  PhoneNumberRegistration: {
    component: PhoneNumberRegistration,
    path: "/PhoneNumberRegistration"
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "/EmailAccountRegistration"
  },
  EmailVerified: {
    component: EmailVerified,
    path: "/EmailVerified"
  },
  PatientRegisterDetails: {
    component: PatientRegisterDetails,
    path: "/PatientRegisterDetails"
  },
  PatientHealthDetails: {
    component: PatientHealthDetails,
    path: "/PatientHealthDetails"
  },
  RegistrationSuccess: {
    component: RegistrationSuccess,
    path: "/RegistrationSuccess"
  },
  HospitalRegisterDetails: {
    component: HospitalRegisterDetails,
    path: "/HospitalRegisterDetails"
  },
  HospitalRegistrationSuccess: {
    component: HospitalRegistrationSuccess,
    path: "/HospitalRegistrationSuccess"
  },
  ConfirmEmail: {
    component: ConfirmEmail,
    path: "/ConfirmEmail"
  },
  HealthCarePersonalRegistrationType: {
    component: HealthCarePersonalRegistrationType,
    path: "/HealthCarePersonalRegistrationType"
  },
  HealthCarePersonalRegistration: {
    component: HealthCarePersonalRegistration,
    path: "/HealthCarePersonalRegistration"
  },
  HealthCarePersonnelAyahRegistration:{
    component: HealthCarePersonnelAyahRegistration,
    path: "/HealthCarePersonnelAyahRegistration"
  },
  CustomisableViewUserProfile: {
    component: CustomisableViewUserProfile,
    path: "/Profile",
  },
  CustomisableEditUserProfile: {
    component: CustomisableEditUserProfile,
    path: "/EditProfile",
  },
  CustomisableHealthDetailsUserProfiles: {
    component: CustomisableHealthDetailsUserProfiles,
    path: "/EditHealthDetails",
  },
  HealthCarePersonnelRegistration:{
    component: HealthCarePersonnelRegistration,
    path: "/HealthCarePersonnelRegistration"
  },
  Dashboard:{
    component: Dashboard,
    path: "/Dashboard"
  },
  CustomisableViewHospitalProfile:{
    component: CustomisableViewHospitalProfile,
    path: "/HospitalProfile"
  },
  CustomisableEditHospitalProfile:{
    component: CustomisableEditHospitalProfile,
    path: "/UpdateHospitalProfile"
  },
  CustomisableHPViewUserProfile:{
    component: CustomisableHPViewUserProfile,
    path: "/HealthcarePersonneProfile"
  },
  CustomisableEditHealthcarePersonnel:{
    component: CustomisableEditHealthcarePersonnel,
    path: "/UpdateHealthcarePersonneProfile"
  },
  Reservations2Booking:{
    component: Reservations2Booking,
    path: "/PatientHospitalBooking"
  },
  Reservations2NurseProfile: {
    component: Reservations2NurseProfile,
    path: "/ViewNurseProfile"
  },
  Reservations2NurseProfile: {
    component: Reservations2NurseProfile,
    path: "/ViewNurseProfile/:navigationBarTitleText"
  },
  Reservations2NurseProfileReviews: {
    component: Reservations2NurseProfileReviews,
    path: "/nurseReviews/:navigationBarTitleText"
  },
  Reservations2ViewNurseBooking: {
    component: Reservations2ViewNurseBooking,
    path: "/bookNurse/:navigationBarTitleText"
  },
  Reservations2ViewHospitalBooking:{
    component: Reservations2ViewHospitalBooking,
    path: "/ViewHospitalBooking/:id"
  },
  Reservations2HospitalBooking:{
    component: Reservations2HospitalBooking,
    path: "/bookHospital/:id"
  },
  Reservations2NurseBooking:{
    component: Reservations2NurseBooking,
    path: "/PatientNurseBooking"
  },
  Scheduling:{
    component: Scheduling,
    path: "/Scheduling"
  },
  LocationView:{
    component: LocationView,
    path: "/LocationView"
  },
  Filter:{
    component: Filter,
    path: "/Filter"
  },
  SchedulingCalendar:{
    component: SchedulingCalendar,
    path: "/SchedulingCalendar"
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent("APP_Loaded");
    
    return (
      <View style={{ maxWidth: '3840px', height:"auto", margin: "auto" }}>
        {/* <TopNav /> */}
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
