import * as React from "react";
// Customizable Area Start
import { Box, Button, FormHelperText, Grid, TextField, Typography, withStyles } from "@material-ui/core";
import LocationController, { Props, configJSON } from "./LocationController";
import { mappls } from 'mappls-web-maps';
import { pexelsKarolina, homecureTransparent } from "./assets";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { styles } from "./styles/LocationView";
// Customizable Area End

export class LocationView extends LocationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.mapObject = null;
        this.mapContainerRef = React.createRef();
        this.marker = null; // Initialize marker to null
        // Customizable Area End
      }
    
      // Customizable Area Start
      async componentDidMount() {
        this.loadMapMyIndiaOnUI()
      }

      loadMapMyIndiaOnUI= async()=>{
        const mapProps = {
          center: [28.6330, 77.2194],
          traffic: false,
          zoom: 4,
          geolocation: false,
          clickableIcons: false,
        };

        const mapplsClassObject = new mappls();

        mapplsClassObject.initialize(configJSON.secretKey, () => {
          this.mapObject = mapplsClassObject?.Map({
              id: this.mapContainerRef.current?.id as string,
              properties: mapProps
          });

          // Add click event listener to the map
          this.mapObject.on('click', this.onMapClick);
        });
        //FOR LACATION ALREADY SELECTED
        if(this.props?.latitude !== "" && this.props?.longitude !== ""){
          this.mapObject = mapplsClassObject?.Map({
            id: this.mapContainerRef?.current?.id as string,
            properties: mapProps
          });
          this.mapObject?.on('click', this.onMapClick)
          this.markOnSelectedLocation(this.props?.latitude,this.props?.longitude)
        }
      }
    
      onMapClick = (event: any) => {
        let lat = null;
        let lng = null;
        
        if (event.lngLat) {
            if (typeof event.lngLat.lat === 'function') {
                lat = event.lngLat.lat();
            } else {
                lat = event.lngLat.lat;
            }
        
            if (typeof event.lngLat.lng === 'function') {
                lng = event.lngLat.lng();
            } else {
                lng = event.lngLat.lng;
            }
        }
        
  
          if (lat !== null && lng !== null) {
              this.markOnSelectedLocation(lat,lng)
              this.getAddress(lat, lng);
          }
      };

      markOnSelectedLocation=(lat:number,lng:number)=>{
        if (this.marker) {
          //REMOVE SELETED MARKER
          this.marker.remove({ map: this.mapObject, layer: this.marker });
          this.marker = null; // Reset marker
        }
        if (lat !== null && lng !== null) {
          //ADD NEW MARKER
          const mapplsClassObject = new mappls();
          this.marker = mapplsClassObject.Marker({
              map:  this.mapObject,
              position:{lat:lat, lng:lng},
              });
          this.getAddress(lat,lng)
        }
      }

      showLocationErrorField = (error: string, classes: any) => {
        return (error ? classes.errorAddressInputFields : classes.addressInputFields)
      };
      // Customizable Area End
   
    render() {
        // Customizable Area Start        
        const { classes } = this.props;
        const styleMap = {
            width: '99%',
            height: '52vh',
            display: 'inline-block',
            borderRadius: "15px"
        };
        // Customizable Area End

        return (
            // Customizable Area Start
            <Grid container style={{ position: "fixed" }}>
            <Grid item className={classes.hideContainers} xl={4} md={4} sm={12} xs={12} >
              <Box
                component="div"
                sx={{
                  height: "100vh"
                }}
                style={{
                  backgroundImage: `url(${pexelsKarolina})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <Box className={classes.homeCureLogosContainer}>
                  <img
                    src={homecureTransparent}
                    alt="HomeCure Logo"
                    className={classes.homecureLogos}
                    style={{ objectFit: "contain" }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xl={8} md={8} sm={12} xs={12} className={`${classes.gridLocationContainer} ${classes.fixedLocationGrid}`} style={{position:"sticky"}}>
              <Box component="div"
                sx={{
                  margin: "35px",
                  height: "100vh",
                }}>
                <Box className={classes.healthDetailsSubContainer}>
                  <Box className={classes.setLocationBackButtons} onClick={this.backToRegister}>
                    <ArrowBackIosIcon className={classes.arrowIcon} onClick={this.backToRegister} />
                  </Box>
                  <Box>
                    <Typography variant='h6' className={classes.setLocationHeadings}>
                      Set Location
                    </Typography>
                  </Box>
                </Box>
                <Box position="relative" width="100%" height="52%">
                    <Box>
                        <div>
                          <div id="map" ref={this.mapContainerRef} style={styleMap}></div>
                        </div>
                    </Box>
                </Box>
                <Box display="flex" flexDirection="column" alignItems="left" mb="20px">
                  <Typography variant="h6" className={classes.AddressInputLabels} style={{ textAlign: "left" }}>
                    Your Location
                  </Typography>
                   <TextField
                    variant="outlined"
                    data-test-id="locationAddress"
                    className={this.showLocationErrorField(this.state.error,classes)}
                    placeholder="Select your location"
                    value={this.state.address}
                    disabled
                  />  
                 {this.state.error && <FormHelperText className={classes.textError}>{this.state.error}</FormHelperText>} 
                  <Button
                    data-test-id="setAddress"
                    variant="contained"
                    color="primary"
                    style={{ textTransform: "capitalize" }}
                    className={classes.setLocationButtons}
                    onClick={this.setAddress}
                  >
                    Confirm Location
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
            // Customizable Area End
        );
    }
}

export default withStyles(styles)(LocationView);
