import React from "react";
// Customizable Area Start
import Reservation2Controller, { Props } from "./Reservations2Controller";
import {
  withStyles,
  Typography,
  Grid,
  Box,
  Avatar,
  Button,
  TextField,
  ThemeProvider,
  createTheme,
  InputAdornment,
  Checkbox,
  ClickAwayListener,
  SvgIcon,
  FormHelperText,
  Dialog,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import DatePicker from "react-datepicker";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { FlexDirectionProperty, OverflowXProperty, PositionValue, textAlignType } from "./types";
import Sidebar from "../../../components/src/Sidebar.web";
import { Calender, DownArrow, UserProfile } from "./assets";

const theme = createTheme({
  palette: {
    primary: {
      contrastText: "#fff",
      main: "#0000ff",
    }
  }
});

const CustomCheckedIcon = () => (
  <SvgIcon viewBox="0 0 18 18" fill="none" width="16" height="16"  xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#014866" />
    <path d="M3.75 7.99992L6.58 10.8299L12.25 5.16992" stroke="#014866" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </SvgIcon>
);

const CustomUncheckedIcon = () => (
  <SvgIcon viewBox="0 0 18 18" fill="none" width="16" height="16" xmlns="http://www.w3.org/2000/svg">
  <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#014866"/>
  </SvgIcon>
);

const scheduleTypes = [
  { key: "one_time", label: "One Time" },
  { key: "recurring", label: "Recurring" },
  { key: "long_term", label: "Long Term" },
];

// Customizable Area End
export class Reservations2ViewNurseBooking extends Reservation2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  getTomorrowDate(todayDate: any) {
    const tomorrow = new Date(todayDate);
    tomorrow.setDate(todayDate.getDate() + 1);
    return tomorrow;
  }

  async componentDidMount() {
    await this.getProfileHPData();
  }

  // Customizable Area End

  render() {
  // Customizable Area Start
    const { classes } = this.props;

    const todayDate = new Date();
    const tomorrowDate = this.getTomorrowDate(todayDate);

    const { shiftAvailabilities } = this.state;
  // Customizable Area End

    return (
      // Customizable Area Start
        <ThemeProvider theme={theme}>
          <Grid container style={webStyles.bookNurseProfileContainer}>
            <Grid item xs={1}>
              <Sidebar />
            </Grid>
            <style>
              {`
                .react-datepicker-wrapper {
                  width: 100%;
                },                
              `}
            </style>
            <Grid item xs={11}>
              <Box style={webStyles.bookNurseContainer}>
                <Box style={webStyles.bookNurseHeaderContainer}>
                  <Box style={webStyles.bookNurseHeading}>
                    <ArrowBackIosIcon
                      style={webStyles.bookNurseBackButton}
                      data-test-id="bookNurseBackBtn"
                      onClick={this.goToViewNurses}
                    />
                    <Typography variant="h6" style={webStyles.bookNurseTitle}>
                      Book Now
                    </Typography>
                  </Box>
                </Box>
                <Box style={webStyles.bookNurseMainBox}>
                  <Box style={webStyles.bookNurseInfo}>
                    <Avatar alt={this?.state?.nurseData?.attributes?.name} src={this?.state?.nurseData?.attributes?.profile_image_url?.url || UserProfile} style={webStyles.avatar} />
                    <Box>
                      <Typography style={webStyles.bookNurseName} variant="h5">{this?.state?.nurseData?.attributes?.name || "N/A"}</Typography>
                      <Typography style={webStyles.bookNurseDesc} color="textSecondary">{this?.state?.nurseData?.attributes?.address?.data?.attributes?.city || "N/A"}</Typography>
                    </Box>
                  </Box>
                </Box>
                <Grid container>
                  <Grid item xl={12} md={12} xs={12}>
                    <Box style={webStyles.fieldContainer}>
                      <Typography variant="body1" style={webStyles.label}>
                        Schedule Type<span style={webStyles.required}>*</span>
                      </Typography>
                      <Box style={webStyles.shiftTypeBox}>
                        {scheduleTypes.map((type: any) => (
                          <Button
                            key={type.key}
                            style={
                              this.state.scheduleType === type.key
                                ? webStyles.shiftTypeRadioButtonSelected
                                : webStyles.shiftTypeRadioButton
                            }
                            data-test-id="scheduleTypeBtn"
                            onClick={() => this.handleScheduleType(type.key)}
                          >
                            <Typography style={this.state.scheduleType === type.key ? webStyles.selectedShiftTypeLabel : webStyles.shiftTypeLabel}>{type.label}</Typography>
                          </Button>
                        ))}
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xl={5} md={6} sm={12} xs={12}>
                    <Box style={webStyles.fieldContainer}>
                      <Typography variant="h4" style={webStyles.label}>
                        Patient Name<span style={webStyles.requiredField}>*</span>
                      </Typography>
                      <TextField
                        variant="outlined"
                        data-test-id="patientHpName"
                        className={classes.bookingRegisterInputFields}
                        placeholder="Enter the name"
                        value={this.state.patientName}
                        onChange={(event) => this.handleChange("patientName", event.target.value)}
                      />
                      {this.state.hpErrors.patientName && <FormHelperText className={classes.textErrors}>{this.state.hpErrors.patientName}</FormHelperText>}
                    </Box>
                  </Grid>


                  {this.state.scheduleType !== 'long_term' && (
                    <>
                      <Grid item xl={5} md={6} sm={12} xs={12}>
                        <Box style={webStyles.fieldContainer}>
                          <Typography variant="h4" style={webStyles.label}>
                            Set Date<span style={webStyles.requiredField}>*</span>
                          </Typography>
                          <Box className={classes.customDPName} style={{ marginBottom: "13px" }}>
                            <span className={classes.customDateInputName}>
                              <DatePicker
                                data-test-id="bookingHpdate"
                                popperPlacement="bottom-start"
                                popperModifiers={[
                                  {
                                    name: "offset",
                                    options: {
                                      offset: [60, 0],
                                    },
                                  },
                                  {
                                    name: "preventOverflow",
                                    options: {
                                      rootBoundary: "viewport",
                                      tether: false,
                                      altAxis: true,
                                    },
                                  },
                                ]}
                                wrapperClassName={classes.datepickerClassFullWidth}
                                calendarClassName={classes.datepickerNurseClass}
                                onChange={this.handleHpBookingDateChange}
                                className={(this.state.hpErrors?.hpSetDate) ? classes.dateFieldError : classes.dateField}
                                dateFormat="Select date"
                                value={this.state.hpSetDate}
                                placeholderText="Select date"
                                minDate={tomorrowDate}
                              />
                            </span>
                          </Box>
                            {this.state.hpErrors.hpSetDate && <FormHelperText className={classes.textErrors}>{this.state.hpErrors.hpSetDate}</FormHelperText>}
                        </Box>
                      </Grid>

                      <Grid item xl={12} md={12} xs={12}>
                        <Box style={webStyles.fieldContainer}>
                          <Typography variant="body1" style={webStyles.label}>
                            Schedule Shift<span style={webStyles.required}>*</span>
                          </Typography>

                        <Box style={webStyles.shiftTimeBox}>
                          {shiftAvailabilities?.length > 0 ? shiftAvailabilities.map((shift: any) => {
                            const { label, time } = this.getShiftDetails(shift.shift_title);
                            return (
                              <Box key={shift.id} data-test-id="shiftsTypeDiv" onClick={() => this.handleShiftClick(shift.shift_title)}>
                                <Box style={label === this.state.shift ? webStyles.radioButton : webStyles.selected}>
                                  <Typography style={webStyles.shiftLabel}>
                                    {label}
                                  </Typography>
                                  <Typography style={webStyles.subLabel}>
                                    {time}
                                  </Typography>
                                </Box>
                              </Box>
                            );
                          }) : (
                              <Typography style={webStyles.notFound}>
                                The healthcare personnel does not have any shift availabilites to continue booking one-time and recurring appointment.
                              </Typography>
                          )}
                        </Box>
                        </Box>
                      </Grid>
                    </>
                  )}

                  {this.state.scheduleType === "recurring" && (
                    <>
                      <Grid item xl={4} md={4} xs={12}>
                        <Box style={webStyles.fieldContainer}>
                          <Typography variant="h4" style={webStyles.label}>
                            Repeat<span style={webStyles.requiredField}>*</span>
                          </Typography>
                          <ClickAwayListener onClickAway={() => this.setState({ toggleRepeat: false })}>
                            <Box className={classes.hpSelectCheckbox}>
                              <TextField
                                variant="outlined"
                                data-test-id="toggleRepeatField"
                                className={classes.repeatTextField}
                                placeholder={this.state.repeatBy}
                                value={""}
                                onClick={() => this.setState({ toggleRepeat: !this.state.toggleRepeat })}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <img
                                        src={DownArrow}
                                        alt="DownArrow"
                                        className={classes.hpDownArrowIcon}
                                      />
                                    </InputAdornment>
                                  ),
                                  readOnly: true
                                }}
                              />
                              <>
                                {this.state.toggleRepeat && (
                                  <Box className={classes.hpSelectFieldDropdown}>
                                    <Box className={classes.hpDropdownContent}>
                                      <Box sx={{ margin: "2px 0px", boxShadow: "0px 0px 30px #80808021", border: "1px solid #80808021", borderRadius: "14px" }}>
                                        {[{ value: "Week" }, { value: "Month" }, { value: "Year" }].map((data: any, index: number) => (
                                          <Box className={classes.hpSelectOption} style={this.state.repeatBy !== data.value ? { opacity: 0.6 } : {}} key={index}>
                                            <Checkbox
                                              data-test-id={`selectGender${index}`}
                                              className={classes.checkbox}
                                              checked={this.state.repeatBy === data.value}
                                              onChange={() => this.setState({ repeatBy: "Week" })}
                                              icon={<CustomUncheckedIcon />}
                                              checkedIcon={<CustomCheckedIcon />}
                                            /><span className={this.state.repeatBy === data.value ? classes.selectRepeatLabel : classes.selectDisabledLabel}>{data.value}</span>
                                          </Box>
                                        ))}
                                      </Box>
                                    </Box>
                                  </Box>
                                )}
                              </>
                            </Box>
                          </ClickAwayListener>
                        </Box>
                      </Grid>

                      <Grid item xl={4} md={4} xs={12}>
                        <Box style={webStyles.fieldContainer}>
                          <Typography variant="h4" style={webStyles.label}>
                            Ends
                          </Typography>
                          <Box style={{ transform: "translateY(-10px)" }}>
                            <Box className={classes.endsOnSelect}>
                              <Checkbox
                                data-test-id="selectOption1"
                                className={classes.checkbox}
                                checked={false}
                                onChange={() => { }}
                                icon={<CustomUncheckedIcon />}
                                checkedIcon={<CustomCheckedIcon />}
                              />
                              <span className={classes.selectLabel}>On </span>
                              <span className={classes.endDateLabel}>Dec 24, 2023</span>
                            </Box>
                            <Box className={classes.endsOnSelect}>
                              <Checkbox
                                data-test-id="selectOption1"
                                className={classes.checkbox}
                                checked={false}
                                onChange={() => { }}
                                icon={<CustomUncheckedIcon />}
                                checkedIcon={<CustomCheckedIcon />}
                              />
                              <span className={classes.selectLabel}>After</span>
                              <span className={classes.endOccurLabel}>01</span>
                              <span className={classes.selectLabel}>Occurence</span>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    </>
                  )}

                  {this.state.scheduleType === 'long_term' && (<>
                    <Grid item xl={12} md={12} xs={12}>
                      <Typography style={{ ...webStyles.label, marginBottom: "10px" }} variant="h4">
                        Set Date<span style={webStyles.requiredField}>*</span>
                      </Typography>
                    </Grid>
                    <Grid item style={{  marginRight: "10px" }} xl={4} md={4} xs={12}>
                      <Box style={webStyles.fieldContainer}>
                        <Typography variant="h4" style={webStyles.label}>
                          From
                        </Typography>
                        <Box id="fromDateField" className={classes.customDPName} style={{ marginBottom: (this.state.hpErrors?.hpFromDate) ? "13px" : "20px" }}>
                          <span id="fromDateSpan" className={classes.duplicateDateField}>
                            <DatePicker
                              data-test-id="bookingdateFrom"
                              wrapperClassName={classes.datepickerClassFullWidth}
                              popperPlacement="bottom-start"
                              popperModifiers={[
                                {
                                  name: "offset",
                                  options: {
                                    offset: [60, 0],
                                  },
                                },
                                {
                                  name: "preventOverflow",
                                  options: {
                                    rootBoundary: "viewport",
                                    tether: false,
                                    altAxis: true,
                                  },
                                },
                              ]}
                              calendarClassName={classes.datepickerNurseClass}
                              className={classes.FromToField}
                              dateFormat="Select date"
                              placeholderText="eg. 02, Feb 2023"
                              minDate={tomorrowDate}
                              value={this.state.hpFromDate}
                              onChange={this.handleBookingFromDateChange}
                            />
                          </span>
                        </Box>
                        {this.state.hpErrors.hpFromDate && <FormHelperText className={classes.textErrors}>{this.state.hpErrors.hpFromDate}</FormHelperText>}
                      </Box>
                    </Grid>

                    <Grid item xl={4} md={4} xs={12}>
                      <Box style={webStyles.fieldContainer}>
                        <Typography variant="h4" style={webStyles.label}>
                          To
                        </Typography>
                        <Box id="toDateField" className={classes.customDPName} style={{ marginBottom: (this.state.hpErrors?.hpToDate) ? "13px" : "20px" }}>
                          <span id="toDateSpan" className={classes.duplicateDateField}>
                            <DatePicker
                              data-test-id="bookingdateTo"
                              popperModifiers={[
                                {
                                  name: "offset",
                                  options: {
                                    offset: [60, 0],
                                  },
                                },
                                {
                                  name: "preventOverflow",
                                  options: {
                                    rootBoundary: "viewport",
                                    tether: false,
                                    altAxis: true,
                                  },
                                },
                              ]}
                              popperPlacement="bottom-start"
                              wrapperClassName={classes.datepickerClassFullWidth}
                              placeholderText="eg. 02, Feb 2023"
                              minDate={tomorrowDate}
                              calendarClassName={classes.datepickerNurseClass}
                              onChange={this.handleBookingToDateChange}
                              className={classes.FromToField}
                              value={this.state.hpToDate}
                              dateFormat="Select date"
                            />
                          </span>
                        </Box>
                        {this.state.hpErrors.hpToDate && <FormHelperText className={classes.textErrors}>{this.state.hpErrors.hpToDate}</FormHelperText>}
                      </Box>
                    </Grid>
                  </>)}
                </Grid>
                <Box>
                  <Button
                    variant="contained"
                    data-test-id={`bookHpAppointment`}
                    color="primary"
                    style={webStyles.bookAppointmentBtn}
                    onClick={this.handleBookedSummaryPopup}
                  >
                    Book Appointment
                  </Button>
                </Box>
                {this.state.showNurseBookedDetails && <Box style={webStyles.PaperSummaryPropsClass} data-test-id="showSummaryNurse">
                  <Box style={webStyles.nursePopoverSummaryContainer}>
                    <Box style={webStyles.selectSummaryHospitalContainer}>
                      <Typography variant="h6" style={webStyles.titleHeading}>
                        Summary
                      </Typography>
                      <Typography
                        variant="h6"
                        data-test-id="closeSummaryHP"
                        style={webStyles.nurseCloseSummaryIcon}
                        onClick={() => this.setState({ showNurseBookedDetails: false })}
                      >
                        <CloseIcon />
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        maxHeight: 'calc(65vh - 100px)',
                        overflowY: 'scroll',
                        scrollbarColor: 'white white',
                        scrollbarWidth: 'thin',
                        marginRight: '-20px',
                      }}
                    >
                      <Box style={webStyles.nurseTopProfileContainer} data-test-id="summaryContainer">
                        <Box style={webStyles.profileImageContainer}>
                          <img
                            data-test-id="avatarSize"
                            style={webStyles.nurseProfileSummary}
                            src={this?.state?.nurseData?.attributes?.profile_image_url?.url || UserProfile}
                            alt="Profile"
                          />
                        </Box>
                        <Box style={webStyles.nurseProfileSummaryBox} data-test-id="summaryDetails">
                          <Typography variant="h6" style={webStyles.nurseuserProfileName}>
                            {this?.state?.nurseData?.attributes?.name || "N/A"}
                          </Typography>
                          <Typography variant="h6" style={webStyles.nurseHeadingAddress}>
                            {this?.state?.nurseData?.attributes?.address?.data?.attributes?.city || "N/A"}
                          </Typography>
                        </Box>
                      </Box>
                      <Box style={webStyles.nurseBottomBorder} />
                      <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box className={classes.nurseSummaryDetailsLeftConatiner}>
                          {Object.keys(this.state?.hpSummaryDetails).map((key) => (
                            <Typography key={key} variant="h6" style={webStyles.nurseSummaryTitle}>
                              {key}
                              <span className={classes.colonStyleNurse}>:</span>
                            </Typography>
                          ))}
                        </Box>
                        <Box className={classes.nurseSummaryDetailsRightConatiner}>
                          {Object.entries(this.state?.hpSummaryDetails).map(([key, value]): any => (
                            <Typography key={key} variant="h6" className={classes.nurseSummaryDetails}>
                              {value as any}
                            </Typography>
                          ))}
                        </Box>
                      </Box>
                    </Box>
                    <Box>
                      <Button
                        data-test-id="changeHpDetails"
                        variant="contained"
                        color="primary"
                        style={webStyles.nurseChangeDetailsBtn}
                        onClick={this.changeHpSummaryDetails}
                      >
                        Change Details
                      </Button>
                      <Button
                        data-test-id="makeHpPaymentBtn"
                        onClick={this.handleHpMakePayment}
                        variant="contained"
                        color="primary"
                        style={webStyles.nurseSelectPaymentBtn}
                      >
                        Select Payment
                      </Button>
                    </Box>
                  </Box>
                </Box>}
              </Box>
            </Grid>
          </Grid>

          <Dialog open={this.state.showDialog} classes={{ paper: classes.paper }} style={{ textAlign: "center" }}>
              <DialogContent>
                <p className={classes.dialogText}>
                 {this.state.bookingErrorMessage}
                </p>
              </DialogContent>
              <DialogActions className={classes.dialogActions}>
                <Button
                  data-test-id="closePopUp"
                  onClick={this.handleDialogClose}
                  variant="contained"
                  color="primary"
                  className={classes.dialogButton}
                  style={{ textTransform: "capitalize" }}
                >
                  Okay
                </Button>
              </DialogActions>
            </Dialog>

        </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyles = {
  bookNurseProfileContainer: {
    display: "flex",
  },
  bookNurseContainer: {
    margin: "40px 50px",
    width: "70%",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column" as FlexDirectionProperty,
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: '20px',
  },
  bookNurseHeaderContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginBottom: "25px",
    justifyContent: "space-between",
  },
  bookNurseHeading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "25px",
  },
  bookNurseBackButton: {
    display: "flex",
    alignItems: "center",
    fontSize: "22px",
    justifyContent: "center",
    borderRadius: "8px",
    border: "1px solid #E9E9E9",
    padding: "12px 8px 12px 16px",
  },
  bookNurseTitle: {
    fontFamily: "SF Pro Text Bold",
    fontSize: "28px",
    fontWeight: 600,
    lineHeight: "33.41px",
    color: "#292929",
  },
  avatar: {
    width: "138px",
    height: "138px",
    margin: 'auto',
  },
  bookNurseMainBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: "100%",
    margin: "10px 0 0px"
  },
  bookNurseName: {
    fontFamily: 'SF Pro Text Bold',
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '28.64px',
    color: '#292929',
    textTransform: 'capitalize' as 'capitalize'
  },
  bookNurseDesc: {
    fontFamily: 'SF Pro Text Regular',
    fontSize: '16px',
    fontWeight: 400,
    color: '#808080',
    marginTop: "4px",
    textTransform: 'capitalize' as 'capitalize'
  },
  bookNurseInfo: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '20px'
  },
  bookAppointmentBtn: {
    caretColor: "transparent",
    backgroundColor: "#014866",
    color: "#F2F6F7",
    border: "none",
    borderRadius: "8px",
    width: "250px",
    height: "56px",
    cursor: "pointer",
    fontSize: "18px",
    fontFamily: "SF Pro Text Bold",
    fontWeight: 600,
    marginTop: "15px",
    "&:hover": {
      backgroundColor: "#014866",
      color: "#fff"
    },
    textTransform: "capitalize" as "capitalize",
    float: "right" as "right",
    '@media (max-width:1250px)': {
      width: "160px !important",
    },
  },
  label: {
    fontSize: "18px",
    fontFamily: "SF Pro Text Medium",
    fontWeight: 500,
    margin: "10px 0px 15px",
    textAlign: "left" as textAlignType,
    caretColor: "transparent",
    color: "#292929"
  },
  required: {
    color: "#ff0000",
  },
  requiredField: {
    color: "#ff0000"
  },
  nurseDatePicker: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },

  endsOnDate: {
    display: "flex",
    alignItems: "center",
    width: "113px",
    fontFamily: "SF Pro Text Regular",
    fontSize: "16px",
    fontWeight: 400,
    padding: "8px",
    borderRadius: "12px",
    letterSpacing: "-0.46px",
    color: "#292929",

    "& .MuiInputBase-input": {
      color: "#292929",
      fontSize: "18px",
      fontFamily: "SF Pro Text Regular",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      fontSize: "18px",
      fontFamily: "SF Pro Text Regular",
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "#3f51b5 !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#f44336",
      borderWidth: "1px",
    },
    "& input": {
      color: "#292929",
      fontSize: "14px",
      fontFamily: "SF Pro Text Regular",
      "&::placeholder": {
        opacity: 1,
        fontSize: "14px",
        fontFamily: "SF Pro Text Regular",
        color: "#808080"
      }
    },
  },
  calendarIcon: {
    marginRight: "10px",
  },
  fieldContainer: {
    width: "94%",
    margin: "15px 0px 20px"
  },
  textErrors: {
    display: "flex",
    justifyContent: "end",
    color: "#f44336",
    fontSize: "14px"
  },
  customNurseDatePickerName: {
    display: "flex",
    alignItems: "center",
  },
  radioGroup: {
    display: 'flex',
    flexDirection: 'row' as FlexDirectionProperty,
    gap: '20px',
    width: '100%',
  },
  radioButton: {
    display: 'flex',
    flexDirection: 'column' as FlexDirectionProperty,
    alignItems: 'center',
    justifyContent: "center",
    borderRadius: '8px',
    cursor: 'pointer',
    background: "#3780E0",
    width: "195px",
    height: "70px",
    color: "#fff",
    textTransform: 'capitalize' as 'capitalize',
    transition: 'background-color 0.3s, transform 0.5s',
  },
  selected: {
    display: 'flex',
    flexDirection: 'column' as FlexDirectionProperty,
    alignItems: 'center',
    justifyContent: "center",
    border: "1px solid #808080",
    borderRadius: '8px',
    cursor: 'pointer',
    color: "#808080",
    width: "195px",
    height: "70px",
    textTransform: 'capitalize' as 'capitalize',
    background: "#fff",
    '&:not(:last-child)': {
      marginRight: theme.spacing(2),
    },
    '&$selected': {
      borderColor: '#4285F4',
    },
  },
  subLabel: {
    fontFamily: 'SF Pro Text Regular',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '19.09px',
    letterSpacing: '-0.4571428596973419px',
    marginBottom: "2px",
    textTransform: "capitalize" as "capitalize",
  },
  shiftLabel: {
    fontFamily: 'SF Pro Text Medium',
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '23.87px',
  },
  shiftTypeRadioButtonSelected: {
    display: 'flex',
    flexDirection: 'column' as FlexDirectionProperty,
    alignItems: 'center',
    justifyContent: "center",
    borderRadius: '20px',
    cursor: 'pointer',
    background: "#3780E0",
    border: "2px solid #3780E0",
    width: "135px",
    textTransform: 'capitalize' as 'capitalize',
    height: "50px",
    color: "#fff",
  },
  shiftTypeBox: {
    display: "flex",
    justifyContent: "flex-start",
    border: "1px solid #808080",
    borderRadius: "22px",
    height: "50px",
    maxWidth: "400px",
    width: "fit-content",
  },
  shiftTimeBox: {
    display: "flex",
    justifyContent: "flex-start",
    width: "fit-content",
    gap: "35px",
    flexWrap: "wrap" as "wrap"
  },
  shiftTypeRadioButton: {
    display: 'flex',
    flexDirection: 'column' as FlexDirectionProperty,
    alignItems: 'center',
    justifyContent: "center",
    borderRadius: '20px',
    cursor: 'pointer',
    background: "#fff",
    width: "135px",
    height: "50px",
    textTransform: 'capitalize' as 'capitalize',
    color: "#808080",
    transition: 'background-color 0.3s, transform 0.5s',
  },
  shiftTypeLabel: {
    fontFamily: 'SF Pro Text Regular',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '21.48px',
  },
  selectedShiftTypeLabel: {
    fontFamily: 'SF Pro Text Bold',
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '21.48px',
  },
  customDPName: {
    display: "flex",
    alignItems: "center",
  },
  customDateInputName: {
    width: "100%",
    borderRadius: "8px",
    "& input": {
      color: "#292929",
      fontSize: "18px",
      background: `url(${Calender}) left / contain no-repeat`,
      backgroundPosition: "12px",
      backgroundSize: "25px",
      width: "100%",
      height: "58px",
      border: "1px solid #808080",
      display: "flex",
      margin: "auto",
      borderRadius: "8px",
      fontFamily: "SF Pro Text Regular",
      caretColor: "transparent"
    },
    "& ::-webkit-input-placeholder": {
      color: "#808080",
    },
    "&:focus": {
      border: "1px solid #292929",
    },
  },
  duplicateDateField: {
    width: "100%",
    borderRadius: "8px",
    "& input": {
      color: "#292929",
      fontSize: "18px",
      width: "100%",
      height: "58px",
      border: "1px solid #808080",
      display: "flex",
      margin: "auto",
      borderRadius: "8px",
      fontFamily: "SF Pro Text Regular",
      caretColor: "transparent"
    },
    "& ::-webkit-input-placeholder": {
      color: "#808080",
    },
    "&:focus": {
      border: "1px solid #292929",
    },
  },
  repeatTextField: {
    width: "100%",
    fontSize: "15px",
    marginBottom: "10px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      cursor: "pointer",
      fontSize: "18px",
      fontFamily: "SF Pro Text Regular",
      border: "1px solid #808080",
      height: "58px",
    },
    "& .MuiOutlinedInput-root:hover": {
      borderWidth: "1px",
      borderColor: "#292929 !important",
    },
    "& .MuiInputBase-input": {
      color: "#292929",
      fontSize: "18px",
      fontFamily: "SF Pro Text Regular",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none"
    },
    "& input": {
      color: "#292929",
      fontSize: "18px",
      fontFamily: "SF Pro Text Regular",
      "&::placeholder": {
        opacity: 1,
        fontSize: "18px",
        color: "#808080",
      }
    }
  },
  customDateFieldName: {
    display: "flex",
    padding: "15px 60px",
    justifyContent: "center",
    borderRadius: "8px",
  },
  datepickerNurseClass: {
    "& .react-datetime-picker__wrapper": {
      border: "none"
    },
    "& .react-datepickerClass-wrapper": {
      display: "flex !important",
    },
    "& .react-datepickerClass__month": {
      margin: "0px !important"
    },
    "& .react-datepickerClass-popper": {
      transform: "translate(90px, 209px) !important"
    },
    "& .react-datepickerClass__day": {
      width: "1.3rem !important",
      height: "1rem !important"
    },
    "& .react-datepickerClass__day-name": {
      width: "1.3rem !important",
      height: "1rem !important"
    },
    "& .react-datepickerClass__month-container": {
      margin: "0px 0px 5px !important"
    },
    "& .react-datepickerClass__day-names": {
      marginBottom: "-1px !important",
      caretColor: "transparent"
    },
    "& .react-datepickerClass__navigation": {
      height: "22px !important",
      width: "22px !important",
      margin: "5px -2px !important",
      caretColor: "transparent"
    },
    "& .react-datepickerClass__day--keyboard-selected": {
      color: "#000 !important",
      backgroundColor: "white !important",
      caretColor: "transparent"
    },
    "& .react-datepickerClass__day--today": {
      color: "#1d5d90 !important",
      backgroundColor: "white !important",
      fontWeight: "bold !important",
      caretColor: "transparent"
    },
    "& .react-datepickerClass__day:hover": {
      color: "#ccc !important",
      backgroundColor: "white !important",
      caretColor: "transparent"
    },
  },
  hpUpArrowIcon: {
    width: "18px",
    height: "15px",
    objectFit: "cover" as "cover",
    cursor: "pointer"
  },
  hpDownArrowIcon: {
    width: "18px",
    height: "15px",
    objectFit: "cover" as "cover",
    cursor: "pointer",
    marginRight: "6px",
  },
  dateFieldError: {
    "& .react-datepicker-ignore-onclickoutside": {
      border: "none !important",
      borderColor: "transparent !important"
    },
    display: "flex",
    padding: "15px 60px",
    border: "1px solid red",
    justifyContent: "center",
    borderRadius: "8px",
    cursor: "pointer",
    "&:focus-visible": {
      outline: "none",
      border: "2px solid #3a52d4",
    },
    "&:focus": {
      outline: "none",
      border: "2px solid #3a52d4",
    },
    "&:hover": {
      outline: "none",
      border: "1px solid #3f51b5",
    },
    color: "#292929",
    "& input": {
      color: "#292929"
    }
  },
  bookingRegisterInputFields: {
    width: "100%",
    fontSize: "18px",
    marginBottom: "10px",
    fontFamily: "SF Pro Text Regular",
    color: "#292929",
    '&::placeholder': {
      color: '#292929 !important',
      opacity: 1,
    },
    '&::-webkit-input-placeholder': {
      color: '#292929 !important',
      opacity: 1,
    },

    "& .MuiInputBase-input": {
      color: "#292929",
      fontSize: "18px",
      fontFamily: "SF Pro Text Regular",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      fontSize: "18px",
      fontFamily: "SF Pro Text Regular",
      border: "1px solid #808080",
      height: "58px",
    },
    "& .MuiOutlinedInput-root:hover": {
      borderWidth: "1px",
      borderColor: "#292929 !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none"
    },
    "& input": {
      color: "#292929",
      fontSize: "18px",
      fontFamily: "SF Pro Text Regular",
      "&::placeholder": {
        opacity: 1,
        fontSize: "18px",
        color: "#808080",
      }
    }
  },

  FromToField: {
    "& .react-datepicker-ignore-onclickoutside": {
      border: "none !important",
      borderColor: "transparent !important"
    },
    display: "flex",
    padding: "15px 60px 15px 13px",
    border: "1px solid #808080",
    justifyContent: "center",
    borderRadius: "8px",
    cursor: "pointer",
    "&:focus-visible": {
      outline: "none",
      border: "2px solid #292929",
    },
    "&:focus": {
      outline: "none",
      border: "2px solid #292929",
    },
    "&:hover": {
      outline: "none",
      border: "1px solid #292929",
    },
    ".react-datepicker__input-container input::placeholder": {
      fontSize: "18px",
      fontFamily: "SF Pro Text Regular",
      fontWeight: 400,
      color: "#808080",
    },
    color: "#292929",
    fontSize: "18px",
    "& input": {
      color: "#292929",
    }
  },

  dateField: {
    "& .react-datepicker-ignore-onclickoutside": {
      border: "none !important",
      borderColor: "transparent !important"
    },
    display: "flex",
    padding: "15px 60px",
    border: "1px solid #808080",
    justifyContent: "center",
    borderRadius: "8px",
    cursor: "pointer",
    "&:focus-visible": {
      outline: "none",
      border: "2px solid #292929",
    },
    "&:focus": {
      outline: "none",
      border: "2px solid #292929",
    },
    "&:hover": {
      outline: "none",
      border: "1px solid #292929",
    },
    ".react-datepicker__input-container input::placeholder": {
      opacity: 1,
      fontSize: "18px",
      color: "#808080",
    },
    color: "#292929",
    fontSize: "18px",
    "& input": {
      color: "#292929",
    }
  },
  checkbox: {
    color: "#fff !important",
    padding: "9px 12px 9px 0px"
  },
  checkboxWhite: {
    color: "#fff !important",
    marginLeft: "2px !important",
  },
  dropdownText: {
    display: "flex",
    justifyContent: "space-between",
    padding: '10px 3px 15px 0px',
    color: "#a4a1a1",
    cursor: 'pointer',
    borderBottom: '1px solid #ccc',
    transition: 'background-color 0.3s ease',
    fontSize: "17px",
    fontFamily: "SF Pro Text Regular",
    alignItems: "center"
  },
  hpSelectOption: {
    padding: '15px 30px 15px 5px',
    cursor: 'pointer',
    borderBottom: '1px solid #F2F2F2',
    marginLeft: "20px",
    width: "80%",
    transition: 'background-color 0.3s ease',
    fontFamily: "Sf Pro Text Regular",
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    color: "#292929"
  },

  endsOnSelect: {
    padding: '10px 30px 10px 0px',
    cursor: 'pointer',
    borderBottom: '1px solid #F2F2F2',
    transition: 'background-color 0.3s ease',
    fontFamily: "Sf Pro Text Regular",
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    color: "#292929",
    gap: "10px",
    "&:first-of-type": {
      paddingBottom: "0px",
    }
  },
  selectLabel: {
    fontFamily: "Sf Pro Text Medium",
    fontSize: "16px",
    color: "#292929",
    fontWeight: 500,
    lineHeight: "19.09px",
  },
  endDateLabel: {
    fontFamily: 'SF Pro Text Regular',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '19.09px',
    letterSpacing: '-0.46px',
    color: '#292929',
    border: '1px solid #DFDFDF',
    borderRadius: '12px',
    width: '119px',
    height: '37px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  endOccurLabel: {
    fontFamily: 'SF Pro Text Regular',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '19.09px',
    letterSpacing: '-0.46px',
    color: '#292929',
    border: '1px solid #DFDFDF',
    borderRadius: '10px',
    width: 'fit-content',
    padding: '8px',
    height: 'fit-content',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  selectRepeatLabel: {
    fontFamily: "Sf Pro Text Medium",
    fontSize: "16px",
    color: "#292929",
    fontWeight: 500,
    lineHeight: "19.09px",
  },
  selectDisabledLabel: {
    opacity: 0.5,
  },
  numberOfOccurence: {
    color: "red",
    background: "yellow",
    width: 'auto',
    minWidth: '39px',
    height: '39px',
    fontSize: '16px',
    marginBottom: '10px',
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
      fontSize: '16px',
      fontFamily: 'SF Pro Text Regular',
    },
    '& .MuiInputBase-input': {
      color: '#292929',
      fontSize: '16px',
      fontFamily: 'SF Pro Text Regular',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#808080',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#3f51b5',
    },
    '& .MuiInputBase-input::placeholder': {
      color: '#808080',
    },
  },
  datepickerClassFullWidth: {
    width: "100%",
    color: "#292929",
    border: "none"
  },


  PaperSummaryPropsClass: {
    backgroundColor: '#050505a3',
    position: "fixed" as PositionValue,
    top: 0,
    left: 0,
    marginLeft: "8.30%",
    width: "100%",
    height: "100%",
    zIndex: 999,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflowY: "auto" as OverflowXProperty
  },
  nursePopoverSummaryContainer: {
    position: 'absolute' as PositionValue,
    top: 'auto',
    bottom: "auto",
    left: 'auto',
    right: 'auto',
    transform: 'translateX(-10%)',
    backgroundColor: '#fff',
    borderRadius: '18px',
    boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.1)',
    zIndex: 9999,
    width: '410px',
    padding: '20px 30px 20px 30px',
    maxHeight: "calc(100vh - 100px)",
    '@media (max-height: 1000px)': {
      top: '50%',
    },
  },
  selectSummaryHospitalContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "381px"
  },
  titleHeading: {
    display: "flex",
    fontSize: "28px",
    marginTop: "15px",
    alignItems: "start",
    fontWeight: 600,
    fontFamily: "SF Pro Text Bold",
    caretColor: "transparent",
    justifyContent: "space-between",
    color: "#292929"
  },
  nurseCloseSummaryIcon: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    transform: "translate(30px, 8px)",
    fontSize: "22px",
    cursor: "pointer",
    caretColor: "transparent"
  },
  nurseTopProfileContainer: {
    display: "flex",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 0px 30px 0px",
    marginTop: "25px",
    gap: "20px",
  },
  profileImageContainer: {
    width: "20%",
    [theme.breakpoints.down("md")]: {
      width: "40%"
    },
    [theme.breakpoints.down("sm")]: {
      width: "50%"
    }
  },
  nurseProfileSummary: {
    borderRadius: "50%",
    width: "138px",
    height: "138px",
    border: "1px solid #eeeaea",
    backgroundColor: "#eeeaea",
  },
  nurseProfileSummaryBox:{
    width: "75%",
    marginTop: "20px",
    paddingLeft: "58px",
  },
  nurseuserProfileName: {
    fontSize: "24px",
    color: "#292929",
    fontFamily: "SF Pro Text Bold",
    caretColor: "transparent",
    lineHeight:"28px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "-webkit-box",
    maxHeight: "55px",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
  } as any,
  nurseHeadingAddress: {
    fontSize: "16px",
    fontFamily: "SF Pro Text Regular",
    caretColor: "transparent",
    color: "#808080",
    textOverflow: "ellipsis",
    overflow: "hidden",
    height: "25px",
    maxHeight: "25px",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    display: "-webkit-box",
  } as any,
  nurseBottomBorder: {
    width: "90%",
    borderBottom: "1px solid #c2c2c2",
    opacity: "0.38",
  },
  nurseSummaryDetailsLeftConatiner: {
    width: "55%",
    textAlign: "left" as textAlignType
  },
  nurseSummaryDetailsRightConatiner: {
    width: "45%",
    textAlign: "left" as textAlignType
  },
  nurseSummaryTitle: {
    fontSize: "18px",
    fontFamily: "SF Pro Text Medium",
    caretColor: "transparent",
    marginTop: "20px",
    color: "#292929",
    display: "flex",
    justifyContent: "space-between",
    paddingRight: "20px"
  },
  nurseSummaryDetails: {
    fontSize: "18px",
    fontFamily: "SF Pro Text Regular",
    caretColor: "transparent",
    color: "#717171",
    marginTop: "20px",
    textTransform: "capitalize" as "capitalize"
  },
  notFound: {
    fontSize: "16px",
    fontFamily: "SF Pro Text Regular",
    caretColor: "transparent",
    color: "#717171",
  },
  nurseSummaryDetailsIns: {
    maxWidth: '150px', 
    whiteSpace: 'nowrap' as any, 
    overflow: 'hidden' as any, 
    textOverflow: 'ellipsis' as any,  
    display: 'inline-block' as any,  
    verticalAlign: 'bottom' as any,
  },
  nurseChangeDetailsBtn: {
    width: "300px",
    height:"56px",
    fontSize: "18px",
    fontFamily: "SF Pro Text Bold",
    backgroundColor: "#fff",
    color: "#014866",
    border: "1px solid #014866",
    borderRadius: "9px",
    padding: "16px",
    cursor: "pointer",
    margin: "30px auto 0px",
    display: "flex",
    textTransform: "capitalize" as "capitalize",
    "&:hover": {
      backgroundColor: "#fff",
      color: "#f014866",
    },
  },
  nurseSelectPaymentBtn: { 
    width: "300px",
    height:"56px",
    textTransform: "capitalize" as "capitalize",
    backgroundColor: "#014866",
    color: "#F2F6F7",
    border: "none",
    borderRadius: "9px",
    padding: "16px",
    cursor: "pointer",
    fontSize: "18px",
    fontFamily: "SF Pro Text Bold",
    display: "flex",
    margin: "25px auto 25px",
    "&:hover": {
      backgroundColor: "#014866",
      color: "#fff",
    },
   },
   paper: {
    borderRadius: "20px",
    maxWidth: "450px",
    padding: "10px 40px 50px",
    marginLeft: "13vw"
  },
  dialogText: {
    fontSize: "20px",
    color: "#383838",
    fontFamily: "SF Pro Text Medium",
    fontWeight: 500,
    lineHeight: "26px",
    caretColor: "transparent !important"
  },
  dialogButton: {
    width: "100%",
    height:"65px",
    backgroundColor: "#014866",
    color: "#fff",
    border: "none",
    borderRadius: "10px",
    padding: "10px",
    cursor: "pointer",
    fontFamily:"SF Pro Text Bold",
    fontWeight: 600,
    fontSize: "18px",
    margin: "20px auto 10px",
    "&:hover": {
      backgroundColor: "#014866",
      color: "#fff"
    },
    caretColor: "transparent"
  },
}

export default withStyles(webStyles)(Reservations2ViewNurseBooking);
// Customizable Area End

