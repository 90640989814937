import * as React from "react";
// Customizable Area Start
import { Box, Typography, Card, CardContent, Button, withStyles } from "@material-ui/core";
import { homecureTransparent, physicianMedical, Account } from "./assets";
import { styles } from "./styles/PhoneNumberRegistration";
import EmailAccountRegistrationController from "./EmailAccountRegistrationController"
// Customizable Area End

export class HospitalRegistrationSuccess extends EmailAccountRegistrationController {
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      <Box
        component="div"
        style={{
          backgroundImage: `url(${physicianMedical})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        sx={{
          minHeight: "100vh"
        }}
      >
        <Box className={classes.homeCureContainers}>
          <img
            alt='HomeCure Logo'
            src={homecureTransparent}
            style={{ objectFit: 'contain' }}
            className={classes.homecureLogos}
          />
        </Box>
        <Box>
          <Card className={classes.transparentCards}>
            <CardContent style={{ flexDirection: "column" }} className={classes.cardContents}>
              <img
                alt='hospitalRegistrationSuccess'
                src={Account}
                className={classes.emailVerifiedLogo}
                style={{ objectFit: 'contain' }}
              />
              <Box className={classes.hospitalSuccessMessageContainer}>
                <Typography  data-test-id="textMessage1" variant="h6" className={classes.hospitalSuccessMessages} style={{ textAlign: "center" }}>
                Your account is being reviewed by
              </Typography>
              <Typography  data-test-id="textMessage2" variant="h6" className={classes.hospitalSuccessMessages} style={{ textAlign: "center" }}>
              the admin. We will get back
              </Typography>
              <Typography  data-test-id="textMessage3" variant="h6" className={classes.hospitalSuccessMessages} style={{ textAlign: "center" }}>
                to you within 1 day.
              </Typography>
              </Box>
              <Button
                data-test-id="successBtn"
                variant="contained"
                color="primary"
                className={classes.successBtns}
                style={{ textTransform: "capitalize" }}
                onClick={() => this.backToHosptalLogin()}
              >
                Done
              </Button>
            </CardContent>
          </Card>
        </Box>
      </Box>
       // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(styles)(HospitalRegistrationSuccess);
// Customizable Area End
