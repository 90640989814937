import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;

  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  hpFilterType: string;
  selectedIndex: number | number[];
  hpRole:string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class FilterController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.state = {
      hpFilterType: "0",
      selectedIndex: 0,
      hpRole:"",
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  hpFilterType = (type: string) => {
    this.setState({ hpFilterType: type, selectedIndex: parseInt(type) > 4 ? [] : 0 });
  };

  handleCheckboxPriceChange = (index: number) => {
    if(this.state.hpFilterType==="7"&& index === 0){
      if((this.state.selectedIndex as number[]).includes(0)){
        this.setState({ selectedIndex: [] });
      }else{
        this.setState({ selectedIndex: [0, 1, 2, 3] });
      }
    }else{
      this.handleCheckboxChange(index)
    }
  };

  handleCheckboxLocationChange = (index: number) => {
    if(this.state.hpFilterType==="8"&& index === 0){
      if((this.state.selectedIndex as number[]).includes(0)){
        this.setState({ selectedIndex: [] });
      }else{
        this.setState({ selectedIndex: [0, 1, 2, 3, 4, 5] });
      }
    }else{
      this.handleCheckboxChange(index)
    }
  };

  handleCheckboxChange = (index: number) => {
    if (parseInt(this.state.hpFilterType) > 4) { 
      const selectedIndexes = this.state.selectedIndex as number[];
      const newSelectedIndexes = selectedIndexes.includes(index)
        ? selectedIndexes.filter(i => i !== index)
        : [...selectedIndexes, index];
      this.setState({ selectedIndex: newSelectedIndexes });
    } else {
      this.setState({ selectedIndex: index });
    }
  };

  getSelectedField = (isSelected: boolean) => {
    return {
      borderBottom: "1px solid #ddd",
      fontSize: "16px",
      fontFamily: isSelected ? "SF Pro Text Medium" : "SF Pro Text Regular",
      padding: "15px 0px",
      color: "#292929",
      caretColor: "transparent",
    };
  };

  getRowStyles = (isSelected: boolean) => {
    return {
      padding: "5px 0px",
      borderBottom: "1px solid #ddd",
      fontSize: "14px",
      fontWeight: isSelected ? 600 : 500,
      width: "14px",
    };
  };

  userRole = async () => {
    const hpRole = await getStorageData("hpUserRole");
    this.setState({
      hpRole,
      hpFilterType: hpRole === 'doctor' ? "5" : this.state.hpFilterType,
      selectedIndex:hpRole === 'doctor' ? [] : this.state.selectedIndex
    });
  };

  getPopoverClass = () => {
    const { hpFilterType } = this.state;
    if(["4", "5", "6"].includes(hpFilterType)){
      return {
        background: "white",
        position: "absolute" as "absolute",
        top: "calc(100% + 52px)",
        borderRadius: "8px",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 10px",
        padding: "20px",
        maxWidth: "530px",
        minWidth: "530px",
        transform: "translateX(-105%)"
      }
    }else{
      return {
        background: "white",
        position: "absolute" as "absolute",
        top: "calc(100% + 52px)",
        borderRadius: "8px",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 10px",
        padding: "20px",
        maxWidth: "400px",
        minWidth: "400px",
        transform: "translateX(-105%)"
      }
    }
  }

  checkSelectedField = (selectedId:string) => {
    if(this.state.hpFilterType === selectedId){
      return {
        cursor: "pointer",
        fontSize: "18px",
        fontFamily: "SF Pro Text Semibold",
        color: "rgb(55, 128, 224)",
        background: "#C3D9F6",
        marginRight: "20px",
        padding: "16px 25px",
        borderRadius: "8px",
        marginBottom: "20px",
        caretColor: "transparent",
        width: "85px",
        display: "flex"
      }
    } else{
        return {
          cursor: "pointer",
          fontSize: "18px",
          fontFamily: "SF Pro Text Medium",
          color: "#808080",
          background: "transparent",
          marginRight: "20px",
          padding: "16px 25px",
          borderRadius: "8px",
          marginBottom: "20px",
          caretColor: "transparent",
          width: "85px",
          display: "flex"
        }
    }
  }
  // Customizable Area End
}
