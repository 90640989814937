import * as React from "react";
// Customizable Area Start
import { withStyles, Box, Typography, Grid, ThemeProvider, createTheme } from "@material-ui/core";
import CustomisableUserProfilesController, {
    Props
} from "./CustomisableUserProfilesController";
import { styles } from "./styles/CustomisableUserProfiles";
import { Notification,Setting, Edit } from "./assets"
import Sidebar from "../../../components/src/Sidebar.web";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff"
        }
    }
});
// Customizable Area End

export class CustomisableHPViewUserProfile extends CustomisableUserProfilesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        await this.fetchDepartmentsList();
        await this.fetchDoctorDetails();
    }

    showDepartment = (userType:string,connectHospital:boolean) => {
        return userType === 'doctor' && connectHospital
    }

    showLabel = (userType:string) => {
        if(userType==='ayah'){
            return "Aadhar Card No."
        }else{
            return "Registration No."
        }
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { district, postalCode, address, city } = this.state;
        const fullHPAddress = [address, city, district, postalCode];
        const hpAddress = fullHPAddress.filter(com => com).join(', ');
        const userType = this.state.user_type
        const department = userType === "doctor" ? this.state.departmentsList.filter((item:any) => item.id == this.state.departmentId): "";
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <ThemeProvider theme={theme}>
                        <Grid container className={classes.leftWrapper}>
                            <Grid item xs={1}>
                                <Sidebar />
                            </Grid>
                            <Grid item xs={11} className={classes.rightWrapper}>
                                <Box className={classes.root}>
                                    <Box>
                                        <Typography variant="h6" className={classes.profileHeading}>
                                            Profile
                                        </Typography>
                                    </Box>
                                    <Grid container>
                                        <Grid item xl={4} md={6} sm={6} xs={12}>
                                            <Box className={classes.topHPDetailsLeftContainer}>
                                                <Box className={classes.profileContainer}>
                                                    <img
                                                        className={classes.avatar}
                                                        src={this.state.profilePicture}
                                                    />
                                                </Box>
                                                <Box className={classes.profileDetailsContainer}>
                                                    <Typography variant="h6" className={classes.userName}>
                                                        {this.state.user_name}
                                                    </Typography>
                                                    <Typography variant="h6" className={classes.userPhoneNumber}>
                                                        {this.state.city} | {this.state.phoneNumber}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Box className={classes.boxHpBottomBorder} />
                                        </Grid>
                                        <Grid item xl={8} md={6} sm={6} xs={12}>
                                            <Box className={classes.topHPRightContainer}>
                                                <Box data-test-id="editHPProfile" onClick={this.hpEditProfile}>
                                                    <img
                                                        alt="Edit"
                                                        className={classes.editImage}
                                                        src={Edit}
                                                    />
                                                </Box>
                                                <Box>
                                                    <img
                                                        alt="Notification"
                                                        className={classes.notificationImage}
                                                        src={Notification}
                                                    />
                                                </Box>
                                                <Box>
                                                    <img
                                                        alt="Setting"
                                                        className={classes.settingImage}
                                                        src={Setting}
                                                    />
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xl={6} md={8} sm={8} xs={12}>
                                            <Box>
                                                <Box className={classes.subContainer}>
                                                    <Typography className={classes.userDetailsHPLabel}>
                                                        Healthcare Personnel
                                                    </Typography>
                                                    <Typography className={classes.userDetailsColon}>
                                                        :
                                                    </Typography>
                                                    <Typography className={classes.userDetails}>
                                                        {this.state.user_type.charAt(0).toUpperCase() + this.state.user_type.slice(1)} 
                                                    </Typography>
                                                </Box>
                                                {this.showDepartment(this.state.user_type,this.state.connect_with_hospital) &&
                                                (<Box className={classes.subContainer}>
                                                    <Typography className={classes.userDetailsHPLabel}>
                                                        Department
                                                    </Typography>
                                                    <Typography className={classes.userDetailsColon}>
                                                        :
                                                    </Typography>
                                                    <Typography className={classes.userDetails}>
                                                        {department[0]?.attributes?.name}
                                                    </Typography>
                                                </Box>)}
                                                <Box className={classes.subContainer}>
                                                    <Typography className={classes.userDetailsHPLabel}>
                                                    {this.showLabel(this.state.user_type)}
                                                    </Typography>
                                                    <Typography className={classes.userDetailsColon}>
                                                        :
                                                    </Typography>
                                                    <Typography className={classes.userDetails}>
                                                        {this.state.identification_no}
                                                    </Typography>
                                                </Box>
                                                <Box className={classes.subContainer}>
                                                    <Typography className={classes.userDetailsHPLabel}>
                                                        Age
                                                    </Typography>
                                                    <Typography className={classes.userDetailsColon}>
                                                        :
                                                    </Typography>
                                                    <Typography className={classes.userDetails}>
                                                        {this.state.userAge} y/o
                                                    </Typography>
                                                </Box>
                                                <Box className={classes.subContainer}>
                                                    <Typography className={classes.userDetailsHPLabel}>
                                                        Gender
                                                    </Typography>
                                                    <Typography className={classes.userDetailsColon}>
                                                        :
                                                    </Typography>
                                                    <Typography className={classes.userDetailsCaps}>
                                                        {this.state.gender}
                                                    </Typography>
                                                </Box>
                                                <Box className={classes.subContainer}>
                                                    <Typography className={classes.userDetailsHPLabel}>
                                                        Email Address
                                                    </Typography>
                                                    <Typography className={classes.userDetailsColon}>
                                                        :
                                                    </Typography>
                                                    <Typography className={classes.userDetails}>
                                                        {this.state.email}
                                                    </Typography>
                                                </Box>
                                                <Box className={classes.addressSubConatiner}>
                                                    <Typography className={classes.userDetailsHPLabel}>
                                                        Address
                                                    </Typography>
                                                    <Typography className={classes.userDetailsColon}>
                                                        :
                                                    </Typography>
                                                    <Typography className={classes.userDetailsCaps}>
                                                        {hpAddress}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                </ThemeProvider>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export default withStyles(styles)(CustomisableHPViewUserProfile);
// Customizable Area End