// Customizable Area Start
import * as React from "react";
import { Grid, Box, withStyles, Typography, TextField, Button, FormHelperText, InputAdornment, ClickAwayListener } from "@material-ui/core";
import { styles } from "./styles/PhoneNumberRegistration";
import EmailAccountRegistrationController, { Props } from "./EmailAccountRegistrationController";
import { homecureTransparent, pexelsKarolina, DownArrow, UpwardArrow } from "./assets";

const Kind = [
  { key:1, value: "Doctor" },
  { key:2, value: "Nurse" },
  { key:3, value: "Ayah" },
  { key:4, value: "Dietician" },
  { key:5, value: "Physiotherapist"}
];
// Customizable Area End

export class HealthCarePersonalRegistrationType extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.navigateTHealthDetails()
    const hpData = await this.getHpData();
    this.setHpDate(hpData)
  }

  showErrorField = (error: string | undefined, classes: any) => {
    return (error ? classes.errorRegisterTypeInputFields : classes.registerTypeInputFields)
  };
  showSelectFieldError = (error: string | undefined, classes: any) => {
    return (error ? classes.hpKindregisterErrorInputFields : classes.hpKindregisterInputFields)
  };

  errorMessage = (error: string | undefined, inputError: any) => {
    return (error ? <FormHelperText className={inputError}>{error}</FormHelperText> : null)
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { hpErrors } = this.state;
    // Customizable Area End

    return (
      // Customizable Area Start
      <>
        <Grid container data-test-id="hpUserType">
          <>
            <Grid data-test-id="hpRegister" item xl={4} md={4} sm={12} xs={12} className={`${classes.gridContainer} ${classes.fixedGrid}`}>
              <Box
                component="div"
                style={{
                  backgroundImage: `url(${pexelsKarolina})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  padding: "10px"
                }}
                sx={{
                  height: "100%"
                }}
                className={classes.container}
              >
                <Box className={classes.homeCureLogosContainer}>
                  <img
                    src={homecureTransparent}
                    className={classes.homecureLogos}
                    alt="HomeCure Logo"
                    style={{ objectFit: "contain" }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xl={8} md={8} sm={12} xs={12} className={classes.gridContainer}>
              <Box className={classes.patientRegistrationContainer}>
                <Box className={classes.patientLabelContainer} data-test-id="hpRegistration">
                  <Typography variant="h6" className={classes.registrationHeading}>
                    Register
                  </Typography>
                </Box>
                <Typography variant="h6" className={classes.patientHeading}>
                  Let's get you all set up so you can verify and manage your account.
                </Typography>
                <Grid container spacing={4}>
                  <Grid item xl={6} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography variant="h6" className={classes.registerLabel} style={{ textAlign: "left" }}>
                        Name<span className={classes.required}>*</span>
                      </Typography>
                      <TextField
                        variant="outlined"
                        data-test-id="hpName"
                        className={this.showErrorField(hpErrors.hpName, classes)}
                        placeholder="Enter your name"
                        value={this.state.hpName}
                        onChange={(event) => this.handleChangehp("hpName", event.target.value.trimStart())}
                      />
                      {this.errorMessage(hpErrors.hpName, classes.inputErrors)}
                    </Box>
                    <Button
                      variant="contained"
                      data-test-id="continue"
                      color="primary"
                      className={classes.hpRegisterDesktopButtons}
                      style={{ textTransform: "capitalize" }}
                      onClick={this.hphandleSubmit}
                    >
                      Continue
                    </Button>
                  </Grid>
                  <Grid item xl={6} md={6} sm={6} xs={12} className={classes.mobileGrid}>
                    <Box>
                      <Typography variant="h6" className={classes.registerLabel} style={{ textAlign: "left" }}>
                        Kind of Healthcare Personnel<span className={classes.required}>*</span>
                      </Typography>
                      <ClickAwayListener  data-test-id="selectType"
                      onClickAway={this.closeGenderDropdown}>
                      <Box className={classes.hpSelectCheckbox} id="selectType">
                        <TextField
                          variant="outlined"
                          data-test-id="hpKind"
                          className={this.showSelectFieldError(hpErrors.kind, classes)}
                          placeholder="Select One"
                          onClick={this.openDropdown}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <img
                                  data-test-id="openDropdown"
                                  src={DownArrow}
                                  alt="DownArrow"
                                  className={classes.hpDownArrowIcon}
                                />
                              </InputAdornment>
                            ),
                            readOnly: true
                          }}
                          value={this.state.kind}
                        />

                        <>
                          {this.state.showDropdown && (
                            <Box className={classes.hpSelectFieldDropdown}>
                              <Box className={classes.hpDropdownContent}>
                                <Box className={classes.dropdownText} onClick={this.openDropdown}>
                                  Select One
                                  <img
                                    src={UpwardArrow}
                                    alt="UpwardArrow"
                                    className={classes.hpUpArrowIcon}
                                    onClick={this.openDropdown}
                                  />
                                </Box>
                                <Box>
                                  {Kind.map((data: any, index: number) => (
                                    <Box data-test-id={`selectHpKind${index}`} className={classes.hpSelectKindOption} key={data.key} onClick={() => this.handleChangehp("kind", data.value)}>
                                      {data.value}
                                    </Box>
                                  ))}
                                </Box>
                              </Box>
                            </Box>
                          )}
                        </>
                      </Box>
                      </ClickAwayListener>
                      {this.errorMessage(hpErrors.kind, classes.inputErrors)}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </>
        </Grid>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(styles)(HealthCarePersonalRegistrationType);
// Customizable Area End
