import * as React from "react";
// Customizable Area Start
import { Box, Typography, Card, CardContent, Button, withStyles } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { homecureTransparent, physicianMedical, verifiedEmailIcon } from "./assets";
import { styles } from "./styles/PhoneNumberRegistration";
import EmailAccountRegistrationController, { Props } from "./EmailAccountRegistrationController";
// Customizable Area End

export class EmailVerified extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  
  render() {
    // Customizable Area Start
    const { classes } = this.props
    // Customizable Area End
    return (
      // Customizable Area Start
      <Box
        component="div"
        style={{
          backgroundImage: `url(${physicianMedical})`,
          backgroundPosition: "top",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
        sx={{
          width: "100%",
          minHeight: "100vh",
          height: "100%"
        }}
       >
        <Box className={classes.homeCureContainers}>
          <img
            src={homecureTransparent}
            alt='HomeCure Logo'
            className={classes.homecureLogos}
            style={{ objectFit: 'contain' }}
          />
        </Box>
        <Box className={classes.backButtons} onClick={this.handleBackButton}>
          <ArrowBackIosIcon style={{ width: "15px", height: "15px" }} />
        </Box>
        <Box>
          <Card className={classes.transparentCards}>
            <CardContent className={classes.cardContents} style={{ flexDirection: "column" }}>
              <img
                src={verifiedEmailIcon}
                alt='email Verified'
                className={classes.emailVerifiedLogo}
                style={{ objectFit: 'contain' }}
              />
              <Typography variant="h6" className={classes.emailMessages} style={{ textAlign: "center" }}>
                Your Email Address Verified 
              </Typography>
              <Typography variant="h6" className={classes.successEmailMessages} style={{ textAlign: "center" }}>
               Successfully
              </Typography>
              <Button
                variant="contained"
                color="primary"
                data-test-id="moveToNextPage"
                className={classes.successBtns}
                onClick={this.navigateToRegistration}
                style={{ textTransform: "capitalize" }}
              >
                Done
              </Button>
            </CardContent>
          </Card>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(styles)(EmailVerified);
// Customizable Area End
