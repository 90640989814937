import {
    Box,
    ListItemIcon,
    ListItem,
    List,
  } from "@material-ui/core";
  import {
   Bed,
   BedGray,
   Notification,
   NotificationGray,
   User,
   UserGray,
   Note,
   NoteGray,
   Home,
   HomeGray,
   HomeCure,
   PersonalCard,
   PersonalCardGray,
   Calendar,
   CalendarActive
  } from "./assets";
  import React from "react";
  import { makeStyles } from "@material-ui/core/styles";
  import { Link } from "react-router-dom";
  const sidebarMediaquery = {
    '@media (min-width: 1800px) and (max-width: 2000px)': {
      paddingLeft: "60px !important",
    },
    '@media (min-width: 1700px) and (max-width: 1800px)': {
      paddingLeft: "60px !important",
    },
    '@media (min-width: 1500px) and (max-width: 1700px)': {
      paddingLeft: "54px !important",
    },
    '@media (min-width: 1400px) and (max-width: 1500px)': {
      paddingLeft: "45px !important",
    },
    '@media (min-width: 1300px) and (max-width: 1400px)': {
      paddingLeft: "40px !important",
    },
    '@media (min-width: 1200px) and (max-width: 1300px)': {
      paddingLeft: "35px !important",
    },
    '@media (min-width: 900px) and (max-width: 1200px)': {
      paddingLeft: "35px !important",
    },
    width: "100%",
    margin: "auto",
  }

  const useStyles = makeStyles((theme) => ({
    asidemenu: {
      background: "#014866",
      minHeight:"100vh",
      height: "100%",
      top: "0px",
      left: "0px",
      width: "100%",
      caretColor:"transparent"
    },
    logo: {
      width:"100px",
      height:"90px",
      [theme.breakpoints.down("md")]: {
        width:"90px",
        height:"60px",
      },
      display: "flex",
      justifyContent: "center",
      '@media (max-width: 1400px)': {
        width:"95px",
        height:"85px",
      },
      '@media (max-width: 1300px)': {
        width:"90px",
        height:"80px",
      },
    },
    listul: {
      width: "85%",
      height:"auto"
    },
    listitem: {
      color: "#fff",
      fontSize: "16px",
      fontFamily: "poppins",
      fontWeight: 500,
      letterSpacing: "0.16px",
      lineHeight: "28px",
      paddingBottom: "50px !important",
      paddingLeft: "0px !important"
    },
    active: {
      borderRadius: '0 10px 10px 0',
      color: '#2B3D7D',
      fontSize:"16px",
      fontFamily:"poppins",
      fontWeight:500,
      letterSpacing:"0.16px",
      lineHeight:"28px",
      paddingBottom: "50px !important",
      paddingLeft: "0px !important"
    },
    activeIcon: {
      color: "#2B3D7D",
      minWidth: "40px",
      paddingLeft:"30px",
      cursor:"none",
      borderLeft:"3px solid white",
      ...sidebarMediaquery

    },
    listicon: {
      color: "#fff",
      minWidth: "40px",
      paddingLeft:"30px",
      cursor:"none",
      ...sidebarMediaquery
    },
    link:{
      textDecoration:"none",
    },
    listText: {
      fontSize: '16px',
      fontFamily: "'Poppins' !important",
      fontWeight: 500,
      letterSpacing: '0.16px',
      lineHeight: '28px',
      margin: '4px 0'
    },
    imageSize:{
      width:"30px",
      height: "30px",
      cursor:"pointer"
    },
    logoContainer: {
      display: "flex",
      justifyContent: "center",
      padding: "35px 10px 25px",
      borderBottom:" 2px solid white",
      margin: "0px 16px 25px",
    },
    border: {
      width:"20px",
      height:"20px",
      color:"#fff"
    }
  }));
  
  const Sidebar = () => {
    const classes = useStyles();
    const [role, setRole] = React.useState<any>(window.localStorage.getItem("userType"));
    const currentLocation = window.location.pathname.split("/");
    const currentPath = "/" + currentLocation[1];
    const [activeTab, setActiveTab] = React.useState(currentPath);
    const hpUserType = (localStorage.getItem("hpUserRole"))?.toLowerCase();
    const tabs =
      role === "patient"
        ? [
            { name: activeTab === "/PatientHospitaltBooking" ? "PatientHospitaltBooking" :"Dashboard", icon: HomeGray, activeIcon: Home, path: activeTab === "/PatientHospitaltBooking" ? "/PatientHospitaltBooking" :"/Dashboard"},
            { name: "Beds", icon: BedGray, activeIcon: Bed, path: "#" },
            { name: "Note", icon: NoteGray, activeIcon: Note, path: "#" },
            { name: activeTab === "/EditProfile" ? "EditProfile" :"Profile", icon: UserGray, activeIcon: User, path: activeTab === "/EditProfile" ? "/EditProfile" :"/Profile"},
            { name: "Notification", icon: NotificationGray, activeIcon: Notification, path: "#" },
          ]
        : role === "hospital"
          ? [
            { name: "Dashboard", icon: HomeGray, activeIcon: Home, path: "/Dashboard" },
            { name: "PersonalCard", icon: PersonalCardGray, activeIcon: PersonalCard, path: "#" },
            { name: "Note", icon: NoteGray, activeIcon: Note, path: "#" },
            { name: activeTab === "/UpdateHospitalProfile" ? "UpdateHospitalProfile" :"HospitalProfile", icon: UserGray, activeIcon: User, path: activeTab === "/UpdateHospitalProfile" ? "/UpdateHospitalProfile" :"/HospitalProfile"},
            { name: "Notification", icon: NotificationGray, activeIcon: Notification, path: "#" },
          ]:
          [
            { name: "Dashboard", icon: HomeGray, activeIcon: Home, path: "/Dashboard" },
            { name: "PersonalCard", icon: PersonalCardGray, activeIcon: PersonalCard, path: "#" },
            { name: "Note", icon: NoteGray, activeIcon: Note, path: "#" },
            { name: activeTab === "/UpdateHealthcarePersonneProfile" ? "UpdateHealthcarePersonneProfile" :"HealthcarePersonneProfile", icon: UserGray, activeIcon: User, path: activeTab === "/UpdateHealthcarePersonneProfile" ? "/UpdateHealthcarePersonneProfile" :"/HealthcarePersonneProfile"},
            { name: "Calendar", icon: Calendar, activeIcon: CalendarActive, path: hpUserType === "ayah" || hpUserType === "nurse" ? "/Scheduling" : "/SchedulingCalendar" },
            { name: "Notification", icon: NotificationGray, activeIcon: Notification, path: "#" },
          ];
  
  
    React.useEffect(() => {
      setRole(window.localStorage.getItem("userType"));
    }, []);
  
    return (
      <Box className={classes.asidemenu}>
        <Box className={classes.logoContainer}>
          <img src={HomeCure} className={classes.logo} alt="Logo"/>
        </Box>
        <Box style={{padding:"32px 0px"}}>
          <List className={classes.listul}>
            {tabs.map((tab: any) => (
              <Link className={classes.link} to={tab.path} key={tab.name}>
                <ListItem
                  key={tab.name}
                  button
                  className={activeTab === tab.path ? classes.active : classes.listitem}
                  onClick={() => {
                    if (tab.path !== "#") {
                      if (activeTab === tab.path) {
                        if(activeTab === "/EditProfile"){
                          setActiveTab("/EditProfile")
                          window.location.href = "/Profile"
                        }else if(activeTab === "/UpdateHospitalProfile"){
                          setActiveTab("/UpdateHospitalProfile")
                          window.location.href = "/HospitalProfile"
                        } else if(activeTab === "/UpdateHealthcarePersonneProfile"){
                          setActiveTab("/UpdateHealthcarePersonneProfile")
                          window.location.href = "/HealthcarePersonneProfile"
                        }else if(activeTab === "/PatientHospitaltBooking"){
                          setActiveTab("/PatientHospitaltBooking")
                          window.location.href = "/Dashboard"
                        }else if(activeTab === "/Dashboard"){
                          window.location.reload()
                        }else{
                          setActiveTab(tab.path);
                        }
                      }
                    }
                  }}
                >
                  <ListItemIcon
                    className={activeTab === tab.path ? classes.activeIcon : classes.listicon}
                  >
                    <img
                      src={tab.path!=="#" && activeTab === tab.path ? tab.activeIcon : tab.icon}
                      className={classes.imageSize}
                      alt={tab.name}
                    />
                  </ListItemIcon>
                </ListItem>
              </Link>
            ))}
          </List>
        </Box>
      </Box>
    );
  };
  
  export default Sidebar;