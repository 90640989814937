Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/dashboard/dashboards";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";
exports.service = "patient_services";
exports.ayahDashboardDetailsEndPoint = "healthcare_personnels/healthcare_personnel_dashboard";
exports.ayahDashboardTotalBookingDetailsEndPoint = "healthcare_personnels/total_booking";
exports.ayahDashboardUpcomingBookingDetailsEndPoint = "healthcare_personnels/upcoming_bookings";
exports.ayahDashboardPaitentHealthDetailsEndPoint = "healthcare_personnels/patient_health_details";
exports.updateHealthcarePersonnelBooking = "healthcare_personnels/update_healthcare_personnel_booking";
exports.ayahDashboardPastBookingDetailsEndPoint = "healthcare_personnels/past_bookings";
exports.patchApiMethodType = "PATCH";
// Customizable Area End