import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import React, { RefObject } from "react";
import { imgPasswordInVisible, imgPasswordVisible, ProfileHPIcon } from "./assets";
import * as YupValidation from "yup";
import { GoogleAPI } from "google-maps-react";
import { Values, HospitalValues, HealthCarePersonal, HPDoctor } from "./types";
import { setStorageData, getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import { Profile } from "./assets";
import Webcam from "react-webcam";
import { Document } from "../../customisableuserprofiles/src/types";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  google: GoogleAPI;
  setAddress?: any;
  closeSetLocationPage?: any;
  diseasesList?: any;
  step?: any;
  userLogin?: boolean;
  updatedKind?: string;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  isError: boolean;
  errorMessage: string;
  name: string;
  city: string;
  pincode: string;
  gender: string;
  address: string;
  district: string;
  age: string;
  language?: string;
  familyMemberName?: string;
  familyMemberPhone?: string;
  errors: Partial<Values>;
  mapCenter: any;
  markerPosition: any,
  showGoogleMap: boolean;
  token: string;
  step: number;
  diseasesList: any;
  selectedDiseases: any;
  showDialog: boolean;
  patient_diseases_attribute: any;
  medicines_attributes: any;
  allergies_attributes: any;
  surgeries_attributes: any;
  previous_hospital: boolean,
  height: string,
  weight: string,
  drink: boolean,
  drink_months: number,
  drink_years: number,
  smoke: boolean,
  smoke_months: number,
  smoke_years: number,
  isPresciptionDialogOpen: boolean,
  prescription: any;
  isPresciption: boolean;
  scan: any;
  isScan: boolean;
  docType: string;
  hospitalName: string;
  hospitalErrors: Partial<HospitalValues>;
  registrationNumber: string;
  bedsList: any;
  bedsType: string;
  isBedsPopoverOpen: boolean,
  bedsTypeAnchorEl: any,
  selectedBeds: any;
  departmentsList: any;
  departmentType: string;
  isDepartsPopoverOpen: boolean,
  departsTypeAnchorEl: any,
  selectedDeparts: any;
  uploadDoc: any;
  isUploadDoc: boolean;
  image: any;
  insuranceList: any;
  insuranceType: string;
  isInsurancePopoverOpen: boolean,
  insuranceTypeAnchorEl: any,
  selectedInsurance: any;
  insuranceSearch: any;
  patientExist: boolean;
  others: boolean;
  othersDiseases: any;
  healthDetailsError: any;
  date: string;
  UploadDocError: string;
  dateError: string;
  uploadFileSuccess: string;
  uploadFileError: string;
  familyMemberRelation: string;
  hpName: string;
  kind: string;
  hpErrors: Partial<HealthCarePersonal>;
  connectHospital: boolean;
  locationLimit: string;
  hpPersonExist: boolean;
  ishpDialogAppear: boolean;
  hospitalList: any;
  languagesList: any;
  specializationsList: any;
  showDropdown: boolean;
  openHospitalDropdown: boolean;
  openLanguageDropdown: boolean;
  openSpecializationeDropdown: boolean;
  openDepartmentsDropdown: boolean;
  degree: string;
  college: string;
  yearofExp: string;
  hpDocDetailsErr: Partial<HPDoctor>;
  selectedHospitals: string;
  selectedSpecializations: string;
  selectedLanguages: any;
  selectedDepartments: string;
  educationDetails: any,
  longTermBooking: boolean;
  ayahList: any;
  selectedAyah: string;
  aadharCardNumber: string;
  openAyahDropdown: boolean;
  degreeList: any;
  openDegreeDropdown: boolean;
  isEditing: boolean;
  editableIndex: string;
  editOpenDegreeDropdown: boolean;
  openFile: boolean;
  openDialog: boolean;
  editHealthDetailsSuccess: boolean;
  profilePicture: string;
  enableWeb: boolean;
  url: any;
  UploadImageError: string;
  healthcarePersonnelError: string;
  hospitalErrorMessage: string;
  loader: boolean;
  skipped: boolean;
  messageType: string;
  selectedBedsName: any;
  selectedInsurancesName: any;
  selectedDepartsName: any;
  showAllergyAddButton: boolean;
  showSurgeryAddButton: boolean;
  showAddButton: boolean;
  showMedicineAddButton: boolean;
  phoneNumber: string;
  contactNumber: string;
  newMail: string;
  showHPSubmitBtn: boolean;
  otpVerified: string;
  otpSend: boolean;
  emailSend: boolean;
  setToken: string;
  resendCounter: number;
  isResendActive: boolean;
  phoneOTP: any;
  confimEmail: boolean;
  verifyType: string;
  latitude: any;
  longitude: any;
  profile_image: Document | null;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  createAccountEmailApiCallId: any;
  registerApiCallId: any;
  diseasesApiCallId: any;
  validationApiCallId: any;
  currentUserPatientApiCall: any;

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;
  updateRegisterApiCallId: any;
  currentCountryCode: any;
  fileInputRef: any;
  getBedsApiCallId: any;
  getDepartmentsApiCallId: any;
  getInsurancesApiCallId: any;
  registerHospitalApiCallId: any;
  uploadDocAPiCallId: any;
  getHospitalCallId: any;
  getSpecializationsCallId: any;
  getLanguagesCallId: any;
  registerHpApiCallId: any;
  getAyahCallId: any;
  getPatienHealthdetailsCallId: any;
  getDegreeCallId: any;
  myElementRef: any;
  getHPDoctorCallId: any;
  uploadProfileAPICallId: any;
  webCameraRef: any;
  verifyCallId: string = "";
  verifyOTPCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      isError: false,
      errorMessage: "",
      name: "",
      city: "",
      pincode: "",
      gender: "",
      language: "",
      familyMemberName: "",
      familyMemberPhone: "",
      age: "",
      address: "",
      district: "",
      errors: {},
      mapCenter: { lat: 28.6139, lng: 77.2090 },
      markerPosition: { lat: 28.6139, lng: 77.2090 },
      showGoogleMap: false,
      token: "",
      step: 1,
      diseasesList: [],
      selectedDiseases: [],
      showDialog: true,
      patient_diseases_attribute: [],
      medicines_attributes: [{
        id: null,
        name: "",
        days: ""
      }],
      allergies_attributes: [{ id: null, name: "" }],
      surgeries_attributes: [{ id: null, name: "", surgery_months: "", surgery_years: "" }],
      previous_hospital: false,
      height: "",
      weight: "",
      drink: false,
      drink_months: 0,
      drink_years: 0,
      smoke: false,
      smoke_months: 0,
      smoke_years: 0,
      isPresciptionDialogOpen: false,
      prescription: null,
      isPresciption: false,
      scan: null,
      isScan: false,
      docType: "",
      hospitalName: "",
      hospitalErrors: {},
      registrationNumber: "",
      bedsList: [],
      bedsType: "",
      isBedsPopoverOpen: false,
      bedsTypeAnchorEl: null,
      selectedBeds: [],
      departmentsList: [],
      departmentType: "",
      isDepartsPopoverOpen: false,
      departsTypeAnchorEl: null,
      selectedDeparts: [],
      uploadDoc: null,
      isUploadDoc: false,
      image: null,
      insuranceList: [],
      insuranceType: "",
      isInsurancePopoverOpen: false,
      insuranceTypeAnchorEl: null,
      selectedInsurance: [],
      insuranceSearch: "",
      patientExist: false,
      others: false,
      othersDiseases: [
        {
          id: null,
          disease_name: "",
          disease_months: "",
          disease_years: ""
        }
      ],
      healthDetailsError: {
        medicine: "",
        allergy: "",
        surgery: "",
        otherDiseases: "",
        height: "",
        weight: ""
      },
      date: "",
      UploadDocError: "",
      dateError: "",
      uploadFileSuccess: "",
      uploadFileError: "",
      familyMemberRelation: "",
      hpName: "",
      kind: "",
      hpErrors: {},
      connectHospital: true,
      locationLimit: "6",
      hpPersonExist: false,
      ishpDialogAppear: true,
      hospitalList: [],
      languagesList: [],
      specializationsList: [],
      showDropdown: false,
      openHospitalDropdown: false,
      openLanguageDropdown: false,
      openSpecializationeDropdown: false,
      openDepartmentsDropdown: false,
      degree: "",
      college: "",
      yearofExp: "",
      hpDocDetailsErr: {},
      selectedHospitals: "",
      selectedSpecializations: "",
      selectedLanguages: [],
      selectedDepartments: "",
      educationDetails: [
        {
          degree: "",
          college: "",
          degreeCursor: false,
          collegeCursor: false,
          showPrevious: true,
        }
      ],
      longTermBooking: true,
      ayahList: [],
      selectedAyah: "",
      aadharCardNumber: "",
      openAyahDropdown: false,
      degreeList: [],
      openDegreeDropdown: false,
      isEditing: false,
      editableIndex: "",
      editOpenDegreeDropdown: false,
      openFile: false,
      openDialog: false,
      editHealthDetailsSuccess: false,
      profilePicture: Profile,
      enableWeb: false,
      url: null,
      UploadImageError: "",
      healthcarePersonnelError: "",
      hospitalErrorMessage: "",
      loader: false,
      skipped: false,
      messageType: "",
      selectedBedsName: [],
      selectedInsurancesName: [],
      selectedDepartsName: [],
      showAllergyAddButton: true,
      showSurgeryAddButton: true,
      showAddButton: true,
      showMedicineAddButton: true,
      phoneNumber: "",
      contactNumber: "",
      newMail: '',
      showHPSubmitBtn: false,
      otpVerified: "",
      otpSend: false,
      emailSend: false,
      setToken: "",
      resendCounter: 0,
      isResendActive: false,
      phoneOTP: ["", "", "", ""],
      confimEmail: false,
      verifyType: "",
      latitude: null,
      longitude: null,
      profile_image: null,
      // Customizable Area End
    };

    // Customizable Area Startphone
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    this.fileInputRef = React.createRef();
    this.myElementRef = React.createRef();
    this.webCameraRef = React.createRef();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    var responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    var errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    const apiCallMappings: { [key: string]: (responseJson: any, errorReponse: any) => void } = {
      [this.validationApiCallId]: (responseJson) => {
        this.arrayholder = responseJson.data;
        this.checkValidation();
      },
      [this.createAccountApiCallId]: (responseJson) => {
        this.createAccountResponse(responseJson);
      },
      [this.createAccountEmailApiCallId]: (responseJson, errorReponse) => {
        this.registerEmailResponse(responseJson);
        this.parseApiCatchErrorResponse(errorReponse);
      },
      [this.diseasesApiCallId]: (responseJson, errorReponse) => {
        this.diseasesReponse(responseJson);
        this.parseApiCatchErrorResponse(errorReponse);
      },
      [this.getBedsApiCallId]: (responseJson, errorReponse) => {
        this.getBedsReponse(responseJson);
        this.parseApiCatchErrorResponse(errorReponse);
      },
      [this.getDepartmentsApiCallId]: (responseJson, errorReponse) => {
        this.getDepartmentsReponse(responseJson);
        this.parseApiCatchErrorResponse(errorReponse);
      },
      [this.getInsurancesApiCallId]: (responseJson, errorReponse) => {
        this.getInsuranceResponse(responseJson);
        this.parseApiCatchErrorResponse(errorReponse);
      },
      [this.registerApiCallId]: (responseJson, errorReponse) => {
        this.registerPatientResponse(responseJson);
        this.parseApiCatchErrorResponse(errorReponse);
      },
      [this.registerHospitalApiCallId]: (responseJson, errorReponse) => {
        this.registerHospitaltResponse(responseJson);
        this.parseApiCatchErrorResponse(errorReponse);
      },
      [this.updateRegisterApiCallId]: (responseJson) => {
        this.updatePatientDetailsResponse(responseJson);
      },
      [this.uploadDocAPiCallId]: (responseJson, errorReponse) => {
        this.uploadDocumentResponse(responseJson);
        this.parseApiCatchErrorResponse(errorReponse);
      },
      [this.getHospitalCallId]: (responseJson, errorReponse) => {
        this.getHospitalReponse(responseJson);
        this.parseApiCatchErrorResponse(errorReponse);
      },
      [this.getSpecializationsCallId]: (responseJson, errorReponse) => {
        this.getSpecializationsReponse(responseJson);
        this.parseApiCatchErrorResponse(errorReponse);
      },
      [this.getLanguagesCallId]: (responseJson) => {
        this.getLanguagesReponse(responseJson);
      },
      [this.registerHpApiCallId]: (responseJson, errorReponse) => {
        this.registerHPResponse(responseJson);
        this.parseApiCatchErrorResponse(errorReponse);
      },
      [this.getAyahCallId]: (responseJson) => {
        this.getAyahReponse(responseJson);
      },
      [this.getPatienHealthdetailsCallId]: (responseJson) => {
        this.getPatienHealthdetailsReponse(responseJson);
      },
      [this.getDegreeCallId]: (responseJson) => {
        this.getDegreeReponse(responseJson);
      },
      [this.getHPDoctorCallId]: (responseJson) => {
        this.checkUsersHealthcarePersonnelProfile(responseJson);
      },
      [this.uploadProfileAPICallId]: (responseJson) => {
        this.updateHPProfile(responseJson);
      },
      [this.verifyCallId]: (responseJson) => {
        this.verifyUserPhoneOrEmail(responseJson);
      },
      [this.verifyOTPCallId]: (responseJson) => {
        this.verifyOTPResponse(responseJson);
      },
    };

    for (const apiCallId in apiCallMappings) {
      if (this.getApiResponse(apiCallId, message)) {
        apiCallMappings[apiCallId](responseJson, errorReponse);
        break; // Exit the loop after the first match
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  uploadDocumentResponse = (responseJson: any) => {
    if (!responseJson.errors && !responseJson.error) {
      let uploadFileSuccessMessage = '';
      if (responseJson.data.attributes.document_type === 'report') {
        setStorageData("report", responseJson.data.id)
        uploadFileSuccessMessage = "Scan and Report uploaded successfully!";
      }
      if (responseJson.data.attributes.document_type === 'prescription') {
        setStorageData("prescription", responseJson.data.id)
        uploadFileSuccessMessage = "Prescription uploaded successfully!";
      }
      this.setState({ uploadFileSuccess: uploadFileSuccessMessage, loader: false })
      setTimeout(() => {
        this.handelPrescriptionDialogClose();
      }, 1000);
    } else {
      this.setState({ uploadFileError: "Something went wrong,Please try uploading the file again!" })
    }
  }

  getApiResponse = (apiCallId: string, message: Message): boolean => {
    return (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      apiCallId != null &&
      apiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    );
  };

  updatePatientDetailsResponse = async (responseJson: any) => {
    if (!responseJson.errors && !responseJson.error) {
      this.redirectToSubimission()
    } else {
      this.showAlert("Error", responseJson.errors ? responseJson.errors.message : responseJson.error.message);
    }
    this.parseApiErrorResponse(responseJson);
  }

  skipHeathDetailsRegistration = () => {
    this.setState({ skipped: true });
  }

  redirectToSubimission = async () => {
    const isUser = await this.isUserLogin();
    if (isUser === "true" || this.props.userLogin) {
      this.setState({ editHealthDetailsSuccess: true })
    } else {
      this.redirectToSuccessPage()
    }
  }

  registerPatientResponse = async (responseJson: any) => {
    if (!responseJson.errors && !responseJson.error) {
      this.navigateTHealthDetails()
      this.setState({ step: 2 });
      const patientData = { ...responseJson.patient.data.attributes, selectedLanguages: this.state.selectedLanguages }
      setStorageData("patientDetails", JSON.stringify(patientData))
      setStorageData("patientId", responseJson.patient.data.id)
    } else {
      this.showAlert("Error", responseJson.errors ? responseJson.errors.message : responseJson.error.message);
    }
    this.parseApiErrorResponse(responseJson);
  }

  registerHospitaltResponse = async (responseJson: any) => {
    if (!responseJson.errors) {
      this.setState({ hospitalErrorMessage: "" })
      this.redirectToHospitalSuccessPage()
    } else {
      const identificationNoError = responseJson.errors.message.registration_no;
      if (identificationNoError && identificationNoError[0]) {
        this.setState({ hospitalErrorMessage: "Registration number has already been registered." });
      } else {
        this.setState({ hospitalErrorMessage: responseJson.errors.message });
      }
    }
  }

  registerHPResponse = async (responseJson: any) => {
    if (!responseJson.errors) {
      removeStorageData("hpDetails");
      this.setState({ healthcarePersonnelError: "", messageType: "Success" })
      if (!this.props.userLogin) {
        this.redirectToSuccessPage();
      } else {
        this.setState({ healthcarePersonnelError: `${this.state.kind} profile updated successfully.`, messageType: "Success" });
      }
    } else {
      const identificationNoError = responseJson.errors.message?.identification_no || responseJson.errors.message;
      if (identificationNoError && identificationNoError[0]) {
        if (this.state.kind === "Ayah") {
          this.setState({ healthcarePersonnelError: "Aadhar card number has already been registered.", messageType: "Error" });
        } else {
          this.setState({ healthcarePersonnelError: "Registration number has already been registered.", messageType: "Error" });
        }
      } else {
        this.setState({ healthcarePersonnelError: responseJson.errors.message, messageType: "Error" });
      }
    }
  }


  diseasesReponse = (responseJson: any) => {
    if (!responseJson.errors && !responseJson.error) {
      this.setState({ diseasesList: responseJson })
    } else {
      this.showAlert("Error", responseJson.errors ? responseJson.errors.message : responseJson.error.message);
    }
  }

  getBedsReponse = async (responseJson: any) => {
    if (!responseJson.errors) {
      this.setState((prevState) => ({
        ...prevState,
        bedsList: responseJson?.data
      }));
    } else {
      this.showAlert("Error", responseJson.errors.message);
    }
  }

  getDepartmentsReponse = (responseJson: any) => {
    if (!responseJson.errors) {
      this.setState((prevState) => ({
        ...prevState,
        departmentsList: responseJson?.data
      }));
    } else {
      this.showAlert("Error", responseJson.errors.message);
    }
  }

  getInsuranceResponse = (responseJson: any) => {
    if (!responseJson.errors) {
      this.setState((prevState) => ({
        ...prevState,
        insuranceList: responseJson?.data
      }));
    } else {
      this.showAlert("Error", responseJson.errors.message);
    }
  }
  patientRegistrationExist = ((responseJson: any) => {
    if (responseJson.patient?.patient_id) {
      return responseJson.patient.patient_id
    } else {
      removeStorageData("patientDetails")
    }
  })

  registerEmailResponse = (responseJson: any) => {
    if (!responseJson.errors && !responseJson.error) {
      setStorageData("email", this.state.email);
      setStorageData("authToken", responseJson.meta.token)
      removeStorageData("patientDetails")
      this.props.navigation.navigate("ConfirmEmail");
    } else {
      this.setState({ errorMessage: responseJson.errors ? responseJson.errors.message : responseJson.error.message, isError: true });
    }
  }

  getEmail = async () => {
    const email = await getStorageData("email") as string;
    return email;
  }

  isUserLogin = async () => {
    const isLogin = await getStorageData("isLogin");
    return isLogin;
  }

  getPatientData = async () => {
    const patientDetails = await getStorageData("patientDetails") as string;
    return JSON.parse(patientDetails);
  }

  redirectToEmailVerified = () => {
    this.props.navigation.navigate("EmailVerified");
  }

  createAccountResponse = (responseJson: any) => {
    if (!responseJson.errors && !responseJson.error) {
      this.updateLocalStorage(responseJson.meta.token)
      this.props.navigation.navigate("OTPInputAuth")
    } else {
      this.setState({
        errorMessage: responseJson.errors ? responseJson.errors.message : responseJson.error.message,
        isError: true
      });
    }
  }

  getHospitalReponse = async (responseJson: any) => {
    if (!responseJson.errors) {
      this.setState((prevState) => ({
        ...prevState,
        hospitalList: responseJson?.data
      }));
    } else {
      this.showAlert("Error", responseJson.errors.message);
    }
  }

  getSpecializationsReponse = async (responseJson: any) => {
    if (!responseJson.errors) {
      this.setState((prevState) => ({
        ...prevState,
        specializationsList: responseJson?.data
      }));
    } else {
      this.showAlert("Error", responseJson.errors.message);
    }
  }

  getLanguagesReponse = async (responseJson: any) => {

    if (!responseJson.errors) {
      this.setState({
        languagesList: responseJson?.data
      });
    } else {
      this.showAlert("Error", responseJson.errors.message);
    }
  }

  getAyahReponse = async (responseJson: any) => {
    if (!responseJson.errors) {
      this.setState({
        ayahList: responseJson?.data
      });
    } else {
      this.showAlert("Error", responseJson.errors.message);
    }
  }

  getDegreeReponse = async (responseJson: any) => {
    if (!responseJson.errors) {
      this.setState({
        degreeList: responseJson?.data
      });
    } else {
      this.showAlert("Error", responseJson.errors.message);
    }
  }

  monthToNumber = (monthName: string) => {
    const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    return months.indexOf(monthName) + 1;
  };

  extractMedicines = (healthDetails: any) => {
    return healthDetails.medicine.map((item: any) => ({
      id: item.id,
      name: item.name,
      days: JSON.stringify(item.days)
    }));
  };

  extractAllergies = (healthDetails: any) => {
    return healthDetails.allergy.map((item: any) => ({
      id: item.id,
      name: item.name
    }));
  };

  extractSurgeries = (healthDetails: any) => {
    return healthDetails.surgery.map((item: any) => ({
      id: item.id,
      name: item.name,
      surgery_months: item.surgery_months,
      surgery_years: JSON.stringify(item.surgery_years)
    }));
  };

  extractDiseases = (healthDetails: any, diseasesList: any) => {
    const matchedDiseases: { id: any, disease_id: any, disease_name: any, disease_months: any, disease_years: any }[] = [];
    const unmatchedDiseases: any[] = [];
    const selectedDiseases: any[] = [];
    const months: { [key: string]: number } = {
      "January": 1, "February": 2, "March": 3, "April": 4, "May": 5, "June": 6,
      "July": 7, "August": 8, "September": 9, "October": 10, "November": 11, "December": 12
    };

    healthDetails.disease.forEach((disease: any) => {
      const matchedDiseaseDetails = diseasesList.find(
        (diseases: any) => diseases.name.toLowerCase() === disease.disease_name.toLowerCase()
      );

      if (matchedDiseaseDetails && disease.is_custom_disease === null) {
        matchedDiseases.push({
          id: disease.id,
          disease_id: matchedDiseaseDetails.id,
          disease_name: disease.disease_name,
          disease_months: months[disease.disease_months],
          disease_years: disease.disease_years,
        });
        selectedDiseases.push(matchedDiseaseDetails.id);
      } else {
        unmatchedDiseases.push({
          id: disease.id,
          disease_name: disease.disease_name,
          disease_months: months[disease.disease_months],
          disease_years: disease.disease_years,
        });
      }
    });

    return { matchedDiseases, unmatchedDiseases, selectedDiseases };
  };

  getHealthInfoAttributes = (healthInfo: any) => {
    if (!healthInfo) return null;
    const { height, weight, previous_hospital, smoke, smoke_months, smoke_years, drink, drink_months, drink_years } = healthInfo;

    return {
      height: height?.toString(),
      weight: weight?.toString(),
      previous_hospital,
      smoke,
      smoke_months: this.monthToNumber(smoke_months) || 0,
      smoke_years: smoke_years || 0,
      drink,
      drink_months: this.monthToNumber(drink_months) || 0,
      drink_years: drink_years || 0,
    };
  };

  getPatienHealthdetailsReponse = async (responseJson: any) => {
    const diseasesList = this.props.diseasesList;
    if (!responseJson.errors) {
      const apiResponse = responseJson;
      setStorageData("patientId", apiResponse.patient_id);

      const medicines = this.extractMedicines(apiResponse.health_details);
      const allergies = this.extractAllergies(apiResponse.health_details);
      const surgeries = this.extractSurgeries(apiResponse.health_details);
      const { matchedDiseases, unmatchedDiseases, selectedDiseases } = this.extractDiseases(apiResponse.health_details, diseasesList);
      const healthInfoAttributes = this.getHealthInfoAttributes(apiResponse.health_details.health_info);

      this.setState(prevState => ({
        ...prevState,
        selectedDiseases,
        patient_diseases_attribute: matchedDiseases,
        medicines_attributes: medicines.length > 0 ? medicines : prevState.medicines_attributes,
        allergies_attributes: allergies.length > 0 ? allergies : prevState.allergies_attributes,
        surgeries_attributes: surgeries.length > 0 ? surgeries : prevState.surgeries_attributes,
        others: unmatchedDiseases.length > 0,
        othersDiseases: unmatchedDiseases.length > 0 ? unmatchedDiseases : prevState.othersDiseases,
        ...healthInfoAttributes,
      }));
    } else {
      this.showAlert("Error", responseJson.errors.message);
    }
  };

  updateLocalStorage = (token: string) => {
    setStorageData("isSignUp", "true")
    setStorageData("phoneNumber", this.state.phone);
    setStorageData("authToken", token)
    setStorageData("isLogin", "false")
  }

  checkValidation = () => {
    if (this.arrayholder && this.arrayholder.length !== 0) {
      let regexData = this.arrayholder[0];

      if (regexData.password_validation_regexp) {
        this.passwordReg = new RegExp(
          regexData.password_validation_regexp
        );
      }

      if (regexData.password_validation_rules) {
        this.setState({
          passwordHelperText: regexData.password_validation_rules
        });
      }

      if (regexData.email_validation_regexp) {
        this.emailReg = new RegExp(regexData.email_validation_regexp);
      }
    }
  }

  goToPrivacyPolicy() {
    const msgs: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msgs.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msgs);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  createAccount(): boolean {
    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }

    if (!this.isValidEmail(this.state.email)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    }

    if (!this.passwordReg.test(this.state.password)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorPasswordNotValid);
      return false;
    }

    if (this.state.password !== this.state.reTypePassword) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorBothPasswordsNotSame
      );
      return false;
    }

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      full_phone_number: "+" + this.state.countryCodeSelected + this.state.phone
    };

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken
    };

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnSignUpProps = {
    onPress: () => this.createAccount()
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    && this.txtInputEmailWebPrpos;


  txtPhoneNumberProps = this.isPlatformWeb() && ""

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };


  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  checkPhoneValidation = () => {
    const { phone } = this.state;

    if (!phone) {
      this.setState({ errorMessage: "Phone number is required", isError: true });
      return false;
    }

    if (!/^[6-9]\d{9}$/.test(phone)) {
      this.setState({
        errorMessage: "Invalid phone number",
        isError: true
      });
      return false;
    }

    this.setState({
      errorMessage: "",
      isError: false
    });
    return true;
  };

  handleBackButton = async () => {
    this.props.navigation.goBack();
  };

  backToLogin = async () => {
    this.props.navigation.navigate("Dashboard");
  };

  backToHosptalLogin = () => {
    this.props.navigation.navigate("MobileAccountLoginBlock");
  };

  navigateTHealthDetails = async () => {
    const token = await getStorageData("authToken");
    this.getdiseasesList(token);
  }

  backToStep1 = () => {
    this.setState({ step: 3 })
  };

  checkIfUserLogin = (userLogin: any) => {
    if (userLogin) {
      this.props.navigation.navigate("CustomisableViewUserProfile")
    } else {
      this.backToStep1()
    }
  }

  phoneNumberHandelChanges = (event: any) => {
    const phone = event.target.value.trimStart();
    if (/^\d{0,10}$/.test(phone)) {
      this.setState({ phone, isError: false });
    }
  };

  emailHandelChange = (event: any) => {
    const email = event.target.value.trimStart();
    this.setState({ email, isError: false });
  };


  async registerViaPhone() {
    if (this.checkPhoneValidation()) {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      const header = {
        "Content-Type": configJSON.contentTypeApiAddDetail,
      };

      this.createAccountApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.sendOTPConfirmation
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      const userType = await getStorageData("userType");
      const httpBody: any = {
        data: {
          attributes: {
            type: "signup",
            user_type: userType,
            full_phone_number: `+91${this.state.phone}`
          }
        }
      }

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeAddDetail
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  }

  emailScheme = YupValidation.object().shape({
    email: YupValidation.string().required("Email is required.").email("Please enter valid email")
  });

  async registerViaEmail() {
    if (!this.state.email) {
      this.setState({ errorMessage: "Email is required", isError: true });
      return false;
    }

    try {
      await this.emailScheme.validate({ email: this.state.email });
    } catch (error) {
      this.setState({ errorMessage: 'Invalid email', isError: true });
      return false;
    }

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.createAccountEmailApiCallId = requestMessage.messageId;

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.emailVeified);
    const token = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: token
    };

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeAddDetail);

    const userType = await getStorageData("userType");

    const httpBody = {
      data: {
        type: "email_account",
        attributes: {
          user_type: userType,
          email: this.state.email
        }
      }
    };

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  registerValidationSchema = YupValidation.object().shape({
    gender: YupValidation.string()
      .notOneOf(["Enter gender"], "Gender is required")
      .required("Gender is required"),
    address: YupValidation.string()
      .required("Address is required")
      .matches(/^\S.*$/, 'Address is required'),
    district: YupValidation.string()
      .required("District is required")
      .matches(/^\S.*$/, 'District is required')
      .matches(/^[a-zA-Z\s]+$/, "District should contain only alphabets"),
    name: YupValidation.string()
      .required("Name is required")
      .matches(/^\S.*$/, 'Name is required')

      .test('is-valid-name', 'Invalid name', function (value) {
        if (value !== '' && value !== null) {
          const regex = /^(?=[a-zA-Z]*[aeiou])[a-zA-Z]{2,}(?:\s+[a-zA-Z]{2,}){0,11}$/;
          if (regex.test(value)) {
            const enterName = value.split(' ');
            for (const username of enterName) {
              if (!/[aeiou]/i.test(username)) {
                return false;
              }
            }
            return true;
          } else {
            return false;
          }
        }
        return true;
      }),
    city: YupValidation.string()
      .required("City is required").matches(/^\S.*$/, 'City is required')
      .matches(/^[a-zA-Z\s]+$/, "City should contain only alphabets"),
    pincode: YupValidation.string()
      .required("Pin Code is required")
      .matches(/^\d{0,9}$/, "Invalid Pin Code")
      .matches(/^\d{6}$/, "Pin Code must be 6 digits"),
    age: YupValidation.string()
      .required("Age is required")
      .matches(/^(?:\b(?:[0-9]\d?|1[01]\d|110)\b)$/, "Invalid age")
      .test('is-valid-name', 'Invalid age', function (value) {
        if (value > 110) {
          return false
        } else {
          return true
        }
      })
      .matches(/^\S.*$/, 'Age is required'),
    familyMemberPhone: YupValidation.string()
      .nullable()
      .notRequired()
      .test('is-valid-name', 'Invalid phone number', function (value) {
        if (value !== null && value !== '') {
          return /^[6-9]\d{9}$/.test(value);
        }
        return true;
      }),
    familyMemberName: YupValidation.string()
      .nullable()
      .notRequired()
      .test('is-valid-name', 'Invalid family member name', function (value) {
        if (value !== null && value !== '') {
          const regex = /^(?=[a-zA-Z]*[aeiou])[a-zA-Z]{2,}(?:\s+[a-zA-Z]{2,}){0,11}$/;
          if (regex.test(value)) {
            const words = value.split(' ');
            for (const word of words) {
              if (!/[aeiou]/i.test(word)) {
                return false;
              }
            }
            return true;
          } else {
            return false;
          }
        }
        return true;
      }),
    familyMemberRelation: YupValidation.string()
      .nullable()
      .notRequired()
      .test('is-valid-name', 'Family member relation should contain only alphabets', function (value) {
        if (value !== null && value !== '') {
          return /^[a-zA-Z\s]+$/.test(value);
        }
        return true;
      }),
  });

  handleChange = (field: keyof Values, value: any) => {
    try {
      value = value?.replace(/ {2,}/g, ' ');
      const fieldValues: Partial<Values> = {
        [field]: value,
      };
      this.registerValidationSchema.validateSyncAt(field, fieldValues as Values);
      this.setState((prevState) => ({
        ...prevState,
        [field]: value,
        errors: { ...prevState.errors, [field]: "" },
      }));
    } catch (error) {
      if (error instanceof YupValidation.ValidationError) {
        const errorMessage = error.message;
        this.setState((prevState) => ({
          ...prevState,
          [field]: value?.trimStart(),
          errors: { ...prevState.errors, [field]: errorMessage },
        }));
      }
    }
  };

  moveNextPage = () => {
    this.setState({ step: 2 });
  }

  handleSubmit = async () => {
    const { ...prevState } = this.state;
    let formNext = true;
    let langExist = true
    Object.keys(prevState).forEach((field) => {
      try {
        const fieldValues: Partial<Values> = {
          [field]: prevState[field as keyof Values],
        };
        this.registerValidationSchema.validateSyncAt(field, fieldValues as Values);
        this.setState((prevState) => ({
          ...prevState,
          errors: { ...prevState.errors, [field]: "" },
        }));
      } catch (error) {
        if (error instanceof YupValidation.ValidationError) {
          const errorMessage = error.message;
          formNext = false;
          this.setState((prevState) => ({
            ...prevState,
            errors: { ...prevState.errors, [field]: errorMessage },
          }));
        }
      }
    });

    if (formNext && langExist) {
      this.addPatientDetailsApi()
    }
  };

  getdiseasesList = (token: string) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.diseasesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDiseasesAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  checkPatientExist = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.currentUserPatientApiCall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.currentUserPatient
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    const token = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  addPatientDetailsApi = async () => {
    let language_ids = this.state.selectedLanguages.map((indexNum: string) => Number(indexNum));
    let patientData = {
      name: this.state.name,
      age: parseInt(this.state.age),
      gender: this.state.gender,
      address_attributes: {
        address: this.state.address,
        district: this.state.district,
        city: this.state.city,
        pincode: this.state.pincode,
        latitude: parseFloat(this.state.latitude),
        longitude: parseFloat(this.state.longitude),
      },
      language_ids,
      fm_name: this.state.familyMemberName,
      fm_phone_number: this.state.familyMemberPhone,
      fm_relation: this.state.familyMemberRelation
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.registerApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.registerPatient
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(patientData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    const token = await getStorageData("authToken");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  onMapClick = (mapProps: any, event: any) => {
    const lats = event.latLng.lat();
    const lngs = event.latLng.lng();

    this.setState({ markerPosition: { lats, lngs } });

    const geocoder = new mapProps.google.maps.Geocoder();
    geocoder?.geocode(
      { location: { lats, lngs } },
      (results: any, status: any) => {
        if (status === "OK" && results && results[0]) {
          this.setState({ address: results[0].formatted_address });
        }
      }
    );
  };

  openSetLocationPage = () => {
    this.setState({ showGoogleMap: true });
  };

  closeSetLocationPage = () => {
    this.setState({ showGoogleMap: false });
  };

  setAddressFromChild = (address: string) => {
    this.setState({ address });
  };

  setAddress = () => {
    const { setAddress, closeSetLocationPage } = this.props;
    setAddress(this.state.address);
    closeSetLocationPage();
  };

  backToRegister = () => {
    const { closeSetLocationPage } = this.props;
    closeSetLocationPage();
  };

  setDiseaseMonths = (event: any, diseaseId: any) => {
    const selectedMonths = event;
    this.updateDiseaseAttribute(diseaseId, "disease_months", selectedMonths);
  };

  setDiseaseYears = (event: any, diseaseId: any) => {
    const selectedYears = event;
    this.updateDiseaseAttribute(diseaseId, "disease_years", parseInt(selectedYears));
  };

  updateDiseaseAttribute = (diseaseId: any, attribute: any, value: any) => {
    this.setState((prevState: any) => {
      const updatedAttributes = prevState.patient_diseases_attribute.map((disease: any) => {
        if (disease.disease_id === diseaseId) {
          return {
            ...disease,
            [attribute]: value,
          };
        }
        return disease;
      });

      return {
        patient_diseases_attribute: updatedAttributes,
      };
    });
  };

  handleDiseasesOthers = () => {
    this.setState((prevState) => ({
      others: !prevState.others,
      othersDiseases: [
        {
          id: null,
          disease_name: "",
          disease_months: "",
          disease_years: ""
        }
      ]
    }));
  };

  handleDiseaseCheckboxChange = (diseaseId: number, disease_name: string) => {
    this.setState((prevState: any) => {
      const isSelected = prevState.selectedDiseases.includes(diseaseId);
      if (isSelected) {
        const updatedAttributes = prevState.patient_diseases_attribute.filter(
          (disease: any) => disease.disease_id !== diseaseId
        );

        const patientID = prevState.patient_diseases_attribute
          .filter((disease: any) => disease.disease_id === diseaseId)
          .map((disease: any) => disease.id)[0];

        if (patientID) {
          updatedAttributes.push({
            "_destroy": true,
            "id": patientID
          })
        }
        return {
          selectedDiseases: prevState.selectedDiseases.filter((desId: any) => desId !== diseaseId),
          patient_diseases_attribute: updatedAttributes,
        };
      } else {
        return {
          selectedDiseases: [...prevState.selectedDiseases, diseaseId],
          patient_diseases_attribute: [
            ...prevState.patient_diseases_attribute,
            {
              disease_id: diseaseId,
              disease_name,
              disease_months: "",
              disease_years: "",
            },
          ],
        };
      }
    });
  };

  handleMedicineChange = (index: number, field: string, value: string) => {
    const updatedMedicines = [...this.state.medicines_attributes];
    const isDuplicateName = field === "name" && this.state.medicines_attributes.some(
      (medicine: any, indexNum: any) => indexNum !== index && medicine.name.toLowerCase() === value.toLowerCase()
    );
    if (field === "name") {
      value = value?.replace(/ {2,}/g, ' ');
    }
    // Update the medicine value
    if (updatedMedicines[index] && updatedMedicines[index][field] !== undefined) {
      updatedMedicines[index][field] = value;
    }

    let updatedHealthDetailsError = { ...this.state.healthDetailsError };

    // Check for errors and update state accordingly
    if (field === 'name') {
      if (value.trim() === "") {
        updatedHealthDetailsError[`medicine${index}`] = "Please enter medicine name";
        this.setState({
          healthDetailsError: updatedHealthDetailsError,
          showMedicineAddButton: false,
          medicines_attributes: updatedMedicines
        });
        return;
      } else if (isDuplicateName) {
        updatedHealthDetailsError[`medicine${index}`] = "Duplicate medicine name";
        this.setState({
          healthDetailsError: updatedHealthDetailsError,
          showMedicineAddButton: false,
          medicines_attributes: updatedMedicines
        });
        return;
      } else {
        updatedHealthDetailsError[`medicine${index}`] = "";
      }
    } else if (field === 'days' && !updatedMedicines[index].name?.trim()) {
      updatedHealthDetailsError[`medicine${index}`] = "Please enter medicine name";
      this.setState({
        healthDetailsError: updatedHealthDetailsError,
        showMedicineAddButton: false,
        medicines_attributes: updatedMedicines
      });
      return;
    }

    // Check for any remaining errors
    const hasError = Object.keys(updatedHealthDetailsError)
      .filter(key => key.startsWith('medicine'))
      .some(key => updatedHealthDetailsError[key] !== "");
    // Update state
    this.setState({
      healthDetailsError: updatedHealthDetailsError,
      showMedicineAddButton: !hasError,
      medicines_attributes: updatedMedicines
    });
  };

  handleOthersDiseasesChange = (index: number, field: string, value: any) => {
    this.setState(prevState => {
      const updatedDiseases = [...prevState.othersDiseases];

      if (field === "disease_name") {
        value = value?.replace(/ {2,}/g, ' ');
      }

      // Update only the specific field that the user edits
      if (index >= 0 && index < updatedDiseases.length) {
        updatedDiseases[index][field] = field === "disease_name" ? value.trimStart() : value;
      }

      const otherDiseasesErrorMessage = this.getOtherDiseasesErrorMessage(updatedDiseases, index, value, field);

      const healthDetailsError = {
        ...prevState.healthDetailsError,
        [`otherDiseases${index}`]: field === "disease_name" ? otherDiseasesErrorMessage : this.state.healthDetailsError[`otherDiseases${index}`]
      };

      // Check if any error message exists
      const hasErrors = Object.keys(healthDetailsError).filter(key => key.startsWith('otherDiseases')).some(key => healthDetailsError[key] !== "");

      return {
        othersDiseases: updatedDiseases,
        healthDetailsError,
        showAddButton: !hasErrors
      };
    });
  };

  getOtherDiseasesErrorMessage = (updatedDiseases: any[], index: number, value: string, field: string) => {
    if (field !== "disease_name") {
      return "";
    }

    if (!value || value.trim() === '') {
      return "Please enter disease name";
    }

    const words = value.split(' ');
    if (words.length < 1 || words.length > 5) {
      return "Invalid disease name";
    }

    const invalidRegex = /[^a-zA-Z0-9 -]/;
    if (invalidRegex.test(value)) {
      return "Invalid disease name";
    }
    const largestWord = words.reduce((largest: any, current: any) => largest.length >= current.length ? largest : current);
    const { isValid, hasValidWord, largestWordWithAlphabet } = this.validateWords(words, largestWord);

    if (!this.validLargestText(isValid, hasValidWord, largestWordWithAlphabet)) {
      return "Invalid disease name";
    } else if (this.checkDiseasesDuplicate(updatedDiseases, index, value)) {
      return "Duplicate disease name";
    } else {
      return "";
    }
  };

  validateWords = (words: string[], largestWord: string) => {
    let isValid = true;
    let hasValidWord = false;
    let largestWordWithAlphabet = '';

    for (const word of words) {
      if (!/^[a-zA-Z0-9-]+$/.test(word)) {
        isValid = false;
        break;
      }
      if (word.length >= 3 && word.match(/[a-zA-Z-]/) && word.match(/[aeiou]/i)) {
        hasValidWord = true;
        if (word === largestWord) {
          largestWordWithAlphabet = word;
          break; // Stop iterating if largest word is found
        }
      }
    }

    return { isValid, hasValidWord, largestWordWithAlphabet };
  };

  validLargestText = (isValid: boolean, hasValidWord: boolean, largestWordWithAlphabet: string) => {
    return isValid && hasValidWord && largestWordWithAlphabet !== '';
  };

  checkDiseasesDuplicate = (updatedDiseases: any[], index: number, value: string) => {
    return updatedDiseases.some((disease, descIndex) => descIndex !== index && disease.disease_name === value);
  };

  handleAddDiseases = () => {
    const lastDisease = this.state.othersDiseases[this.state.othersDiseases.length - 1];

    if (lastDisease && lastDisease.disease_name === "") {
      this.setState(prevState => ({
        healthDetailsError: {
          ...prevState.healthDetailsError,
          [`otherDiseases${prevState.othersDiseases.length - 1}`]: "Please enter disease name"
        },
        showAddButton: false
      }));
    } else {
      this.setState(prevState => ({
        othersDiseases: [...prevState.othersDiseases, { disease_name: "" }],
        healthDetailsError: {
          ...prevState.healthDetailsError,
          [`otherDiseases${prevState.othersDiseases.length}`]: ""
        },
        showAddButton: true
      }));
    }
  };

  handleAddMedicine = () => {
    const lastMedicine = this.state.medicines_attributes[this.state.medicines_attributes.length - 1];

    if (lastMedicine && lastMedicine.name?.trim() !== "") {
      // If the last medicine has a non-empty name, add a new empty medicine
      this.setState((prevState) => ({
        medicines_attributes: [...prevState.medicines_attributes, { name: "", days: "" }],
        healthDetailsError: {
          ...prevState.healthDetailsError,
          [`medicine${prevState.medicines_attributes.length - 1}`]: "" // Clear any previous error
        },
        showMedicineAddButton: true
      }));
    } else {
      // If the last medicine name is empty or contains only spaces, show an error
      this.setState((prevState) => ({
        healthDetailsError: {
          ...prevState.healthDetailsError,
          [`medicine${prevState.medicines_attributes.length - 1}`]: "Please enter medicine name"
        },
        showMedicineAddButton: false
      }));
    }
  };


  handleAllergyChange = (index: number, field: string, value: string) => {
    const updatedAllergies = [...this.state.allergies_attributes];
    const isDuplicate = updatedAllergies.some((allergy, allergyIndex) => allergyIndex !== index && allergy[field].toLowerCase() === value.toLowerCase());
    value = value?.replace(/ {2,}/g, ' ');
    // Create a copy of the current error state
    const updatedHealthDetailsError = { ...this.state.healthDetailsError };

    // Reset error message for the current allergy
    updatedHealthDetailsError[`allergy${index}`] = "";

    // Check for specific conditions and update error message accordingly
    if (index < updatedAllergies.length - 1 && value.trim() === "") {
      updatedHealthDetailsError[`allergy${index}`] = "Please enter allergy name";
    } else if (isDuplicate) {
      updatedHealthDetailsError[`allergy${index}`] = "Duplicate allergy name";
    }

    // Update state with the new allergies and error messages
    this.setState({
      allergies_attributes: updatedAllergies,
      healthDetailsError: updatedHealthDetailsError
    });


    const hasErrors = Object.keys(updatedHealthDetailsError)
      .filter(key => key.startsWith('allergy')).some(key => updatedHealthDetailsError[key] !== "");
    // Update the value of the current allergy
    updatedAllergies[index][field] = value;
    this.setState({ allergies_attributes: updatedAllergies, showAllergyAddButton: !hasErrors });
  };

  handleAddAllergy = () => {
    const lastAllergy = this.state.allergies_attributes[this.state.allergies_attributes.length - 1];

    if (lastAllergy && lastAllergy.name?.trim() !== "") {
      // If the last allergy has a non-empty name, add a new empty allergy
      this.setState((prevState) => ({
        allergies_attributes: [...prevState.allergies_attributes, { id: null, name: "" }],
        healthDetailsError: {
          ...prevState.healthDetailsError,
          [`allergy${prevState.allergies_attributes.length}`]: "" // Clear any previous error
        },
        showAllergyAddButton: true
      }));
    } else {
      // If the last allergy name is empty or contains only spaces, show an error
      this.setState((prevState) => ({
        healthDetailsError: {
          ...prevState.healthDetailsError,
          [`allergy${prevState.allergies_attributes.length - 1}`]: "Please enter allergy name"
        },
        showAllergyAddButton: false
      }));
    }
  };

  checkSurgeryDuplicasy = (updatedSurgeries: any, index: number, value: string) => {
    return updatedSurgeries.some((surgery: any, surgeryIndex: any) => surgeryIndex !== index && surgery.name === value)
  }

  isValid = (isValid: boolean, validWord: boolean, largestWordAlphabet: string) => {
    const state = !isValid || !validWord || largestWordAlphabet === '';
    return state;
  }

  checkLength = (word: any) => {
    return word?.length < 1 || word?.length > 10
  }

  validateWord = (wordExist: string, largestWordExist: string) => {
    if (!/^[a-zA-Z0-9-]+$/.test(wordExist)) {
      return { isValid: false, validWord: false, largestWordAlphabet: '' };
    }

    let validWord = false;
    let largestWordAlphabet = '';
    if (this.checkWordValidation(wordExist)) {
      validWord = true;
      if (wordExist === largestWordExist) {
        largestWordAlphabet = wordExist;
      }
    }

    return { isValid: true, validWord, largestWordAlphabet };
  };

  validateSurgeryName = (value: string, index: number, updatedSurgeries: any) => {
    const updatedHealthDetailsError = { ...this.state.healthDetailsError };

    const wordsExist = value.split(' ');
    if (this.checkLength(wordsExist)) {
      updatedHealthDetailsError[`surgery${index}`] = "Invalid surgery";
    } else {
      const largestWordExist = wordsExist.reduce((largest, current) =>
        largest.length >= current.length ? largest : current
      );

      let isValid = true;
      let validWord = false;
      let largestWordAlphabet = '';

      for (const wordExist of wordsExist) {
        const validationResult = this.validateWord(wordExist, largestWordExist);
        isValid = validationResult.isValid;
        validWord = validationResult.validWord;
        largestWordAlphabet = validationResult.largestWordAlphabet;

        if (!isValid || largestWordAlphabet) {
          break;
        }
      }
      updatedHealthDetailsError[`surgery${index}`] = this.updateHealthDetailsError(isValid, validWord, largestWordAlphabet, updatedSurgeries, index, value);
    }

    return updatedHealthDetailsError;
  };

  checkWordValidation = (wordExist: any) => {
    return wordExist.length >= 3 && /[aeiou]/i.test(wordExist) && /[a-zA-Z-]/.test(wordExist);
  };

  updateHealthDetailsError = (isValid: boolean, validWord: any, largestWordAlphabet: any, updatedSurgeries: any, index: number, value: string) => {
    let error;

    if (this.isValid(isValid, validWord, largestWordAlphabet)) {
      error = "Invalid surgery";
    } else if (this.checkSurgeryDuplicasy(updatedSurgeries, index, value)) {
      error = "Duplicate surgery name";
    } else {
      error = "";
    }

    return error;
  };

  handleSurgeryChange = (index: number, field: string, value: string) => {
    const updatedSurgeries = [...this.state.surgeries_attributes];
    if (field === "name") {
      value = value?.replace(/ {2,}/g, ' ');
    }
    const updatedSurgery = { ...updatedSurgeries[index], [field]: value };
    updatedSurgeries[index] = updatedSurgery;

    let updatedHealthDetailsError = { ...this.state.healthDetailsError };

    if (field === "name") {
      if (value !== null && value?.trim() !== '') {
        const invalidCharRegex = /[^a-zA-Z0-9 -]/;
        if (invalidCharRegex.test(value)) {
          updatedHealthDetailsError[`surgery${index}`] = "Invalid surgery name";
        } else {
          updatedHealthDetailsError = this.validateSurgeryName(value, index, updatedSurgeries);
        }
      } else {
        updatedHealthDetailsError[`surgery${index}`] = "Please enter surgery name";
      }
    }

    const hasSurgeryErrors = Object.keys(updatedHealthDetailsError).filter(key => key.startsWith('surgery')).some(key => updatedHealthDetailsError[key] !== "");

    this.setState({
      healthDetailsError: updatedHealthDetailsError,
      surgeries_attributes: updatedSurgeries,
      showSurgeryAddButton: !hasSurgeryErrors
    });
  };

  handelAddSurgery = () => {
    const lastSurgery = this.state.surgeries_attributes[this.state.surgeries_attributes.length - 1];

    if (lastSurgery && lastSurgery.name?.trim() !== "") {
      // If the last surgery has a non-empty name, add a new empty surgery
      this.setState((prevState) => ({
        surgeries_attributes: [...prevState.surgeries_attributes, { id: null, name: "", surgery_months: "", surgery_years: "" }],
        healthDetailsError: {
          ...prevState.healthDetailsError,
          [`surgery${prevState.surgeries_attributes.length}`]: "" // Clear any previous error
        },
        showSurgeryAddButton: true
      }));
    } else {
      // If the last surgery name is empty or contains only spaces, show an error
      this.setState((prevState) => ({
        healthDetailsError: {
          ...prevState.healthDetailsError,
          [`surgery${prevState.surgeries_attributes.length - 1}`]: "Please enter surgery name"
        },
        showSurgeryAddButton: false
      }));
    }
  };

  handleSmokeChange = () => {
    this.setState((prevState: any) => ({
      smoke: !prevState.smoke,
      smoke_months: !prevState.smoke ? prevState.smoke_months : 0,
      smoke_years: !prevState.smoke ? prevState.smoke_year : 0
    }));
  };


  handleDrinkChange = () => {
    this.setState((prevState) => ({
      drink: !prevState.drink,
      drink_months: !prevState.drink ? prevState.drink_months : 0,
      drink_years: !prevState.drink ? prevState.drink_years : 0
    }));
  };

  handleDrinkMonthChange = (selectedMonth: number) => {
    this.setState({
      drink_months: selectedMonth,
    });
  };

  handleDrinkYearChange = (selectedYear: number) => {
    this.setState({
      drink_years: selectedYear,
    });
  };

  handleSmokeMonthChange = (selectedMonth: number) => {
    this.setState({
      smoke_months: selectedMonth,
    });
  };

  handleSmokeYearChange = (selectedYear: number) => {
    this.setState({
      smoke_years: selectedYear,
    });
  };

  handlePreviousHospitalChange = () => {
    this.setState((prevState) => ({
      previous_hospital: !prevState.previous_hospital,
    }));
  };

  healthDetailsValidation = YupValidation.object().shape({
    height: YupValidation.string()
      .nullable()
      .notRequired()
      .matches(/^(?:\d+(?:\.\d{1,3})?)?$/, "Enter height should be between 30 and 250 cm")
      .test('is-valid-height', 'Enter height should be between 30 and 250 cm', function (value) {
        if (!value.trim()) return true; // If value is empty or contains only whitespace, skip validation
        const floatValue = parseFloat(value);
        return floatValue >= 30 && floatValue <= 250;
      }),
    weight: YupValidation.string()
      .nullable()
      .notRequired()
      .matches(/^(?:\d+(?:\.\d{1,3})?)?$/, "Enter weight should be between 3 and 160 kg")
      .test('is-valid-weight', 'Enter weight should be between 3 and 160 kg', function (value) {
        if (!value.trim()) return true; // If value is empty or contains only whitespace, skip validation
        const floatValue = parseFloat(value);
        return floatValue >= 3 && floatValue <= 160;
      })
  });


  handleHealthDetailsChange = (fieldName: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value.trim();
    try {
      const fieldValues: any = {
        [fieldName]: inputValue,
      };
      this.healthDetailsValidation.validateSyncAt(fieldName, fieldValues);
      this.setState((prevState) => ({
        ...prevState,
        [fieldName]: inputValue,
        healthDetailsError: { ...prevState.healthDetailsError, [fieldName]: "" },
      }));
    } catch (error) {
      if (error instanceof YupValidation.ValidationError) {
        const errorMessage = error.message;
        this.setState((prevState) => ({
          ...prevState,
          [fieldName]: inputValue,
          healthDetailsError: { ...prevState.healthDetailsError, [fieldName]: errorMessage },
        }));
      }
    }
  };

  changeHeight = this.handleHealthDetailsChange('height');
  changeWeight = this.handleHealthDetailsChange('weight');


  handleDialogClose = () => {
    this.setState({ showDialog: false });
  };

  getSmokeMonthData = (smoke_months: any) => {
    return smoke_months > 0 ? this.state.smoke_months : null
  }

  getSmokeYearData = (smoke_years: any) => {
    return smoke_years > 0 ? this.state.smoke_years : null
  }

  getDrinkMonthData = (drink_months: any) => {
    return drink_months > 0 ? this.state.drink_months : null
  }

  getDrinkYearData = (drink_years: any) => {
    return drink_years > 0 ? this.state.drink_years : null
  }

  getDiseasesId = (disease: any) => {
    return disease || null
  }

  getDiseasesMonth = (disease: any) => {
    return parseInt(disease?.disease_months) || null
  }

  getDiseasesYear = (disease: any) => {
    return parseInt(disease?.disease_years) || null
  }

  submitPatientDetails = async () => {
    const { othersDiseases } = this.state;
    let otherDiseaseState = [...this.state.patient_diseases_attribute];

    if (Array.isArray(othersDiseases) && othersDiseases.length > 0) {
      othersDiseases.forEach((disease) => {
        if (disease.disease_name !== "") {
          otherDiseaseState.push({
            id: this.getDiseasesId(disease.id),
            disease_name: disease.disease_name,
            disease_months: this.getDiseasesMonth(disease),
            disease_years: this.getDiseasesYear(disease)
          });
        }
      });
    }
    const allKeysEmpty = Object.values(this.state.healthDetailsError).every(value => value === "" || value === undefined);
    if (allKeysEmpty) {
      let datas: any = {
        patient_health_info_attributes: {
          previous_hospital: this.state.previous_hospital,
          height: this.state.height,
          weight: this.state.weight,
          smoke: this.state.smoke,
          smoke_months: this.getSmokeMonthData(this.state.smoke_months),
          smoke_years: this.getSmokeYearData(this.state.smoke_years),
          drink: this.state.drink,
          drink_months: this.getDrinkMonthData(this.state.drink_months),
          drink_years: this.getDrinkYearData(this.state.drink_years)
        },
        patient_diseases_attributes: otherDiseaseState,
      }

      const filteredMedicines = this.state.medicines_attributes.filter((medicine: any) => medicine.name.trim() !== '');
      const filteredAllergies = this.state.allergies_attributes.filter((allergie: any) => allergie.name.trim() !== '');
      const filteredSurgeries = this.state.surgeries_attributes.filter((surgerie: any) => surgerie.name.trim() !== '');
      if (filteredMedicines.length > 0) {
        datas = {
          ...datas,
          medicines_attributes: filteredMedicines,
        };
      }

      if (filteredAllergies.length > 0) {
        datas = {
          ...datas,
          allergies_attributes: filteredAllergies,
        };
      }

      if (filteredSurgeries.length > 0) {
        datas = {
          ...datas,
          surgeries_attributes: filteredSurgeries,
        };
      }
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.updateRegisterApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.updateRegisterPatient
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(datas)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeUpdateDetail
      );

      const token = await getStorageData("authToken");

      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: token,
      };

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  }

  redirectToSuccessPage = () => {
    this.props.navigation.navigate("RegistrationSuccess")
  }

  redirectToHospitalSuccessPage = () => {
    this.props.navigation.navigate("HospitalRegistrationSuccess")
  }

  handelPrescriptionDialog: (docType: string) => void = (docType) => {
    this.setState((prevState) => ({
      isPresciptionDialogOpen: !prevState.isPresciptionDialogOpen,
      docType: docType,
      date: "",
      dateError: "",
      UploadDocError: "",
      prescription: null,
      isPresciption: false,
      scan: null,
      isScan: false,
      uploadFileSuccess: ""
    }));
  };

  handelPrescriptionDialogClose = () => {
    this.setState({
      isPresciptionDialogOpen: false,
      docType: "",
      date: "",
      dateError: "",
      UploadDocError: "",
      prescription: null,
      isPresciption: false,
      scan: null,
      isScan: false,
      uploadFileSuccess: "",
    });
  };

  handleButtonClick = () => {
    this.fileInputRef.current.click();
  };

  handleUploadPrescription = (event: any) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const allowedExtensions = ['jpg', 'jpeg', 'png', 'pdf'];
      const extension = selectedFile.name.split('.').pop();
      if (!allowedExtensions.includes(extension)) {
        this.setState({
          UploadDocError: 'Please select a file with a valid extension (.jpg, .jpeg, .png, .pdf)'
        });
        return;
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        let base64data;

        if (typeof reader.result === 'string') {
          base64data = reader.result.split(',')[1];
        }

        const content_type = selectedFile.type;
        const filename = selectedFile.name;

        if (this.state.docType === "upload") {
          this.setState({
            prescription: {
              data: base64data,
              content_type: content_type,
              filename: filename,
            },
            isPresciption: true,
            UploadDocError: ""
          });
        } else {
          this.setState({
            scan: {
              data: base64data,
              content_type: content_type,
              filename: filename,
            },
            isScan: true,
            UploadDocError: ""
          });
        }
      };

      reader.readAsDataURL(selectedFile);
    }
  };

  uploadDocument = async (fileData: any) => {
    if (this.state.date === "") {
      this.setState({
        dateError: "Date field is required",
      });
    } else {
      this.setState({
        dateError: "",
      });
    }

    if (fileData === null) {
      this.setState({
        UploadDocError: "Please upload documents"
      });
    } else {
      this.setState({
        UploadDocError: "",
      });
    }

    if (this.state.date && fileData) {
      this.setState({
        loader: true
      });
      const patientId = await getStorageData("patientId");
      let uploadDocument = {
        document: {
          document_type: this.state.isPresciption ? "prescription" : "report",
          patient_id: patientId,
          date: this.state.date,
          healthcare_personnel_id: null,
          file_data: fileData
        }
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.uploadDocAPiCallId = requestMessage.messageId;
      const docUploadAPI = configJSON.uploadDoc
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        docUploadAPI
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(uploadDocument)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeAddDetail
      );

      const authorizedToken = await getStorageData("authToken");

      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: authorizedToken,
      };

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);

      return true;
    } else {

      return false;

    }
  };

  hospitalRegisterValidationSchema = YupValidation.object().shape({
    hospitalName: YupValidation.string()
      .required("Hospital name is required")
      .matches(/^\S.*$/, 'Hospital name is required')
      .matches(/^[a-zA-Z\s]+$/, "Please enter valid name"),
    city: YupValidation.string()
      .required("City is required").matches(/^\S.*$/, 'City is required')
      .matches(/^[a-zA-Z\s]+$/, "Enter valid city"),
    pincode: YupValidation.string()
      .required("Pin Code is required")
      .matches(/^\d{0,9}$/, "Invalid Pin Code")
      .matches(/^\d{6}$/, "Pin Code must be 6 digits"),
    address: YupValidation.string()
      .required("Address is required")
      .matches(/^\S.*$/, 'Enter valid address'),
    district: YupValidation.string()
      .required("District is required")
      .matches(/^\S.*$/, 'District is required')
      .matches(/^[a-zA-Z\s]+$/, "Enter valid district"),
    registrationNumber: YupValidation.string()
      .required("Registration number is required")
      .matches(/^\d{0,9}$/, "Enter valid registration no")
      .matches(/^\d{8}$/, "Enter valid registration no"),
    bedsType: YupValidation.string().required("Please select a bed"),
    departmentType: YupValidation.string().required("Please select departments"),
    insuranceType: YupValidation.string().required("Please select insurances"),
    uploadDoc: YupValidation.string().required("Please upload a valid document")
  });

  getBedsList = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getBedsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getBedsAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    const token = await getStorageData("authToken");

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getDepartmentsList = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDepartmentsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDepartments
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    const token = await getStorageData("authToken");

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getInsurancesList = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getInsurancesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getInsurances
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    const token = await getStorageData("authToken");

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleHospitalChange = (field: keyof HospitalValues, value: any) => {
    try {
      const fieldValues: Partial<HospitalValues> = {
        [field]: value,
      };
      this.hospitalRegisterValidationSchema.validateSyncAt(field, fieldValues as HospitalValues);
      this.setState((prevState) => ({
        ...prevState,
        [field]: value,
        hospitalErrors: { ...prevState.hospitalErrors, [field]: "" },
      }));
    } catch (error) {
      if (error instanceof YupValidation.ValidationError) {
        const errorMessage = error.message;
        this.setState((prevState) => ({
          ...prevState,
          [field]: value,
          hospitalErrors: { ...prevState.hospitalErrors, [field]: errorMessage },
        }));
      }
    }
  };

  handleBedsCheckboxChange = (bedId: any) => {
    this.setState((prevState: any) => ({
      selectedBeds: prevState.selectedBeds.includes(bedId)
        ? prevState.selectedBeds.filter((serialNo: any) => serialNo !== bedId)
        : [...prevState.selectedBeds, bedId],
      hospitalErrors: { ...prevState.hospitalErrors, bedsType: '' },
    }), () => {
      const updatedBedsList = this.state.bedsList.map((bedData: any) => {
        if (bedData.id === bedId && !this.state.selectedBeds.includes(bedId)) {
          return {
            ...bedData,
            attributes: {
              ...bedData.attributes,
              count: bedData.attributes.count,
              price: bedData.attributes.price,
            },
          };
        }
        if (bedData.id === bedId && this.state.selectedBeds.includes(bedId)) {
          return {
            ...bedData,
            attributes: {
              ...bedData.attributes,
              count: "",
              price: "",
            },
          };
        }
        return bedData;
      });

      this.setState({
        bedsList: updatedBedsList,
      });
    });
  };


  handleDepartmentsCheckboxChange = (departId: any) => {
    this.setState((prevState: any) => ({
      selectedDeparts: prevState.selectedDeparts.includes(departId)
        ? prevState.selectedDeparts.filter((serialNum: any) => serialNum !== departId)
        : [...prevState.selectedDeparts, departId],
      hospitalErrors: { ...prevState.hospitalErrors, departmentType: '' },
    }));
  };


  handleUploadDoc = (event: any) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const allowedExtensions = ['jpg', 'jpeg', 'png'];
      const extension = selectedFile.name.split('.').pop();
      if (!allowedExtensions.includes(extension)) {
        this.setState((prevState: any) => ({
          hospitalErrors: { ...prevState.hospitalErrors, uploadDoc: 'Invalid file type. Please upload only image' },
          hpDocDetailsErr: { ...prevState.hpDocDetailsErr, uploadDoc: 'Invalid file type. Please upload only image' },
        }));
        return;
      } else {
        this.setState((prevState: any) => ({
          hospitalErrors: { ...prevState.hospitalErrors, uploadDoc: '' },
          hpDocDetailsErr: { ...prevState.hpDocDetailsErr, uploadDoc: '' },
        }));
      }

      const reader = new FileReader();

      reader.onloadend = () => {
        let base64Data;

        if (typeof reader.result === 'string') {
          base64Data = reader.result.split(',')[1];
        }

        const contentType = selectedFile.type;
        const filename = selectedFile.name;

        this.setState({
          uploadDoc: {
            base64Data,
            contentType,
            filename,
          },
          isUploadDoc: true,
          image: reader.result
        });
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  handleInsuranceCheckboxChange = (insuranceId: any) => {
    this.setState((prevState: any) => ({
      selectedInsurance: prevState.selectedInsurance.includes(insuranceId)
        ? prevState.selectedInsurance.filter((serialNum: any) => serialNum !== insuranceId)
        : [...prevState.selectedInsurance, insuranceId],
      hospitalErrors: { ...prevState.hospitalErrors, insuranceType: '' },
    }));
  };


  handleSelectedBeds = () => {
    let hasErrorMsg = false;
    const errorMessage = 'Please enter count and price for selected bed types.';

    const updatedBedList = this.state.bedsList.map((beds: any) => {
      if (this.state.selectedBeds.includes(beds.id)) {
        const countValue = beds.attributes.count;
        let priceValue = beds.attributes.price;

        if (priceValue && priceValue.includes('.') && priceValue.split('.')[1] === '') {
          priceValue += '0';
        }

        if (!countValue || !priceValue) {
          hasErrorMsg = true;
        }
        return {
          ...beds,
          dirty: false,
          attributes: {
            ...beds.attributes,
            price: priceValue,
          },
        };
      }
      return beds;
    });

    if (hasErrorMsg) {
      this.setState(prevState => ({
        hospitalErrors: {
          ...prevState.hospitalErrors,
          bedsType: errorMessage
        }
      }));
      return;
    }

    const selectedBeds = updatedBedList
      .filter((beds: any) => this.state.selectedBeds.includes(beds.id))
      .map((beds: any) => beds.attributes.name);

    this.setState({
      bedsList: updatedBedList, // Save updated bedsList
      bedsType: selectedBeds.join(', '),
      isBedsPopoverOpen: false,
      selectedBedsName: this.state.selectedBeds,
      hospitalErrors: {
        ...this.state.hospitalErrors,
        bedsType: '' // Clear error message
      }
    });
  };

  selectedDepartemnts = () => {
    const selectedDeparts = this.state.departmentsList
      .filter((department: any) => this.state.selectedDeparts.includes(department.id))
      .map((department: any) => department.attributes.name);

    this.setState({
      departmentType: selectedDeparts.join(', '),
      isDepartsPopoverOpen: false,
      selectedDepartsName: this.state.selectedDeparts
    });
  };

  selectedInsurance = () => {
    const selectedInsurance = this.state.insuranceList
      .filter((insurance: any) => this.state.selectedInsurance.includes(insurance.id))
      .map((insurance: any) => insurance.attributes.name);

    this.setState({
      insuranceType: selectedInsurance.join(', '),
      isInsurancePopoverOpen: false,
      selectedInsurancesName: this.state.selectedInsurance
    });
  };

  handleInsuranceSearch = (searchTerm: string) => {
    this.setState({ insuranceSearch: searchTerm });
  };

  handleHospitalDetailsSubmit = async () => {
    const { ...prevState } = this.state;
    let formNext = true;

    Object.keys(prevState).forEach((field) => {
      try {
        if (field === 'uploadDoc' && this.state.uploadDoc === null) {
          formNext = false;
          this.setState((prevState) => ({
            ...prevState,
            hospitalErrors: { ...prevState.hospitalErrors, uploadDoc: 'Please upload a valid document' },
          }));
          return
        } else {
          this.setState((prevState) => ({
            ...prevState,
            hospitalErrors: { ...prevState.hospitalErrors, prescription: null },
          }));
        }
        const fieldValues: Partial<HospitalValues> = {
          [field]: prevState[field as keyof typeof prevState],
        };
        this.hospitalRegisterValidationSchema.validateSyncAt(field, fieldValues as HospitalValues);
        const isHospitalAllErrorsCleared = Object.values(this.state.hospitalErrors).every((error) => error === "" || error === undefined || error === null);
        if (isHospitalAllErrorsCleared) {
          this.setState((prevState) => ({
            ...prevState,
            hospitalErrors: { ...prevState.hospitalErrors, [field]: "" },
          }));
          formNext = true;
        }else{
          formNext = false;
        }
      } catch (error) {
        if (error instanceof YupValidation.ValidationError) {
          const errorMessage = error.message;
          formNext = false;
          this.setState((prevState) => ({
            ...prevState,
            hospitalErrors: { ...prevState.hospitalErrors, [field]: errorMessage },
          }));
        }
      }
    });
    if (formNext) {
      this.addHospitalDetailsApi()
    }
  };


  addHospitalDetailsApi = async () => {
    const newArrBed = this.state.selectedBeds.reduce((bedDetails: any, bedId: any) => {
      const selectedBed = this.state.bedsList.find((beds: any) => beds.id === bedId);

      if (selectedBed) {
        const existingBedIndex = bedDetails.findIndex((beds: any) => beds.bed_id === selectedBed.id);

        if (existingBedIndex === -1) {
          bedDetails.push({
            bed_id: parseInt(selectedBed.id),
            count: parseInt(selectedBed.attributes.count),
            price: parseFloat(selectedBed.attributes.price),
          });
        }
      }
      return bedDetails;
    }, []);

    let newArrDep = this.state.departmentsList.filter((department: any) => this.state.selectedDeparts.includes(department.id)).map(({ id }: any) => parseInt(id));
    let newArrInsaurance = this.state.insuranceList.filter((insurance: any) => this.state.selectedInsurance.includes(insurance.id)).map(({ id }: any) => parseInt(id));

    const newData = {
      name: this.state.hospitalName,
      registration_no: this.state.registrationNumber,
      certificate: {
        data: this.state.uploadDoc.base64Data,
        filename: this.state.uploadDoc.filename,
        content_type: this.state.uploadDoc.contentType
      },
      address_attributes: {
        address: this.state.address,
        district: this.state.district,
        city: this.state.city,
        pincode: this.state.pincode,
        latitude: parseFloat(this.state.latitude),
        longitude: parseFloat(this.state.longitude),
      },
      beds:
        newArrBed
      ,
      departments: newArrDep,
      insurances: newArrInsaurance
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.registerHospitalApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.registerHospital
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(newData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    const token = await getStorageData("authToken");

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  navigateToRegistration = async () => {
    const userType = await getStorageData('userType');
    if (userType === "patient") {
      this.props.navigation.navigate("PatientRegisterDetails")
    } else if (userType === "hospital") {
      this.props.navigation.navigate("HospitalRegisterDetails")
    } else {
      this.props.navigation.navigate("HealthCarePersonalRegistrationType")
    }
  };

  setPatientDetails = (patientData: any) => {
    const {
      name = '',
      gender = '',
      language = '',
      fm_name: familyMemberName = '',
      fm_phone_number: familyMemberPhone = '',
      fm_relation: familyMemberRelation = '',
      address: {
        data: {
          attributes: {
            address = '',
            pincode = '',
            city = '',
            district = '',
          } = {},
        } = {},
      } = {},
      selectedLanguages = []
    } = patientData;

    this.setState({
      patientExist: !!name,
      name,
      age: patientData.age || '',
      gender,
      language,
      familyMemberName,
      familyMemberPhone,
      familyMemberRelation,
      address,
      pincode,
      city,
      district,
      selectedLanguages
    });
  };

  customStyle = {
    dropdownIndicator: (base: any, state: any) => ({
      ...base,
      "&:hover": {
        borderColor: "#3f51b5 !important"
      },
      borderWidth: "2px !important",
      background: "#ffffff",
      borderRadius: "8px !important",
      alignItems: "center !important",
      transitEditProfileion: "all .2s ease",
      transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null
    }),
    menuList: (styles: any) => ({
      ...styles,
      background: 'white',
      borderRadius: "10px !important",
      color: "#808080",
      fontSize:"16px",
      fontFamily: "SF Pro Text Regular !important",
      overflowY: "auto",
      "-ms-overflow-style": "none",
      "scrollbar-width": "none",
      "&& ::-webkit-scrollbar": {
        display: "none"
      },
      maxHeight: "500px"
    }),
    option: (styles: any, { isSelected }: { isSelected: boolean }) => ({
      ...styles,
      fontFamily: isSelected ? "SF Pro Text Bold !important":"SF Pro Text Regular !important",
      backgroundColor: "white",
      borderColor: "hsl(0, 2.2%, 64.3%)",
      color: isSelected ? "#292929":"#808080",
      fontSize:"16px",
      zIndex: 1,
      cursor: "pointer !important",
    }),
    control: (baseStyles: any, state: any) => ({
      ...baseStyles,
      borderWidth: "1px !important",
      borderRadius: "8px !important",
      borderColor: "hsl(0, 2.2%, 64.3%)",
      backgroundColor: "#ffffff",
      cursor: "pointer",
      boxShadow: "none !important",
      '&:hover': {
        borderColor: "#3f51b5 !important",
        borderWidth: "1px !important",
        padding: "8px"
      },
      '&:visited': {
        borderColor: "#3f51b5 !important",
        borderWidth: "2px !important",
        padding: "8px"
      },
    }),
  };

  handleDateChange = (date: any) => {
    const currentDate = new Date(date);
    const formattedDate = currentDate.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });

    this.setState({ date: formattedDate, dateError: "" })
  };
  handleCountChange = (bedId: any, newCount: any) => {
    const regex = /^\d{0,9}$/
    let selectedBed = this.state.selectedBeds.includes(bedId);
    if (!selectedBed) return false;
    const updatedBedsList = this.state.bedsList.map((beds: any) => {
      if (beds.id === bedId) {
        return {
          ...beds,
          attributes: {
            ...beds.attributes,
            count: newCount,
          },
          dirty: true,
        };
      }
      return beds;
    });
    if (newCount === '' || regex.test(newCount)) {
      this.setState({
        bedsList: updatedBedsList,
      });
    }
  };

  handlePriceChange = (bedId: any, newPrice: any) => {
    const regex = /^-?\d+(\.\d*)?$/;
    let selectedBedDetails = this.state.selectedBeds.includes(bedId);
    if (!selectedBedDetails) return false;

    const updatedBedsList = this.state.bedsList.map((beds: any) => {
      if (beds.id === bedId) {
        return {
          ...beds,
          attributes: {
            ...beds.attributes,
            price: newPrice.trimStart(),
          },
          dirty: true,
        };
      }
      return beds;
    });

    if (newPrice === '' || regex.test(newPrice)) {
      this.setState({
        bedsList: updatedBedsList,
      });
    }
  };


  //-----------------Health Care------------------

  hpRegisterValidationSchema = YupValidation.object().shape({
    hpName: YupValidation.string()
      .required("Name is required")
      .matches(/^\S.*$/, 'Name is required')
      .test('is-valid-name', 'Invalid name', function (value) {
        if (value !== null && value !== "") {
          const regexId = /^(?=[a-zA-Z]*[aeiou])[a-zA-Z]{2,}(?:\s+[a-zA-Z]{2,}){0,11}$/;
          if (regexId.test(value)) {
            const hpNames = value.split(' ');
            for (const hpName of hpNames) {
              if (!/[aeiou]/i.test(hpName)) {
                return false;
              }
            }
            return true;
          } else { return false; }
        }
        return true;
      }),
    kind: YupValidation.string()
      .notOneOf(["Select One"], "Healthcare personnel type required")
      .required("Healthcare personnel type required")
  });

  handleChangehp = (field: keyof HealthCarePersonal, value: any) => {
    try {
      value = value?.replace(/ {2,}/g, ' ');
      const fieldValues: Partial<HealthCarePersonal> = {
        [field]: value,
      };
      this.hpRegisterValidationSchema.validateSyncAt(field, fieldValues as HealthCarePersonal);
      this.setState((prevState) => ({
        ...prevState,
        [field]: value,
        showDropdown: false,
        hpErrors: { ...prevState.hpErrors, [field]: "" },
      }));
    } catch (error) {
      if (error instanceof YupValidation.ValidationError) {
        const errorMessage = error.message;
        this.setState((prevState) => ({
          ...prevState,
          [field]: value,
          showDropdown: false,
          hpErrors: { ...prevState.hpErrors, [field]: errorMessage },
        }));
      }
    }
  };

  hphandleSubmit = async () => {
    const { ...prevState } = this.state;
    let formNext = true;
    Object.keys(prevState).forEach((field) => {
      try {
        const fieldValues: Partial<HealthCarePersonal> = {
          [field]: prevState[field as keyof HealthCarePersonal],
        };
        this.hpRegisterValidationSchema.validateSyncAt(field, fieldValues as HealthCarePersonal);
        this.setState((prevState) => ({
          ...prevState,
          hpErrors: { ...prevState.hpErrors, [field]: "" },
        }));
      } catch (error) {
        if (error instanceof YupValidation.ValidationError) {
          const errorMessage = error.message;
          formNext = false;
          this.setState((prevState) => ({
            ...prevState,
            hpErrors: { ...prevState.hpErrors, [field]: errorMessage },
          }));
        }
      }
    })
    if (formNext) {
      const hpDetails = {
        hpName: this.state.hpName,
        kind: this.state.kind
      }
      setStorageData("hpUserRole", this.state.kind);
      setStorageData("hpDetails", JSON.stringify(hpDetails));
      if (this.state.kind === "Doctor") {
        this.props.navigation.navigate("HealthCarePersonalRegistration");
      } else if (this.state.kind === "Ayah") {
        this.props.navigation.navigate("HealthCarePersonnelAyahRegistration");
      } else {
        this.props.navigation.navigate("HealthCarePersonnelRegistration");
      }
    }
  };

  hpConnectHospitalChange = () => {
    this.setState((prevState) => ({
      connectHospital: !prevState.connectHospital,
    }));
  }

  getHpData = async () => {
    const hpDetails = await getStorageData("hpDetails") as string;
    return JSON.parse(hpDetails);
  }

  setHpDate = (hpData: any) => {
    this.setState({
      hpName: hpData.hpName,
      kind: hpData.kind,
      hpPersonExist: true
    })
  };

  closeHpPopup = () => {
    this.setState({
      ishpDialogAppear: false
    })
  }

  getHospitalList = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getHospitalCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.hospital
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    const token = await getStorageData("authToken");

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getSpecializationsList = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSpecializationsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.specializations
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    const token = await getStorageData("authToken");

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getLanguagesList = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getLanguagesCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.languages
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    const token = await getStorageData("authToken");

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };


  openDropdown = () => {
    this.setState({
      showDropdown: !this.state.showDropdown,
      openHospitalDropdown: false,
      openDepartmentsDropdown: false,
      openSpecializationeDropdown: false,
      openLanguageDropdown: false,
    })
  }

  openHospitalDropdown = () => {
    this.setState({
      openHospitalDropdown: !this.state.openHospitalDropdown,
      openDepartmentsDropdown: false,
      openSpecializationeDropdown: false,
      openLanguageDropdown: false,
      showDropdown: false
    })
  }

  openLanguageDropdown = () => {
    this.setState((prevState) => ({
      openLanguageDropdown: !prevState.openLanguageDropdown,
      openDepartmentsDropdown: false,
      openSpecializationeDropdown: false,
      openHospitalDropdown: false,
      showDropdown: false,
    }))
  }

  openSpecializationeDropdown = () => {
    this.setState({
      openSpecializationeDropdown: !this.state.openSpecializationeDropdown,
      openDepartmentsDropdown: false,
      openLanguageDropdown: false,
      openHospitalDropdown: false,
      showDropdown: false,
    })
  }

  openDepartmentsDropdown = () => {
    this.setState({
      openDepartmentsDropdown: !this.state.openDepartmentsDropdown,
      openSpecializationeDropdown: false,
      openLanguageDropdown: false,
      openHospitalDropdown: false,
      showDropdown: false,
    })
  }

  handleHospitalsCheckboxChange = (hospitalId: any) => {
    const selectedHospital = this.state.hospitalList.find(
      (hospital: any) => hospital.id === hospitalId
    );

    if (selectedHospital) {
      if (this.state.selectedHospitals === hospitalId) {
        // If already selected, deselect
        this.setState((prevState) => ({
          selectedHospitals: "",
          openHospitalDropdown: false,
          hpDocDetailsErr: {
            ...prevState.hpDocDetailsErr,
            selectedHospitals: ""
          }
        }));
      } else {
        // If not selected, select
        this.setState((prevState) => ({
          selectedHospitals: hospitalId,
          openHospitalDropdown: false,
          hpDocDetailsErr: {
            ...prevState.hpDocDetailsErr,
            selectedHospitals: ""
          }
        }));
      }
    }
  };


  handleSpecializationsCheckboxChange = (specializationId: any) => {
    const selectedSpecialization = this.state.specializationsList.find(
      (specialization: any) => specialization.id === specializationId
    );

    if (selectedSpecialization) {
      if (this.state.selectedSpecializations === specializationId) {
        this.setState((prevState) => ({
          selectedSpecializations: "",
          openSpecializationeDropdown: false,
          hpDocDetailsErr: {
            ...prevState.hpDocDetailsErr,
            selectedSpecializations: ""
          }
        }));
      } else {
        // If not selected, select
        this.setState((prevState) => ({
          selectedSpecializations: specializationId,
          openSpecializationeDropdown: false,
          hpDocDetailsErr: {
            ...prevState.hpDocDetailsErr,
            selectedSpecializations: ""
          }
        }));
      }
    }
  };

  handleLanguagesCheckboxChange = (languageId: any) => {
    const selectedLanguages = this.state.languagesList.find(
      (language: any) => language.id === languageId
    );

    if (selectedLanguages) {
      if (this.state.selectedLanguages.includes(languageId)) {
        const updatedLanguages = this.state.selectedLanguages.filter((serialNum: any) => serialNum !== languageId);

        this.setState((prevState) => ({
          selectedLanguages: updatedLanguages,
          hpDocDetailsErr: {
            ...prevState.hpDocDetailsErr,
            language: ""
          }
        }));
      } else {
        const updatedLanguages = [...this.state.selectedLanguages, languageId];
        if (this.state.selectedLanguages.length <= 3) {
          this.setState((prevState) => ({
            selectedLanguages: updatedLanguages,
            hpDocDetailsErr: {
              ...prevState.hpDocDetailsErr,
              language: ""
            }
          }));
        }
      }
    }
  };

  handleLanguagesChange = (languageId: any) => {
    if (this.state.patientExist) return true
    const selectedLanguages = this.state.languagesList.find(
      (language: any) => language.id === languageId
    );

    if (selectedLanguages) {
      if (this.state.selectedLanguages.includes(languageId)) {
        const updatedLanguages = this.state.selectedLanguages.filter((serialId: any) => serialId !== languageId);

        this.setState({
          selectedLanguages: updatedLanguages
        });

      } else {
        const updatedLanguages = [...this.state.selectedLanguages, languageId];
        if (this.state.selectedLanguages.length <= 3) {
          this.setState({
            selectedLanguages: updatedLanguages,
          });
        }
      }
    }
  };

  removeSelectedLanguage = (languageId: string) => {
    if (this.state.patientExist) return true
    if (this.state.selectedLanguages.includes(languageId)) {
      const updatedLanguages = this.state.selectedLanguages.filter((serialNumber: any) => serialNumber !== languageId);

      this.setState((prevState) => ({
        selectedLanguages: updatedLanguages,
        hpDocDetailsErr: {
          ...prevState.hpDocDetailsErr,
          language: ""
        }
      }));
    }
  }

  handleHpDepartmentsCheckboxChange = (departId: any) => {
    const selectedDepartment = this.state.departmentsList.find(
      (depart: any) => depart.id === departId
    );

    if (selectedDepartment) {
      if (this.state.selectedDepartments === departId) {
        // If already selected, deselect
        this.setState((prevState) => ({
          selectedDepartments: "",
          openDepartmentsDropdown: false,
          hpDocDetailsErr: {
            ...prevState.hpDocDetailsErr,
            selectedDepartments: ""
          }
        }));
      } else {
        // If not selected, select
        this.setState((prevState) => ({
          selectedDepartments: departId,
          openDepartmentsDropdown: false,
          hpDocDetailsErr: {
            ...prevState.hpDocDetailsErr,
            selectedDepartments: ""
          }
        }));
      }
    }
  };


  handleHpGenderCheckboxChange = (gender: string) => {
    if (this.state.gender !== gender) {
      this.setState((prevState) => ({
        gender: gender,
        showDropdown: false,
        hpDocDetailsErr: {
          ...prevState.hpDocDetailsErr,
          gender: ""
        }
      }));
    } else {
      this.setState((prevState) => ({
        gender: "",
        showDropdown: false,
        hpDocDetailsErr: {
          ...prevState.hpDocDetailsErr,
          gender: ""
        }
      }));
    }
  };

  commonValidationSchemas = () => {
    let commonValidationSchemas = {
      city: YupValidation.string()
        .required("City is required").matches(/^\S.*$/, 'City is required')
        .matches(/^[a-zA-Z\s]+$/, "City should contain only alphabets"),
      pincode: YupValidation.string()
        .required("Pin Code is required")
        .matches(/^\d{0,9}$/, "Invalid Pin Code")
        .matches(/^\d{6}$/, "Pin Code must be 6 digits"),

      gender: YupValidation.string()
        .notOneOf(["Enter gender"], "Gender is required")
        .required("Gender is required"),

      address: YupValidation.string()
        .required("Address is required")
        .matches(/^\S.*$/, 'Address is required'),

      age: YupValidation.string()
        .required("Age is required").matches(/^(?:\b(?:[0-9]\d?|1[01]\d|110)\b)$/, "Invalid age")
        .test('is-valid-name', 'Invalid age', function (value) {
          if (value > 110) {
            return false
          } else {
            return true
          }
        })
        .matches(/^\S.*$/, 'Age is required'),

      district: YupValidation.string()
        .required("District is required")
        .matches(/^\S.*$/, 'District is required')
        .matches(/^[a-zA-Z\s]+$/, "District should contain only alphabets"),

      yearofExp: YupValidation.string()
        .required("Years of experience is required")
        .matches(/^([0-5]?\d|6[0-5])$/, "Invalid years")
        .matches(/^\S.*$/, 'Year of experience is required'),
      phoneNumber: YupValidation.string()
        .notRequired(),
      email: YupValidation.string()
        .notRequired()
    };

    if (this.props.userLogin) {
      commonValidationSchemas = {
        ...commonValidationSchemas,
        phoneNumber: YupValidation.string()
          .required("Phone number is required")
          .test('is-valid-phone', 'Invalid phone number', function (value) {
            if (value !== null && value !== '') {
              return /^[6-9]\d{9}$/.test(value);
            }
            return true;
          }),
        email: YupValidation.string()
          .required('Email is required.')
          .email('Invalid email'),
      }
    }
    return commonValidationSchemas
  };

  hpNurseRegisterValidationSchema = YupValidation.object().shape({
    ...this.commonValidationSchemas(),
    registrationNumber: YupValidation.string()
      .required("Registration number is required")
      .matches(/^[A-Za-z]{3,4}\d{4,6}$/, "Enter valid registration no"),
  });

  hpDoctorRegisterValidationSchema = YupValidation.object().shape({
    ...this.commonValidationSchemas(),
    registrationNumber: YupValidation.string()
      .required("Registration number is required")
      .matches(/^[A-Za-z]{3,4}\d{4,6}$/, "Enter valid registration no"),
    selectedSpecializations: YupValidation.string()
      .required("Please select specialization"),
  });

  hpDieticianRegisterValidationSchema = YupValidation.object().shape({
    ...this.commonValidationSchemas(),
    registrationNumber: YupValidation.string()
      .nullable()
      .notRequired()
      .test('is-valid-registration-number', 'Enter a valid registration number', function (value) {
        if (value !== null && value !== '') {
          // Check if the string has exactly one space
          const parts = value.split(' ');
          if (parts.length !== 2) {
            return false;
          }

          // Check if one part is 3-50 alphabetic characters and the other part is 3-10 numeric digits
          const [part1, part2] = parts;
          const alphaNumericRegex = /^[a-zA-Z0-9]+$/;
          if (!alphaNumericRegex.test(part1) || !alphaNumericRegex.test(part2)) {
            return false;
          }

          const isPart1Alpha = /^[a-zA-Z]{3,50}$/.test(part1);
          const isPart1Num = /^\d{3,10}$/.test(part1);
          const isPart2Alpha = /^[a-zA-Z]{3,50}$/.test(part2);
          const isPart2Num = /^\d{3,10}$/.test(part2);

          return (isPart1Alpha && isPart2Num) || (isPart1Num && isPart2Alpha);
        }
        return true;
      }),
  });

  hpPhysioRegisterValidationSchema = YupValidation.object().shape({
    ...this.commonValidationSchemas(),
    registrationNumber: YupValidation.string()
      .nullable()
      .notRequired()
      .test('is-valid-name', 'Enter valid registration no', function (value) {
        if (value !== null && value !== '') {
          return /^[A-Za-z]{1}\d{5,6}$/.test(value);
        }
        return true;
      }),
  });

  hpAyahRegisterValidationSchema = YupValidation.object().shape({
    ...this.commonValidationSchemas(),
    aadharCardNumber: YupValidation.string()
      .required("Aadhar Card number is required")
      .matches(/^[2-9]\d{11}$/, "Invalid Aadhar Card number")
      .matches(/^\d{12}$/, "Aadhar Card number must be 12 digits"),
    selectedAyah: YupValidation.string()
      .required("Please select ayah type"),
  });

  validationSchemas: any = {
    Ayah: this.hpAyahRegisterValidationSchema,
    Dietician: this.hpDieticianRegisterValidationSchema,
    Physiotherapist: this.hpPhysioRegisterValidationSchema,
    Doctor: this.hpDoctorRegisterValidationSchema,
    Nurse: this.hpNurseRegisterValidationSchema,
  };

  hpValidation = (userType: string, field: string, fieldValues: any) => {
    if (this.state.kind === "Ayah") {
      this.hpAyahRegisterValidationSchema.validateSyncAt(field, fieldValues as HPDoctor);
    } else if (this.state.kind === "Dietician") {
      this.setState({
        longTermBooking: false,
      });
      this.hpDieticianRegisterValidationSchema.validateSyncAt(field, fieldValues as HPDoctor);
    } else if (this.state.kind === "Physiotherapist") {
      this.setState({
        longTermBooking: false,
      });
      this.hpPhysioRegisterValidationSchema.validateSyncAt(field, fieldValues as HPDoctor);
    } else if (this.state.kind === "Doctor") {
      this.hpDoctorRegisterValidationSchema.validateSyncAt(field, fieldValues as HPDoctor);
    } else {
      this.hpNurseRegisterValidationSchema.validateSyncAt(field, fieldValues as HPDoctor);
    }
  }

  handlehpChange = (field: keyof HPDoctor, value: any) => {
    try {
      value = value?.replace(/ {2,}/g, ' ');
      const fieldValues: Partial<HPDoctor> = {
        [field]: value,
      };

      this.hpValidation(this.state.kind, field, fieldValues)

      this.setState((prevState) => ({
        ...prevState,
        [field]: value,
        hpDocDetailsErr: { ...prevState.hpDocDetailsErr, [field]: "" },
      }));
    } catch (error) {
      if (error instanceof YupValidation.ValidationError) {
        const errorMessage = error.message;
        this.setState((prevState) => ({
          ...prevState,
          [field]: value,
          hpDocDetailsErr: { ...prevState.hpDocDetailsErr, [field]: errorMessage },
        }));
      }
    }
  };

  handleSliderChange = (event: any, newValue: any) => {
    this.setState({ locationLimit: JSON.stringify(newValue) });
  }

  checkOtpValidations() {
    if (this.state.phoneOTP.every((item: string) => item === "")) {
      this.setState({
        isError: true, errorMessage: "Please enter OTP"
      })
      return false;
    } else if (this.state.phoneOTP.length !== 4 || this.state.phoneOTP.some((item: any) => item === "")) {
      this.setState({
        isError: true, errorMessage: "Please enter 4 digit OTP"
      });
      return false;
    } else if (this.state.phoneOTP.length === 4) {
      this.setState({ isError: false, errorMessage: "" });
    }
    return true;
  }

  verifyOTPData = async () => {
    if (this.checkOtpValidations()) {
      const updatedOtp = {
        "data": { "attributes": { "pin": this.state.phoneOTP.map((otp: any) => otp.toString()).join('') } }
      }
      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

      this.verifyOTPCallId = requestMessage.messageId;

      requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(updatedOtp));

      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.otpVerification);

      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeAddDetail);

      const header = { "Content-Type": configJSON.validationApiContentType, token: this.state.setToken };

      requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  }

  verifyUserPhoneOrEmail = (responseJson: any) => {
    if (responseJson.data) {
      if (responseJson.data.type !== "email_account") {
        this.setState({ otpSend: true, setToken: responseJson.meta.token })
      } else {
        this.setState({ emailSend: true })
      }
    } else {
      const verifyType = this.state.verifyType
      this.setState((prevState) => ({
        ...prevState,
        hpDocDetailsErr: {
          ...prevState.hpDocDetailsErr,
          [verifyType]: responseJson.errors ? responseJson.errors.message : responseJson.error.message,
        },
        isError: true
      }));
    }
  };

  verifyOTPResponse = (responseJson: any) => {
    const msg = responseJson;

    if (msg?.message) {
      this.setState({ otpVerified: msg?.message, showHPSubmitBtn: false, setToken: "", otpSend: false })
      // } else {this.setState({errorMessage:msg?.error?.message,isError:true})}
    } else { this.setState({ errorMessage: msg.errors ? msg.errors.message : msg?.error?.message, isError: true }) }
  };

  verifyPhoneOrEmail = async (typeName: string) => {
    let updatedEmailData;
    if (typeName === 'phone') {
      updatedEmailData = { "data": { "attributes": { "full_phone_number": `+91${this.state.phoneNumber}` } } };
      this.setState({ verifyType: "phoneNumber" })
    }

    if (typeName === 'email') {
      updatedEmailData = { data: { attributes: { email: this.state.email } } };
      this.setState({ verifyType: "email" })
    }

    if (updatedEmailData) {
      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

      this.verifyCallId = requestMessage.messageId;

      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.verifications);

      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeAddDetail);

      requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(updatedEmailData));

      const authToken = await getStorageData("authToken");

      const header = {
        "Content-Type": configJSON.validationApiContentType, token: authToken,
      };

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header)
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  handleHpSubmit = async () => {
    const { ...prevState } = this.state;
    this.myElementRef = React.createRef();
    let formNext = true;
    const fieldChecked = await this.callErrorFunction();
    Object.keys(prevState).forEach((field) => {
      try {
        const fieldValues: Partial<HPDoctor> = {
          [field]: prevState[field as keyof HPDoctor],
        };
        this.hpValidation(this.state.kind, field, fieldValues)
        this.setState((prevState) => ({
          ...prevState,
          hpDocDetailsErr: { ...prevState.hpDocDetailsErr, [field]: "" },
        }));
      } catch (error) {
        if (error instanceof YupValidation.ValidationError) {
          const errorMessage = error.message;
          formNext = false;
          this.setState((prevState) => ({
            ...prevState,
            hpDocDetailsErr: { ...prevState.hpDocDetailsErr, [field]: errorMessage },
          }));
        }
      }
    });
    const allErrorsCleared = Object.values(this.state.hpDocDetailsErr).every((error) => error === "" || error === undefined);
    if (allErrorsCleared && formNext && fieldChecked) {
      this.verifiedMail(this.props.userLogin)
    }
  };

  callPhoneVerifyApi = () => {
    this.setState({ showHPSubmitBtn: true })
    this.verifyPhoneOrEmail("phone")
  }

  verifiedMail = (userLogin: any) => {
    if (userLogin) {
      const { email, phoneNumber, contactNumber, newMail } = this.state;
      const tempPhoneNumber = Number(phoneNumber);
      const tempphNumber = Number(contactNumber)
      this.setState({isError:false})
      if (tempPhoneNumber === tempphNumber && email === newMail) {
        this.callHPApi();
      } else if (tempPhoneNumber !== tempphNumber && email !== newMail) { this.callPhoneVerifyApi() }
      else if (tempPhoneNumber !== tempphNumber && email === newMail) {
        this.callPhoneVerifyApi()
      }
      else if (tempPhoneNumber === tempphNumber && email !== newMail) {
        this.setState({ showHPSubmitBtn: true })
        this.verifyPhoneOrEmail("email")
      }
    } else {
      this.callHPApi()
    }
  }

  confirmEmail = () => {
    this.setState({ emailSend: false, confimEmail: true })
  }

  otpSuccessEmail = (pageType: string) => {
    this.setState({ confimEmail: false, newMail:this.state.email }, () => {
      if (pageType === "paitent") {
        this.callHPApi()
      }
      if (pageType === "hospital") {
        this.callHPApi()
      }
    })
  }

  otpSuccess = (pageType: string) => {
    this.setState({ otpVerified: "", contactNumber: this.state.phoneNumber }, () => {
      if (this.state.email !== this.state.newMail) {
        this.verifyPhoneOrEmail("email")
      } else {
        this.callHPApi()
      }
    })
  }

  closeOtpPopup = (pageType: string, type: string) => {
    this.setState({ otpSend: false, emailSend: false })
    if (type === "phone") {
      if (this.state.email !== this.state.newMail) {
        this.setState({ phoneNumber: this.state.contactNumber }, () => {
          this.verifyPhoneOrEmail('email')
        })
      } else {
        this.setState({ phoneNumber: this.state.contactNumber }, () => {
          this.callHPApi();
        })
      }
    }
    if (type === "email") {
      this.setState({ phoneNumber: this.state.contactNumber, email: this.state.newMail }, () => {
        this.callHPApi();
      })
    }
  }

  toggleResend = (state: boolean) => { this.setState({ isResendActive: state }) }

  handleOTPChange = (index: any, event: any) => {
    const inputValue = event.target.value;
    const { phoneOTP } = this.state;
    const newOTP = [...phoneOTP];

    // Check if the input value is a number
    if (/^\d{0,9}$/.test(inputValue)) {
      newOTP[index] = inputValue;

      if (inputValue === "" && index > 0) {
        const prevInputData = index - 1;
        const input = document.getElementById(`updateOtpInput${prevInputData}`);
        if (input) {
          input.focus();
        }
      } else if (index < phoneOTP.length - 1 && inputValue !== "") {
        const input = index + 1;
        const element = document.getElementById(`updateOtpInput${input}`);
        if (element) { element.focus(); }
      }
      this.setState({ phoneOTP: newOTP });
    }
  };

  showUploadDocError = (uploadDoc: any, image: any) => {
    const uploadedFileError = uploadDoc === null && image === null;
    return uploadedFileError;
  }

  checkDoctorConnectwithHospital = (kind: any, connectHospital: any) => {
    const doctorConnectedWithHospital = kind === "Doctor" && connectHospital;
    return doctorConnectedWithHospital;
  }

  getSelectedHospitalsErrorMessage = (selectedHospitals: string) => {
    return selectedHospitals === "" ? "Please select hospital" : "";
  }

  // Function to generate error message for selected departments
  getSelectedDepartmentsErrorMessage = (selectedDepartments: string) => {
    return selectedDepartments === "" ? "Please select departments" : "";
  }

  getUserRole = (type: string) => {
    return type !== "Ayah"
  }

  getDocValidation = (type: string) => {
    return type === "Ayah" ? "Please upload your Aadhar Card" : "Please upload a valid document"
  }


  validateDegree = (degree: string) => {
    if (degree === '' && this.state.educationDetails.length === 1) {
      return "Please enter degree";
    } else if (degree.length < 3 && this.state.educationDetails.length === 1) {
      return "Please enter a minimum of 3 characters";
    } else {
      return "";
    }
  }

  validateCollege = (college: string) => {
    if (college === '' && this.state.educationDetails.length === 1) {
      return "Please enter college";
    } else if (college.length < 3 && this.state.educationDetails.length === 1) {
      return "Please enter a minimum of 3 characters";
    } else {
      return "";
    }
  }

  checkFieldValidity(detailsToCheck: any, field: any) {
    let errorMessage = "";
    detailsToCheck.forEach((detail: any) => {
      if (detail[field].trim() === "") {
        errorMessage = `Please enter ${field}`;
      } else if (detail[field].length < 3) {
        errorMessage = "Please enter a minimum of 3 characters";
      }
    });
    return errorMessage;
  }

  adjustLastIndex = (educationDetails: any[]) => {
    let lastIndex = educationDetails.length;
    if (lastIndex > 0 && educationDetails[lastIndex - 1].degree === '' && educationDetails[lastIndex - 1].college === '') {
      lastIndex -= 1;
    }
    return lastIndex;
  }

  callErrorFunction = async () => {
    let updatedState = {};

    if (this.showUploadDocError(this.state.uploadDoc, this.state.image)) {
      const errorMessage = this.getDocValidation(this.state.kind);
      updatedState = { ...updatedState, uploadDoc: errorMessage };
    }

    if (this.state.selectedLanguages.length === 0) {
      updatedState = { ...updatedState, language: "Please select minimum 1 language" };
    }

    if (this.getUserRole(this.state.kind)) {

      if (this.state.educationDetails.length > 1) {
        // Get the index of the last element in educationDetails
        let lastIndex = this.adjustLastIndex(this.state.educationDetails);
        // Slice the array to exclude the last element
        const detailsToCheck = this.state.educationDetails.slice(0, lastIndex);
        // Check for empty degree fields
        const invalidDegree = this.checkFieldValidity(detailsToCheck, 'degree');

        const invalidCollege = this.checkFieldValidity(detailsToCheck, 'college');


        if (invalidDegree) {
          updatedState = {
            ...updatedState,
            degree: invalidDegree
          };
        }

        if (invalidCollege) {
          updatedState = {
            ...updatedState,
            college: invalidCollege
          };
        }
      }
      else {
        updatedState = {
          ...updatedState,
          degree: this.validateDegree(this.state.educationDetails[0].degree),
          college: this.validateCollege(this.state.educationDetails[0].college),
        };
      }
    }

    if (this.checkDoctorConnectwithHospital(this.state.kind, this.state.connectHospital)) {
      const selectedHospitals = this.getSelectedHospitalsErrorMessage(this.state.selectedHospitals);
      const selectedDepartments = this.getSelectedDepartmentsErrorMessage(this.state.selectedDepartments);
      updatedState = { ...updatedState, selectedHospitals, selectedDepartments };
    }

    // Update state after all conditions are checked
    this.setState(prevState => ({
      ...prevState,
      hpDocDetailsErr: { ...prevState.hpDocDetailsErr, ...updatedState }
    }));

    return true;
  }

  callHPApi = async () => {
    if (this.state.selectedLanguages.length < 1) {
      this.setState((prevState) => ({
        hpDocDetailsErr: { ...prevState.hpDocDetailsErr, language: "Please select miniumum 1 language" },
      }));
      return;
    }

    const languages = this.state.selectedLanguages.map(Number);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.registerHpApiCallId = requestMessage.messageId;

    const endPoints = this.props.userLogin ? configJSON.updateHealthcareUserProfile : configJSON.healthcarePersonals;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoints
    );
    let datas = {}
    if (this.state.kind === "Doctor") {
      const doctorData = {
        connect_with_hospital: this.state.connectHospital,
        sos_available: false,
        specialization_id: parseInt(this.state.selectedSpecializations),
      }
      if (this.state.connectHospital) {
        const connectedHospital = {
          hospital_id: parseInt(this.state.selectedHospitals),
          department_id: parseInt(this.state.selectedDepartments),
        }
        datas = { ...datas, ...connectedHospital, ...doctorData }

      } else {
        const limit = {
          location_limit: parseInt(this.state.locationLimit),
        }
        datas = { ...datas, ...limit, ...doctorData }
      }
    }

    if (this.state.kind === "Ayah") {
      const ayhaData = {
        long_term_booking: this.state.longTermBooking,
        ayah_type_id: parseInt(this.state.selectedAyah),
        identification_no: this.state.aadharCardNumber,
      }
      datas = { ...datas, ...ayhaData }
    } else {
      const newData = {
        long_term_booking: this.state.longTermBooking,
        identification_no: this.state.registrationNumber,
        educational_details_attributes: this.state.educationDetails
      }
      datas = { ...datas, ...newData }
    }

    let newData = {
      name: this.state.hpName.trim(),
      profile_type: this.state.kind.toLocaleLowerCase(),
      connect_with_hospital: this.state.connectHospital,
      sos_available: false,
      specialization_id: parseInt(this.state.selectedSpecializations),
      educational_details_attributes: this.state.educationDetails,
      language_ids: languages,
      experience: parseFloat(this.state.yearofExp),
      age: parseInt(this.state.age),
      gender: this.state.gender,
      address_attributes: {
        address: this.state.address,
        district: this.state.district,
        city: this.state.city,
        pincode: this.state.pincode,
        latitude: parseFloat(this.state.latitude),
        longitude: parseFloat(this.state.longitude),
      },
      email: this.state.email,
      full_phone_number: `91${this.state.phoneNumber}`,
    }
    if (this.state.uploadDoc !== null) {
      const identification_document = {
        identification_document: {
          data: this.state.uploadDoc.base64Data,
          filename: this.state.uploadDoc.filename,
          content_type: this.state.uploadDoc.contentType,
        }
      }
      datas = { ...datas, ...identification_document }
    }
    datas = { ...datas, ...newData };

    if(this.state.profile_image){
      datas = {...datas,profile_image: this.state.profile_image}
    }


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(datas)
    );

    const token = await getStorageData("authToken");

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleEducationDegreeChange = (index: number, value: string) => {
    const updatedEducationDetails = [...this.state.educationDetails];
    value = value?.replace(/ {2,}/g, ' ');
    const regexAlphabetsDegree= /^[a-zA-Z\s]+$/;
    const regexNotEmpty = /^\S.*$/;
    if (!value || !regexNotEmpty.test(value)) {
      this.setState((prevState) => ({
        hpDocDetailsErr: { ...prevState.hpDocDetailsErr, degree: 'Please enter degree' },
      }));
    } else if (!regexAlphabetsDegree.test(value)) {
      this.setState((prevState) => ({
        hpDocDetailsErr: { ...prevState.hpDocDetailsErr, degree
          : 'Degree name should contain only alphabets' },
      }));
    } else if (value.length < 3) {
      this.setState((prevState) => ({
        hpDocDetailsErr: { ...prevState.hpDocDetailsErr, degree: 'Please enter a minimum of 3 characters' },
      }));
    } else if (this.isDuplicateDegree(updatedEducationDetails, value, 'degree', index)) {
      this.setState((prevState) => ({
        hpDocDetailsErr: { ...prevState.hpDocDetailsErr, degree: 'Duplicate degree found' },
      }));
    } else {
      this.setState((prevState) => ({
        hpDocDetailsErr: { ...prevState.hpDocDetailsErr, degree: '', college: prevState.hpDocDetailsErr.college },
      }));
    }
    updatedEducationDetails[index].degree = value;
    updatedEducationDetails[index].degreeCursor = true;
    this.setState({ educationDetails: updatedEducationDetails, openDegreeDropdown: false, degree: !this.state.isEditing ? value : "" });
  };

  handleEducationCollegeChange = (index: number, value: string) => {
    const updatedEducationDetails = [...this.state.educationDetails];
    const regexNotEmpty = /^\S.*$/;
    value = value?.replace(/ {2,}/g, ' ');
    const regexAlphabetsOnly = /^[a-zA-Z\s]+$/;

    if (!value || !regexNotEmpty.test(value)) {
      this.setState((prevState) => ({
        hpDocDetailsErr: { ...prevState.hpDocDetailsErr, college: 'Please enter college' },
      }));
    } else if (!regexAlphabetsOnly.test(value)) {
      this.setState((prevState) => ({
        hpDocDetailsErr: { ...prevState.hpDocDetailsErr, college: 'College name should contain only alphabets' },
      }));
    } else if (value.length < 3) {
      this.setState((prevState) => ({
        hpDocDetailsErr: { ...prevState.hpDocDetailsErr, college: 'Please enter a minimum of 3 characters' },
      }));
    } else {
      this.setState((prevState) => ({
        hpDocDetailsErr: { ...prevState.hpDocDetailsErr, college: '', degree: prevState.hpDocDetailsErr.degree },
      }));
    }
    updatedEducationDetails[index].college = value;
    this.setState({ educationDetails: updatedEducationDetails, college: !this.state.isEditing ? value : "" });
  };

  isDuplicateDegree = (details: any[], value: string, field: string, index: number) => {
    const formattedValue = value.toLowerCase().trim();
    return details.some((eduDetail: any, serialNo: number) => serialNo !== index && eduDetail[field].toLowerCase().trim() === formattedValue);
  };

  isDegreeAlreadySelected = (degreeName: string, currentIndex: number) => {
    return this.state.educationDetails.some((eduDetails: any, index: number) => {
      return index !== currentIndex && eduDetails.degree === degreeName;
    })
  }

  addEducationDetails = () => {
    const educationDetails = this.state.educationDetails;
    const degree = "";
    const college = "";
    const newEducationDetail = { degree, college, degreeCursor: false, collegeCursor: false };

    const isDuplicateDegree = this.isEducationDetailDuplicate(educationDetails, degree, '');
    const lastEducationDetails = educationDetails[educationDetails.length - 1];
    if (!lastEducationDetails.degree) {
      this.setState((prevState) => ({
        hpDocDetailsErr: { ...prevState.hpDocDetailsErr, degree: 'Please enter degree' },
      }));
    }
    if (!lastEducationDetails.college) {
      this.setState((prevState) => ({
        hpDocDetailsErr: { ...prevState.hpDocDetailsErr, college: 'Please enter college' },
      }));
    }

    if (lastEducationDetails.degree && lastEducationDetails.college) {
      if (!isDuplicateDegree && !(degree === '' && educationDetails.some((detail: any) => detail.degree === ''))) {
        const updatedDetails = [...educationDetails, newEducationDetail];
        this.setState((prevState) => ({
          educationDetails: updatedDetails,
          hpDocDetailsErr: { ...prevState.hpDocDetailsErr, degree: '', college: '' }
        }));
      } else {
        if (isDuplicateDegree) {
          this.setState((prevState) => ({
            hpDocDetailsErr: { ...prevState.hpDocDetailsErr, degree: 'Duplicate degree found' },
          }));
        }
      }
    }
  };

  isEducationDetailDuplicate = (details: any, degree: string, college: string) => {
    return details.some(
      (eduDetail: any) => eduDetail.degree === degree && eduDetail.college === college
    );
  };


  //Ayah FLow
  hpLongTermBookingChange = () => {
    this.setState((prevState) => ({
      longTermBooking: !prevState.longTermBooking,
    }));
  }

  handleAyahCheckboxChange = (ayahId: any) => {
    const selectedAyah = this.state.ayahList.find(
      (ayah: any) => ayah.id === ayahId
    );

    if (selectedAyah) {
      if (this.state.selectedAyah === ayahId) {
        // If already selected, deselect
        this.setState((prevState) => ({
          selectedAyah: "",
          openAyahDropdown: false,
          hpDocDetailsErr: {
            ...prevState.hpDocDetailsErr,
            selectedAyah: ""
          }
        }));
      } else {
        // If not selected, select
        this.setState((prevState) => ({
          selectedAyah: ayahId,
          openAyahDropdown: false,
          hpDocDetailsErr: {
            ...prevState.hpDocDetailsErr,
            selectedAyah: ""
          }
        }));
      }
    }
  };

  getAyahList = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAyahCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.ayahTypes
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    const token = await getStorageData("authToken");

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  openAyahDropdown = () => {
    this.setState({
      openAyahDropdown: !this.state.openAyahDropdown,
      showDropdown: false,
    })
  }

  getDegreeList = async () => {

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDegreeCallId = requestMessage.messageId;
    let type: string = this.state.kind.toLocaleLowerCase()
    if (this.props.updatedKind) {
      type = this.props.updatedKind.toLocaleLowerCase();
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.degrees}?profile_type=${type}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    const token = await getStorageData("authToken");

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getHealthDetails = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPatienHealthdetailsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.patient_healthdetails
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    const token = await getStorageData("authToken");

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  openDegreeDropdown = () => {
    this.setState({
      openDegreeDropdown: !this.state.openDegreeDropdown,
      openLanguageDropdown: false,
      showDropdown: false
    })
  }

  editOpenDegreeDropdown = () => {
    this.setState({
      editOpenDegreeDropdown: !this.state.editOpenDegreeDropdown
    })
  }

  openEditable = (index: number) => {
    this.setState({ isEditing: true, editableIndex: JSON.stringify(index) });
  }


  openFileattachment = () => {
    this.setState({ openFile: true, isPresciptionDialogOpen: false, openDialog: true })
  }

  handleDocDialog = () => {
    this.setState((prevState) => ({
      ...prevState,
      openDialog: !prevState.openDialog,
    }));
  }

  handelSuccessMessage = () => {
    if (!this.props.userLogin) {
      this.props.navigation.navigate("RegistrationSuccess");
      this.setState({ skipped: false });
    } else {
      this.setState({ editHealthDetailsSuccess: false });
      this.props.navigation.navigate("CustomisableViewUserProfile");
    }
  }

  closHPPopup = () => {
    if (this.state.messageType === 'Success') {
      this.props.navigation.navigate("CustomisableHPViewUserProfile")
      this.setState({ healthcarePersonnelError: "", hospitalErrorMessage: "", messageType: "" })
    } else {
      this.setState({ healthcarePersonnelError: "", hospitalErrorMessage: "", messageType: "" })
    }
  }

  closePopup = () => {
    if (this.state.hospitalErrorMessage === '') {
      this.props.navigation.goBack();
    } else {
      this.setState({ healthcarePersonnelError: "", hospitalErrorMessage: "", messageType: "" })
    }
  }

  closeHospitalDropdown = () => {
    this.setState({ openHospitalDropdown: false })
  }

  closeSpecializationeDropdown = () => {
    this.setState({ openSpecializationeDropdown: false })
  }

  closeDepartmentDropdown = () => {
    this.setState({ openDepartmentsDropdown: false })
  }

  closeLanguageDropdown = () => {
    this.setState({ openLanguageDropdown: false })
  }

  closeGenderDropdown = () => {
    this.setState({ showDropdown: false })
  }

  closeAyahDropdown = () => {
    this.setState({ openAyahDropdown: false })
  }

  closeDegreeDropdown = () => {
    this.setState({ editOpenDegreeDropdown: false })
  }


  closeNewDegreeDropdown = () => {
    this.setState({ openDegreeDropdown: false })
  }

  fetchDoctorData = async () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getHPDoctorCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getHealthcarePersonnelDetails
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    const token = await getStorageData("authToken");

    const header = {
      "Content-Type": "application/json",
      token: token,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getLocationLimit(locationLimit: any) {
    return locationLimit === null ? "6" : JSON.stringify(locationLimit);
  }

  convertToJSONString(data: any, key: string) {
    return data && data[key] ? JSON.stringify(data[key]) : '';
  }


  getValueOrDefault(data: any, key: string) {
    return data && data[key] !== null ? data[key] : '';
  }

  checkUsersHealthcarePersonnelProfile = (responseJson: any) => {
    if (!responseJson.data || !responseJson.data.attributes) return;
    const datas = responseJson.data;
    const educationDetails = datas.attributes.educational_degrees.filter((degreeObj: any) => degreeObj.degree !== "" && degreeObj.college !== "").map((degreeObj: any) => ({
      degree: degreeObj.degree,
      college: degreeObj.college,
      degreeCursor: true,
      collegeCursor: true
    }))

    const newObj = {
      degree: "",
      college: "",
      degreeCursor: false,
      collegeCursor: false
    };
    educationDetails.push(newObj);

    let profile = datas.attributes.profile_image_url?.url ? datas.attributes.profile_image_url?.url : ProfileHPIcon;
    let identificationDocument = datas.attributes.identification_document_url?.url !== "" ? datas.attributes.identification_document_url?.url : "";

    let language: any = [];
    if (datas.attributes.language_attributes.length > 0) {
      datas.attributes.language_attributes.map((element: any) => language.push(JSON.stringify(element.id)));
    }

    const userRole = datas.attributes.profile_type.charAt(0).toUpperCase() + datas.attributes.profile_type.slice(1)
    this.setState({ kind: userRole });
    if (userRole === "Doctor") {
      const location = this.getLocationLimit(datas.attributes.location_limit);
      this.setState({
        connectHospital: datas.attributes.profile_type_specific_data.connect_with_hospital,
        selectedHospitals: this.convertToJSONString(datas.attributes.profile_type_specific_data, 'hospital_id'),
        selectedDepartments: this.convertToJSONString(datas.attributes.profile_type_specific_data, 'department_id'),
        selectedSpecializations: this.convertToJSONString(datas.attributes.profile_type_specific_data, 'specialization_id'),
        locationLimit: location
      })
    } else if (userRole === "Ayah") {
      this.setState({
        longTermBooking: datas.attributes.profile_type_specific_data.long_term,
        selectedAyah: this.convertToJSONString(datas.attributes.profile_type_specific_data, 'ayah_type_id'),
      })
    } else {
      this.setState({
        longTermBooking: datas.attributes.profile_type_specific_data?.long_term,
      })
      this.getDegreeList()
    }
    this.setState({
      yearofExp: this.getValueOrDefault(datas.attributes, 'experience'),
      selectedLanguages: language,
      educationDetails: educationDetails,
      address: datas.attributes.address.data?.attributes.address,
      city: datas.attributes.address.data?.attributes.city,
      pincode: datas.attributes.address.data?.attributes.pincode,
      district: datas.attributes.address.data?.attributes.district,
      age: this.getValueOrDefault(datas.attributes, 'age'),
      gender: this.getValueOrDefault(datas.attributes, 'gender'),
      registrationNumber: datas.attributes.identification_no,
      aadharCardNumber: datas.attributes.identification_no,
      hpName: datas.attributes.name,
      hpPersonExist: true,
      phoneNumber: datas.attributes.phone_number,
      email: datas.attributes.email,
      contactNumber: datas.attributes.phone_number,
      newMail: datas.attributes.email,
      image: identificationDocument || null,
      isUploadDoc: true,
      uploadDoc: null,
      profilePicture: profile,
    });
  };

  handleOpenDialogs = () => {
    this.setState({
      openDialog: true
    })
  }

  handleCloseDialog = () => {
    this.setState({
      openDialog: false,
      enableWeb: false,
      url: null,
      UploadImageError: ""
    })
  }

  openGenderDropdowns = () => {
    this.setState({
      openDialog: false,
      enableWeb: false,
      url: null
    })
  }

  closeWebCamera = () => {
    this.setState({
      enableWeb: false,
      url: null
    })
  };

  capturePhoto = () => {
    const imageSrc = this.webCameraRef.current?.getScreenshot();
    this.setState({ url: imageSrc })
  };

  getUrlExtension = (link: string) => {
    return link.split(/[#?]/)[0].split(".").pop();
  }

  openHPWeb = () => {
    this.setState({ enableWeb: true })
  }

  handleHpUploadProfiles = () => {
    this.webCameraRef.current?.click();
    this.setState({ uploadDoc: null })
  };

  handleUploadImage = (event: any) => {
    const allowedImageExtensions = ['jpg', 'jpeg', 'png'];
    const selectedUploadedProfile = event.target.files[0];
    const extensions = selectedUploadedProfile.name.split('.').pop();
    if (!allowedImageExtensions.includes(extensions)) {
      this.setState({ UploadImageError: 'Invalid file type. Please upload only image' });
      return;
    } else {
      this.setState({ UploadImageError: "" })
    }
    if (selectedUploadedProfile) {

      const reader = new FileReader();
      reader.onloadend = () => {
        let base64data;

        if (typeof reader.result === 'string') {
          base64data = reader.result.split(',')[1];
          this.setState({profilePicture:reader.result}) 
        }

        const content_type = selectedUploadedProfile.type;
        const filename = selectedUploadedProfile.name;
        const profileImage = { data: base64data, content_type: content_type, filename: filename }
        this.setState({profile_image:profileImage,openDialog:false})
      };

      reader.readAsDataURL(selectedUploadedProfile);
    }
  };

  updateHPProfile = (responseJson: any) => {
    if (responseJson.data) {
      this.handleCloseDialog()
      this.fetchDoctorData()
    }
  }

  callOnBlur = (educationDetails: any, editableIndex: any) => {
    const updatedHPEducationDetails = [...this.state.educationDetails];
    updatedHPEducationDetails[parseInt(editableIndex)].degreeCursor = true;
    if (educationDetails[parseInt(editableIndex)].degree.trim() !== "") {
      this.setState({ educationDetails: updatedHPEducationDetails, isEditing: false, editableIndex: "" });
    }
  }

  backToProfileView = () => {
    this.props.navigation.navigate("CustomisableHPViewUserProfile")
  }

  setAddressFieldsByMap = (address: any) => {
    if (address) {
      const { city, district, pincode,lat,lng } = address
      this.setState({ 
        city: city,
        district: district,
        pincode: pincode, 
        latitude: lat, 
        longitude: lng 
      })
    }
  }

  onBlurDoctorDegree = () => {
    const updatedEducationDetails = [...this.state.educationDetails];
    updatedEducationDetails[parseInt(this.state.editableIndex)].degreeCursor = true;

    // Check if the degree field is not blank before setting isEditing to false
    if (this.state.educationDetails[parseInt(this.state.editableIndex)].degree.trim() !== "") {
      this.setState({ educationDetails: updatedEducationDetails, isEditing: false, editableIndex: "" });
    }
  }

  onBlurDoctorCollege = () => {
    const updatedEducationDetails = [...this.state.educationDetails];
    updatedEducationDetails[parseInt(this.state.editableIndex)].collegeCursor = true;

    // Check if the college field is not blank before setting isEditing to false
    if (this.state.educationDetails[parseInt(this.state.editableIndex)].college.trim() !== "") {
      this.setState({ educationDetails: updatedEducationDetails, isEditing: false, editableIndex: "" });
    }
  }

  updateDoctorDegree = () => {
    const updatedEducationDetails = [...this.state.educationDetails]
    updatedEducationDetails[this.state.educationDetails.length - 1].degreeCursor = true;
    this.setState({ educationDetails: updatedEducationDetails })
  }

  updateDoctorCollege = () => {
    const updatedEducation = [...this.state.educationDetails];
    updatedEducation[this.state.educationDetails.length - 1].collegeCursor = true;
    this.setState({ educationDetails: updatedEducation });
  }

  hpUpdateNurseCollegeName = () => {
    const updatedEducationDetails = [...this.state.educationDetails];
    updatedEducationDetails[parseInt(this.state.editableIndex)].collegeCursor = true;

    // Check if the college field is not blank before setting isEditing to false
    if (this.state.educationDetails[parseInt(this.state.editableIndex)].college.trim() !== "") {
      this.setState({ educationDetails: updatedEducationDetails, isEditing: false, editableIndex: "" });
    }
  }

  hpUpdateNurseDegree = () => {
    const updatedHPDegreeEducationDetail = [...this.state.educationDetails];
    updatedHPDegreeEducationDetail[this.state.educationDetails.length - 1].degreeCursor = true;
    this.setState({ educationDetails: updatedHPDegreeEducationDetail });
  }
  // Customizable Area End
}
