Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account/accounts";

exports.apiMethodTypeAddDetail = "POST";
exports.apiMethodTypeUpdateDetail = "PUT";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";
exports.sendOTPConfirmation = "send_confirmations"
exports.emailVeified = "accounts/email_verify"
exports.registerPatient = "patients";
exports.getDiseasesAPIEndPoint = "diseases";
exports.updateRegisterPatient = "patients/update_patient_healthdetail"
exports.getBedsAPIEndPoint = "bed_types";
exports.getDepartments = "departments";
exports.getInsurances = "insurances";
exports.registerHospital = "hospitals";
exports.currentUserPatient = "patients/current_user_patient";
exports.uploadDoc = "documents";
exports.hospital = "hospitals";
exports.degrees = "degrees";
exports.departments = "departments";
exports.specializations = "specializations";
exports.languages = "languages";
exports.healthcarePersonals =  "healthcare_personnels";
exports.ayahTypes =  "ayah_types";
exports.patient_healthdetails = "patients/patient_healthdetails";
exports.getHealthcarePersonnelDetails = "healthcare_personnel_profile_details";
exports.updateHealthcareUserProfile = "update_healthcare_user_profile";
exports.uploadProfile = "update_healthcare_user_image";
exports.profileImage = "https://builderbuckets.blob.core.windows.net/trackerimages/uploads/document/917accea-7a96-4b12-b015-9b6cdc6a1669/person.png"
exports.verifications = "verifications";
exports.otpVerification = "verifications/verify_otp";
// Customizable Area End
