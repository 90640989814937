export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const homecureLogoBlue = require("../assets/homecureLogoBlue.png");
export const physicianMedical = require("../assets/physicianMedical.png");
export const homecureTransparent = require("../assets/homecureTransparent.png");
export const pexelsKarolina = require("../assets/pexelsKarolina.jpg");
export const verifiedEmailIcon = require("../assets/verifiedEmailIcon.png");
export const Account = require("../assets/Account.png");
export const ImageIcons = require("../assets/addImage.png");
export const searchIcon = require("../assets/searchIcon.png");
export const EmailIcon = require("../assets/EmailIcon.png");
export const CalenderIcon = require("../assets/calender.png")
export const MiscGreen = require("../assets/image_Misc_Green.png");
export const MiscGray = require("../assets/image_Misc_Gray.png");
export const DownArrow = require("../assets/down-arrow.png");
export const UpwardArrow  = require("../assets/upward-arrow.png");
export const Edit = require("../assets/edit.png");
export const camera  = require("../assets/camera.png");
export const gallery  = require("../assets/gallery.png");
export const patinetArrowBtn  = require("../assets/patinetArrowBtn.png");
export const Profile = require("../assets/user_icon.png")
export const ProfileHPIcon = require("../assets/user_icon.png");