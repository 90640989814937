import * as React from "react";
// Customizable Area Start
import { Box, Typography, Card, CardContent, Button, withStyles } from "@material-ui/core";
import { homecureTransparent, physicianMedical, Account } from "./assets";
import { styles } from "./styles/PhoneNumberRegistration";
import EmailAccountRegistrationController, { Props } from "./EmailAccountRegistrationController";
// Customizable Area End

export class RegistrationSuccess extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    // Customizable Area End

    return (
      // Customizable Area Start
      <Box
        component="div"
        sx={{
          width:"100%",
          minHeight: "100vh",
          height: "100%",
        }}
        style={{
          backgroundImage: `url(${physicianMedical})`,
          backgroundPosition: "top",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Box className={classes.homeCureContainers}>
          <img
            src={homecureTransparent}
            alt='HomeCure Logo'
            className={classes.homecureLogos}
            style={{ objectFit: 'contain' }}
          />
        </Box>
        <Box>
          <Card className={classes.transparentCards}>
            <CardContent className={classes.cardContents} style={{ flexDirection: "column" }}>
              <img
                src={Account}
                alt='registrationSuccess'
                className={classes.emailVerifiedLogo}
                style={{ objectFit: 'contain' }}
              />
              <Typography variant="h6" className={classes.successMessages} style={{ textAlign: "center" }}>
                Your account is created successfully
              </Typography>
              <Button
                data-test-id="backToLogin"
                variant="contained"
                color="primary"
                className={classes.successBtns}
                style={{ textTransform: "capitalize" }}
                onClick={() => this.backToLogin()}
              >
                Done
              </Button>
            </CardContent>
          </Card>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(styles)(RegistrationSuccess);
// Customizable Area End
