import { Theme } from "@material-ui/core";
// Customizable Area Start
// Customizable Area End

export const styles = (theme: Theme) => ({
 // Customizable Area Start
  homeCureContainer: {
    display: "flex",
    alignItems: "center",
    margin: "20px",
  },
  backButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "20px",
    height: "20px",
    backgroundColor: "rgba(255, 255, 255, 0.3)",
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
    padding: "10px",
    margin: "35px",
    borderRadius: "5px",
  },
  imageStyle: {
    height: 80,
    width: 80,
    marginRight: "15px"
  },
  homeCureHeading: {
    fontSize: 20,
    color: "#fff",
    fontWeight: 600,
  },
  homecureLogo: {
    width: 180,
    margin: 20,
    [theme.breakpoints.down('sm')]: {
      width: 180,
      margin: 10
    }
  },
  
otpTransparentCard: {
    backgroundColor: "rgba(255, 255, 255, 0.3)",
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
    width: "380px",
    margin: "50px 120px",
    borderRadius: "25px",
    padding: "15px 35px",
    [theme.breakpoints.down("sm")]: {
      width: "380px !important",
      margin: "100px auto",
      padding: "30px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "300px !important",
      margin: "80px auto",
      padding: "15px",
    }
  },
  otpCardContent: {
    display: "flex",
  },
  otpTextBold: {
    fontWeight: 600
  },  
  otpTitleWelcome: {
    fontSize: "28px",
    color: "#292929",
    fontFamily:"SF Pro Text Medium",
    marginVertical: 8,
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px"
    },
    caretColor:"transparent",
  },
  subtitle: {
    caretColor:"transparent",
    fontSize: "16px",
    fontFamily:"SF Pro Text Medium",
    padding: 5,
    color:"#292929"
  },
  inputLabel: {
    fontSize: "18px",
    fontFamily:"SF Pro Text Regular",
    color: "#292929",
    margin: "0px auto",
    width: "270px",
    caretColor:"transparent",
  },
  otpContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "295px",
    margin: "10px auto 0px",
    [theme.breakpoints.down("xs")]: {
      width: "270px"
    }
  },
  inputField: {
    width: "52px",
    height: "52px !important",
    margin:"5px auto",
    fontSize: "14px",
    fontFamily:"SF Pro Text Regular",
    color: "#292929",
    "& .MuiInputBase-root":{
      color:"#292929",
      fontSize: "14px",
      fontFamily:"SF Pro Text Regular",
      background: "white !important",
      borderRadius: "10px",
      border: "none",
      height: "52px !important",
    },
    "& input": {
      color:"#292929",
      fontSize: "14px",
      fontFamily:"SF Pro Text Regular",
      "&::placeholder": {
        opacity: 1,
        color:"808080",
        fontSize: "14px",
        fontFamily:"SF Pro Text Regular",
      }
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#3f51b5 !important"
    },
    "& .MuiOutlinedInput-inputMarginDense": {
     padding:"13px 0px !important"
    },
    [theme.breakpoints.down("xs")]: {
      "& .MuiOutlinedInput-inputMarginDense": {
        padding:"12px 0px !important"
       },
    },
  },
  inputErrorField: {
    width: "52px",
    height: "52px !important",
    margin:"5px auto",
    fontSize: "16px",
    "& .MuiInputBase-root":{
      color:"#292929",
      fontSize: "14px",
      fontFamily:"SF Pro Text Regular",
      background: "white !important",
      borderRadius: "10px",
      border: "1px solid #FF0000 !important",
    },
    "& input": {
      color:"#292929",
      fontSize: "14px",
      fontFamily:"SF Pro Text Regular",
      "&::placeholder": {
        opacity: 1,
        fontSize: "14px",
        fontFamily:"SF Pro Text Regular",
        color:"#808080"
      } 
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#f44336"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent !important"
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent !important"
    },
    "& .MuiOutlinedInput-root.Mui-focuse .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent !important"
    },
    "& .MuiOutlinedInput-inputMarginDense": {
      padding:"13px 0px !important"
     },
     [theme.breakpoints.down("xs")]: {
       "& .MuiOutlinedInput-inputMarginDense": {
         padding:"12px 0px !important"
        },
     },
  },
  verifyOTP: {
    width: "100%",
    backgroundColor: "#014866",
    color: "#F2F6F7",
    border: "none",
    borderRadius: "10px",
    padding: "16px",
    cursor: "pointer",
    fontFamily:"SF Pro Text Bold",
    fontSize: "18px",
    margin: "10px auto",
    "&:hover": {
      backgroundColor: "#014866",
      color: "#F2F6F7",
    },
  },
  inputError: {
    color: "#FF0000",
    fontSize: "12px",
    marginTop: "10px",
    fontFamily:"SF Pro Text Regular",
  },
  successMessage: {
    fontSize: "20px",
    fontFamily:"SF Pro Text Bold",
    color: "#292929",
    margin: "30px auto",
    caretColor:"transparent",
  },
  successLogo: {
    width: "85px",
    margin: "20px auto",
  },
  emailSuccessLogo: {
    width: "85px",
    margin: "20px auto",
  },
  successBtn: {
    width: "100%",
    caretColor:"transparent",
    backgroundColor: "#014866",
    color: "#fff",
    border: "none",
    borderRadius: "10px",
    padding: "16px",
    cursor: "pointer",
    fontFamily:"SF Pro Text Bold",
    fontSize: "18px",
    margin: "10px auto",
    "&:hover": {
      backgroundColor: "#014866",
      color: "#fff",
    },
  },
  errorMessage: {
    display:"flex",
    justifyContent: "end",
    width: "85%",
    margin: "0px auto 10px"
  }
  // Customizable Area End
});

