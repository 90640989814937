// Customizable Area Start
import * as React from "react";
import { Grid, Box, withStyles, Typography, TextField, Button, FormHelperText, InputAdornment, Checkbox, SvgIcon, Chip, Dialog, ClickAwayListener, DialogContent, CardContent, Card } from "@material-ui/core";
import { styles } from "./styles/PhoneNumberRegistration";
import EmailAccountRegistrationController, { Props } from "./EmailAccountRegistrationController";
import { homecureTransparent, pexelsKarolina, MiscGreen, MiscGray, DownArrow, UpwardArrow, ImageIcons, Edit, gallery, camera, EmailIcon } from "./assets";
import CloseIcon from '@material-ui/icons/Close';
import Webcam from "react-webcam"
import { otp_success, verifiedEmail } from "../../customisableuserprofiles/src/assets";
import CountDownTimer from "../../../components/src/CountDownTimer.web";
import LocationView from "../../location/src/LocationView.web";

const genderSelectOptionsAyah = [
  { key: 1, value: "Male" },
  { key: 2, value: "Female" },
  { key: 3, value: "Other" },
];

const ayahContainerStyle = {
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundImage: `url(${pexelsKarolina})`,
  backgroundPosition: "center",
  padding: "10px"
}

const videoConstraint = {
  width: 450,
  facingMode:"environment"
}
// Customizable Area End

export class HealthCarePersonnelAyahRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    try {
      await Promise.all([this.getLanguagesList(), this.getAyahList()]);
        if(this.props.userLogin){
          await this.fetchDoctorData();
        }else{
        const hpData = await this.getHpData();
        this.setHpDate(hpData)
        }
      }catch (errors) {
        this.showAlert("Error fetching data:", "Something went wrong");
      }
  }

  showAyahErrorField = (error: string | undefined, classes: any, keyNumber?: string) => {
    if (keyNumber == "language") {
      return (error ? classes.languageAyahBoxError : classes.languageBox)
    }
    return (error ? classes.errorRegisterInputFields : classes?.registerInputFields)
  };

  showAyahSelectErrorField = (error: string | undefined, classes: any, keys?: string) => {
    return (error ? classes.hpErrorRegisterInputFields : classes.hpRegisterInputFields)
  };


  errorHpAyahMessage = (error: string | undefined, inputError: any) => {
    return (error ? <FormHelperText className={inputError}>{error}</FormHelperText> : null)
  };

  showOTPErrorAyah = (isError:boolean,otpErrors:any,errorMessage:string) => {
    return (
      isError && (
        <FormHelperText className={otpErrors}>
          {errorMessage}
        </FormHelperText>
      )
    );
  };

  getAyahClassName = (classes: any, error: string | undefined) => {
    const { isUploadDoc } = this.state;
    if (isUploadDoc) return error ? classes.registrationTextFieldError : classes.registrationTextField
    return (error ? classes.hpUploadErrorRegisterInputFields : classes.hpUploadRegisterInputFields)
  }

  getAyahPlaceholder = () => {
    const { isUploadDoc } = this.state;
    if (!isUploadDoc) return "Upload aadhar card";
    return "";
  }

  getAyahInputProps = (classes: any) => {
    const { isUploadDoc, image } = this.state;
    if (isUploadDoc) {
      return (
        <img
          className={classes.uploadImage}
          src={image}
          alt="Preview"
          style={{ objectFit: "cover" }}
        />
      );
    }
    return null;
  };

  selectLanguageText = (selectedLanguages: any, classes: any) => {
    return (
      <>
        {selectedLanguages.length === 0 && (
          <span className={classes.selectLanguagePlaceholder}>Enter languages</span>
        )}
      </>
    )
  }

  getFontWeightStyle = (selectedValue: any, targetValue: string, keyNum?:string) => {
    if(keyNum==="language") {
      const matchedLanguage = selectedValue.some((language: string) => language === targetValue);
      return { fontFamily: matchedLanguage ?  'SF Pro Text Medium' : 'SF Pro Text Regular' };
    }  
    return { fontFamily: selectedValue === targetValue ?  'SF Pro Text Medium' : 'SF Pro Text Regular' };
  };

  ayahErrorMessage = (classes:any, state:string, message:string) => {
    const showPopup = !!state;
    return (
      <Box>
        <Dialog open={showPopup}>
          <Box className={classes.customContainer}>
            <Box style={{ padding: "0px 10px" }}>
              <p className={classes.heading}>{message}</p>
              <p className={classes.paragraph}>{state}</p>
              <Button 
              style={{
                backgroundColor: "rgb(33, 150, 243)",
                border: "none",
                borderRadius: "3px",
                color: "#fff",
                padding: "5px",
                marginTop:"10px",
                marginBottom: "15px",
                fontFamily:"SF Pro Text Regular"
              }} 
              onClick={this.closHPPopup}>OK</Button>
            </Box>
          </Box>
        </Dialog>
      </Box>
    )
  }
  hpAyahLanguageContainer = (selectedLanguages:any,classes:any) => {
    return selectedLanguages.length>0 ? classes: "";
  }

  enableAyahDialogBox = (enableWeb:any,classes:any) => {
    const showDialog =  enableWeb ? classes.webDialogBox : classes.dialogBox;
    return showDialog;
  } 

  ayahData = (state:any) => {
    const value = state.ayahList
    .find((ayah: any) => ayah.id === state.selectedAyah)
    ?.attributes.name || ""
    return value;
  }
  ayahProfileHeading = this.props.userLogin ? "Edit Profile" : "Register";

  generateOTPResendComponent = (text:string, testId: string) => (
    <Typography variant="h6" data-test-id={testId} 
      style={{
        color: this.state.resendCounter === 5 || !this.state.isResendActive ? "#292929" : "#014866", fontSize: "16px", fontFamily: "SF Pro Text Medium", textTransform: "none", fontWeight: 500,
        cursor: this.state.resendCounter === 5 || !this.state.isResendActive ? "not-allowed" : "pointer",
        opacity: this.state.resendCounter === 5 || !this.state.isResendActive ? 0.5 : 1,
        caretColor: "transparent"
      }}
     >
      {text}</Typography>
  );

  checkLanguageMsg = (language:any,openLanguageDropdown:boolean) => {
    return !language && !openLanguageDropdown
  }

  inputErrorFieldVerifyOtp = (isError:boolean,classes:any) => {
    return  isError ? classes.inputErrorFieldVerifyOtp : classes.inputFieldOtpVerify
  }

  checkboxIcon = (selectedAyah:any, id:any) => {
    return selectedAyah === id ? 600 : 500 
  }

  ayahMouseLeave = (hpDocDetailsErr:any) => {return !hpDocDetailsErr.language ? "32px" : ""}
  hpAyahLongTermBooking = (longTermBooking:any,classes:any) => {
    return longTermBooking ? classes.hpConnectHospitalFields : classes.hpConnectHospitalFieldsGray
  }
  hpAyahIcon = (longTermBooking:any) => {return longTermBooking ? MiscGreen : MiscGray}
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { hpDocDetailsErr } = this.state;
    // Customizable Area End

    return (
      // Customizable Area Start
      <>
      {
        !this.state.showGoogleMap ? 
        (
      <>
        <Grid container id="ayha-container">
          <>
            <Grid item xl={4} md={4} sm={12} xs={12} className={`${classes.fixedGrid} ${classes.gridContainer}`}>
              <Box
                data-test-id="hpAyahAyahLogoContainer"
                component="div"
                sx={{
                  height: "100%"
                }}
                className={classes.container}
                style={ayahContainerStyle}
              >
                <Box className={classes.homeCureLogosContainer}>
                  <img
                    data-test-id="ayah-logo"
                    src={homecureTransparent}
                    alt="HomeCure Logo"
                    className={classes.ayahHomecureLogos}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item className={classes.gridContainer} xl={8} md={8} sm={12} xs={12}>
              <Box className={classes.patientRegistrationContainer}>
                <Box className={classes.patientLabelContainer}>
                  <Typography variant="h6" className={classes.registrationHeading}>
                    {this.ayahProfileHeading}
                  </Typography>
                  <Box data-test-id="setLocationAyah" className={classes.setLocationContainer}
                    onClick={this.openSetLocationPage}
                  >
                    <Typography variant="h6" data-test-id="setLocation" className={classes.setLocation} onClick={this.openSetLocationPage}>
                      Set Location
                    </Typography>
                  </Box>
                </Box>
                {this.props.userLogin ? (<Box className={classes.editIconsConatiners}>
                    <Box>
                        <img
                            className={classes.avatarEdit}
                            data-test-id="ayahprofile"
                            src={this.state.profilePicture}
                            onClick={() => this.backToProfileView()}
                        />
                        <img
                            data-test-id="editProfile"
                            className={classes.avatarEditIcons}
                            src={Edit}
                            onClick={this.handleOpenDialogs}
                        />
                    </Box>
                </Box>):
                (<Typography variant="h6" className={classes.patientHeading}>
                  Let's get you all set up so you can verify and manage your account.
                </Typography>)}
                <Grid container spacing={4}>
                  <Grid item xl={12} md={12} sm={12} xs={12}>
                    <Box style={{ width: "48%" }}>
                      <TextField
                        variant="outlined"
                        data-test-id="hpAyahLongTermBooking"
                        className={this.hpAyahLongTermBooking(this.state.longTermBooking,classes)}
                        placeholder="Long Term Booking"
                        onClick={this.hpLongTermBookingChange}
                        InputProps={{
                          endAdornment: (<InputAdornment position="end">
                            <img
                              src={this.hpAyahIcon(this.state.longTermBooking)}
                              alt="Misc"
                              className={classes.connectIcon}
                            />
                          </InputAdornment>),
                          readOnly:true
                        }}
                      />
                    </Box>   
                  </Grid>
                  <Grid item xl={6} md={6} sm={6} xs={12} className={classes.hospitalContainerSpacing}>
                    <ClickAwayListener data-test-id="hpAyahMouseLeave"
                      onClickAway={this.closeAyahDropdown}>
                      <Box style={{ marginBottom: this.ayahMouseLeave(hpDocDetailsErr) }} >
                      <Typography variant="h6" className={classes.registerLabel} style={{ textAlign: "left" }}>
                        Select the Ayah<span className={classes.required}>*</span>
                      </Typography>
                      <Box className={classes.hpSelectCheckbox}>
                        <TextField
                          variant="outlined"
                          data-test-id="hpAyahDropdown"
                          className={this.showAyahSelectErrorField(hpDocDetailsErr.selectedAyah, classes)}
                          placeholder="Select the Ayah"
                          value={this.ayahData(this.state)}
                          onClick={this.openAyahDropdown}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <img
                                  src={DownArrow}
                                  alt="DownArrow"
                                  className={classes.hpDownArrowIcon}
                                />
                               </InputAdornment>),
                               readOnly: true
                          }}
                        />
                        <>
                          {this.state.openAyahDropdown && (
                            <Box className={classes.hpSelectFieldDropdown}>
                              <Box className={classes.hpDropdownContent}>
                                <Box className={classes.dropdownText}>
                                  Select the ayah
                                  <img
                                    data-test-id="hideAyahDropdown"
                                    src={UpwardArrow}
                                    alt="UpwardArrow"
                                    className={classes.hpUpArrowIcon}
                                    onClick={this.openAyahDropdown}
                                  />
                                </Box>
                                <Box className={classes.hpSelectFieldSize}>
                                  {this.state.ayahList.map((ayah: any, index: number) => (
                                    <Box className={classes.hpSelectOption} key={ayah.id} style={{ fontWeight: this.checkboxIcon(this.state.selectedAyah,ayah.id) }}>
                                      <Checkbox
                                        data-test-id={`selectAyah${index}`}
                                        className={classes.checkbox}
                                        onChange={() => this.handleAyahCheckboxChange(ayah.id)}
                                        checked={this.state.selectedAyah === ayah.id}
                                        icon={ <SvgIcon width="16" height="16"  viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#014866"/>
                                        </SvgIcon>}
                                        checkedIcon={<SvgIcon width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#014866" />
                                        <path d="M3.75 7.99992L6.58 10.8299L12.25 5.16992" stroke="#014866" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                      </SvgIcon>}
                                      />{ayah.attributes.name}
                                    </Box>
                                  ))}
                                </Box>
                              </Box>
                            </Box>
                          )}
                        </>
                        {this.errorHpAyahMessage(hpDocDetailsErr.selectedAyah, classes.inputErrors)}
                      </Box>
                      </Box>
                    </ClickAwayListener>
                  </Grid>  
                  <Grid item xl={6} md={6} sm={6} xs={12} className={classes.mobileHospitalContainerSpacing}>
                  <ClickAwayListener data-test-id="lanaguageMouseLeave"
                      onClickAway={this.closeLanguageDropdown}>
                      <Box id="languageContainer">
                      <Typography variant="h6" className={classes.registerLabel} style={{ textAlign: "left" }}>
                        Languages Known<span className={classes.required}>*</span>
                      </Typography>
                      <Box className={classes.hpSelectCheckbox}>
                        <Box data-test-id="hpAyahLanguage" className={this.showAyahErrorField(hpDocDetailsErr.language, classes, "language")} onClick={this.openLanguageDropdown}>
                        <Box className={this.hpAyahLanguageContainer(this.state.selectedLanguages,classes.patientLanguageBox)}>
                          {this.state.selectedLanguages.map((languageId: any) => {
                            const language = this.state.languagesList.find((language: any) => language.id === languageId);
                            return (
                              <Chip
                                data-test-id="removeAyahLanguage"
                                key={language.id}
                                label={language.attributes?.name}
                                onDelete={() => this.removeSelectedLanguage(language.id)}
                                deleteIcon={<CloseIcon fontSize="small" />}
                                className={classes.langIcon}
                              />
                            );
                          })}
                          </Box>
                          {this.selectLanguageText(this.state.selectedLanguages, classes)}
                          <img
                            className={classes.hpLangDownArrowIcon}
                            src={DownArrow}
                            alt="DownAyahArrow"
                            style={{ marginLeft: 'auto' }}
                          />
                        </Box>

                        {this.checkLanguageMsg(hpDocDetailsErr.language,this.state.openLanguageDropdown) && (
                          <Typography variant="h6" className={classes.languageText}>
                            (Please select a maximum of 4 languages)
                          </Typography>)}
                        <>
                          {this.state.openLanguageDropdown && (
                            <Box className={classes.hplangSelectFieldDropdown}>
                              <Box className={classes.hpLangDropdownContent}>
                                <Box className={classes.langdropdownText}>
                                  <img
                                    data-test-id="hideAyahLanguageDropdown"
                                    src={UpwardArrow}
                                    alt="UpwardAyahArrow"
                                    className={classes.hpUpArrowIcon}
                                    onClick={this.openLanguageDropdown}
                                  />
                                </Box>
                                <Box data-test-id="hpLangSelectFieldSize"  className={classes.hpLangSelectFieldSize}>
                                  {this.state.languagesList.map((language: any, index: number) => (
                                    <Box className={classes.hpSelectOption} key={language.id} style={this.getFontWeightStyle(this.state.selectedLanguages, language.id, "language")}>
                                      <Checkbox
                                        data-test-id={`selectAyahLanguage${index}`}
                                        className={classes.checkbox}
                                        icon={ <SvgIcon fill="none" width="16" height="16" viewBox="0 0 18 18"  xmlns="http://www.w3.org/2000/svg">
                                        <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#014866"/>
                                        </SvgIcon>}
                                        checkedIcon={<SvgIcon  height="16" width="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="0.5" y="0.5" rx="1.5" width="15" height="15" stroke="#014866" />
                                        <path d="M3.75 7.99992L6.58 10.8299L12.25 5.16992" stroke="#014866" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                      </SvgIcon>}
                                        checked={this.state.selectedLanguages.includes(language.id)}
                                        onChange={() => this.handleLanguagesCheckboxChange(language.id)}
                                      />{language.attributes.name}
                                    </Box>
                                  ))}
                                </Box>
                              </Box>
                            </Box>
                          )}
                        </>
                      </Box>
                      {this.errorHpAyahMessage(hpDocDetailsErr.language, classes.ayahInputErrors)}
                      </Box>
                    </ClickAwayListener>
                  </Grid>  
                  <Grid item xl={6} md={6} sm={6} xs={12} className={classes.hospitalContainerSpacing}>
                    <Box data-test-id="years-of-experience">
                      <Typography variant="h6" className={classes.registerLabel} style={{ textAlign: "left" }}>
                        Years of Experience<span className={classes.required}>*</span>
                      </Typography>
                      <TextField
                        variant="outlined"
                        data-test-id="hpAyahExperience"
                        className={this.showAyahErrorField(hpDocDetailsErr.yearofExp, classes)}
                        placeholder="Enter experience"
                        value={this.state.yearofExp}
                        onChange={(event) => this.handlehpChange("yearofExp", event.target.value.trimStart())}
                      />
                      {this.errorHpAyahMessage(hpDocDetailsErr.yearofExp, classes.inputErrors)}
                    </Box>
                    <Box data-test-id="pincode">
                      <Typography variant="h6" className={classes.registerLabel} style={{ textAlign: "left" }}>
                        Pin Code<span className={classes.required}>*</span>
                      </Typography>
                      <TextField
                        variant="outlined"
                        data-test-id="hpAyahPincode"
                        InputProps={{ inputProps: { maxLength: 6 } }}
                        className={this.showAyahErrorField(hpDocDetailsErr.pincode, classes)}
                        placeholder="6 digit Pin Code"
                        value={this.state.pincode}
                        onChange={(event) => this.handlehpChange("pincode", event.target.value.trimStart())}
                      />
                      {this.errorHpAyahMessage(hpDocDetailsErr.pincode, classes.inputErrors)}
                    </Box>
                    <Box data-test-id="address">
                      <Typography variant="h6" className={classes.registerLabel} style={{ textAlign: "left" }}>
                        Address<span className={classes.required}>*</span>
                      </Typography>
                      <TextField
                        variant="outlined"
                        data-test-id="hpAyahAddress"
                        className={this.showAyahErrorField(hpDocDetailsErr.address, classes)}
                        placeholder="Enter house no. , street name"
                        value={this.state.address}
                        onChange={(event) => this.handlehpChange("address", event.target.value.trimStart())}
                      />
                      {this.errorHpAyahMessage(hpDocDetailsErr.address, classes.inputErrors)}
                    </Box>
                    {
                      this.props.userLogin && 
                      <>
                        <Box data-test-id="hpAyahemail">
                          <Typography variant="h6" className={classes.registerLabel} style={{ textAlign: "left" }}>
                          Email Address<span className={classes.required}>*</span>
                          </Typography>
                          <TextField
                            variant="outlined"
                            data-test-id="hpAyahEmail"
                            className={this.showAyahErrorField(hpDocDetailsErr.email, classes)}
                            placeholder="Enter Email"
                            value={this.state.email}
                            onChange={(event) => this.handlehpChange("email", event.target.value.trimStart())}
                          />
                          {this.errorHpAyahMessage(hpDocDetailsErr.email, classes.inputErrors)}
                        </Box>
                      </>
                    }  
                    <Box>
                      <Typography variant="h6" className={classes.registerLabel} style={{ textAlign: "left" }}>
                        Aadhar Card<span className={classes.required}>*</span>
                      </Typography>
                      {!this.state.isUploadDoc &&

                        <TextField
                          id="uploadDoc"
                          variant="outlined"
                          data-test-id="uploadDocAyah"
                          style={{caretColor:"white !important"}}
                          className={this.getAyahClassName(classes, hpDocDetailsErr.uploadDoc)}
                          placeholder={this.getAyahPlaceholder()}
                          value=""
                          InputProps={{
                            startAdornment: (<InputAdornment position="start">
                              <img
                                src={ImageIcons}
                                alt="Preview"
                                className={classes.ayahDocIcon}
                              />
                            </InputAdornment>)
                          }}
                          onClick={this.handleButtonClick}
                        />}
                      <Box onClick={this.handleButtonClick}>
                        <Typography variant="h6" style={{ textAlign: "left" }}>
                          {this.getAyahInputProps(classes)}
                        </Typography>
                      </Box>
                      {this.errorHpAyahMessage(hpDocDetailsErr.uploadDoc, classes.inputErrors)}
                      <input
                        type="file"
                        ref={this.fileInputRef}
                        style={{ display: "none" }}
                        onChange={this.handleUploadDoc}
                      />
                    </Box>
                    <Button
                      variant="contained"
                      data-test-id="submit"
                      color="primary"
                      className={classes.registerDesktopButtons}
                      style={{ textTransform: "capitalize" }}
                      onClick={this.handleHpSubmit}
                    >
                      Submit
                    </Button>
                  </Grid>
                  <Grid item xl={6} md={6} sm={6} xs={12} className={classes.mobileHospitalContainerSpacing}>
                    <Box className={classes.ayahAddressDetailsContainer}>
                      <Box>
                        <Box data-test-id="age">
                          <Typography variant="h6" className={classes.registerLabel} style={{ textAlign: "left" }}>
                            Age<span className={classes.required}>*</span>
                          </Typography>
                          <TextField
                            variant="outlined"
                            data-test-id="hpAyahAge"
                            placeholder="Enter age"
                            value={this.state.age}
                            className={this.showAyahErrorField(hpDocDetailsErr.age, classes)}
                            onChange={(event) => this.handlehpChange("age", event.target.value.trimStart())}
                          />
                          {this.errorHpAyahMessage(hpDocDetailsErr.age, classes.inputErrors)}
                        </Box>
                        <Box>
                          <Typography variant="h6" className={classes.registerLabel} style={{ textAlign: "left" }}>
                            City<span className={classes.required}>*</span>
                          </Typography>
                          <TextField
                            variant="outlined"
                            data-test-id="hpAyahCity"
                            className={this.showAyahErrorField(hpDocDetailsErr.city, classes)}
                            placeholder="Enter city"
                            value={this.state.city}
                            onChange={(event) => this.handlehpChange("city", event.target.value.trimStart())}
                          />
                          {this.errorHpAyahMessage(hpDocDetailsErr.city, classes.inputErrors)}
                        </Box>
                      </Box>
                      <Box>
                      <ClickAwayListener data-test-id="openAyahGenderDropdown"
                         onClickAway={this.closeGenderDropdown}>
                      <Box id="genderContainer" data-test-id="genderContainer">
                        <Typography variant="h6" className={classes.registerLabel} style={{ textAlign: "left" }}>
                          Gender<span className={classes.required}>*</span>
                        </Typography>
                        <Box className={classes.hpSelectCheckbox}>
                          <TextField
                            variant="outlined"
                            data-test-id="hpAyahGender"
                            placeholder="Enter gender"
                            value={this.state.gender}
                            className={this.showAyahSelectErrorField(hpDocDetailsErr.gender, classes)}
                            InputProps={{
                              endAdornment: (<InputAdornment position="end">
                                  <img
                                    src={DownArrow}
                                    alt="DownArrow"
                                    className={classes.hpDownArrowIcon}
                                    onClick={this.openDropdown}
                                  />
                                </InputAdornment>),
                              readOnly: true
                            }}
                            onClick={this.openDropdown}
                          />
                          <Box>
                            {this.state.showDropdown && (
                              <Box className={classes.hpSelectFieldDropdown}>
                                <Box className={classes.hpDropdownContent}>
                                  <Box className={classes.dropdownText}>
                                    Enter gender
                                    <img
                                      data-test-id="hideGenderDropdown"
                                      src={UpwardArrow}
                                      alt="hideGenderDropdown"
                                      className={classes.hpUpArrowIcon}
                                      onClick={this.openDropdown}
                                    />
                                  </Box>
                                  <Box>
                                      {genderSelectOptionsAyah.map((data: any, index: number) => (
                                          <Box className={classes.hpSelectOption} key={index} style={this.getFontWeightStyle(this.state.gender, data.value)}>
                                            <Checkbox
                                              data-test-id={`selectGender${index}`}
                                              className={classes.checkbox}
                                              checked={this.state.gender === data.value}
                                              icon={<SvgIcon fill="none"  width="16" height="16" viewBox="0 0 18 18"  xmlns="http://www.w3.org/2000/svg">
                                              <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#014866"/>
                                              </SvgIcon>}
                                              checkedIcon={<SvgIcon height="16" width="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <rect x="0.5" y="0.5" rx="1.5" width="15" height="15" stroke="#014866" />
                                              <path d="M3.75 7.99992L6.58 10.8299L12.25 5.16992" stroke="#014866" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            </SvgIcon>}
                                              onChange={() => this.handleHpGenderCheckboxChange(data.value)}
                                            />{data.value}
                                          </Box>
                                        ))}
                                  </Box>
                                </Box>
                              </Box>
                            )}
                          </Box>
                          {this.errorHpAyahMessage(hpDocDetailsErr.gender, classes.inputErrors)}
                        </Box>
                      </Box>
                      </ClickAwayListener>
                        <Box>
                          <Typography variant="h6" className={classes.registerLabel} style={{ textAlign: "left" }}>
                            District<span className={classes.required}>*</span>
                          </Typography>
                          <TextField
                            variant="outlined"
                            data-test-id="hpAyahDistrict"
                            className={this.showAyahErrorField(hpDocDetailsErr.district, classes)}
                            placeholder="Enter district"
                            value={this.state.district}
                            onChange={(event) => this.handlehpChange("district", event.target.value.trimStart())}
                          />
                          {this.errorHpAyahMessage(hpDocDetailsErr.district, classes.inputErrors)}
                        </Box>
                      </Box>
                    </Box>
                    <Box>
                      <Typography variant="h6" className={classes.registerLabel} style={{ textAlign: "left" }}>
                        Aadhar Card No.<span className={classes.required}>*</span>
                      </Typography>
                      <TextField
                        variant="outlined"
                        data-test-id="hpAyahAaadharCardNumber"
                        className={this.showAyahErrorField(hpDocDetailsErr.aadharCardNumber, classes)}
                        placeholder="Enter aadhar card no."
                        value={this.state.aadharCardNumber}
                        onChange={(event) => this.handlehpChange("aadharCardNumber", event.target.value.trimStart())}
                      />
                      {this.errorHpAyahMessage(hpDocDetailsErr.aadharCardNumber, classes.inputErrors)}
                    </Box>
                    {
                      this.props.userLogin &&
                      <Box>
                      <Typography variant="h6" className={classes.registerLabel} style={{ textAlign: "left" }}>
                        Phone Number<span className={classes.required}>*</span>
                      </Typography>
                      <TextField
                        variant="outlined"
                        data-test-id="hpPhoneNumber"
                        className={this.showAyahErrorField(hpDocDetailsErr.phoneNumber, classes)}
                        placeholder="Enter Phone Number"
                        value={this.state.phoneNumber}
                        InputProps={{inputProps: { maxLength: 10 } }}
                        onChange={(event) => this.handlehpChange("phoneNumber", event.target.value.trimStart())}
                      />
                    {this.errorHpAyahMessage(hpDocDetailsErr.phoneNumber, classes.inputErrors)}
                    </Box>
                    }
                    
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </>
          <Dialog className={ classes.otpDialogBoxNew } open={this.state.otpSend}>
              <Card className={classes.otpNewTransparentCard}>
                <CardContent className={ classes.otpCardContentNew } style={{ flexDirection: "column" }}>
                  <Box className={ classes.profileCaredWrapper }>
                    <Typography variant="h6" gutterBottom className={ classes.otpWelcomeTitle } style={{ textAlign: "center" }}>
                      <span className={ classes.otpText }>OTP</span>{" "} Verification
                    </Typography>
                    <CloseIcon data-test-id="closeDialog" style={{cursor: "pointer",caretColor: "transparent",marginTop: "10px" }} onClick={() => this.closeOtpPopup("hospital", "phone")} />
                  </Box>
                  <Typography variant="h6" gutterBottom className={ classes.cardSubtitle } style={{ textAlign: "center" }}>Enter OTP</Typography>
                  <Typography variant="h6" className={ classes.cardInputLabel } style={{ textAlign: "center" }}  >
                    An 4- Digit code has been sent to {"\n"}+91{""}  {`******${this.state.phoneNumber?.toString().slice(6, 10)}`}</Typography>
                  <Box className={classes.otpUpdateContainer}>
                    {this.state.phoneOTP.map((value: any, index: any) =>(
                      <TextField key={index} className={this.inputErrorFieldVerifyOtp(this.state.isError,classes)} variant="outlined"
                        size="small" id={`updateOtpInput${index}`} data-test-id={`updateOtpInput${index}`} value={value}
                        onChange={event =>this.handleOTPChange(index, event)}
                        style={{ margin: "5px auto 0px",width: "50px", height: "45px"}}
                        inputProps={{maxLength: 1,style: {textAlign: "center"}}}
                      />
                    ))}
                  </Box>
                  <Box className={ classes.errorMessages }> {this.showOTPErrorAyah(this.state.isError,classes.otpErrors,this.state.errorMessage)}</Box>
                  <CountDownTimer times={this.state.resendCounter} isResendActive={this.state.isResendActive} toggleResend={this.toggleResend} />
                  <Box display="flex" flexDirection="row" justifyContent="space-between" padding="8px 0px">
                    {this.generateOTPResendComponent("Resend OTP","resendOTP" )}

                    {this.generateOTPResendComponent("Get OTP by Call","resendOTPByCall")}
                  </Box>
                  <Button variant="contained" color="primary" data-test-id="updateVerifyOtpBtn" className={classes.verifyOTPNew} onClick={() => this.verifyOTPData()} style={{ textTransform: "capitalize" }}>
                    Verify
                  </Button>
                 </CardContent>
               </Card>
            </Dialog>
            <Dialog open={this.state.emailSend} className={classes.otpDialogBoxNew}>
              <Card className={classes.transparentOtpCards}>
                <CardContent className={classes.cardOtpContents} style={{ flexDirection: "column" }}>
                  <Box className={classes.confimPopup}><CloseIcon data-test-id="closeDialogtest" style={{cursor: "pointer", caretColor: "transparent"}}  onClick={() => this.closeOtpPopup("hospital", "email")}/></Box>
                  <img src={EmailIcon} alt="email Verified" className={classes.emailConfirmOtpLogo} style={{ objectFit: "contain" }}/>
                  <Typography  variant="h6" className={classes.confirmEmailOtpHeading} style={{ textAlign: "center" }}>Confirm your email address</Typography>
                  <Typography variant="h6" className={classes.confirmOtpEmailText} style={{ textAlign: "center" }}>  We sent a confirmation email to {this.state.email}</Typography>
                  <Box className={classes.hospitalSuccessMessageContainer}>
                    <Typography data-test-id="textMessage1" variant="h6" className={classes.confirmEmailOtpSubText} style={{ textAlign: "center" }}>Check your email and click on the</Typography>
                    <Typography data-test-id="textMessage2" variant="h6" className={classes.confirmEmailOtpSubText} style={{ textAlign: "center" }}>  confirmation link to continue.</Typography>
                  </Box>
                  <Button variant="contained"  color="primary"  data-test-id="emailSend" className={classes.successOtpBtns}  onClick={this.confirmEmail} style={{ textTransform: "capitalize" }}>
                    Resend
                  </Button>
                </CardContent>
              </Card>
            </Dialog>
            <Dialog open={this.state.confimEmail} className={classes.otpDialogBoxNew}>
              <Card className={classes.transparentOtpCards}>
                <CardContent className={classes.cardOtpContents}  style={{ flexDirection: "column" }}>
                  <img src={verifiedEmail} alt="email Verified" className={classes.emailVerified}  style={{ objectFit: "contain" }}/>
                  <Typography  variant="h6" className={classes.emailMessage} style={{ textAlign: "center" }}> Your Email Address Verified</Typography>
                  <Typography variant="h6" className={classes.successEmailMessages} style={{ textAlign: "center" }}>Successfully</Typography>
                  <Button variant="contained" color="primary" data-test-id="confimEmail" className={classes.successOtpBtns} onClick={() => this.otpSuccessEmail("hospital")} style={{ textTransform: "capitalize" }}>  Done</Button>
                </CardContent>
              </Card>
            </Dialog>
            <Dialog open={!!this.state.otpVerified} className={classes.otpDialogBoxNew}>
              <Card data-test-id="otpCardContainer" className={classes.otpNewTransparentCard}>
                <CardContent className={classes.otpCardContentNew}  style={{ flexDirection: "column" }}>
                  <img src={otp_success}  alt="PhoneVerified" className={classes.OtpSuccessLogo} style={{ objectFit: "contain" }}/>
                  <Typography variant="h6" className={classes.OtpSuccessMessage} style={{ textAlign: "center" }}>Phone Verified Successfully</Typography>
                  <Button data-test-id="otpVerified" variant="contained" color="primary" className={classes.successOtpBtn} onClick={() => this.otpSuccess("hospital")}  style={{ textTransform: "capitalize" }}> Done</Button>
                </CardContent>
              </Card>
            </Dialog>
          <Dialog
              open={this.state.openDialog}
              className={this.enableAyahDialogBox(this.state.enableWeb,classes)}
              onClose={this.handleCloseDialog}
            >
              <Box className={classes.dialogTitleConatiner}>
                <Typography className={classes.dialogTitle}>
                  Upload Profile Image
                </Typography>
                <CloseIcon data-test-id="closeDocAyahDialog" style={{ cursor: "pointer" }} onClick={this.handleCloseDialog} />
              </Box>
              <DialogContent>
                <>
                    <>
                      <Box className={classes.dialogBoxIcons} data-test-id="customAyahContainer">
                        <img
                          data-test-id="openAyahWebToUpload"
                          className={classes.camera}
                          src={camera}
                        />
                        <img
                          data-test-id="openAyahPopupToUpload"
                          className={classes.gallery}
                          src={gallery}
                          onClick={this.handleHpUploadProfiles}
                        />
                        <input
                          type="file"
                          className={classes.imageInput}
                          ref={this.webCameraRef}
                          onChange={this.handleUploadImage}
                        />
                      </Box>
                      <Box className={classes.dialogBoxText}>
                        <Typography data-test-id="takePhoto" className={classes.uploadCameraText}>Take Photo</Typography>
                        <Typography data-test-id="gallery" className={classes.uploadText}>Gallery</Typography>
                      </Box>
                    </>
                  {this.errorHpAyahMessage(this.state.UploadImageError, classes.profileError)}
                </>
              </DialogContent>
          </Dialog>
        </Grid>
        {this.ayahErrorMessage(classes,this.state.healthcarePersonnelError,this.state.messageType)}
      </>
        )
        :
        (
          <>
            <LocationView id="" navigation="undefined" setAddress={this.setAddressFromChild} closeSetLocationPage={this.closeSetLocationPage} setAddressFieldsByMap={this.setAddressFieldsByMap} showGoogleMap={this.state.showGoogleMap} longitude={this.state.longitude} latitude={this.state.latitude} />
          </>
        )
      }
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(styles)(HealthCarePersonnelAyahRegistration);
// Customizable Area End
