import * as React from "react";
// Customizable Area Start
import { Box, Typography, Card, CardContent, Button, withStyles } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { homecureTransparent, physicianMedical, EmailIcon } from "./assets";
import { styles } from "./styles/PhoneNumberRegistration";
import EmailAccountRegistrationController, { Props } from "./EmailAccountRegistrationController";
// Customizable Area End

export class ConfirmEmail extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const email = await this.getEmail();
    if(email){
      this.setState({email:email});
    }
  }
  // Customizable Area End
  
  render() {
    // Customizable Area Start
    const { classes } = this.props
    // Customizable Area End
    return (
      // Customizable Area Start
      <Box
        component="div"
        style={{
          backgroundImage: `url(${physicianMedical})`,
          backgroundPosition: "top",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
        sx={{
          width: "100%",
          minHeight: "100vh",
          height: "100%"
        }}
      >
        <Box className={classes.homeCureContainers}>
          <img
            src={homecureTransparent}
            alt='HomeCure Logo'
            className={classes.homecureLogos}
            style={{ objectFit: 'contain' }}
          />
        </Box>
        <Box className={classes.backButtons} onClick={this.handleBackButton}>
          <ArrowBackIosIcon style={{ width: "15px", height: "15px" }} />
        </Box>
        <Box>
          <Card className={classes.transparentCards}>
            <CardContent className={classes.cardContents} style={{ flexDirection: "column" }}>
              <img
                src={EmailIcon}
                alt='email Verified'
                className={classes.emailConfirmLogo}
                style={{ objectFit: 'contain' }}
              />
              <Typography variant="h6" className={classes.confirmEmailHeading} style={{ textAlign: "center" }}>
                Confirm your email address
              </Typography>
              <Typography variant="h6" className={classes.confirmEmailText} style={{ textAlign: "center" }}>
                We sent a confirmation email to {this.state.email}
              </Typography>
              <Box className={classes.hospitalSuccessMessageContainer}>
                <Typography data-test-id="textMessage1" variant="h6" className={classes.confirmEmailSubText} style={{ textAlign: "center" }}>
                  Check your email and click on the
                </Typography>
                <Typography data-test-id="textMessage2" variant="h6" className={classes.confirmEmailSubText} style={{ textAlign: "center" }}>
                  confirmation link to continue.
                </Typography>
              </Box>
              <Button
                variant="contained"
                color="primary"
                data-test-id="moveToNextPage"
                className={classes.successBtns}
                onClick={this.redirectToEmailVerified}
                style={{ textTransform: "capitalize" }}
              >
                Resend
              </Button>
            </CardContent>
          </Card>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(styles)(ConfirmEmail);
// Customizable Area End
