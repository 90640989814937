import * as React from "react";
// Customizable Area Start
import { Box, withStyles } from "@material-ui/core";
import CustomisableUserProfilesController, {
    Props
} from "./CustomisableUserProfilesController";
import { styles } from "./styles/CustomisableUserProfiles";
import { HealthCarePersonalRegistration } from "../../email-account-registration/src/HealthCarePersonalRegistration.web";
import { getStorageData } from "../../../framework/src/Utilities";
import { HealthCarePersonnelAyahRegistration } from "../../email-account-registration/src/HealthCarePersonnelAyahRegistration.web";
import { HealthCarePersonnelRegistration } from "../../email-account-registration/src/HealthCarePersonnelRegistration.web";
import Loader from "../../../components/src/Loader";
// Customizable Area End

export class CustomisableEditHealthcarePersonnel extends CustomisableUserProfilesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End 
    }

    // Customizable Area Start
    async componentDidMount() {
        const kind = await getStorageData("hpRole");
        this.setState({ kind: kind.charAt(0).toUpperCase() + kind.slice(1) })

    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const kind = this.state.kind;
        // Customizable Area End 
        return (
            // Customizable Area Start
            <>
                {!!kind ? (
                    <>
                        {kind === "Doctor" && (<HealthCarePersonalRegistration classes={this.props.classes} navigation={this.props.navigation} id={""} google={undefined} userLogin={true} />)}
                        {kind === "Ayah" && (<HealthCarePersonnelAyahRegistration classes={this.props.classes} navigation={this.props.navigation} id={""} google={undefined} userLogin={true}/>)}
                        {kind !== "Doctor" && kind !== "Ayah" && (
                            <HealthCarePersonnelRegistration classes={this.props.classes} navigation={this.props.navigation} id={""} google={undefined} userLogin={true} updatedKind={kind}/>
                        )} 
                </>
                ) : (
                <>
                <Box>
                    <Loader loading={!kind} /> 
                </Box>
                </>
                )}
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export default withStyles(styles)(CustomisableEditHealthcarePersonnel);
// Customizable Area End