import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData, setStorageData, removeStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  countryCodeSelected: string;
  mobileNo: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;

  placeHolderCountryCode: string;
  placeHolderMobile: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  labelForgotPassword: string;
  btnTxtEmailLogin: string;
  isError: boolean;
  errorMessage: string;
  isLoading: boolean;
  token: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class MobileAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  apiPhoneLoginCallId: any;
  getDataCallId: any;
  labelTitle: string;
  private readonly errorTitle = "Error";
  // Customizable Area End    

  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
    ];

    this.state = {
      countryCodeSelected: "",
      mobileNo: "",
      password: "",
      placeHolderCountryCode: "Select Country",
      enablePasswordField: true,
      checkedRememberMe: false,

      placeHolderMobile: configJSON.placeHolderMobile,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      labelForgotPassword: configJSON.labelForgotPassword,
      btnTxtEmailLogin: configJSON.btnTxtEmailLogin,
      isError: false,
      errorMessage: "",
      isLoading: false,
      token: ""
    };

    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.ReciveUserCredentials) === message.id) {
      const countryCode = message.getData(
        getName(MessageEnum.LoginCountryCode)
      );

      const userName = message.getData(getName(MessageEnum.LoginUserName));

      const password = message.getData(getName(MessageEnum.LoginPassword));

      if (countryCode && userName && password) {
        this.setState({
          mobileNo: userName,
          placeHolderCountryCode: "+" + countryCode,
          countryCodeSelected: countryCode,
          password: password,
          checkedRememberMe: true
        });
      }
    } else if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      const selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      this.checkCountryCode(selectedCode)
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiPhoneLoginCallId &&
      this.apiPhoneLoginCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.meta?.token) {
        this.setState({ token: responseJson.meta.token });

        const msgs: Message = new Message(
          getName(MessageEnum.NavigationMobilePhoneOTPMessage)
        );

        msgs.addData(
          getName(MessageEnum.AuthTokenDataMessage),
          this.state.token
        );

        msgs.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

        msgs.addData(
          getName(MessageEnum.AuthTokenPhoneNumberMessage),
          this.state.mobileNo
        );
        setStorageData("authToken", responseJson.meta.token);
        setStorageData("phoneNumber", this.state.mobileNo);
        setStorageData("isSignUp", "false");
        setStorageData("isLogin", "true")
        this.send(msgs);
        this.props.navigation.navigate("OTPInputAuth")
      } else if (responseJson?.errors) {
        this.showErrorMessage(responseJson)
      } else if(responseJson.error){
        this.setState({ errorMessage: responseJson.error.message, isError: true });
      }else {
        const errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }

   // Customizable Area Start 
   checkCountryCode(selectedCode:string) {
    if (selectedCode !== undefined) {
      this.setState({
        countryCodeSelected:
          selectedCode.indexOf("+") > 0
            ? selectedCode.split("+")[1]
            : selectedCode
      });
    }
   }
   
  showErrorMessage(responseJson:any) {
    this.setState({ errorMessage: responseJson.errors.failed_login, isError: true });
    this.parseApiErrorResponse(responseJson);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  goToForgotPasswords() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "sms");
    this.send(msg);
  }

  goToSocialLogins() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToEmailLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  doMobileLogIn(): boolean {
    if (
      this.state.countryCodeSelected === null ||
      this.state.countryCodeSelected.length === 0
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorCountryCodeNotSelected
      );
      return false;
    }

    if (this.state.mobileNo === null || this.state.mobileNo.length === 0) {
      this.showAlert(configJSON.errorTitle, configJSON.errorMobileNoNotValid);
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.showAlert(configJSON.errorTitle, configJSON.errorPasswordNotValid);
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType
    };

    const attrs = {
      full_phone_number: this.state.countryCodeSelected + this.state.mobileNo,
      password: this.state.password
    };

    const dataDetails = {
      type: "sms_account",
      attributes: attrs
    };

    const httpBody = {
      data: dataDetails
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPhoneLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }


  // Only for web use
  async signupViaContactNumber() {
    if (this.checkPhoneValidation()) {
      this.setState({ isLoading: true });
      removeStorageData("authToken");

      const header = {
        "Content-Type": configJSON.ApiContentType,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.apiPhoneLoginCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.loginAPI
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeAddDetail
      );
      const currentUserType = await getStorageData("userType");

      const dataDetails = {
        data: {
          type: "sms_account",
          attributes: {
            user_type: currentUserType,
            full_phone_number: `+91${this.state.mobileNo}`
          },
        }
      };
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(dataDetails)
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  }

  handleBackButton = async () => {
    this.props.navigation.navigate("UserRole")
  };

  openRegistrationPage = async () => {
   setStorageData("isSignUp", "true");
   setStorageData("phoneNumber", this.state.mobileNo);
    this.props.navigation.navigate("PhoneNumberRegistration")
  };

  checkPhoneValidation = () => {
    const { mobileNo } = this.state;

    if (!mobileNo) {
      this.setState({ errorMessage: "Phone number is required", isError: true });
      return false;
    }
    
    if (!/^[6-9]\d{9}$/.test(mobileNo)) {
      this.setState({
        errorMessage: "Invalid phone number",
        isError: true
      });
      return false;
    }
    this.setState({
      errorMessage: "",
      isError: false
    });
    return true;
  };

  handelChangePhoneNumber = (e: any) => {
    const mobileNo = e.target.value.trimStart();
    if (/^\d{0,10}$/.test(mobileNo)) {
    this.setState({ mobileNo });
    }
  };
  // Only for web use
  // Customizable Area End
}
