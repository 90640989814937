Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.searchApiContentType = "application/json";
exports.getSearchApiEndPoint = "search/users";
exports.errorTitle = "Error";
exports.getCustomizableProfileEndPoint = "bx_block_profile/profile/current_user_profile";
exports.updateCustomizableProfileEndPoint = "bx_block_profile/profile/update_user_profile";
exports.getCustomizableProfileFieldsEndPoint = "bx_block_profile/profile/custom_user_profile_fields";
exports.getProfile = "patient_profile_details";
exports.updateProfile = "update_patient_profile";
exports.uploadProfile = "upload_patient_image";
exports.getDiseasesAPIEndPoint = "diseases";
exports.getHealthcarePersonnelDetails = "healthcare_personnel_profile_details";
exports.getHospitalProfile = "profile_details";
exports.updatePatientProfile = "update_profile";
exports.uploadHospitalProfile = "upload_hospital_image";
exports.fetchDepartments = "departments";
exports.fetchInsurances = "insurances";
exports.verifications = "verifications";
exports.otpVerification = "verifications/verify_otp";
// Customizable Area End
