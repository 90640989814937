import * as React from "react";
// Customizable Area Start
import { Box, Typography, Card, CardContent, Button, withStyles } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { otpSuccess, physicianMedical, homecureTransparent } from "./assets";
import OTPInputAuthController, {
  Props,
} from "./OTPInputAuthController";
import { styles } from "./styles/OTPInputAuth";
// Customizable Area End

export class OTPVerified extends OTPInputAuthController {
  constructor(props: Props) {
    super(props);
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    // Customizable Area End

    return (
      // Customizable Area Start
      <Box
        component="div"
        sx={{
          width: "100%",
          minHeight:"100vh",
          height:"100%"
        }}
        style={{
          backgroundPosition: "top",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(${physicianMedical})`
        }}
      >
        <Box
          data-test-id="otpVerifyContainer"
          className={classes.homeCureContainer}
        >
          <img
            src={homecureTransparent}
            alt="HomeCure Logo"
            className={classes.homecureLogo}
            style={{ objectFit: "contain" }}
          />
        </Box>
        <Box
          data-test-id="backBtn"
          className={classes.backButton}
          onClick={this.handleBackButton}
        >
          <ArrowBackIosIcon style={{ width: "15px", height: "15px" }} />
        </Box>
        <Box>
          <Card
            data-test-id="otpCardContainer"
            className={classes.otpTransparentCard}
          >
            <CardContent
              className={classes.otpCardContent}
              style={{ flexDirection: "column" }}
            >
              <img
                src={otpSuccess}
                alt="Phone Verified"
                className={classes.successLogo}
                style={{ objectFit: "contain" }}
              />
              <Typography
                variant="h6"
                className={classes.successMessage}
                style={{ textAlign: "center" }}
              >
                Phone Verified Successfully
              </Typography>
              <Button
                data-test-id="moveToNextPage"
                variant="contained"
                color="primary"
                className={classes.successBtn}
                onClick={() => this.navigateToNextPage()}
                style={{ textTransform: "capitalize" }}
              >
                Done
              </Button>
            </CardContent>
          </Card>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(styles)(OTPVerified);
// Customizable Area End
