import React from "react";
// Customizable Area Start
import {
  Box,
  Grid,
  createTheme,
  TextField,
  ThemeProvider,
  Typography,
  FormHelperText,
  withStyles,
  Popover,
  TableContainer,
  Table,
  TableBody,
  SvgIcon,
  TableRow,
  TableCell,
  Checkbox,
  Button,
  InputAdornment,
  Dialog,
} from "@material-ui/core";
import Sidebar from "../../../components/src/Sidebar.web";
import Reservation2Controller, { Props } from "./Reservations2Controller";
import { OverflowXProperty, PositionValue, ScrollbarType, textAlignType } from "./types";
import { Calender, searchIcons} from "./assets";
import DatePicker from "react-datepicker";
import CloseIcon from '@material-ui/icons/Close';
import "react-datepicker/dist/react-datepicker.css";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import '../../../web/src/fonts/stylesheet.css';
import { Link } from "react-router-dom";

const theme = createTheme({
  palette: {
    primary: {
      contrastText: "#fff",
      main: "#0000ff",
    }
  }
});
// Customizable Area End
export class Reservations2HospitalBooking extends Reservation2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  async componentDidMount() {
    try {
      await Promise.all([this.getHospitalInfo(), this.getInsuranceList()]);
    } catch (errors) {
      this.showAlert("Error fetching data:", "Something went wrong");
    }
  }

  showFieldError = (error: string | undefined, webclassName: any) => {
    return (error ? webclassName.errorbookingRegisterInputFields : webclassName.bookingRegisterInputFields)
  };


  showSelectHospitalErrorField = (error: string | undefined, classes: any) => {
    return (error ? classes.errorHospitalBookingInputFields : classes.registerHospitalBookingFields)
  };

  renderBookingOverlay = (isOpen: boolean, closeHandel: () => void, overlay: any) => {
    if (isOpen) {
      return (
        <Box className={overlay} style={{ position: "fixed" }} onClick={closeHandel} />
      );
    }
    return null;
  };

  getBedTableCellStyles = (index: number, isBedCell: boolean, type: string, idBedSelected: boolean) => {
    const commonStyle = {
      borderBottom: index === this.state.bedList.length - 1 ? "none" : "1px solid #ddd",
      fontSize: "16px",
      maxHeight: "100px",
      fontFamily: idBedSelected ? "SF Pro Text Medium" : "SF Pro Text Regular",
      caretColor:"transparent"
    };

    if (type === "count" && isBedCell) {
      return {
        ...commonStyle,
        width: "50px !important",
        background: "#e6e5e5",
        color: "#3780E0",
      };
    }

    if (!isBedCell && type === "price") {
      return {
        ...commonStyle,
        color: "#3780E0"
      };
    }

    if (isBedCell && type === "name") {
      return {
        ...commonStyle,
        color: "#292929",
        background: "#eeeeee",
      };
    }

    return {
      ...commonStyle,
      width: type==="checkbox"?"20px !important": "50px !important"
    }
  };

  getCheckboxStyle = (index: number, slectedInsuranceId: boolean) => {
    return {
      padding: "5px 0px",
      borderBottom: index === this.state.insuranceList.length - 1 ? "none" : "1px solid #ddd",
      fontSize: "15px",
      fontWeight: slectedInsuranceId ? 600 : 500,
      width: "14px",
    };
  };

  getInsuranceRowStyle = (index: number, isSelected: boolean) => {
    return {
      borderBottom: index === this.state.insuranceList.length - 1 ? "none" : "1px solid #ddd",
      padding: "5px 0px",
      fontSize: "16px",
      fontFamily: isSelected ? "SF Pro Text Medium" : "SF Pro Text Regular",
      color:"#292929"
    };
  };

  renderSelectedBedTypeName = (selectedBedType:any, classes:any) => {
    if (selectedBedType === "Ward") {
      return (
        <Typography variant="h6" className={classes.summaryTitle}>
        Bed
      </Typography>
      );
    }
    return null;
  };

  renderSelectedBedType = (selectedBedType:any, summaryData:any, classes:any) => {
    if (selectedBedType === "Ward") {
      return (
        <Typography variant="h6" className={classes.summartDetails}>
          {summaryData.attributes.bed_name}
        </Typography>
      );
    }
    return null;
  };

  selectBedClass = (selectedBedType:any,category:any,classes:any) =>{
    return selectedBedType === category ? classes.selectedBedsType : classes.unSelectedBedsType
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <ThemeProvider theme={theme}>
          <Grid container className={classes.hospitalBookingSection}>
            <Grid item xs={1}>
              <Sidebar />
            </Grid>
            <Grid item xs={11} className={classes.hospitalBookingContentSection}>
              <Box className={classes.ProfileSection}>
                <Box style={{ display: "flex" }}>
                  <Link data-test-id="hospitalBackBtn" to={`/ViewHospitalBooking/${this.props.navigation.getParam("id")}`} className={classes.bookingViewNavigationButton} >
                    <Box className={classes.bookingViewEditBackButtons}>
                      <ArrowBackIosIcon data-test-id="hospitalBackBtn" className={classes.backToPrePage} />
                    </Box>
                  </Link>
                  <Typography variant="h6" className={classes.profileHeadingText}>
                    Book Now
                  </Typography>
                </Box>
                <Grid container>
                  <Grid item xl={8} md={8} sm={8} xs={12}>
                    <>
                      <Box className={classes.topLeftSection}>
                        <Box className={classes.profileImageContainer}>
                          <img
                            className={classes.avatarSize}
                            src={this.checkProfile(this.state.hpList)}
                          />
                        </Box>
                        <Box className={classes.profileDetailContainer}>
                          <Typography variant="h6" className={classes.textName}>
                           {this.state.hpList?.attributes?.name}                                         
                          </Typography>
                          <Typography variant="h6" className={classes.textPhoneNumber}>
                            {this.state.hpList?.attributes?.address?.data?.attributes?.city}
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        <Typography variant="h6" className={classes.textLabel}>
                          Select Bed Type<span className={classes.requiredField}>*</span>
                        </Typography>
                      </Box>
                      <Box className={classes.selectBedsclasses} style={{width:this.getSelectBedsWidth()}}>
                        {this.state.hpList?.attributes?.categories.map((category:any, index:any) => (
                          <Box
                            key={index}
                            className={
                              this.selectBedClass(this.state.selectedBedType,category,classes)
                            }
                            onClick={() => this.setBedType(category)}
                            data-test-id={`select${category.replace(/\s+/g, '')}`} 
                          >
                            {category}
                          </Box>
                        ))}
                      </Box>
                      {this.state.selectedBedType === "ICU" &&
                        (<Box>
                          <Typography variant="h6" className={classes.noteForICU}>
                            Only those patients can book ICU beds who are already admitted in ICU in another hospital. Discharge documents need to be submitted during admission.
                          </Typography>
                        </Box>
                        )}
                    </>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xl={4} md={4} sm={4} xs={12}>
                    <Box className={classes.boxContainer}>
                      <Typography variant="h4" className={classes.registerHospitalBookingLabel}>
                        Patient Name<span className={classes.requiredField}>*</span>
                      </Typography>
                      <TextField
                        variant="outlined"
                        data-test-id="patientName"
                        className={this.showFieldError(this.state.errors.patientName, classes)}
                        placeholder="Enter your name"
                        value={this.state.patientName}
                        onChange={(event) => this.handleChange("patientName", event.target.value)}
                      />
                      {this.state.errors.patientName && <FormHelperText className={classes.inputTextErrors}>{this.state.errors.patientName}</FormHelperText>}
                    </Box>
                    <Box className={classes.boxContainer}>
                      {this.state.selectedBedType !== "Ward" ?
                        (
                          <><Typography variant="h6" className={classes.registerHospitalBookingLabel} style={{ textAlign: "left" }}>
                            Select Insurance<span className={classes.requiredField}>*</span>
                          </Typography>
                            <TextField
                              variant="outlined"
                              data-test-id="insuranceType"
                              style={{ caretColor: "white !important" }}
                              className={this.showSelectHospitalErrorField(this.state.errors.insurance, classes)}
                              placeholder="Select the Insurance"
                              value={this.state.insurance}
                              onClick={this.openInsurancePopover}
                            />
                            {this.state.errors.insurance && <FormHelperText className={classes.inputTextErrors}>{this.state.errors.insurance}</FormHelperText>}
                          </>) : (
                          <>
                            <Typography variant="h6" className={classes.registerHospitalBookingLabel} style={{ textAlign: "left" }}>
                              Select Bed<span className={classes.requiredField}>*</span>
                            </Typography>
                            <TextField
                              variant="outlined"
                              data-test-id="bedBooking"
                              style={{ caretColor: "white !important" }}
                              className={this.showSelectHospitalErrorField(this.state.errors.bedType, classes)}
                              placeholder="Select the Bed"
                              value={this.state.bedType}
                              onClick={this.openHospitalBedsPopover}
                            />
                            {this.state.errors.bedType && <FormHelperText className={classes.inputTextErrors}>{this.state.errors.bedType}</FormHelperText>}
                          </>
                        )}

                      {this.renderBookingOverlay(this.state.isBedPopoverOpen, this.closeBedPopover, classes.bookingOverlay)}
                      <Popover
                        className={classes.PaperBookingPropsClass}
                        data-test-id="bedPopover"
                        open={this.state.isBedPopoverOpen}
                        anchorEl={this.state.bedsAnchorEl}
                        anchorOrigin={{
                          vertical: 'center',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'center',
                          horizontal: 'center',
                        }}
                      >
                        <Box className={classes.popoverBookingContainer}>
                          <Box className={classes.selectHospitalContainer}>
                            <Typography variant="h6" className={classes.heading}>
                              Select Beds
                            </Typography>
                            <Typography variant="h6" data-test-id="closeBedsPopover" className={classes.closeBedIconBtn} onClick={this.selectBedsType}>
                              <CloseIcon />
                            </Typography>
                          </Box>
                          <Box className={classes.tbBookingPopoverContaint}>
                            <TableContainer className={classes.tableBookingContainer}>
                              <Table className={classes.tbStyle}>
                                <TableBody className={classes.tbStyle}>
                                {this.state.bedList?.filter((beds: any) => beds.attributes.category === "Ward").map((beds: any, index: number) => (
                                    <TableRow key={beds.id}>
                                      <TableCell className={classes.tbStyle} style={this.getBedTableCellStyles(index, false, "checkbox", this.state.selectedBed.includes(beds.id))}>
                                        <Checkbox
                                          data-test-id="selectBeds"
                                          className={classes.bedCheckbox}
                                          checked={this.state.tempSelectedBed.includes(beds.attributes.name)}
                                          onChange={() => this.handleBedCheckboxChange(beds.id, beds.attributes?.name)}
                                          icon={<SvgIcon width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="8" cy="8" r="7.5" stroke="#292929" />
                                          </SvgIcon>}
                                          checkedIcon={<SvgIcon width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="8" cy="8" r="7.5" stroke="#02A405" />
                                            <circle cx="8" cy="8" r="5" fill="#02A405" />
                                          </SvgIcon>}
                                        />
                                      </TableCell>
                                      <TableCell  className={classes.tbStyle} style={this.getBedTableCellStyles(index, true, "name", this.state.tempSelectedBed.includes(beds.attributes.name))}>
                                        {beds.attributes?.name}
                                      </TableCell>
                                      <TableCell  className={classes.tbStyle} style={this.getBedTableCellStyles(index, true, "count", this.state.tempSelectedBed.includes(beds.attributes.name))}>
                                         {beds.attributes?.count}
                                        </TableCell>
                                        <TableCell  className={classes.tbStyle} style={this.getBedTableCellStyles(index, false, "price", this.state.tempSelectedBed.includes(beds.attributes.name))}>
                                          ₹{beds.attributes?.price}
                                        </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Box>
                          <Box className={classes.selecthospitalBtnContainer}>
                            <Button
                              data-test-id="saveBeds"
                              variant="contained"
                              color="primary"
                              className={classes.selectBedButtons}
                              style={{ textTransform: "capitalize" }}
                              onClick={this.bedBooking}
                            >
                              Select
                            </Button>
                          </Box>
                        </Box>
                      </Popover>
                    </Box>
                  </Grid>
                  <Grid item xl={4} md={4} sm={4} xs={12}>
                    <Box className={classes.boxContainer}>
                      <Typography variant="h6" className={classes.registerDateLabel}>
                        Set Date<span className={classes.requiredField}>*</span>
                      </Typography>
                      <Box className={classes.customDatePickerName} style={{marginBottom:(this.state.errors?.date) ? "13px":"20px"}}>
                        <span className={this.state.errors?.date?classes.customDateInputNameError:classes.customDateInputName}>
                          <DatePicker
                            data-test-id="bookingdate"
                            popperPlacement="bottom-start"
                            popperModifiers={[
                              {
                                name: "offset",
                                options: {
                                  offset: [60, 0],
                                },
                              },
                              {
                                name: "preventOverflow",
                                options: {
                                  rootBoundary: "viewport",
                                  tether: false,
                                  altAxis: true,
                                },
                              },
                            ]}
                            wrapperClassName={classes.datepickerClassFullWidth}
                            calendarClassName={classes.datepickerClass}
                            onChange={this.handleBookingDateChange}
                            className={(this.state.errors?.date) ? classes.dateFieldError : classes.dateField}
                            dateFormat="Select date"
                            value={this.state.date}
                            placeholderText="Select date"
                            minDate={tomorrow}
                          />
                        </span>
                      </Box>
                      {this.state.errors.date && <FormHelperText className={classes.inputTextErrors}>{this.state.errors.date}</FormHelperText>}
                    </Box>
                    <Box className={classes.boxContainer}>
                      {this.state.selectedBedType === "Ward" &&
                        (
                          <>
                          <Typography variant="h6" style={{ textAlign: "left" }} className={classes.registerHospitalBookingLabel} >
                            Select Insurance<span className={classes.requiredField}>*</span></Typography>
                            <TextField
                              variant="outlined"
                              data-test-id="insuranceType"
                              className={this.showSelectHospitalErrorField(this.state.errors.insurance, classes)}
                              style={{ caretColor: "white !important" }}
                              placeholder="Select the Insurance"
                              value={this.state.insurance}
                              onClick={this.openInsurancePopover}
                            />
                            {this.state.errors.insurance && 
                            <FormHelperText className={classes.inputTextErrors}>{this.state.errors.insurance}
                            </FormHelperText>}
                          </>)}
                      {this.renderBookingOverlay(this.state.isInsuranceOpen, this.closeInsurancePopover, classes.bookingOverlay)}
                      <Popover
                        className={classes.PaperBookingPropsClass}
                        data-test-id="inurancePopover"
                        open={this.state.isInsuranceOpen}
                        anchorEl={this.state.insurancesTypeAnchorEl}
                        anchorOrigin={{
                          vertical: 'center',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'center',
                          horizontal: 'center',
                        }}
                      >
                        <Box className={classes.popoverBookingContainer}>
                          <Box className={classes.selectHospitalContainer}>
                            <Typography variant="h6" className={classes.inuranceHeading}>
                              Select Insurance
                            </Typography>
                            <Typography variant="h6" data-test-id="closeInsurancesPopover" className={classes.closeInsuranceIconBtn} onClick={this.selectInsuranceType}>
                              <CloseIcon />
                            </Typography>
                          </Box>
                          <Box className={classes.selectInsuranceContainer}>
                            <TextField
                              data-test-id="searchInsuranceData"
                              variant="outlined"
                              placeholder="Search insurance here.."
                              className={classes.searchField}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <img
                                      src={searchIcons}
                                      alt="search"
                                      style={{ width: "25px", height: "25px", display: "flex", objectFit: "cover", justifyContent: "center", margin: "0px auto" }}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                              onChange={(event) => this.handleInsurancesSearch(event.target.value)}
                            />
                          </Box>
                          <Box className={classes.tbsInsurancePopoverContainer}>
                            <TableContainer className={classes.tablecontainer}>
                              <Table style={{width:"96%"}}>
                                <TableBody>
                                  {this.state.hpList?.attributes?.insurances?.filter((insurance: any) => insurance.name.toLowerCase().includes(this.state.insuranceSearch.toLowerCase()))
                                    .map((insurance: any, index: number) => (
                                      <TableRow key={index}>
                                        <TableCell style={this.getCheckboxStyle(index, false)}>
                                          <Checkbox
                                            data-test-id={`selectInsurance${index}`}
                                            className={classes.checkbox}
                                            checked={this.state.selectedInsurances.includes(insurance.id)}
                                            onChange={() => this.handleInsuranceCheckboxChange(insurance.id,insurance?.name)}
                                            icon={<SvgIcon width="16" height="16" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" fill="none">
                                              <rect x="0.5" y="0.5" rx="1.5" stroke="#014866" width="15" height="15" />
                                            </SvgIcon>}
                                            checkedIcon={<SvgIcon xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 18 18" fill="none">
                                              <rect x="0.5" y="0.5" rx="1.5" width="15" height="15" stroke="#014866" />
                                              <path d="M3.75 7.99992L6.58 10.8299L12.25 5.16992" stroke="#014866" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            </SvgIcon>}
                                          />
                                        </TableCell>
                                        <TableCell style={this.getInsuranceRowStyle(index, this.state.selectedInsurances.includes(insurance.id))}>{insurance?.name}</TableCell>
                                      </TableRow>
                                    ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Box>
                          <Box className={classes.selecthospitalBtnContainer}>
                            <Button
                              data-test-id="saveInsurances"
                              variant="contained"
                              className={classes.selectBedButtons}
                              color="primary"
                              style={{ textTransform: "capitalize" }}
                              onClick={this.selectedBokingInsurance}
                            >
                              Select
                            </Button>
                          </Box>
                        </Box>
                      </Popover>
                    </Box>
                  </Grid>
                </Grid>
                <Button data-test-id="bookBeds" variant="contained" color="primary" className={classes.bookButtons} style={{ textTransform: "capitalize" }} onClick={this.openSummaryPopup}>
                  Book Bed
                </Button>
                {this.state.showSummaryDetails && (
                  <Box
                    className={classes.PaperSummaryPropsClass}
                    data-test-id="showSummary"
                  >
                    <Box className={classes.popoverSummaryContainer}>
                      <Box className={classes.selectSummaryHospitalContainer}>
                        <Typography variant="h6" className={classes.titleHeading}>
                          Summary
                        </Typography>
                        <Typography variant="h6" data-test-id="closeSummary" className={classes.closeInsuranceIconBtn} onClick={this.closeSummaryPopup}>
                          <CloseIcon />
                        </Typography>
                      </Box>
                      <Box style={{ maxHeight:'calc(65vh - 100px)',  overflowY: 'scroll', scrollbarColor: "white white", scrollbarWidth: "thin",marginRight:"-9px" }}>
                      <Box className={classes.topProfileContainer} data-test-id="summaryContainer">
                        <Box className={classes.profileImageContainer}>
                          <img
                            data-test-id="avatarSize"
                            className={classes.summaryProfile}
                            src={this.checkProfile(this.state.hpList)}
                          />
                        </Box>
                        <Box className={classes.profileHpDetailContainer} data-test-id="summaryDetails">
                          <Typography variant="h6" className={classes.userProfileName}>
                            {this.state.summaryData.attributes.hospital_name}
                          </Typography>
                          <Typography variant="h6" className={classes.headingAddress}>
                            {this.state.summaryData.attributes.address}
                          </Typography>
                        </Box>
                      </Box>
                      <Box className={classes.bottomBorder} />
                        <Box style={{display:"flex", justifyContent:"space-between"}}>
                        <Box className={classes.summaryDetailsConatiner}>
                          <Typography variant="h6" className={classes.summaryTitle}>
                            Service
                          </Typography>
                          <Typography variant="h6" className={classes.summaryTitle}>
                            Patient Name
                          </Typography>
                          <Typography variant="h6" className={classes.summaryTitle}>
                            Date
                          </Typography>
                          <Typography variant="h6" className={classes.summaryTitle}>
                            Bed Type
                          </Typography>
                          {this.renderSelectedBedTypeName(this.state.selectedBedType,classes)}
                          <Typography variant="h6" className={classes.summaryTitle}>
                            Insurance
                          </Typography>
                          <Typography variant="h6" className={classes.summaryTitle}>
                            Price
                          </Typography>
                        </Box>
                        <Box className={classes.summaryDetailsConatiner}>
                          <Typography variant="h6" className={classes.summartDetails}>
                            Hospital
                          </Typography>
                          <Typography variant="h6" className={classes.summartDetails}>
                            {this.state.summaryData.attributes.patient_name}
                          </Typography>
                          <Typography variant="h6" className={classes.summartDetails}>
                           {this.formatDateToDDMonthYYYY(new Date(this.state.summaryData.attributes.date))}
                          </Typography>
                          <Typography variant="h6" className={classes.summartDetails}>
                            {this.state.summaryData.attributes.bed_type}
                          </Typography>
                          {this.renderSelectedBedType(this.state.selectedBedType,this.state.summaryData,classes)}
                          <Typography variant="h6" className={classes.summartDetails}>
                            <span className={classes.summaryInsuranceDetails}>{this.state.summaryData.attributes.insurances[0].name}</span><span>{this.state.summaryData.attributes.insurances.length>0&&(<>( +{this.state.summaryData.attributes.insurances.length} )</>)}</span>
                          </Typography>
                          <Typography variant="h6" className={classes.summartDetails}>
                            Rs. {this.state.summaryData.attributes.price}
                          </Typography>
                        </Box>
                        </Box>
                      </Box>
                      <Box>
                        <Button data-test-id="changeDetails" variant="contained" color="primary" className={classes.changeDetailsButtons} style={{ textTransform: "capitalize" }} onClick={this.changeSummaryDetails}>
                          Change Details
                        </Button>
                        <Button data-test-id="makePaymentBtn" onClick={() => this.handleMakePayment()} variant="contained" color="primary" className={classes.paymentButtons} style={{ textTransform: "capitalize" }}>
                          Select Payment
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
              <Dialog open={!!this.state.bookingErrorMessage} data-test-id="popup">
                <Box className={classes.customContainerBooking}>
                  <Box style={{ padding: "0px 10px" }}>
                    <p className={classes.headingBooking}>Error</p>
                    <p className={classes.paragraphBooking}>{this.state.bookingErrorMessage}</p>
                    <Button style={{
                      backgroundColor: "rgb(33, 150, 243)",
                      borderRadius: "3px",
                      marginBottom: "15px",
                      border: "none",
                      color: "white",
                      padding: "5px",
                      marginTop: "10px"
                    }}
                    onClick={()=>this.closeErrorPopup()}>OK</Button>
                  </Box>
                </Box>
              </Dialog>
            </Grid>
          </Grid>
        </ThemeProvider>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const style = {
  hospitalBookingSection: {
    height: "100vh",
    overflowX: "hidden" as OverflowXProperty,
  },
  hospitalBookingContentSection: {
    height: "auto",
  },
  ProfileSection: {
    padding: "4%"
  },
  profileHeadingText: {
    width: "100%",
    fontSize: "28px",
    marginBottom: "45px",
    fontFamily: "SF Pro Text Bold",
    caretColor: "transparent",
    color:"#2929292"
  },
  topLeftSection: {
    width:"573px",
    display: "flex",
    fontFamily: "SF Pro Text Bold",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 0px 35px 0px",
  },
  profileImageContainer: {
    width: "20%",
    [theme.breakpoints.down("md")]: {
      width: "40%"
    },
    [theme.breakpoints.down("sm")]: {
      width: "50%"
    }
  },
  avatarSize: {
    borderRadius: "50%",
    width: "138px",
    height: "138px",
    border: "1px solid #eeeaea",
    backgroundColor: "#ebebeb",
  },
  profileDetailContainer:{
    width: "75%",
    paddingLeft: "65px",
    '@media (max-width: 1279px)': {
      width:"100%",
      paddingLeft: "40px",
    },
  },
  profileHpDetailContainer:{
    width: "75%",
    marginTop: "20px",
    paddingLeft: "57px",
  },
  textName: {
    fontSize: "24px",
    color: "#292929",
    fontFamily: "SF Pro Text Bold",
    caretColor: "transparent",
    textOverflow: "ellipsis",
    overflow: "hidden",
    maxHeight: "45px",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    display: "-webkit-box",
    lineHeight:'26px'
  } as any,
  textPhoneNumber: {
    fontSize: "16px",
    fontFamily: "SF Pro Text Regular",
    caretColor: "transparent",
    color: "#808080",
    textOverflow: "ellipsis",
    overflow: "hidden",
    height: "25px",
    width: "200px",
    maxHeight: "25px",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    display: "-webkit-box",
  } as any,
  headingAddress: {
    fontSize: "16px",
    fontFamily: "SF Pro Text Regular",
    caretColor: "transparent",
    color: "#808080",
    textOverflow: "ellipsis",
    overflow: "hidden",
    height: "25px",
    width: "75%",
    maxHeight: "25px",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    display: "-webkit-box",
  } as any,
  textLabel: {
    fontSize: "18px",
    margin: "10px 0px",
    textAlign: "left" as textAlignType,
    fontFamily: "SF Pro Text Medium",
    caretColor: "transparent",
    color:"#292929"
  },
  selectedBedsType: {
    background: "#3780E0",
    borderRadius: "21px",
    alignItem: "center",
    color: "white",
    height: "28px",
    fontSize: "18px",
    fontFamily: "SF Pro Text Semibold",
    padding: "12px 35px",
    caretColor: "transparent",
    cursor: "pointer",
    display: "flex",
    alignItems: "center"
  },
  unSelectedBedsType: {
    color: "#808080",
    fontSize: "18px",
    fontFamily: "SF Pro Text Regular",
    height: "28px",
    display: "flex",
    alignItems: "center",
    padding: "12px 30px",
    caretColor: "transparent",
    cursor: "pointer"
  },
  bookingRegisterInputFields: {
    width: "100%",
    fontSize: "18px",
    marginBottom: "10px",
    '&::placeholder': {
      fontSize: "18px",
      color: '#808080 !important',
      opacity: 1,
    },
    '&::-webkit-input-placeholder': {
      color: '#808080 !important',
      opacity: 1,
    },
    "& .MuiInputBase-input": {
      color:"#292929",
      fontSize: "18px",
      fontFamily: "SF Pro Text Regular",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      cursor: "pointer",
      fontSize: "18px",
      fontFamily: "SF Pro Text Regular",
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "#3f51b5 !important",
    },
    fontFamily: "SF Pro Text Regular",
    "& ::-webkit-input-placeholder":{
      color:"#808080",
    },
    "& input": {
      color:"#292929",
      fontSize: "14px",
      fontFamily:"SF Pro Text Regular",
      "&::placeholder": {
        opacity: 1,
        fontSize: "18px",
        fontFamily:"SF Pro Text Regular",
        color:"#808080"
      } 
    }
  },
  errorbookingRegisterInputFields: {
    width: "100%",
    fontSize: "18px",
    marginBottom: "10px",
    borderRadius: "8px",
    '&::placeholder': {
      fontSize: "18px",
      color: '#808080 !important',
      opacity: 1,
    },
    '&::-webkit-input-placeholder': {
      color: '#808080 !important',
      opacity: 1,
    },
    "& .MuiInputBase-input": {
      color:"#292929",
      fontSize: "18px",
      fontFamily: "SF Pro Text Regular",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      fontSize: "18px",
      fontFamily: "SF Pro Text Regular",
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "#3f51b5 !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#f44336",
      borderWidth: "1px",
    },
    fontFamily: "SF Pro Text Regular",
    "& input": {
      color:"#292929",
      fontSize: "14px",
      fontFamily:"SF Pro Text Regular",
      "&::placeholder": {
        opacity: 1,
        fontSize: "18px",
        fontFamily:"SF Pro Text Regular",
        color:"#808080"
      } 
    },
  },
  requiredField: {
    color: "#f44336"
  },
  registerHospitalBookingLabel: {
    fontSize: "18px",
    fontFamily: "SF Pro Text Regular",
    margin: "10px 0px",
    textAlign: "left" as textAlignType,
    caretColor: "transparent",
    color:"#292929"
  },
  registerDateLabel: {
    fontSize: "18px",
    fontFamily: "SF Pro Text Regular",
    margin: "4px 0px 10px",
    textAlign: "left" as textAlignType,
    caretColor: "transparent",
    color:"#292929"
  },
  inputTextErrors: {
    display: "flex",
    justifyContent: "end",
    color: "#f44336",
    fontSize: "14px"
  },
  selectBedsclasses: {
    display: "flex",
    justifyContent: "space-between",
    height:"51px",
    marginTop: "10px",
    border: "1px solid rgb(192 192 192)",
    borderRadius: "22px",
    alignItem: "center",
    marginBottom: "35px"
  },
  customDatePickerName: {
    display: "flex",
    alignItems: "center",
  },
  customDateInputName: {
    width: "100%",
    borderRadius: "8px",
    "& input": {
      color:"#292929",
      fontSize: "18px",
      background: `url(${Calender}) left / contain no-repeat`,
      backgroundPosition: "12px",
      backgroundSize: "25px",
      width: "100%",
      height: "58px",
      border: "1px solid rgba(0, 0, 0, 0.23)",
      display: "flex",
      margin: "auto",
      borderRadius: "8px",
      fontFamily:"SF Pro Text Regular",
      caretColor: "transparent"
    },
    "& ::-webkit-input-placeholder":{
      color:"#565454",
    },
    "&:focus": {
      border: "1px solid blue",
    },
  },
  customDateInputNameError: {
    width: "100%",
    "& input": {
      color:"#292929",
      borderRadius: "8px",
      background: `url(${Calender}) left / contain no-repeat`,
      backgroundPosition: "12px",
      backgroundSize: "25px",
      width: "100%",
      height: "58px",
      border: "1px solid red",
      fontSize: "18px",
      display: "flex",
      margin: "auto",
      caretColor: "transparent",
      fontFamily:"SF Pro Text Regular"
    },
    '&::placeholder': {
      color: '#808080 !important',
      opacity: 1,
    },
    '&::-webkit-input-placeholder': {
      color: '#808080 !important',
      opacity: 1,
    },
    "&:focus": {
      border: "1px solid blue",
    },
    borderRadius: "8px",
  },
  customDateFieldName: {
    display: "flex",
    padding: "15px 60px",
    justifyContent: "center",
    borderRadius: "8px",
  },
  datepickerClass: {
    "& .react-datetime-picker__wrapper": {
      border: "none"
    },
    "& .react-datepickerClass-wrapper": {
      display: "flex !important",
    },
    "& .react-datepickerClass__month": {
      margin: "0px !important"
    },
    "& .react-datepickerClass-popper": {
      transform: "translate(90px, 209px) !important"
    },
    "& .react-datepickerClass__day": {
      width: "1.3rem !important",
      height: "1rem !important"
    },
    "& .react-datepickerClass__day-name": {
      width: "1.3rem !important",
      height: "1rem !important"
    },
    "& .react-datepickerClass__month-container": {
      margin: "0px 0px 5px !important"
    },
    "& .react-datepickerClass__day-names": {
      marginBottom: "-1px !important",
      caretColor: "transparent"
    },
    "& .react-datepickerClass__navigation": {
      height: "22px !important",
      width: "22px !important",
      margin: "5px -2px !important",
      caretColor: "transparent"
    },
    "& .react-datepickerClass__day--keyboard-selected": {
      color: "#000 !important",
      backgroundColor: "white !important",
      caretColor: "transparent"
    },
    "& .react-datepickerClass__day--today": {
      color: "#1d5d90 !important",
      backgroundColor: "white !important",
      fontWeight: "bold !important",
      caretColor: "transparent"
    },
    "& .react-datepickerClass__day:hover": {
      color: "#ccc !important",
      backgroundColor: "white !important",
      caretColor: "transparent"
    },
  },
  dateFieldError: {
    "& .react-datepicker-ignore-onclickoutside":{
      border:"none !important",
      borderColor:"transparent !important"
    },
    display: "flex",
    padding: "15px 60px",
    border: "1px solid red",
    justifyContent: "center",
    borderRadius: "8px",
    cursor: "pointer",
    "&:focus-visible": {
      outline:"none",
      border: "2px solid #3a52d4",
    },
    "&:focus": {
      outline:"none",
      border: "2px solid #3a52d4",
    },
    "&:hover": {
      outline:"none",
      border: "1px solid #3f51b5",
    },
    color:"#292929",
    "& input": {
      color:"#292929"
    }
  },
  dateField: {
    "& .react-datepicker-ignore-onclickoutside":{
      border:"none !important",
      borderColor:"transparent !important"
    },
    display: "flex",
    padding: "15px 60px",
    border: "1px solid #3f51b5",
    justifyContent: "center",
    borderRadius: "8px",
    cursor: "pointer",
    "&:focus-visible": {
      outline:"none",
      border: "2px solid #3a52d4",
    },
    "&:focus": {
      outline:"none",
      border: "2px solid #3a52d4",
    },
    "&:hover": {
      outline:"none",
      border: "1px solid #3f51b5",
    },
    color:"#292929",
    "& input": {
      color:"#292929"
    }
  },
  boxContainer: {
    width: "94%"
  },
  registerHospitalBookingFields: {
    width: "100%",
    marginBottom: "10px",
    "& ::-webkit-input-placeholder":{
      color:"#808080",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      cursor: "pointer",
      fontSize: "18px",
      fontFamily: "SF Pro Text Regular",
      caretColor: "white !important"
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "#3f51b5 !important",
    },
    fontSize: "18px",
    fontFamily: "SF Pro Text Regular",
    '&::placeholder': {
      color: '#808080 !important',
      opacity: 1,
    },
    '&::-webkit-input-placeholder': {
      fontSize: "18px",
      color: '#808080 !important',
      opacity: 1,
    },
    "& .MuiInputBase-input": {
      cursor: "pointer",
      caretColor: "white",
      color:"#292929",
      fontSize: "18px",
      fontFamily: "SF Pro Text Regular",
    },
    "& input": {
      color:"#292929",
      fontSize: "16px",
      fontFamily:"SF Pro Text Regular",
      "&::placeholder": {
        opacity: 1,
        fontSize: "18px",
        fontFamily:"SF Pro Text Regular",
        color:"#808080"
      } 
    },
  },
  errorHospitalBookingInputFields: {
    width: "100%",
    fontSize: "18px",
    "& ::-webkit-input-placeholder":{
      color:"#808080",
    },
    fontFamily: "SF Pro Text Regular",
    marginBottom: "10px",
    borderRadius: "8px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      cursor: "pointer",
      caretColor: "white !important",
      fontSize: "18px",
      fontFamily: "SF Pro Text Regular",
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "#3f51b5 !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#f44336",
      fontSize: "18px",
      fontFamily: "SF Pro Text Regular",
      borderWidth: "1px",
    },
    '&::placeholder': {
      color: '#808080 !important',
      opacity: 1,
    },
    '&::-webkit-input-placeholder': {
      color: '#808080 !important',
      opacity: 1,
    },
    "& .MuiInputBase-input": {
      cursor: "pointer",
      caretColor: "white",
      color:"#292929",
      fontSize: "18px",
      fontFamily: "SF Pro Text Regular",
    },
    "& input": {
      color:"#292929",
      fontSize: "16px",
      fontFamily:"SF Pro Text Regular",
      "&::placeholder": {
        opacity: 1,
        fontSize: "16px",
        fontFamily:"SF Pro Text Regular",
        color:"#808080"
      } 
    },
  },
  bookingOverlay: {
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 9999,
  },
  PaperBookingPropsClass: {
    '& > div:nth-child(1)': {
      backgroundColor: '#050505a3 !important',
      left: "8.3%!important"
    },
    "& .MuiPopover-paper": {
      borderRadius: '30px',
      overflow: "unset",
      '@media (max-width: 2100px)': {
        left: '35% !important',
      },
      '@media (max-width: 1920px)': {
        left: '39% !important',
      },
      '@media (max-width: 1500px)': {
        left: '39% !important',
      },
      '@media (max-width: 1366px)': {
        left: '37% !important',
      },
      '@media (max-width: 1024px)': {
        left: '35% !important',
      },
      '@media (max-height: 1500px)': {
        top: '32% !important',
      },
      '@media (max-height: 1200px)': {
        top: '30% !important',
      },
      '@media (max-height: 1000px)': {
        top: '20% !important',
      },
      '@media (max-height: 900px)': {
        top: '15% !important',
      },
      '@media (max-height: 768px)': {
        top: '10% !important',
      },
    }
  },
  popoverBookingContainer: {
    padding: 20,
    width: "360px",
    maxHeight: "83vh",

    [theme.breakpoints.down("sm")]: {
      padding: "5px",
    },
  },
  selectHospitalContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "360px"
  },
  heading: {
    display: "flex",
    fontFamily: "SF Pro Text Bold",
    caretColor: "transparent",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "18px",
    marginLeft: "35%",
    marginTop: "18px",
    color:"#292929",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "32%"
    }
  },
  inuranceHeading: {
    display: "flex",
    fontFamily: "SF Pro Text Bold",
    caretColor: "transparent",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "18px",
    marginLeft: "30%",
    marginTop: "18px",
    color:"#292929",
  },
  closIconBtn: {
    marginTop:"18px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginRight: "-5px",
    fontSize: "22px",
    cursor: "pointer",
    caretColor: "transparent"
  },
  closeInsuranceIconBtn: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginRight: "14px",
    marginTop: "18px",
    fontSize: "22px",
    cursor: "pointer",
    caretColor: "transparent"
  },
  closeBedIconBtn: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginRight: "4px",
    marginTop: "18px",
    fontSize: "22px",
    cursor: "pointer",
    caretColor: "transparent"
  },
  tbBookingPopoverContaint: {
    maxHeight: '346px',
    margin: "10px 0px",
    scrollbarColor: "transparent transparent",
    overflowY: "scroll" as OverflowXProperty,
    scrollbarWidth: "thin" as ScrollbarType,
    '&::-webkit-scrollbar': {
      backgroundColor: 'white',
      color: "white"
    },
    '&::-webkit-scrollbar-button': {
      backgroundColor: 'white',
      display: "none",
      color: "white"
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: 'white',
      color: "white"
    },
    '::-webkit-scrollbar-track': {
      backgroundColor: 'white',
      color: "white"
    },
    '@media (max-height: 700px)': {
      maxHeight: '330px',
    },
    '@media (max-height: 660px)': {
      maxHeight: '300px',
    },
    '@media (max-height: 659px)': {
      maxHeight: '250px',
    },
  },
  tbsInsurancePopoverContainer: {
    maxHeight: '360px',
    margin: "10px 0px",
    scrollbarColor:" #d9d5d5 #fff",
    overflowY: "scroll" as OverflowXProperty,
    scrollbarWidth: "thin" as ScrollbarType,
    '&::-webkit-scrollbar-button': {
      backgroundColor: 'white',
      display: "none",
      color: "white"
    },
    '@media (max-height: 700px)': {
      maxHeight: '330px',
    },
    '@media (max-height: 660px)': {
      maxHeight: '300px',
    },
    '@media (max-height: 659px)': {
      maxHeight: '250px',
    },
  },
  tableBookingContainer: {
    width: "100%",
    margin: "5% auto"
  },
  checkbox: {
    color: "#fff !important",
    padding: "9px 20px 9px 0px"
  },
  bedCheckbox: {
    color: "#fff !important",
    padding: "9px 0px 9px 0px"
  },
  selecthospitalBtnContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "360px"
  },
  selectBedButtons: {
    width: "253px",
    height:"56px",
    backgroundColor: "#014866",
    color: "#fff",
    border: "none",
    borderRadius: "10px",
    padding: "16px",
    cursor: "pointer",
    fontWeight: 600,
    fontSize: "16px",
    margin: "10px auto",
    caretColor:"transparent",
    "&:hover": {
      backgroundColor: "#014866",
      color: "#fff",
    },
  },
  bookButtons: {
    width: "20%",
    backgroundColor: "#014866",
    color: "#F2F6F7",
    border: "none",
    borderRadius: "10px",
    padding: "16px",
    cursor: "pointer",
    fontSize: "18px",
    margin: "60px auto 10px",
    fontFamily:"SF Pro Text Bold",
    "&:hover": {
      backgroundColor: "#014866",
      color: "#fff",
    },
    caretColor:"transparent"
  },
  searchField: {
    width: "100%",// Removes spaces from category name for test-id
    fontFamily:"SF Pro Text Regular",
    margin: "15px auto",
    fontSize:"16px",
    [theme.breakpoints.down("sm")]: {
      width: "90%"
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
    }
  },
  selectInsuranceContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "10px"
  },
  PaperSummaryPropsClass: {
    backgroundColor: '#050505a3',
    position: "fixed" as PositionValue,
    top: 0,
    left: 0,
    marginLeft: "8.30%",
    width: "100%",
    height: "100%",
    zIndex: 999,
    overflowY: "auto" as OverflowXProperty
  },
  popoverSummaryContainer: {
    position: 'relative' as PositionValue,
    top: '65%',
    left: '46%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#fff',
    borderRadius: '18px',
    boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.1)',
    zIndex: 9999,
    width: '430px',
    padding: '20px 30px',
    maxHeight: "calc(100vh - 100px)",
    '@media (max-height: 1000px)': {
      top: '50%',
    },

  },
  titleHeading: {
    display: "flex",
    fontSize: "28px",
    marginTop: "15px",
    alignItems: "start",
    fontFamily: "SF Pro Text Bold",
    caretColor: "transparent",
    justifyContent: "space-between",
  },
  selectSummaryHospitalContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "433px"
  },
  summaryProfile: {
    borderRadius: "50%",
    width: "138px",
    height: "138px",
    border: "1px solid #eeeaea",
    backgroundColor: "#eeeaea",
  },
  topProfileContainer: {
    display: "flex",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 0px 30px 0px",
    marginTop: "25px",
    gap: "20px"
  },
  userProfileName: {
    fontSize: "24px",
    color: "#292929",
    fontFamily: "SF Pro Text Bold",
    caretColor: "transparent",
    lineHeight:"28px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "-webkit-box",
    width: "85%",
    maxHeight: "55px",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
  } as any,
  summaryTitle: {
    fontSize: "18px",
    fontFamily: "SF Pro Text Medium",
    caretColor: "transparent",
    marginTop: "20px",
    color: "#292929",
  },
  summartDetails: {
    fontSize: "18px",
    fontFamily: "SF Pro Text Regular",
    caretColor: "transparent",
    color: "#808080",
    marginTop: "20px"
  },
  bottomBorder: {
    width: "85%",
    borderBottom: "2px solid rgb(236, 232, 232)",
  },
  paymentButtons: {
    width: "300",
    height:"56px",
    backgroundColor: "#014866",
    color: "#F2F6F7",
    border: "none",
    borderRadius: "9px",
    padding: "16px",
    cursor: "pointer",
    fontSize: "18px",
    fontFamily: "SF Pro Text Bold",
    display: "flex",
    margin: "25px auto 25px",
    "&:hover": {
      backgroundColor: "#014866",
      color: "#fff",
    },
  },
  changeDetailsButtons: {
    width: "300",
    height:"56px",
    fontSize: "18px",
    fontFamily: "SF Pro Text Bold",
    backgroundColor: "#fff",
    color: "#014866",
    border: "1px solid #014866",
    borderRadius: "9px",
    padding: "16px",
    cursor: "pointer",
    margin: "30px auto 0px",
    display: "flex",
    "&:hover": {
      backgroundColor: "#fff",
      color: "#f014866",
    },
  },
  summaryDetailsConatiner: {
    width: "50%",
    textAlign: "left" as textAlignType
  },
  datepickerClassFullWidth: {
    width: "100%",
    color:"#292929",
    border:"none"
  },
  bookingViewEditBackButtons: {
    display: "flex",
    padding: "10px 10px 10px 13px",
    justifyContent: "center",
    alignItems: "center",
    width: "24px",
    height: "28px",
    backgroundColor: "rgba(255, 255, 255, 0.3)",
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
    borderRadius: "5px",
    caretColor:"transparent",
  },
  bookingViewNavigationButton: {
    width: "50px",
    height: "50px",
    marginRight:"20px",
    cursor:'auto',
  },
  backToPrePage: {
    caretColor: "transparent",
    height: "17px",
    width: "17px",
    color:"#292929 !important"
  },
  hospitalTablePlaceholder:{
    '&::placeholder': {
      color: 'rgb(175, 206, 246)',
      opacity: 1,
    },
  '&::-webkit-input-placeholder': {
    color: 'rgb(175, 206, 246)',
    opacity: 1,
  },
},
tbStyle: {
  "& .MuiTableCell-root": {
    padding: "2px 12px !important"
  }
},
customContainerBooking: {
  display: "block",
  maxWidth: "550px",
  width: "500px",
  height: "auto",
  textAlign: "left" as textAlignType,
  borderRadius: "5px",
},
headingBooking: {
  width: "100%",
  color: "black",
  fontFamily: "SF Pro Text Bold",
  fontSize: "28px",
  fontWeight: 700,
  height: "8px",
  marginTop: "15px",
  caretColor:"white"
},
paragraphBooking: {
  width: "100%",
  color: "black",
  fontFamily: "SF Pro Text Regular",
  fontSize: "16px",
  height: "8px",
  margin: "30px 0px",
  caretColor:"white"
},
summaryInsuranceDetails: {
  maxWidth: '150px', 
  whiteSpace: 'nowrap' as any, 
  overflow: 'hidden' as any, 
  textOverflow: 'ellipsis' as any,  
  display: 'inline-block' as any,  
  verticalAlign: 'bottom' as any,
},
noteForICU: {
  fontSize: "18px",
  margin: "10px 0px",
  textAlign: "left" as textAlignType,
  fontFamily: "SF Pro Text Medium",
  caretColor: "transparent",
  color:"#f44336"
},
};

export default withStyles(style)(Reservations2HospitalBooking);
// Customizable Area End

