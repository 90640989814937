import * as React from "react";
// Customizable Area Start
import { Box, Typography, Card, CardContent, TextField, Button, FormHelperText, withStyles } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { homecureTransparent, physicianMedical } from "./assets";
import { styles } from "./styles/PhoneNumberRegistration";
import EmailAccountRegistrationController, { Props } from "./EmailAccountRegistrationController";
// Customizable Area End

export class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const inputFields = this.state.isError ? classes.inputErrorFields : classes.inputFields;
    // Customizable Area End

    return (
      // Customizable Area Start
      <Box
        component="div"
        sx={{
          width: "100%",
          minHeight:"100vh",
          height:"100%"
        }}
        style={{
          backgroundImage: `url(${physicianMedical})`,
          backgroundPosition: "top",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Box className={classes.homeCureContainers}>
          <img
            src={homecureTransparent}
            alt='HomeCure Logo'
            className={classes.homecureLogos}
            style={{ objectFit: 'contain' }}
          />
        </Box>
        <Box className={classes.backButtons} onClick={this.handleBackButton}>
          <ArrowBackIosIcon data-test-id="backBtn" className={classes.arrowIcon} onClick={this.handleBackButton} />
        </Box>
        <Box>
          <Card className={classes.transparentCards}>
            <CardContent className={classes.cardContents} style={{ flexDirection: "column" }}>
              <Typography variant="h6" className={classes.titleWelcomes} style={{ textAlign: "center" }}>
                  Register
                </Typography>
              <Typography variant="h6" gutterBottom className={classes.subtitles} style={{ textAlign: "center" }}>
                Enter your Email ID for registration
              </Typography>
              <Typography variant="h6" className={classes.inputLabels} style={{ textAlign: "left" }}>
                Email ID<span style={{ color: "#f44336" }}>*</span>
              </Typography>
              <TextField
                data-test-id="emailRegistration"
                variant="outlined"
                InputProps={{
                  classes: {
                    notchedOutline: this.state.errorMessage ? classes.errorOutlines : classes.notchedOutlines
                  }
                }}
                type="email"
                className={inputFields}
                placeholder="Enter your mail id "
                value={this.state.email}
                onChange={this.emailHandelChange}
              />
              {this.state.isError && (
                <FormHelperText className={classes.inputErrors}>
                  {this.state.errorMessage}
                </FormHelperText>
              )}
              <Button
                variant="contained"
                color="primary"
                data-test-id="continueRegistration"
                className={classes.loginButtons}
                style={{ textTransform: "capitalize" }}
                onClick={() => this.registerViaEmail()}
              >
                Continue
              </Button>
            </CardContent>
          </Card>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(styles)(EmailAccountRegistration);
// Customizable Area End
