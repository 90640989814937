// Customizable Area Start
import React from "react";
import SchedulingController, { Props } from "./SchedulingController";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Box, Button, Checkbox, Grid, InputAdornment, Popover, SvgIcon, TextField, ThemeProvider, Typography, createTheme, withStyles } from "@material-ui/core";
import Sidebar from "../../../components/src/Sidebar.web";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Calender, PenIcon, successIcon, GreenToggle, GrayToggle, Arrow, DownArrow, GrayDownArrow } from "./assets";
import CloseIcon from "@material-ui/icons/Close";

const theme = createTheme({
    palette: {
        primary: {
            contrastText: "#fff",
            main: "#0000ff",
        }
    }
});

const CustomsCheckboxIconChecked = () => (<SvgIcon width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#014866" /><path d="M3.75 7.99992L6.58 10.8299L12.25 5.16992" stroke="#014866" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" /></SvgIcon>);
  
const CustomsCheckboxIconUnChecked = () => (
<SvgIcon width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#014866"/></SvgIcon>);
  

type DayAbbreviation = "S" | "M" | "T" | "W" | "T2" | "F";

type ActiveDays = DayAbbreviation[];
// Customizable Area End

export class SchedulingCalendar extends SchedulingController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    TimeSlot = (
        title: string,
        duration: string,
        slots: string[],
        classes: any,
        index:number
    ) => {
        return (
            <Box className={classes.availabilitySlotContainer}>
                <Box style={{ width: '25%', maxWidth: '150px', marginTop: '12px' }}>
                    <Typography variant="h4" className={classes.weekDays}>
                        {title}
                    </Typography>
                    <Typography variant="h4" className={classes.slotDuration}>
                        {duration}
                    </Typography>
                </Box>
                <Box
                    key={index}
                    style={{
                        width: '75%',
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '2px',
                        justifyContent: 'flex-start',
                    }}
                >
                    {slots.map((slot, slotIndex) => (
                        <>
                            <Button
                                key={slotIndex}
                                variant="contained"
                                data-test-id={`selectSlot${index}`}
                                className={`${this.state.selectedSlots.includes(slot) ? classes.selected : classes.unSelected}`}
                                onClick={() => this.handleSlotSelect(slot)}
                            >
                                {slot}
                            </Button>
                        </>
                    ))}
                </Box>
            </Box>
        );
    };

    showHeading = () => {
        if(this.state.isEdit){
            return "Edit Availability"
        }else{
            return "Calendar"
        }
    }

    getSuccessConfirmationPopup = (classes: any) => {
        return (
            <>
                {this.state.showSuccessMsg ?
                    (
                        <Box className={classes.successPopover}>
                            <img src={successIcon} width={140} height={140} className={classes.successIcon} />
                            <Typography variant="h6" className={classes.hpPopoverTextSemiBold}>
                                The slot has been cancelled
                            </Typography>
                            <Typography variant="h6" className={classes.hpPopoverTextSemiBold}>
                                successfully. The appointment gets
                            </Typography>
                            <Typography variant="h6" className={classes.hpPopoverTextSemiBold}>
                                moved to the other slot
                            </Typography>
                            <Button
                                variant="contained"
                                className={classes.doneBtn}
                                data-test-id="closeSlotCancellation"
                                onClick={this.closeSlotCancellation}
                            >
                                Done
                            </Button>
                        </Box>
                    )
                    :
                    (
                        <Box className={classes.hpPopoverConfirmationContainer}>
                            <Typography variant="h6" className={classes.popoverHeading}>
                                Are you sure?
                            </Typography>
                            <Box className={classes.confirmCancellation}>
                                <Button
                                    variant="contained"
                                    className={classes.cancelSlotBtn}
                                    data-test-id="handelCancelAllSlot"
                                    onClick={() => this.handelCancelAllSlot()}
                                >
                                    No
                                </Button>
                                <Button
                                    variant="contained"
                                    className={classes.cancelSlotConfirmationBtn}
                                    data-test-id="handelShowSuccessMsg"
                                    onClick={() => this.handelShowSuccessMsg()}
                                >
                                    Yes
                                </Button>
                            </Box>
                        </Box>
                    )}</>
        )
    }

    getSlotCancellationContainer = (classes: any) => {
        return (
            <>
                <Box className={classes.hpPopoverContainer}>
                    <Typography variant="h6" className={classes.popoverHeading}>
                        Select slot to be cancelled
                    </Typography>
                    <Box
                        data-test-id="slot1"
                        className={
                            this.state.cancelSlot.includes("10:30 AM - 11:15 AM")
                                ? classes.cancelAppointmentCard
                                : classes.appointmentCard
                        }
                        onClick={() => this.handleCancelBooking("10:30 AM - 11:15 AM")}
                    >
                        <Typography variant="h6" className={classes.patientSlot}>
                            Appointment with Mrs. Marina & Mr.John
                        </Typography>
                        <Typography variant="h6" className={classes.slotBooked}>
                            10:30 AM - 11:15 AM
                        </Typography>
                    </Box>
                    <Box
                        data-test-id="slot2"
                        className={
                            this.state.cancelSlot.includes("11:15 AM - 12 PM")
                                ? classes.cancelAppointmentCard
                                : classes.appointmentCard
                        }
                        onClick={() => this.handleCancelBooking("11:15 AM - 12 PM")}
                    >
                        <Typography variant="h6" className={classes.patientSlot}>
                            Appointment with Mrs. Marina & Mr.John
                        </Typography>
                        <Typography variant="h6" className={classes.slotBooked}>
                            11:15 AM - 12 PM
                        </Typography>
                    </Box>
                    <Button
                        variant="contained"
                        data-test-id="continueHandelSlot"
                        className={classes.continueBtn}
                        onClick={this.handelSelectedSlot}
                    >
                        Continue
                    </Button>
                </Box>
            </>
        )
    }

    getSingleSlotPopup = (classes: any) => {
        return (
            <Box className={classes.hpPopoverContainer}>
                <Typography variant="h6" className={classes.popoverHeading}>
                    Which appointment do you want to cancel?
                </Typography>
                <Box className={this.state.slot === "10:30 - 11-15am" ? classes.appointmentSelectedNewCard : classes.appointmentSelectedTransparentCard} data-test-id="showSlotCancelPopup0" onClick={() => this.handelShowSlotCancelPopup("10:30 - 11-15am")}>
                    <Typography variant="h6" className={classes.patientSlot}>Appointment with Mrs. Marina</Typography>
                </Box>
                <Box className={this.state.slot === "11:15 - 12pm" ? classes.appointmentSelectedNewCard : classes.appointmentSelectedTransparentCard} data-test-id="showSlotCancelPopup1" onClick={() => this.handelShowSlotCancelPopup("11:15 - 12pm")}>
                    <Typography variant="h6" className={classes.patientSlot}>Appointment with  Mr.John</Typography>
                </Box>
                <Button
                    variant="contained"
                    className={classes.confirmButton}
                    data-test-id="confirmRemoveSlot"
                    onClick={() => this.handelRemoveSlot()}
                >
                    Confirm
                </Button>
            </Box>
        )
    }

    getCancelConfirmationContent = (classes: any) => {
        return (
            <>
                <Box>
                    {this.state.cancelAllSlot ? (
                        <Box className={classes.confirmationMessage}>
                            {this.state.cancelSlot.length === 2
                                ?
                                (<>
                                    {this.state.removeSlot ? (
                                        <>
                                            {this.getSuccessConfirmationPopup(classes)}
                                        </>
                                    ) : (
                                        <Box style={{ paddingTop: "40px", paddingBottom: "40px" }} className={classes.hpPopoverContainer}>
                                            <Typography variant="h6" className={classes.popoverHeading}>
                                                Do you want to cancel both the slots?
                                            </Typography>
                                            <Box className={classes.confirmCancellation}>
                                                <Button
                                                    variant="contained"
                                                    className={classes.cancelSlotBtn}
                                                    data-test-id="handelCancelSlot"
                                                    onClick={() => this.handelCancelSlot()}
                                                >
                                                    No
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    data-test-id="handelRemoveSlot"
                                                    className={classes.cancelSlotConfirmationBtn}
                                                    onClick={() => this.handelRemoveSlot()}
                                                >
                                                    Yes
                                                </Button>
                                            </Box>
                                        </Box>
                                    )}
                                </>)
                                :
                                (<>
                                    {this.state.removeSlot ? (
                                        <>
                                            {this.getSucceesConfirmatiomAllSlots(classes)}
                                        </>
                                    ) : (
                                            <>
                                                {this.getSingleSlotPopup(classes)}
                                            </>
                                    )}</>
                                )}
                        </Box>
                    ) : (
                        <>
                            {this.getSlotCancellationContainer(classes)}
                        </>
                    )}
                </Box>
            </>
        )
    }

    getSlotListContent = (classes: any) => {
        return (
            <>
                <Box className={classes.hpPopoverContainer}>
                    <Typography variant="h6" className={classes.popoverHeading}>
                        Slots
                    </Typography>
                    <Box className={classes.appointmentCard} data-test-id="currentSlotAppointment0" onClick={() => this.handelShowSlotCancelPopup("10:30 - 11-15am")}>
                        <Typography variant="h6" className={classes.patientSlot}>
                            Appointment with Mrs. Marina & Mr.John
                        </Typography>
                        <Typography variant="h6" className={classes.slotBooked}>
                            10:30 AM - 11: 15 AM
                        </Typography>
                    </Box>
                    <Box className={classes.appointmentCard} data-test-id="currentSlotAppointment1" onClick={() => this.handelShowSlotCancelPopup("11-15 - 12pm")}>
                        <Typography variant="h6" className={classes.patientSlot}>
                            Appointment with Mrs. Marina & Mr.John
                        </Typography>
                        <Typography variant="h6" className={classes.slotBooked}>
                            11: 15 AM - 12 PM
                        </Typography>
                    </Box>
                </Box>
            </>
        )
    }

    getSucceesConfirmatiomAllSlots = (classes: any) => {
        return(
        <>
            {this.state.showSuccessMsg ?
                (
                    <Box className={classes.successPopover}>
                        <img src={successIcon} width={140} height={140} className={classes.successIcon} />
                        <Typography variant="h6" className={classes.hpPopoverTextSemiBold}>
                            Appointment with Mrs. Marina
                        </Typography>
                        <Typography variant="h6" className={classes.hpPopoverTextSemiBold}>
                            slot {this.state.slot} cancelled
                        </Typography>
                        <Typography variant="h6" className={classes.hpPopoverTextSemiBold}>
                            successfully.
                        </Typography>
                        <Button
                            data-test-id="clearSingleSlot"
                            variant="contained"
                            className={classes.doneBtn}
                            onClick={this.closeSlotCancellation}
                        >
                            Done
                        </Button>
                    </Box>
                )
                :
                (
                    <Box className={classes.hpPopoverConfirmationContainer}>
                        <Typography variant="h6" className={classes.popoverHeading}>
                            Are you sure?
                        </Typography>
                        <Box className={classes.confirmCancellation}>
                            <Button
                                variant="contained"
                                data-test-id="singleSlotStopSlotCancellation"
                                className={classes.cancelSlotBtn}
                                onClick={() => this.handelCancelAllSlot()}
                            >
                                No
                            </Button>
                            <Button
                                variant="contained"
                                data-test-id="singleSlotCancellation"
                                className={classes.cancelSlotConfirmationBtn}
                                onClick={() => this.handelShowSuccessMsg()}
                            >
                                Yes
                            </Button>
                        </Box>
                    </Box>
            )}
        </>)
    }

    getDayBoxes = (activeDays:ActiveDays) => {
        const days = [
          { text: "S", isActive: activeDays.includes("S") },
          { text: "M", isActive: activeDays.includes("M") },
          { text: "T", isActive: activeDays.includes("T") },
          { text: "W", isActive: activeDays.includes("W") },
          { text: "T", isActive: activeDays.includes("T2") }, 
          { text: "F", isActive: activeDays.includes("F") }
        ];
      
        return days.map(day => (
          <Box
            key={day.text}
            style={{
              width: "27px",
              height: "33px",
              borderRadius: "6px",
              color: day.isActive ? "#FFFFFF" : "#7A7A7A",
              backgroundColor: day.isActive ? "#3780E0" : "#D7D2D2",
              fontFamily: "SF Pro Text Medium",
              fontSize: "16px",
              marginRight: "5px",
              alignContent: "center",
              textAlign: "center"
            }}
          >
            {day.text}
          </Box>
        ));
    }
    
    getRecurrence = (classes:any) => {
        return(
            <>
                {this.state.isRecurrence ? (
                    <>
                        <Box>
                            <Box style={{ display: "flex", justifyContent: "space-between", alignContent: "center", textAlign: "center" }}>
                                <Box>
                                    <Typography variant="h4" className={classes.title}>Add title</Typography>
                                </Box>
                                <Box>
                                    <CloseIcon style={{ cursor: "pointer", width: "25px", height: "25px" }} onClick={this.handelAvaibilityPopup} />
                                </Box>
                            </Box>
                            <Box style={{ border: "none8", width: "343px", height: "1px", backgroundColor: "#D8D8D8" }} />
                            <Box style={{ display: "flex", justifyContent: "space-between", alignContent: "center", textAlign: "center" }}>
                                <Box>
                                    <Typography variant="h4" className={classes.text}>Full Day</Typography>
                                    {!this.state.isFullDay &&
                                        (
                                            <>
                                                <Typography variant="h4" className={classes.text}>Mon, Dec 6, 2023</Typography>
                                                <Typography variant="h4" className={classes.text}>Mon, Dec 6, 2023</Typography>
                                            </>
                                        )}
                                </Box>
                                <Box>
                                    {!this.state.isFullDay ?
                                        (
                                            <>
                                                <img
                                                    width="46"
                                                    height="24"
                                                    src={GrayToggle}
                                                    style={{ marginTop: "29px" }}
                                                    onClick={this.handelIsFullDay}
                                                />
                                                <Typography variant="h4" className={classes.text}>12:00 PM</Typography>
                                                <Typography variant="h4" className={classes.text}>1:30 PM</Typography>
                                            </>
                                        ) : (
                                            <img
                                                width="46"
                                                height="24"
                                                data-test-id="fullDay"
                                                src={GreenToggle}
                                                style={{ marginTop: "29px" }}
                                                onClick={this.handelIsFullDay}
                                            />)}
                                </Box>
                            </Box>
                            <Box style={{ border: "none8", width: "343px", height: "1px", backgroundColor: "#D8D8D8", marginTop: "15px" }} />
                            <Box style={{ display: "flex", justifyContent: "space-between", alignContent: "center", textAlign: "center", marginTop: "20px" }}>
                                <Typography variant="h4" className={classes.title} onClick={this.handelIsRepeat} data-test-id="noRepeat">Does not repeate</Typography>
                            </Box>
                            <Box style={{ display: "flex", justifyContent: "space-between", alignContent: "center", textAlign: "center", gap: "20px" }}>
                                <Button
                                    variant="contained"
                                    className={classes.discardButton}
                                >
                                    Discard
                                </Button>
                                <Button
                                    variant="contained"
                                    className={classes.saveButton}
                                >
                                    Save
                                </Button>
                            </Box>
                        </Box>
                    </>
                ) : (
                    <>
                        {["Does not repeat", "Every day", "Every week", "Every month", "Every year", "Custom"].map((scheduleDateLabel, index) => (
                            <div key={index}>
                                <Checkbox
                                    className={classes.checkbox}
                                    data-test-id={`selectedRecurringType${index}`}
                                    checked={this.state.selectedType === scheduleDateLabel}
                                    onChange={() => this.handelSelecteype(scheduleDateLabel, "Monthly")}
                                    icon={<CustomsCheckboxIconUnChecked />}
                                    checkedIcon={<CustomsCheckboxIconChecked />}
                                />
                                <span className={this.state.selectedType === scheduleDateLabel ? classes.selectedDay : classes.unSelectedDay}>{scheduleDateLabel}</span>
                            </div>
                        ))}
                    </>
                )}
            </>
        )
    }

    getRecussingType = (classes: any) => {
        return (
            <>
                {!this.state.isOpen ?
                    (
                        <TextField
                            variant="outlined"
                            placeholder="Select"
                            value={this.state.recurringType}
                            className={classes.repeatInputFields}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <img
                                            src={DownArrow}
                                            width="15"
                                            height="8"
                                            onClick={this.handelSelectOption}
                                        />
                                    </InputAdornment>
                                ),
                                readOnly: true,
                            }}
                            data-test-id="recurringType"
                            onClick={this.handelSelectOption}
                        />
                    ) : (
                        <Box style={{ width: "302px", height: "145px", border: "1px solid #808080", borderRadius: "8px", padding: "20px" }}>
                            <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                <Typography variant="h4" style={{ width: "50%", fontFamily: "SF Pro Text Medium", fontSize: "16px", color: "#292929", marginBottom: "15px" }}>
                                    {this.state.recurringType}
                                </Typography>
                                <img
                                    width="15"
                                    height="8"
                                    src={GrayDownArrow}
                                    onClick={this.handelSelectOption}
                                />
                            </Box>
                            <Box style={{ border: "none", width: "306px", height: "1px", backgroundColor: "#D8D8D8", marginBottom: "15px" }} />
                            <Typography variant="h4" style={{ fontFamily: "SF Pro Text Regular", fontSize: "16px", color: "#292929", marginBottom: "15px" }} data-test-id="everyDay" onClick={() => this.handelSelecteype('Every day', "Daily")}>
                                Daily
                            </Typography>
                            <Typography variant="h4" style={{ fontFamily: "SF Pro Text Regular", fontSize: "16px", color: "#292929", marginBottom: "15px" }} data-test-id="everyWeek" onClick={() => this.handelSelecteype('Every week', "Weekly")}>
                                Weekly
                            </Typography>
                            <Typography variant="h4" style={{ fontFamily: "SF Pro Text Regular", fontSize: "16px", color: "#292929", marginBottom: "15px" }} data-test-id="everyMonthly" onClick={() => this.handelSelecteype('Every month', "Monthly")}>
                                Monthly
                            </Typography>
                        </Box>
                    )
                }
                {this.state.selectedType === "Every week" && (
                    <>
                        <Typography variant="h4" style={{ fontFamily: "SF Pro Text Medium", fontSize: "16px", color: "#292929", margin: "20px auto" }}>Repeate On</Typography>
                        <Box style={{ display: "flex", justifyContent: "space-between" }}>
                            {this.getDayBoxes(["T", "T2"])}
                        </Box>

                    </>
                )}
                {this.state.selectedType === "Every month" && (
                    <Box>
                        {!this.state.isMonthly ? (<TextField
                            variant="outlined"
                            placeholder="Select"
                            value={this.state.selectedMonthType}
                            className={classes.repeatInputFields}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <img
                                            src={DownArrow}
                                            width="15"
                                            height="8"
                                            onClick={this.handelMonthlySelectOption}
                                        />
                                    </InputAdornment>
                                ),
                                readOnly: true,
                            }}
                            onClick={this.handelMonthlySelectOption}
                        />) : (
                            <Box style={{ width: "302px", height: "125px", border: "1px solid #808080", borderRadius: "8px", padding: "20px" }}>
                                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Typography
                                        variant="h4"
                                        style={{
                                            width: "50%",
                                            fontSize: "16px",
                                            fontFamily: this.state.selectedMonthType ? "SF Pro Text Medium" : "SF Pro Text Regular",
                                            color: this.state.selectedMonthType ? "#292929" : "#808080",
                                            marginBottom: "15px"
                                        }}
                                    >
                                        {this.state.selectedMonthType ? this.state.selectedMonthType : "Select"}
                                    </Typography>
                                    <img
                                        width="15"
                                        height="8"
                                        src={GrayDownArrow}
                                        onClick={this.handelMonthlySelectOption}
                                    />
                                </Box>
                                <Box style={{ border: "none", width: "306px", height: "1px", backgroundColor: "#D8D8D8", marginBottom: "15px" }} />
                                <Typography variant="h4" style={{ fontFamily: "SF Pro Text Regular", fontSize: "16px", color: "#292929", marginBottom: "15px" }} onClick={() => this.handelselectedMonthType("Monthly on day 6")}>
                                    Monthly on day 6
                                </Typography>
                                <Typography variant="h4" style={{ fontFamily: "SF Pro Text Regular", fontSize: "16px", color: "#292929", marginBottom: "15px" }} onClick={() => this.handelselectedMonthType("Monthly on second monday")}>
                                    Monthly on second monday
                                </Typography>
                            </Box>
                        )}
                    </Box>
                )}
            </>
        )
    }

    recurringClasses = (classes:any)=>{
        if (!this.state.isRecurrence && !this.state.isRepeat) { return classes.popover }
        else if (this.state.isRecurrence) { return classes.customRecurrence }
        else { return classes.hpNewPaperPropsClass }
    }

    leaveHandel = (classes: any) => {
        return (
            <Box className={classes.availabilitySlotContainer}>
                <Box className={this.state.isEdit ? classes.leaveLabel : classes.manageAvaibilityscheduleDateLabel} onClick={this.handelAvaibilityPopup}>
                    <Box className={this.state.isEdit ? classes.leaveLabel : classes.manageAvaibilitylabel} data-test-id="selectRecurringOption" onClick={this.handelAvaibilityPopup}>
                        {this.state.isEdit ? "Mon 6" : "Manage Availability"}
                    </Box>
                </Box>
                <Box className={this.state.isEdit ? classes.leaveLabel : classes.editBtn} data-test-id="selectLeave" onClick={this.handelOpenEdit}>
                    {this.state.isEdit ? (
                        <>
                            <span>Leave{' '}</span>
                            <span>
                                {this.state.isLeave ? (
                                    <img width="46" height="24" src={GreenToggle} alt="Green Toggle" data-test-id="leave" />
                                ) : (
                                    <img width="46" height="24" src={GrayToggle} alt="Gray Toggle" data-test-id="noLeave" onClick={this.handelLeave} />
                                )}
                            </span>
                        </>
                    ) : (
                        <>
                            <span> <img width="16" height="16" src={PenIcon} alt="Edit Icon" style={{ marginTop: "2px" }} /> </span> {' '}Edit
                        </>
                    )}
                </Box>
            </Box>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const totalWidth = 60;
        const height = 14;
        const selectedWidth = (50 / 100) * totalWidth;
        const unSelectedWidth = totalWidth - selectedWidth;
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <ThemeProvider theme={theme}>
                    <Grid container className={classes.schedularContainer}>
                        <Grid item xs={1}>
                            <Sidebar />
                        </Grid>
                        <Grid item xs={11}>
                            <Box className={classes.schedularAvailbleContainer}>
                                <Box className={classes.schedularHeaderContainer}>
                                    <Box className={classes.schedularHeading}>
                                        <ArrowBackIosIcon
                                            className={classes.calendarBackBtn}
                                            data-test-id="calendarBackBtn"
                                            onClick={this.handelPreviousPage}
                                        />
                                        <Typography variant="h6" className={classes.calendarTitle}>
                                            {this.showHeading}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box>
                                    <Box className={classes.scheduleFieldContainer}>
                                        <Typography variant="h4" className={classes.scheduleDateLabel}>Set Date</Typography>
                                        <Box className={classes.customSCName} style={{ marginBottom: "20px" }}>
                                            <span className={classes.customDateInputName}>
                                                <DatePicker
                                                    monthsShown={2}
                                                    popperClassName={classes.popperScheduleClassName}
                                                    wrapperClassName={classes.datepickerScheduleClassFullWidth}
                                                    calendarClassName={classes.datepickerNurseClass}
                                                    formatWeekDay={nameOfDay => nameOfDay.slice(0, 1)}
                                                    onChange={this.handleDateChange}
                                                    className={classes.dateField}
                                                    dateFormat="Select date"
                                                    value={this.state.date}
                                                    placeholderText="Select date"
                                                    minDate={new Date()}
                                                    startOpen={true}
                                                    shouldCloseOnSelect={false}
                                                    open={true}
                                                    data-test-id="select-schedule-date"
                                                />
                                            </span>
                                        </Box>
                                    </Box>
                                    <Box className={classes.availabilityContainer}>
                                        <Box>
                                            {this.leaveHandel(classes)}
                                            <Box className={classes.slotContainer}>
                                                <Box style={{ display: "flex", gap: "4px" }}>
                                                    <Box
                                                        style={{display: "flex",
                                                            width: `${totalWidth}px`,
                                                            height: `${height}px`,
                                                            border: "1px solid #808080",
                                                            borderRadius: "4px"
                                                        }}
                                                    ></Box>
                                                    <Box className={classes.slotText}>- Full Free Slot</Box>
                                                </Box>
                                                <Box style={{ display: "flex", gap: "4px" }}>
                                                    <Box
                                                        style={{
                                                            display: "flex",
                                                            width: `${totalWidth}px`,
                                                            height: `${height}px`,
                                                            border: "none",
                                                        }}
                                                    >
                                                        <Box
                                                            style={{
                                                                width: `${selectedWidth}px`,
                                                                height: "100%",
                                                                backgroundColor: "#3780E0",
                                                                borderTopLeftRadius: "4px",
                                                                borderBottomLeftRadius: "4px"
                                                            }}
                                                        />
                                                        <Box
                                                            style={{
                                                                width: `${unSelectedWidth}px`,
                                                                height: "100%",
                                                                backgroundColor: "#d3e3f8",
                                                                borderTopRightRadius: "4px",
                                                                borderBottomRightRadius: "4px"
                                                            }}
                                                        />
                                                    </Box>
                                                    <Box className={classes.slotText}>- Half Booked</Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box className={classes.availabilityAllSlotContainer}>
                                            {this.TimeSlot("Morning", "7:30am - 3pm", ["7:30 - 9 am", "9 - 10:30 am", "10:30 - 12 pm", "12 - 1:30 pm", "1:30 - 3 pm"], classes,0)}
                                            {this.TimeSlot("Evening", "3pm - 10:30pm", ["3 - 4:30 pm", "4:30 - 6 pm", "6 - 7:30 pm", "7:30 - 9 pm", "9 - 10:30 pm"], classes,1)}
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Popover
                                className={classes.hpNewPaperPropsClass}
                                open={this.state.showSlot}
                                anchorOrigin={{ vertical: 'center',horizontal: 'center',}}
                                transformOrigin={{ vertical: 'center',horizontal: 'center'}}
                                data-test-id="showSlotCancelPopup"
                                onClose={this.handleClose}
                            >
                                <Box>
                                    
                                    {this.state.showSlotCancelPopup ? this.getCancelConfirmationContent(classes) : this.getSlotListContent(classes)}
                                </Box>
                            </Popover>
                            <Popover
                                className={this.recurringClasses(classes)}
                                open={this.state.avaibilityPopup}
                                data-test-id="closeAvaibilityPopup"
                                anchorOrigin={{horizontal: 'center',vertical: 'center'}}
                                transformOrigin={{horizontal: 'center',vertical: 'center',}}
                                onClose={this.handelAvaibilityPopup}
                            >
                                {!this.state.isRepeat ? (
                                    <Box className={this.state.isRecurrence ? classes.recurrence : classes.notePopover}>
                                        <>
                                            {this.getRecurrence(classes)}
                                        </>
                                    </Box>
                                ) : (
                                    <Box className={classes.notePopover}>
                                        <Box style={{ display: "flex", marginBottom: "15px", gap: "25px", alignContent: "center", alignItems: "center" }}>
                                            <img width="10" height="15" src={Arrow} onClick={this.handelUpdateSelectedType} />
                                            <Typography variant="h4" className={classes.customReferanceBackBtn}>Custom Recurrence</Typography>
                                        </Box>
                                        <Box style={{ border: "none8", width: "343px", height: "1px", backgroundColor: "#D8D8D8", marginBottom: "15px" }} />
                                        <Typography variant="h4" style={{ fontFamily: "SF Pro Text Medium", fontSize: "16px", color: "#292929", margin: "20px auto" }}>Repeate Every</Typography>
                                        {this.getRecussingType(classes)}
                                        <Typography variant="h4" style={{ fontFamily: "SF Pro Text Medium", fontSize: "16px", color: "#292929", margin: "20px auto" }}>Ends</Typography>
                                        <Box style={{ marginBottom: "12px" }}>
                                            <Checkbox
                                                className={classes.checkbox}
                                                checked={this.state.endType === "On"}
                                                onChange={() => this.handelEndType("On")}
                                                icon={<CustomsCheckboxIconUnChecked />}
                                                checkedIcon={<CustomsCheckboxIconChecked />}
                                            />
                                            <span className={this.state.endType === "On" ? classes.selectedDay : classes.unSelectedDay}>On</span>
                                            <span className={this.state.endType === "On" ? classes.selectedDay : classes.unSelectedDay} style={{ height: "22", border: "1px solid #808080", padding: "10px", borderRadius: "8px", margin: "0px 15px" }} >Dec 24 , 2023</span>
                                        </Box>
                                        <Box style={{ marginBottom: "12px" }}>
                                            <Checkbox
                                                className={classes.checkbox}
                                                checked={this.state.endType === "After"}
                                                onChange={() => this.handelEndType("After")}
                                                icon={<CustomsCheckboxIconUnChecked />}
                                                checkedIcon={<CustomsCheckboxIconChecked />}
                                            />
                                            <span className={this.state.endType === "After" ? classes.selectedDay : classes.unSelectedDay}>After</span>
                                            <span className={this.state.endType === "After" ? classes.selectedDay : classes.unSelectedDay} style={{ height: "22", border: "1px solid #808080", padding: "10px", borderRadius: "8px", margin: "0px 15px" }} >01</span>
                                            <span className={this.state.endType === "After" ? classes.selectedDay : classes.unSelectedDay}>Occurrence</span>
                                        </Box>
                                        <Button
                                            variant="contained"
                                            className={classes.doneBtn}
                                        >
                                            Done
                                        </Button>
                                    </Box>
                                )}
                            </Popover>
                            <Popover
                                className={classes.hpNewPaperPropsClass}
                                open={this.state.isLeaveConfirmation}
                                anchorOrigin={{
                                    vertical: 'center',
                                    horizontal: 'center',
                                }}
                                date-test-id="isLeaveConfirmation"
                                onClose={this.handelLeaveConfirmation}
                                transformOrigin={{
                                    vertical: 'center',
                                    horizontal: 'center',
                                }}
                            >
                                <Box className={classes.leavePopover}>
                                    {this.state.showSuccessMsg ?
                                        (<Box>
                                            <Typography variant="h6" className={classes.leaveText}>
                                                Taking a leave will cancel all your
                                            </Typography>
                                            <Typography variant="h6" className={classes.leaveText}>
                                                appointments (if any) for the day.
                                            </Typography>
                                            <Typography variant="h6" className={classes.leaveConfirmation}>
                                                Are you sure?
                                            </Typography>
                                            <Box className={classes.confirmCancellation}>
                                                <Button
                                                    variant="contained"
                                                    className={classes.cancelSlotBtn}
                                                    onClick={this.handelLeaveConfirmation}
                                                    data-test-id="cancelLeave"
                                                >
                                                    No
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    className={classes.cancelSlotConfirmationBtn}
                                                    onClick={this.handelOpenLeaveConfirmationPopup}
                                                    data-test-id="applyLeave"
                                                >
                                                    Yes
                                                </Button>
                                            </Box>
                                        </Box>) : (
                                        <Box>
                                            <img src={successIcon} width={140} height={140} className={classes.successIcon} />
                                            <Typography variant="h6" className={classes.hpPopoverTextSemiBold}>
                                                All your appointments / pending
                                            </Typography>
                                            <Typography variant="h6" className={classes.hpPopoverTextSemiBold}>
                                                appointments for 06/12/2023
                                            </Typography>
                                            <Typography variant="h6" className={classes.hpPopoverTextSemiBold}>
                                                have been cancelled.
                                            </Typography>
                                            <Button
                                                variant="contained"
                                                className={classes.doneBtn}
                                                data-test-id="confirmLeave"
                                                onClick={this.closeLeaveConfirmation}
                                            >
                                                Done
                                            </Button>
                                        </Box>)}
                                </Box>
                            </Popover>
                        </Grid>
                    </Grid>
                </ThemeProvider>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles = {
    schedularContainer: {
        display: "flex",
    },
    schedularAvailbleContainer: {
        margin: "40px 50px",
        width: "70%",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column" as "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gap: "20px",
    },
    schedularHeaderContainer: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    schedularHeading: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: "25px",
    },
    calendarBackBtn: {
        display: "flex",
        alignItems: "center",
        fontSize: "22px",
        justifyContent: "center",
        borderRadius: "8px",
        border: "1px solid #E9E9E9",
        padding: "13px 9px 13px 17px",
    },
    calendarTitle: {
        fontFamily: "SF Pro Text Bold",
        fontSize: "28px",
        lineHeight: "33.41px",
    },
    scheduleFieldContainer: {
        width: "94%",
        margin: "15px 0px 20px"
    },
    scheduleDateLabel: {
        fontSize: "18px",
        fontFamily: "SF Pro Text Regular",
        margin: "0px 0px 15px",
        textAlign: "left" as "left",
        caretColor: "transparent",
        color: "#292929"
    },
    customSCName: {
        display: "flex",
        alignItems: "center",
    },
    customDateInputName: {
        width: "100%",
        borderRadius: "8px",
        "& input": {
            color: "#292929",
            fontSize: "18px",
            background: `url(${Calender}) left / contain no-repeat`,
            backgroundPosition: "12px",
            backgroundSize: "25px",
            width: "382px",
            height: "58px",
            border: "1px solid #808080",
            display: "flex",
            borderRadius: "8px",
            fontFamily: "SF Pro Text Regular",
            caretColor: "transparent"
        },
        "& ::-webkit-input-placeholder": {
            color: "#808080",
        },
        "&:focus": {
            border: "1px solid #292929",
        },
    },
    datepickerScheduleClassFullWidth: {
        width: "100%",
        color: "#292929",
        border: "none",
    },
    datepickerNurseClass: {
        "& .react-datepicker__day-name": {
            padding: "11px",
            fontSize: "16px",
            color: "#808080",
            fontFamily: "SF Pro Text Medium",
            width: "25px"
        },
        "& .react-datepicker__day": {
            padding: "11px !important",
            fontSize: "16px",
            fontFamily: "SF Pro Text Medium",
            color: "#292929",
            width: "25px",
            height: "33px",
        },
        "& .react-datepicker__day--disabled": {
            color: "#808080 !important",
            width: "25px",
            height: "33px",
        },
        "& .react-datetime-picker__wrapper": {
            border: "none"
        },
        "& .react-datepickerClass-wrapper": {
            display: "flex !important",
        },
        "& .react-datepickerClass__month": {
            margin: "0px !important"
        },
        "& .react-datepickerClass-popper": {
            transform: "translate(90px, 209px) !important"
        },
        "& .react-datepickerClass__day": {
            width: "1.3rem !important",
            height: "1rem !important"
        },
        "& .react-datepickerClass__day-name": {
            width: "1.3rem !important",
            height: "1rem !important"
        },
        "& .react-datepickerClass__month-container": {
            margin: "0px 0px 5px !important"
        },
        "& .react-datepickerClass__day-names": {
            marginBottom: "-1px !important",
            caretColor: "transparent"
        },
        "& .react-datepickerClass__navigation": {
            height: "22px !important",
            width: "22px !important",
            margin: "5px -2px !important",
            caretColor: "transparent"
        },
        "& .react-datepickerClass__day--keyboard-selected": {
            color: "#000 !important",
            backgroundColor: "white !important",
            caretColor: "transparent"
        },
        "& .react-datepickerClass__day--today": {
            color: "#1d5d90 !important",
            backgroundColor: "white !important",
            fontWeight: "bold !important",
            caretColor: "transparent"
        },
        "& .react-datepickerClass__day:hover": {
            color: "#ccc !important",
            backgroundColor: "white !important",
            caretColor: "transparent"
        },
        "& .react-datepicker__triangle": {
            display: "none !important"
        },
        "& .react-datepicker__navigation--next": {
            right: "30px",
            backgroundColor: "#292929",
            width: "28px",
            height: "28px",
            borderRadius: "6px",
            top: "30px"
        },
        "& .react-datepicker__navigation--previous": {
            left: "30px",
            backgroundColor: "#292929",
            width: "28px",
            height: "28px",
            borderRadius: "6px",
            top: "30px"
        },
        "& .react-datepicker__navigation-icon::before": {
            borderColor: "#fff !important",
            borderRadius: "2px !important",
            top: "7px !important",
            borderWidth: "2px 2px 0 0"
        },
        "& .react-datepicker__navigation-icon::after": {
            borderColor: "#fff !important",
            borderRadius: "2px !important",
            top: "7px !important",
            borderWidth: "2px 2px 0 0"
        },
        "& .react-datepicker__month-container": {
            padding: "10px"
        },
        "& .react-datepicker__header": {
            backgroundColor: "#fff !important",
            border: "none !important"
        },
        "& .react-datepicker__current-month": {
            margin: "15px auto",
            fontSize: "16px",
            fontFamily: "SF Pro Text Medium",
            color: "#292929"
        },
        "& .react-datepicker__day--keyboard-selected": {
            border: "none !important",
            color: "#ffffff !important",
            backgroundColor: "#3780E0",
            width: "25px",
            height: "33px",
        },
        "& .react-datepicker__day:hover": {
            width: "25px",
            height: "33px",
        },
    },
    dateField: {
        "& .react-datepicker-ignore-onclickoutside": {
            border: "none !important",
            borderColor: "transparent !important"
        },
        display: "flex",
        padding: "15px 60px",
        border: "1px solid #808080",
        justifyContent: "center",
        borderRadius: "8px",
        cursor: "pointer",
        "&:focus-visible": {
            outline: "none",
            border: "2px solid #292929",
        },
        "&:focus": {
            outline: "none",
            border: "2px solid #292929",
        },
        "&:hover": {
            outline: "none",
            border: "1px solid #292929",
        },
        ".react-datepicker__input-container input::placeholder": {
            opacity: 1,
            fontSize: "18px",
            color: "#808080",
        },
        color: "#292929",
        fontSize: "18px",
        "& input": {
            color: "#292929",
        }
    },
    popperScheduleClassName: {
        "& .react-datepicker": {
            fontFamily: "SF Pro Text Regular !important",
            fontSize: "18px !important",
            border: "1px solid #80808040 !important",
            marginTop: "10px !important",
            borderRadius: "8px"
        }
    },
    availabilityContainer: {
        marginTop: "500px",
    },
    availabilityAllSlotContainer: {
        maxHeight:"275px",
        overflowY: "scroll" as "scroll",
        scrollbarColor: "#d9d5d5 #fff",
        scrollbarWidth: "thin" as "thin",
        "& ::-webkit-scrollbar-button": {
        display: "none"
        }
    },
    availabilitySlotContainer: {
        display: "flex",
        justifyContent: "space-between",
    },
    slotContainer: {
        display: "flex",
        width: "53%",
        gap: "20px",
        margin: "35px 0px"
    },
    manageAvaibilityscheduleDateLabel: {
        fontFamily: "SF Pro Text Bold",
        fontSize: "18px",
        color: "#292929"
    },
    editBtn: {
        fontFamily: "SF Pro Text Medium",
        fontSize: "16px",
        display: "flex",
        justifyContent: "space-between",
        gap: "14px",
        color: "#3780E0",
    },
    slotText: {
        fontSize: "13px",
        fontFamily: "SF Pro Text Medium",
        color: "#808080"
    },
    selected: {
        width: "150px",
        height: "50px",
        borderRadius: "8px",
        backgroundColor: "#3780E0",
        color: "#FFFFFF",
        fontFamily: "SF Pro Text Regular",
        border: "none",
        padding: "16px",
        cursor: "pointer",
        fontSize: "16px",
        margin: "10px 0px 20px 10px",
        caretColor: "transparent",
        textTransform: "lowercase " as "lowercase",
        "&:hover": {
            backgroundColor: "#ffffff",
            color: "#292929"
        }
    },
    unSelected: {
        width: "150px",
        height: "50px",
        borderRadius: "8px",
        backgroundColor: "#ffffff",
        color: "#292929",
        fontFamily: "SF Pro Text Regular",
        border: "1px solid #808080",
        padding: "16px",
        cursor: "pointer",
        fontSize: "16px",
        margin: "10px 0px 20px 10px",
        caretColor: "transparent",
        textTransform: "lowercase " as "lowercase",
        "&:hover": {
            backgroundColor: "#ffffff",
            color: "#292929"
        }
    },
    weekDays: {
        color: "#292929",
        fontSize: "20px",
        fontFamily: "SF Pro Text Medium",
    },
    slotDuration: {
        color: "#808080",
        fontSize: "16px",
        fontFamily: "SF Pro Text Regular",
    },
    popover: {
        "& .MuiPopover-paper": {
            borderRadius: "12px",
            overflow: "unset",
            maxWidth: "500px",
            padding: "20px",
            top: "200px !important" 
        },
        "& > div:nth-child(1)": {
            backgroundColor: "#050505a3 !important",
            marginLeft: "8.30%"
        },
        "@media (max-height: 660px)": {
            "& .MuiPopover-paper": {
                top: "100px !important"
            },
        },
    },
    customRecurrence: {
        "& .MuiPopover-paper": {
            borderRadius: "12px",
            overflow: "unset",
            maxWidth: "500px",
            padding: "20px",
            top: "200px !important" 
        },
        "& > div:nth-child(1)": {
            backgroundColor: "#050505a3 !important",
            marginLeft: "8.30%"
        },
        "@media (max-height: 760px)": {
            "& .MuiPopover-paper": {
                top: "120px !important"
            },
        },
        "@media (max-height: 700px)": {
            "& .MuiPopover-paper": {
                top: "100px !important"
            },
        },
    },
    hpNewPaperPropsClass: {

        "& > div:nth-child(1)": {
            backgroundColor: "#050505a3 !important",
            marginLeft: "8.30%"
        },
        "& .MuiPopover-paper": {
            borderRadius: "12px",
            overflow: "unset",
            maxWidth: "500px",
            padding: "20px",
            top: "200px !important" 
        },
        "@media (max-height: 700px)": {
            "& .MuiPopover-paper": {
                top: "150px !important"
            },
        },
        "@media (max-height: 660px)": {
            "& .MuiPopover-paper": {
                top: "125 !important"
            },
        },
    },
    hpPopoverContainer: {
        width: "445px",
        margin: "auto 20px"
    },
    hpPopoverConfirmationContainer: {
        width: "325px",
        margin: "auto 20px",
        paddingTop: "40px",
        paddingBottom: "40px"
    },
    hpPopoverTextSemiBold: {
        fontSize: "20px",
        caretColor: "transparent",
        color: "#292929",
        margin: "auto",
        fontFamily: "SF Pro Text Semibold",
        textAlign: "center" as "center",
        lineHeight: "32px"
    },
    hpPopoverText: {
        fontSize: "20px",
        caretColor: "transparent",
        color: "#292929",
        margin: "auto",
        fontFamily: "SF Pro Text Medium",
        textAlign: "center" as "center",
        lineHeight: "32px"
    },
    hpClosePopup: {
        display: "flex",
        borderRadius: "8px",
        justifyContent: "center",
        width: "181px",
        height: "56px",
        backgroundColor: "#014866",
        color: "#fff",
        border: "none",
        padding: "16px",
        cursor: "pointer",
        fontFamily: "SF Pro Text Bold",
        fontSize: "18px",
        margin: "10px auto",
        textTransform: "capitalize" as "capitalize",
        "&:hover": {
            backgroundColor: "#014866",
            color: "#fff"
        }
    },
    popoverHeading: {
        color: "#292929",
        fontSize: "20px",
        fontFamily: "SF Pro Text Medium",
        textAlign: "center" as "center",
        marginBottom: "20px"
    },
    appointmentCard: {
        height: "67px",
        border: "1px solid #014866",
        borderRadius: "6px",
        marginBottom: "25px",
        padding: "10px 35px"
    },
    cancelAppointmentCard: {
        height: "67px",
        border: "1px solid #014866",
        borderRadius: "6px",
        marginBottom: "20px",
        padding: "10px 35px",
        backgroundColor: "#d3e3f8"
    },
    patientSlot: {
        color: "#014866",
        fontSize: "16px",
        fontFamily: "SF Pro Text Medium",
        lineHeight: "30px"
    },
    slotBooked: {
        color: "#014866",
        fontSize: "14px",
        fontFamily: "SF Pro Text Regular",
        lineHeight: "30px"
    },
    doneBtn: {
        display: "flex",
        borderRadius: "8px",
        justifyContent: "center",
        width: "345px",
        height: "56px",
        backgroundColor: "#014866",
        color: "#fff",
        border: "none",
        padding: "16px",
        cursor: "pointer",
        fontFamily: "SF Pro Text Bold",
        fontSize: "18px",
        margin: "30px auto",
        textTransform: "capitalize" as "capitalize",
        "&:hover": {
            backgroundColor: "#014866",
            color: "#fff"
        }
    },
    continueBtn: {
        display: "flex",
        borderRadius: "8px",
        justifyContent: "center",
        width: "275px",
        height: "56px",
        backgroundColor: "#014866",
        color: "#fff",
        border: "none",
        padding: "16px",
        cursor: "pointer",
        fontFamily: "SF Pro Text Bold",
        fontSize: "18px",
        margin: "10px auto",
        textTransform: "capitalize" as "capitalize",
        "&:hover": {
            backgroundColor: "#014866",
            color: "#fff"
        }
    },
    cancelSlotBtn: {
        display: "flex",
        borderRadius: "8px",
        justifyContent: "center",
        width: "120px",
        height: "42px",
        backgroundColor: "#ffffff",
        color: "#014866",
        border: "1px solid #014866",
        padding: "16px",
        cursor: "pointer",
        fontFamily: "SF Pro Text Bold",
        fontSize: "18px",
        margin: "10px auto",
        textTransform: "capitalize" as "capitalize",
        "&:hover": {
            backgroundColor: "#ffffff",
            color: "#014866",
        }
    },
    cancelSlotConfirmationBtn: {
        display: "flex",
        borderRadius: "8px",
        justifyContent: "center",
        width: "120px",
        height: "42px",
        backgroundColor: "#014866",
        color: "#fff",
        border: "none",
        padding: "16px",
        cursor: "pointer",
        fontFamily: "SF Pro Text Bold",
        fontSize: "18px",
        margin: "10px auto",
        textTransform: "capitalize" as "capitalize",
        "&:hover": {
            backgroundColor: "#014866",
            color: "#fff"
        }
    },
    appointmentSelectedNewCard: {
        border: "1px solid #014866",
        borderRadius: "6px",
        margin: "auto auto 20px",
        width: "320px",
        height: "46px",
        textAlign: "center" as "center",
        alignContent: "center" as "center",
        backgroundColor: "#d3e3f8"
    },
    appointmentSelectedTransparentCard: {
        border: "1px solid #014866",
        borderRadius: "6px",
        margin: "auto auto 20px",
        width: "320px",
        height: "46px",
        textAlign: "center" as "center",
        alignContent: "center" as "center"
    },
    confirmButton: {
        display: "flex",
        borderRadius: "8px",
        justifyContent: "center",
        width: "253px",
        height: "56px",
        backgroundColor: "#014866",
        color: "#fff",
        border: "none",
        padding: "16px",
        cursor: "pointer",
        fontFamily: "SF Pro Text Bold",
        fontSize: "18px",
        margin: "25px auto 20px",
        textTransform: "capitalize" as "capitalize",
        "&:hover": {
            backgroundColor: "#014866",
            color: "#fff"
        }
    },
    successIcon: {
        display: "flex",
        margin: "30px auto"
    },
    confirmCancellation: {
        display: "flex",
        justifyContent: "space-between",
        width: "300px",
        margin: "auto"
    },
    successPopover: {
        width: "374px",
        margin: "auto 20px"
    },
    leaveText: {
        color: "#292929",
        fontSize: "20px",
        fontFamily: "SF Pro Text Bold",
        textAlign: "center" as "center"
    },
    leaveConfirmation: {
        color: "#292929",
        fontSize: "18px",
        fontFamily: "SF Pro Text Medium",
        textAlign: "center" as "center",
        margin: "10px auto"
    },
    leaveLabel: {
        color: "#292929",
        fontSize: "18px",
        fontFamily: "SF Pro Text Medium",
        display: "flex",
        alignContent: "center" as "center",
        textAlign: "center" as "center",
        gap: "12px"
    },
    leavePopover: {
        width: "342px",
        margin: "10px 25px"
    },
    notePopover: {
        width: "342px",
        margin: "20px 15px"
    },
    recurrence: {
        width: "340px",
        margin: "20px 15px"
    },
    title:{
        fontFamily:"SF Pro Text Medium",
        fontSize:"20px",
        color:"#3780E0",
        marginBottom:"15px",
        alignContent: "center" as "center",
        textAlign: "center" as "center",
    },
    text: {
        color: "#292929",
        fontSize: "18px",
        fontFamily: "SF Pro Text Medium",
        textAlign: "start" as "start",
        margin: "30px auto"
    },
    saveButton: {
        display: "flex",
        borderRadius: "8px",
        justifyContent: "center",
        width: "163px",
        height: "56px",
        backgroundColor: "#014866",
        color: "#fff",
        border: "none",
        padding: "16px",
        cursor: "pointer",
        fontFamily: "SF Pro Text Bold",
        fontSize: "18px",
        margin: "25px auto 20px",
        textTransform: "capitalize" as "capitalize",
        "&:hover": {
            backgroundColor: "#014866",
            color: "#fff"
        }
    },
    discardButton: {
        display: "flex",
        borderRadius: "8px",
        justifyContent: "center",
        width: "163px",
        height: "56px",
        backgroundColor: "#fff",
        color: "#014866",
        border: "1px solid #014866",
        padding: "16px",
        cursor: "pointer",
        fontFamily: "SF Pro Text Bold",
        fontSize: "18px",
        margin: "25px auto 20px",
        textTransform: "capitalize" as "capitalize",
        "&:hover": {
            backgroundColor: "#fff",
            color: "#014866"
        }
    },
    checkbox: {
        color: "#fff !important",
        padding: "9px 20px 9px 0px"
    },
    selectedDay:{
        fontFamily: "SF Pro Text Medium",
        fontSize: "16px",
        color:"#292929"
    },
    unSelectedDay:{
        fontFamily: "SF Pro Text Regular",
        fontSize: "16px",
        color:"#292929"
    },
    customReferanceBackBtn: {
        color: "#292929",
        fontSize: "20px",
        fontFamily: "SF Pro Text Medium",
    },
    repeatInputFields: {
        width: "100%",
        fontSize: "16px",
        marginBottom: "10px",
        "& .MuiOutlinedInput-root": {
          borderRadius: "8px",
          cursor: "pointer"
        },
        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
          borderWidth: "1px",
          borderColor: "#3f51b5 !important",
        },
        fontFamily: "SF Pro Text Regular",
        "& .MuiInputBase-input": {
          cursor: "pointer",
        },
        "& input": {
          color: '#292929',
          fontSize: "16px",
          fontFamily:"SF Pro Text Regular",
          "&::placeholder": {
            opacity: 1,
            fontSize: "16px",
            fontFamily:"SF Pro Text Regular",
            color:"#808080"
          },
        },
      },
}
export default withStyles(styles)(SchedulingCalendar);
// Customizable Area End
