import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { DashboardDataType } from "./types";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes:any
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: any;
  token: string;
  errorMsg: string;
  loading: boolean;
  userType: string;
  errorMessage: string;
  data:any;
  banner:string;
  hpRole: string;
  isShiftStart:boolean;
  openDialog:boolean;
  updateShiftStart:boolean;
  graphData:any;
  isHealthDetailsDialogOpen:boolean;
  currentPage:string;
  ayahDashboardDetails:any;
  totalBookingDetails:any;
  allBooking:any;
  ayahDashboardPaitentHelthDetails:any;
  bookingId:string;
  healthDetails:any;
  selectedMonth:number;
  selectedYear:number;
  showYear:boolean;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  apiServiceItemCallId: string = "";
  apiAyahDashboardDetailsCallId: string = "";
  apiAyahDashboardBookingDetailsCallId: string = "";
  apiAyahDashboardTotalBookingDetailsCallId: string = "";
  apiUpdateStatusCallId: string = "";
  apiPaitentHealthDetailsCallId: string = "";
  // Customizable Area End
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      dashboardData: [],
      errorMsg: "",
      token: "",
      loading: false,
      userType: "",
      errorMessage:"",
      data:[],
      banner:"",
      hpRole:"",
      isShiftStart:true,
      openDialog:false,
      updateShiftStart:true,
      isHealthDetailsDialogOpen:false,
      currentPage:"Dashboard",
      ayahDashboardDetails:{},
      totalBookingDetails:{},
      allBooking:{},
      ayahDashboardPaitentHelthDetails:{},
      graphData: {
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
        ],
        datasets: [
          {
            label: "My First dataset",
            backgroundColor: "#014866",
            data: [40, 25, 50, 70, 45, 60],
          },

          {
            label: "My second dataset",
            backgroundColor: "#4D6EFF",
            data: [25, 40, 15, 42, 30, 25],
          },
        ],
      }, 
      bookingId:'',
      healthDetails: {},   
      selectedMonth:new Date().getMonth(),
      selectedYear:2024,
      showYear:false,
    };
    
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener('willFocus', () => {
        this.getToken();
      });
    }
    // Customizable Area Start
      this.callNextStep()
    // Customizable Area End
  }
  
  getToken=()=>{
    const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(msg);
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dashboardGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token, loading: true }, () => {
        this.getDashboardData();
      });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const apiResponseHandlers: { [key: string]: (responseJson: any, errorReponse: any) => void } = {
        [this.apiServiceItemCallId]: (responseJson) => {
            this.setState({ data: responseJson.services, banner: responseJson.banner });
        },
        [this.apiAyahDashboardDetailsCallId]: (responseJson) => {
            this.setState({ ayahDashboardDetails: responseJson.data.attributes, loading: false });
        },
        [this.apiAyahDashboardBookingDetailsCallId]: (responseJson) => {
            this.setState({ allBooking: responseJson.data });
        },
        [this.apiAyahDashboardTotalBookingDetailsCallId]: (responseJson) => {
            this.setState({ totalBookingDetails: responseJson.data });
        },
        [this.apiUpdateStatusCallId]: () => {
            this.setState({ bookingId: "" });
            this.fetchAyahDashboardDetails();
        },
        [this.apiPaitentHealthDetailsCallId]: (responseJson) => {
          this.setState({ healthDetails: responseJson.data,isHealthDetailsDialogOpen:true });
        }
      };

      if (!responseJson.errors) {
        const handler = apiResponseHandlers[apiRequestCallId];
        if (handler) {
            handler(responseJson,errorReponse);
        }
    } else {
        this.setErrorMessage(responseJson);
    }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  setErrorMessage = (responseJson:any) => {
    this.setState({ errorMessage: responseJson.errors ? responseJson.errors.message : responseJson.error.message });
  }
  getAPIData = (responseJson: { errors:{message:string}; data: DashboardDataType },errorReponse: string |undefined) => {
    if (responseJson && !responseJson.errors && responseJson.data) {
      if (responseJson.data.length === 0) {
        this.setState({
          errorMsg: "Data Not Found",
          loading: false
        });
      } else {
        this.setState({
          dashboardData: responseJson.data,
          errorMsg: "",
          loading: false
        });
      }
    } else {
      if (errorReponse === undefined) {
        this.setState({
          errorMsg: "Something went wrong",
          loading: false
        });
      } else {
        this.setState({
          errorMsg: errorReponse,
          loading: false
        });
      }
    }
  }
  getServiceData = async() => {
    const token = await getStorageData("authToken");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiServiceItemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.service
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

    callNextStep =async() => {
      this.getServiceData();
      const userType = await getStorageData("userType");
      const hpRole = await getStorageData("hpUserRole");
      this.setState({userType,hpRole})
    }

    openService = (name:string|undefined) => {
      if (name === "Hospitals") {
        removeStorageData("filters")
        this.props.navigation.navigate("Reservations2Booking");
      } else if (name === "Nurses" || name === "Ayah") {
        setStorageData("serviceType", name);
        removeStorageData("filters")
        const navigateTo = new Message(getName(MessageEnum.NavigationMessage));
        navigateTo.addData(getName(MessageEnum.NavigationTargetMessage), "Reservations2NurseBooking");
        navigateTo.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateTo); 
      } else {
        return;
      }
    }


    handleCloseHealthDetailsDialog = () => {
      this.setState((prev)=>({...prev,isHealthDetailsDialogOpen:false}))
    }

    handelUpdateShift = () => {
      this.setState((prev) => ({ ...prev, updateShiftStart: !prev.updateShiftStart}));
    }  

    handelShiftStatus = () => {
      this.setState((prev) => ({ ...prev, isShiftStart: !prev.isShiftStart}));
    }  

    showRecentTransaction = () => {
      if (this.state.totalBookingDetails?.length > 0) {
      this.setState(
        { currentPage: "Recent Transactions" },
        () => {
          this.fetchAyahDashboardTotalBookingDetails();
        }
      )}   
    }

    showEmergencyContact = () => {
      this.setState({openDialog:!this.state.openDialog})
    }

    openDashboard = (heading:string) => {
      if(heading === "Recent Transactions"){
        this.setState({currentPage:"Total Money"})
        this.fetchAyahDashboardTotalBookingDetails();
      }else{
        this.setState((prev) => ({ ...prev, currentPage: "Dashboard" }))
      }
    }

    showTotalBooking = () => {
      this.setState({currentPage:"Total Bookings"})
    }

    showAllBooking = (bookingType:string) => {
      this.setState({currentPage:bookingType})
      this.fetchAyahDashboardBookingDetails(bookingType)
    }

    showTotalMoney = () => {
      this.setState({currentPage:"Total Money"})
    }

    fetchAyahDashboardDetails = () => {
      this.setState({loading: true}, async() => {
      const token = await getStorageData("authToken");
      const header = {
        "Content-Type": configJSON.dashboarContentType,
        token: token
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiAyahDashboardDetailsCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.ayahDashboardDetailsEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.dashboarApiMethodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    })
    }

    fetchAyahDashboardBookingDetails = async(bookingType:string) => {
      const token = await getStorageData("authToken");
      const header = {
        "Content-Type": configJSON.dashboarContentType,
        token: token
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiAyahDashboardBookingDetailsCallId = requestMessage.messageId;
      if(bookingType==="Upcoming Bookings"){
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.ayahDashboardUpcomingBookingDetailsEndPoint
        );
      }else{
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.ayahDashboardPastBookingDetailsEndPoint
        );
      }
      
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.dashboarApiMethodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }

    fetchAyahDashboardTotalBookingDetails = async() => {
      const token = await getStorageData("authToken");
      const header = {
        "Content-Type": configJSON.dashboarContentType,
        token: token
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiAyahDashboardTotalBookingDetailsCallId = requestMessage.messageId;
      if(this.state.currentPage == "Recent Transactions"){
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.ayahDashboardTotalBookingDetailsEndPoint}?month=${this.state.selectedMonth+1}&year=${this.state.selectedYear}`
        );
      }else{
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.ayahDashboardTotalBookingDetailsEndPoint
        );
      }

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.dashboarApiMethodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }

    updateBookingStatus = async (userId:number,status:string,bookingId:string) => {
      if(status === 'accepted'){this.setState({bookingId})}

      const token = await getStorageData("authToken");
      const header = {
        "Content-Type": configJSON.dashboarContentType,
        token: token 
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiUpdateStatusCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.updateHealthcarePersonnelBooking
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      const httpBody = {
          id:userId,
          status
      }

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.patchApiMethodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }

    getWeekdayName= (dateString:string) => {
      const date = new Date(dateString);
      const weekdayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      return weekdayNames[date.getDay()];
    }

    formatDate = (dateString:string) => {
      const date = new Date(dateString);
      const day = date.getDate();
    
      const daySuffix = (day:number) => {
        if (day > 3 && day < 21) return 'th'; 
        switch (day % 10) {
          case 1: return 'st';
          case 2: return 'nd';
          case 3: return 'rd';
          default: return 'th';
        }
      };
    
      const months = ["Jan.", "Feb.", "Mar.", "Apr.", "May.", "Jun.", "Jul.", "Aug.", "Sep.", "Oct.", "Nov.", "Dec."];
    
      const formattedDate = `${day}${daySuffix(day)} ${months[date.getMonth()]} ${date.getFullYear()}`;
      return formattedDate;
    }
    
    bookingTypeStatus = () => {
      if(this.state.currentPage === "Upcoming Bookings") return true;
      if(this.state.currentPage === "Past Bookings") return true;
      return false;
    }

    fetchAyahDashboardPaitentHealthDetails = async(patientId:string) => {
      const token = await getStorageData("authToken");
      const header = {
        "Content-Type": configJSON.dashboarContentType,
        token: token
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiPaitentHealthDetailsCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.ayahDashboardPaitentHealthDetailsEndPoint}?id=${parseInt(patientId)}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.dashboarApiMethodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }

    getMonthNumber = (monthName: string): number => {
      const monthMap: { [key: string]: number } = {
          January: 1,
          February: 2,
          March: 3,
          April: 4,
          May: 5,
          June: 6,
          July: 7,
          August: 8,
          September: 9,
          October: 10,
          November: 11,
          December: 12,
      };
  
      return monthMap[monthName] ?? -1; 
  };

  cardDateFormat(dateString:string) {
    const date = new Date(dateString)
  
    const day = String(date.getDate()).padStart(2, '0')
    const weekday = date.toLocaleDateString('en-US', { weekday: 'short' })
    const month = date.toLocaleDateString('en-US', { month: 'short' })
    const hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0')
  
    const period = hours >= 12 ? 'PM' : 'AM'
    const formattedHours = hours % 12 || 12
  
    const formatedDateAndTime = `${weekday}, ${day} | ${formattedHours}:${minutes}${period}`
  
    const FormatedDate = `${formattedHours}:${minutes}${period}`
  
    const formatedDateAndMonth = `${weekday}, ${day} ${month}`
  
    return {
      formatedDateAndTime,
      FormatedDate,
      formatedDateAndMonth,
    }
  }

  selectedPreviousMonth = () => {
    this.setState({ selectedMonth: this.state.selectedMonth - 1 },() => {
      this.fetchAyahDashboardTotalBookingDetails();
    })
  }

  selectedNextMonth = () =>{
    this.setState({ selectedMonth: this.state.selectedMonth + 1 },() => {
      this.fetchAyahDashboardTotalBookingDetails();
    })
  }

  selectedYear = (year:number) => {
    this.setState({ showYear: false, selectedYear: year },() => {
      this.fetchAyahDashboardTotalBookingDetails();
    })
  }

  formatBookingDate(dateString: string): string {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = { 
        weekday: 'short', 
        day: '2-digit', 
        month: 'short' 
    };
    const dateParts = date.toLocaleDateString('en-US', options).split(' ');

    const formattedDate = `${dateParts[0]} ${dateParts[1]} ${dateParts[2]}`;

    return formattedDate;
}
  // Customizable Area End

}
