import React from "react";
// Customizable Area Start
import {
  Box,
  Grid,
  InputAdornment,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
} from "@material-ui/core";
import Sidebar from "../../../components/src/Sidebar.web";
import DashboardController, { Props } from "./DashboardController";
import {OverflowXProperty, Position, objectFitType, textAlignType} from  './types'
import { searchIcon, SOSImage }from "./assets"
import DashboardView from "./DashboardView.web"

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: '#0000ff',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: '#0000ff',
        },
        '& input': {
          color: '#292929',
          fontSize: '16px',
          cursor: 'pointer',
          fontFamily: 'SF Pro Text Regular',
          '&::placeholder': {
            opacity: 1,
            fontSize: '16px',
            fontFamily: 'SF Pro Text Regular',
            color: '#808080',
          },
        },
      },
    },
    MuiInputLabel: {
      root: {
        '&.Mui-focused': {
          color: '#0000ff',
        },
      },
    },
  },
});

// Customizable Area End
class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
    // Customizable Area Start
    // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <>
       <ThemeProvider theme={theme}>
        <Grid container style={webStyle.container}>
          <Grid item xs={1}>
            <Sidebar/>
          </Grid>
          <Grid item xs={11} style={webStyle.rightWrapper}>
            {this.state.userType === "patient" && (
              <>
              <Box style={webStyle.dashboardContainer}>
                    <Box style={webStyle.searchBarContainr}>
                      <TextField
                        data-test-id="search"
                        variant="outlined"
                        placeholder="Search Doctors, Nurses, Hospitals..."
                        style={webStyle.searchField}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img
                                src={searchIcon}
                                alt="search"
                                style={webStyle.searchIcons}
                              />
                            </InputAdornment>
                          ),
                          style: {
                            borderRadius: "8px",
                          }
                        }}
                      />
                      <img
                        style={webStyle.sosImage}
                        src={SOSImage}
                      />
                    </Box>
                    <Box>
                      <img
                        style={webStyle.bannerImage}
                        src={this.state.banner}
                      />
                    </Box>
                    <Box style={webStyle.ServiceBtn}>
                      <Box style={webStyle.serviceBox}>
                      <Typography variant="h6" style={webStyle.serviceText}>
                        Services
                      </Typography>
                      </Box>
                      <Box style={webStyle.serviceBoxTwo}>
                      <Typography variant="h6"style={webStyle.Services}>
                        Plans
                      </Typography>
                      <Typography variant="h6" style={webStyle.Services}>
                        View Prescriptions
                        </Typography>
                      <Typography variant="h6" style={webStyle.Services}>
                        Book Lab Tests
                      </Typography>
                      </Box>
                    </Box>
                    <Grid container spacing={2}>
                    {this.state.data.map((item: { image: string | undefined; name:string | undefined}, index: React.Key | undefined) => (
                          <Grid item xl={4} lg={4} md={4} sm={6} xs={12} key={index}>
                        <Box data-test-id={`clickToService${index}`} style={{
                          ...webStyle.serviceContainer, backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(${item.image})`
                        }} onClick={()=>this.openService(item.name)}>
                          <Typography style={webStyle.serviceName}>{item.name}</Typography>
                        </Box>
                          </Grid>
                        ))}
                    </Grid>                 
              </Box>
              </>
            )}
            {this.state.userType === "healthcare_personnel" && (
            <DashboardView id={""} navigation={undefined}/>
            )}

            {this.state.userType !== "patient" && this.state.userType !== "healthcare_personnel" && (
              <p style={webStyle.textContainer}>Dashboard coming soon...</p>
            )}
            
          </Grid>
        </Grid>
      </ThemeProvider>
      </>
      // Customizable Area End
    );
  }
}

export default Dashboard;

// Customizable Area Start
  const webStyle = {
    dashboardContainer:{
      margin:"40px 50px",
      width:"65%"
    },
    searchBarContainr: {
      display:"flex",
      justifyContent:"space-between"
    },
    searchField: {
      width: "85%",
      margin: "15px 0px",
      "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: "8px !important",
        border: "1px solid #808080"
      },
    "& input": {
      paddingLeft:"8px",
      paddingTop:"16px",
      fontSize: "16px !important",
      color: "#292929 !important",
      fontFamily: "SF Pro Text Regular !important",
      "&::placeholder": {
        opacity: 1,
        color: "#808080 !important",
        fontSize: "16px !important",
        fontFamily: "SF Pro Text Regular !important",
      }
    },
    },
    searchIcons:{
       width: "24px", 
       objectFit: "cover" as objectFitType, 
       height: "24px", 
       display: "flex", 
       justifyContent: "center", 
       margin: "0px auto" 
    },
    sosImage: {
      width: "100px",
      height: "100px",
      cursor:"pointer",
    },
    container: {
      height: "100%",
      minHeight:"100vh",
      overflowX: "hidden" as OverflowXProperty
    },
    rightWrapper:{
      height: "auto",
    },
    textContainer:{
      display:"flex",
      margin:"30px"
    },
    bannerImage: {
      width: "100%",
      objectFit: "cover" as objectFitType,
      display: "block",
      borderRadius:"8px",
      margin:"5% 0% 10%"
    },
    serviceText:{
      fontSize: "22px",
      color: "#292929",
      caretColor:"transparent",
      fontFamily: "SF Pro Text Bold",
    },
    Services : {
      caretColor:"transparent",
      color: "#3780E0",
      fontSize: "16px",
      fontFamily: "SF Pro Text Semibold",
    },
    ServiceBtn:{
      display:"flex",
      justifyContent:"space-between", 
      width:"100%", 
      marginBottom:"30px",
      cursor:"pointer"
    },
    serviceName: {
      caretColor:"transparent",
      display: 'block', 
      position: 'absolute' as Position, 
      top: '80%', 
      left: '50%', 
      margin: '0 auto', 
      transform: 'translate(-50%,-50%)', 
      fontSize: "18px",
      fontFamily: "SF Pro Text Medium",
      color:"#fff"
    },
    imageText: {
      position: 'absolute' as Position, // Position absolutely within the container
      top: 0, // Align text to the top
      left: 0, // Align text to the left
      width: '100%', // Take full width of container
      padding: '10px', // Adjust padding as needed
      backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background color
      color: '#ffffff', // Text color
      textAlign: 'center' as textAlignType // Center text horizontally
    },
    serviceBox:{
      width:"55%"
    },
    serviceBoxTwo: {
      width:"45%", 
      display:"flex",
      justifyContent:"space-between"
    },
    serviceContainer:{
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
      zIndex: 1, 
      position: 'relative' as Position, 
      borderRadius:"8px", 
      width: '100%', 
      height: '150px', 
      backgroundSize: 'cover', 
      cursor:"pointer"
    }
  };
// Customizable Area End

