import * as React from "react";
// Customizable Area Start
import {
  Box,
  Typography,
  TextField,
  Button,
  FormHelperText,
  Popover,
  Grid,
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Checkbox,
  TableRow,
  InputAdornment,
  SvgIcon,
  Dialog
} from '@material-ui/core';
import { styles } from "./styles/PhoneNumberRegistration";
import EmailAccountRegistrationController, { Props } from "./EmailAccountRegistrationController";
import { homecureTransparent, ImageIcons, searchIcon } from "./assets";
import CloseIcon from '@material-ui/icons/Close';
import LocationView from "../../location/src/LocationView.web";

// Customizable Area End

export class HospitalRegisterDetails extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    try {
      await Promise.all([this.getBedsList(), this.getDepartmentsList(), this.getInsurancesList()]);
    } catch (errors) {
      this.showAlert("Error fetching data:", "Something went wrong");
    }
  }

  errorMessage = (error: string | undefined, inputError: any) => {
    return (error ? <FormHelperText className={inputError}>{error}</FormHelperText> : null)
  };


  openBedsPopover = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    this.setState({ bedsTypeAnchorEl: event.currentTarget, isBedsPopoverOpen: true });
  };

  closeBedsPopover = () => {
    this.setState({ isBedsPopoverOpen: false });
  };

  selectBedsType = async () => {
    let hasError = false;
    const updatedBedsList = await this.state.bedsList.map((beds: any) => {
      if (this.state.selectedBeds.includes(beds.id) && this.state.selectedBedsName.length > 0) {
        const count = beds.attributes.count;
        let price = beds.attributes.price;
  
        if (price && price.includes('.') && price.split('.')[1] === '') {
          price += '0'; 
        }
  
        if (!count || !price) {
          hasError = true;
        } else {
          hasError = false;
        }
  
        return {
          ...beds,
          attributes: {
            ...beds.attributes,
            price: price,
          },
        };
      }
      return beds;
    });

    this.setState(
      (prevState) => ({
        isBedsPopoverOpen: !prevState.isBedsPopoverOpen,
        selectedBeds: prevState.selectedBedsName,
        bedsList: updatedBedsList,
        hospitalErrors: {
        ...prevState.hospitalErrors,
          bedsType:  hasError ? "Please enter count and price for selected bed types." : ""
        }
      }))
  };
  
  selectedBeds = () => {
    const errorMessage = 'Please enter count and price for selected bed types.';
    let hasError = false;
  
    const updatedBedsList = this.state.bedsList.map((bedData: any) => {
      if (this.state.selectedBeds.includes(bedData.id)) {
        const price = bedData.attributes.price;
        const count = bedData.attributes.count;

        if (!price || !count) {
          hasError = true;
        }
        return {
          ...bedData,
          dirty: false, 
        };
      }
      return bedData;
    });
  
    if (hasError) {
      this.setState(prevState => ({
        hospitalErrors: {
          ...prevState.hospitalErrors,
          bedsType: errorMessage
        }
      }));
      return;
    }
  
    const selectedBeds = updatedBedsList.filter((beds: any) => this.state.selectedBeds.includes(beds.id)).map((beds: any) => beds.attributes.name);
  
    this.setState(prevState => ({
      bedsType: selectedBeds.join(', '),
      selectedBedsName: this.state.selectedBeds,
      bedsList: updatedBedsList,
      hospitalErrors: {
        ...prevState.hospitalErrors,
        bedsType: ''
      },
      isBedsPopoverOpen: false,
    }));
  };
  

  openDeparmentsPopover = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    this.setState({ departsTypeAnchorEl: event.currentTarget, isDepartsPopoverOpen: true });
  };

  closeDepartmentsPopover = () => {
    this.setState({ isDepartsPopoverOpen: false });
  };

  selectDepartmentsType = () => {
    this.setState((prevState) => ({
      isDepartsPopoverOpen: !prevState.isDepartsPopoverOpen,
      selectedDeparts: this.state.selectedDepartsName
    }));
  };

  openInsurancesPopover = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    this.setState({ insuranceTypeAnchorEl: event.currentTarget, isInsurancePopoverOpen: true, insuranceSearch: "" });
  };

  closeInsurancessPopover = () => {
    this.setState({ isInsurancePopoverOpen: false });
  };

  selectInsurancesType = () => {
    this.setState((prevState) => ({
      isInsurancePopoverOpen: !prevState.isInsurancePopoverOpen,
      selectedInsurance:this.state.selectedInsurancesName
    }));
  };

  getDepartRowStyles = (index: number, idDepartmentSelected: boolean) => {
    return {
      padding: "5px 0px",
      borderBottom: index === this.state.departmentsList.length - 1 ? "none" : "1px solid #ddd",
      fontSize: "16px",
      caretColor:"transparent",
      fontFamily:idDepartmentSelected ? 'SF Pro Text Medium' : 'SF Pro Text Regular'
    };
  };

  getInsuranceRowStyles = (index: number, idInsuranceSelected: boolean) => {
    return {
      padding: "5px 0px",
      borderBottom: index === this.state.insuranceList.length - 1 ? "none" : "1px solid #ddd",
      fontSize: "15px",
      caretColor:"transparent",
      fontFamily:idInsuranceSelected ? 'SF Pro Text Medium' : 'SF Pro Text Regular'
    };
  };

  getCheckboxStyles = (index: number, idInsuranceSelected: boolean) => {
    return {
      padding: "5px 0px",
      fontSize: "15px",
      borderBottom: index === this.state.insuranceList.length - 1 ? "none" : "1px solid #ddd",
      fontWeight: idInsuranceSelected ? 600 : 500,
      width:"14px",
    };
  };

  getBedsTableCellStyles = (index: number, isBedTypeCell: boolean, type: string, idBedSelected: boolean) => {
    const commonStyles = {
      padding: "2px 0px",
      borderBottom: index === this.state.bedsList.length - 1 ? "none" : "1px solid #ddd",
      fontSize: "16px",
      maxHeight: "100px",
      fontFamily:"SF Pro Text Medium"
    };

    if (isBedTypeCell && type === "count") {
      return {
        ...commonStyles,
        background: "#e6e5e5",
        color: idBedSelected ? "#4480c9" : "#77A6E1",
        width: "50px !important"
      };
    }

    if (!isBedTypeCell && type === "price") {
      return {
        ...commonStyles,
        padding: "2px 5px !important",
        color: idBedSelected ? "#4480c9" : "#77A6E1"
      };
    }

    if (isBedTypeCell && type === "name") {
      return {
        ...commonStyles,
        padding: "2px 10px !important",
        background: "#eeeeee",
        color: idBedSelected ? "#292929" : "#b7aeae",
        fontWeight: idBedSelected ? 600 : 500
      };
    }

    return {
      ...commonStyles,
      width: "50px !important"
    }
  };

  renderOverlay = (isOpen: boolean, closeHandler: () => void, overlay: any) => {
    if (isOpen) {
      return (
        <Box className={overlay} style={{ position: "fixed" }} onClick={closeHandler} />
      );
    }
    return null;
  };

  getClassName = (classes: any, error: string | undefined) => {
    const { isUploadDoc } = this.state;
    if (isUploadDoc) return error ? classes.registrationTextFieldError : classes.registrationTextField
    return (error ? classes.errorHospitalRegisterInputFields : classes.registerHospitalInputFields)
  }

  getPlaceholder = () => {
    const { isUploadDoc } = this.state;
    if (!isUploadDoc) return "Upload registration certificate";
    return "";
  }

  getInputProps = (classes: any) => {
    const { isUploadDoc, image } = this.state;
    if (isUploadDoc) {
      return (
        <img
          className={classes.uploadImage}
          src={image}
          alt="Preview"
          style={{ objectFit: "cover" }}
        />
      );
    }
    return null;
  };

  showErrorField = (error: string | undefined, classes: any) => {
    return (error ? classes.errorRegisterInputFields : classes.registerInputFields)
  };

  showSelectErrorField = (error: string | undefined, classes: any) => {
    return (error ? classes.errorHospitalRegisterInputFields : classes.registerHospitalInputFields)
  };

  hospitalErroMessage = (classes:any, state:string) => {
    return (
        <Dialog open={!!state}>
          <>
            <Box data-test-id="hospitalErroMessage" className={classes.customContainer}>
              <Box  style={{ padding: "0px 10px" }}>
                <p className={classes.heading}>Error</p>
                <p className={classes.paragraph}>{state}</p>
                <Button className={ classes.button }  onClick={this.closePopup}>OK</Button>
              </Box>
            </Box>
          </>
        </Dialog>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const {
      hospitalErrors,
      showGoogleMap,
      bedsList,
      isBedsPopoverOpen,
      bedsTypeAnchorEl,
      departsTypeAnchorEl,
      insuranceTypeAnchorEl,
      departmentsList,
      insuranceList,
      isDepartsPopoverOpen,
      isInsurancePopoverOpen
    } = this.state;

    // Customizable Area End

    return (
      // Customizable Area Start
      <>
        {!showGoogleMap ? (
          <>
            <Grid container>
              <Grid item xl={4} md={4} sm={12} xs={12} className={`${classes.gridContainer} ${classes.fixedGrid}`} style={{ position: "sticky" }}>
                <Box
                  data-test-id="hospital"
                  sx={{
                    height: "100%"
                  }}
                  className={classes.container}
                  component="div"

                >
                  <Box data-test-id="homeCureLogosContainer" className={classes.homeCureLogosContainer}>
                    <img
                      className={classes.homecureLogos}
                      src={homecureTransparent}
                      style={{ objectFit: "contain" }}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xl={8} md={8} sm={12} xs={12} className={classes.gridContainer}>
                <Box className={classes.patientRegistrationContainer}>
                  <Box className={classes.patientLabelContainer}>
                    <Typography variant="h6" className={classes.registrationHeading}>
                      Register
                    </Typography>
                    <Box data-test-id="setLocation" style={{ width: "50%", textAlign: "right", textDecoration: "none" }}
                      onClick={this.openSetLocationPage}
                    >
                      <Typography variant="h6" data-test-id="setLocation" className={classes.setLocation} onClick={this.openSetLocationPage}>
                        Set Location
                      </Typography>
                    </Box>
                  </Box>
                  <Typography variant="h6" className={classes.patientHeading}>
                    Let's get you all set up so you can verify and manage your account.
                  </Typography>
                  <Grid container spacing={4}>
                    <Grid item xl={6} md={6} sm={6} xs={12}>
                      <Box>
                        <Typography variant="h6" className={classes.registerLabel} style={{ textAlign: "left" }}>
                          Hospital Name<span className={classes.required}>*</span>
                        </Typography>
                        <TextField
                          variant="outlined"
                          data-test-id="hospitalName"
                          className={this.showErrorField(hospitalErrors.hospitalName, classes)}
                          placeholder="Enter your hospital name"
                          value={this.state.hospitalName}
                          onChange={(event) => this.handleHospitalChange("hospitalName",event.target.value.trimStart())}
                        />
                        {this.errorMessage(hospitalErrors.hospitalName, classes.inputErrors)}
                      </Box>
                      <Box>
                        <Typography variant="h6" className={classes.registerLabel} style={{ textAlign: "left" }}>
                          City<span className={classes.required}>*</span>
                        </Typography>
                        <TextField
                          variant="outlined"
                          data-test-id="hospitalCity"
                          className={this.showErrorField(hospitalErrors.city, classes)}
                          placeholder="Enter city"
                          value={this.state.city}
                          onChange={(event) => this.handleHospitalChange("city",event.target.value.trimStart())}
                        />
                        {this.errorMessage(hospitalErrors.city, classes.inputErrors)}
                      </Box>
                      <Box>
                        <Typography variant="h6" className={classes.registerLabel} style={{ textAlign: "left" }}>
                          Pin Code<span className={classes.required}>*</span>
                        </Typography>
                        <TextField
                          variant="outlined"
                          data-test-id="hospitalPincode"
                          className={this.showErrorField(hospitalErrors.pincode, classes)}
                          placeholder="6 digit Pin Code"
                          InputProps={{ inputProps: { maxLength: 6 } }}
                          value={this.state.pincode}
                          onChange={(event) => this.handleHospitalChange("pincode",event.target.value.trimStart())}
                        />
                        {this.errorMessage(hospitalErrors.pincode, classes.inputErrors)}
                      </Box>
                      <Box>
                        <Typography variant="h6" className={classes.registerLabel} style={{ textAlign: "left" }}>
                          Select Types of Beds Available<span className={classes.required}>*</span>
                        </Typography>
                        <TextField
                          variant="outlined"
                          data-test-id="bedsType"
                          style={{caretColor:"white !important"}}
                          className={this.showSelectErrorField(hospitalErrors.bedsType, classes)}
                          placeholder="Select types of beds"
                          value={this.state.bedsType}
                          onClick={this.openBedsPopover}
                        />
                        {!this.state.isBedsPopoverOpen && this.errorMessage(hospitalErrors.bedsType, classes.inputErrors)}
                        {this.renderOverlay(isBedsPopoverOpen, this.closeBedsPopover, classes.overlay)}
                        <Popover
                          className={classes.PaperPropsClass}
                          data-test-id="bedsPopover"
                          open={isBedsPopoverOpen}
                          anchorEl={bedsTypeAnchorEl}
                          anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'center',
                          }}
                          transformOrigin={{
                            vertical: 'center',
                            horizontal: 'center',
                          }}
                        >
                          <Box className={classes.popoverContainer}>
                           {this.errorMessage(hospitalErrors.bedsType, classes.hospitalBedError)}
                            <Box className={classes.selecthospitalContainer}>
                              <Typography variant="h6" className={classes.bedsHeading}>
                                Add Beds
                              </Typography>
                              <Typography variant="h6" data-test-id="closeBedsPopover" className={classes.closeBtn} onClick={this.selectBedsType}>
                                <CloseIcon/>
                              </Typography>
                            </Box>
                            <Box className={classes.tablePopoverContainer}>
                              <TableContainer className={classes.tableContainer}>
                                <Table>
                                  <TableBody>
                                    {bedsList?.map((beds: { id: React.Key | undefined; attributes: { name: string; count: any; price: any; }; }, index: number) => (
                                      <TableRow key={beds.id}>
                                        <TableCell style={this.getBedsTableCellStyles(index, false, "checkbox", this.state.selectedBeds.includes(beds.id))}>
                                          <Checkbox
                                            data-test-id="selectBeds"
                                            className={classes.checkbox}
                                            checked={this.state.selectedBeds.includes(beds.id)}
                                            onChange={() => this.handleBedsCheckboxChange(beds.id)}
                                            icon={<SvgIcon width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#014866"/></SvgIcon>}
                                            checkedIcon={<SvgIcon width="16" height="16"fill="none" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#014866" />
                                            <path d="M3.75 7.99992L6.58 10.8299L12.25 5.16992" stroke="#014866" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                          </SvgIcon>}
                                          />
                                        </TableCell>
                                        <TableCell style={this.getBedsTableCellStyles(index, true, "name", this.state.selectedBeds.includes(beds.id))}>
                                          {beds.attributes?.name}
                                        </TableCell>
                                        <TableCell style={this.getBedsTableCellStyles(index, true, "count", this.state.selectedBeds.includes(beds.id))}>
                                          <input
                                            style={{ width: "55px", textAlign: "center", border: "none", backgroundColor: "transparent", color: this.state.selectedBeds.includes(beds.id) ? "#3780E0" : "#AFCEF6" }}
                                            placeholder="51"
                                            className={classes.placeholderClass}
                                            value={(this.state.selectedBeds.includes(beds.id)&& beds.attributes.count) || ''}
                                            onChange={(event) => this.handleCountChange(beds.id, event.target.value)}
                                            data-test-id="selectedBedsCount"
                                          />
                                        </TableCell>
                                        <TableCell style={this.getBedsTableCellStyles(index, false, "price", this.state.selectedBeds.includes(beds.id))}>
                                          <input
                                            style={{
                                              width: "60px",
                                              border: "none",
                                              color: this.state.selectedBeds.includes(beds.id) ? "#3780E0" : "rgb(175, 206, 246)",
                                              backgroundColor: "transparent",
                                            }}
                                            className={classes.placeholderClass}
                                            data-test-id="selectedBedsprice"
                                            placeholder="₹ 2080"
                                            value={(this.state.selectedBeds.includes(beds.id)&& beds.attributes.price) ? `₹ ${beds.attributes.price}` : ''}
                                            onChange={(event) => {
                                              // Remove the rupees sign before updating the state
                                              const inputValue = event.target.value.replace('₹ ', '');
                                              this.handlePriceChange(beds.id, inputValue);
                                            }}
                                          />
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Box>
                            <Box className={classes.selecthospitalContainer}>
                              <Button
                                data-test-id="saveBeds"
                                variant="contained"
                                color="primary"
                                className={classes.selectButtons}
                                style={{ textTransform: "capitalize" }}
                                onClick={this.handleSelectedBeds}
                              >
                                Select
                              </Button>
                            </Box>
                          </Box>
                        </Popover>
                      </Box>
                      <Box>
                        <Typography variant="h6" className={classes.registerLabel} style={{ textAlign: "left" }}>
                          Registration Certificate<span className={classes.required}>*</span>
                        </Typography>
                        {!this.state.isUploadDoc &&
                          <TextField
                            id="uploadDoc"
                            variant="outlined"
                            data-test-id="uploadDoc"
                            style={{caretColor:"white !important"}}
                            className={this.getClassName(classes, hospitalErrors.uploadDoc)}
                            placeholder={this.getPlaceholder()}
                            value=""
                            InputProps={{
                              startAdornment: (<InputAdornment position="start">
                                <img
                                  src={ImageIcons}
                                  alt="Preview"
                                  className={classes.uploadIcon}
                                />
                              </InputAdornment>)
                            }}
                            onClick={this.handleButtonClick}
                          />}
                        <Box onClick={this.handleButtonClick}>
                          <Typography variant="h6" style={{caretColor:"transparent", textAlign: "left" }}>
                            {this.getInputProps(classes)}
                          </Typography>
                        </Box>
                        {this.errorMessage(hospitalErrors.uploadDoc, classes.inputErrors)}
                        <input
                          type="file"
                          accept="image/*"
                          ref={this.fileInputRef}
                          style={{ display: "none" }}
                          onChange={this.handleUploadDoc}
                        />
                      </Box>
                      <Button
                        variant="contained"
                        data-test-id="submit"
                        color="primary"
                        className={classes.registerHospitalDesktopButtons}
                        style={{ textTransform: "capitalize" }}
                        onClick={this.handleHospitalDetailsSubmit}
                      >
                        Submit
                      </Button>
                    </Grid>
                    <Grid item xl={6} md={6} sm={6} xs={12} className={classes.mobileGrid}>
                      <Box>
                        <Typography variant="h6" className={classes.registerLabel} style={{ textAlign: "left" }}>
                          Address<span className={classes.required}>*</span>
                        </Typography>
                        <TextField
                          variant="outlined"
                          data-test-id="hospitalAddress"
                          className={this.showErrorField(hospitalErrors.address, classes)}
                          placeholder="Select address"
                          value={this.state.address}
                          onChange={(event) => this.handleHospitalChange("address",event.target.value.trimStart())}
                        />
                        {this.errorMessage(hospitalErrors.address, classes.inputErrors)}
                      </Box>
                      <Box>
                        <Typography variant="h6" className={classes.registerLabel} style={{ textAlign: "left" }}>
                          District<span className={classes.required}>*</span>
                        </Typography>
                        <TextField
                          variant="outlined"
                          data-test-id="hospitalDistrict"
                          className={this.showErrorField(hospitalErrors.district, classes)}
                          placeholder="Enter district"
                          value={this.state.district}
                          onChange={(event) => this.handleHospitalChange("district",event.target.value.trimStart())}
                        />
                        {this.errorMessage(hospitalErrors.district, classes.inputErrors)}
                      </Box>
                      <Box>
                        <Typography variant="h6" className={classes.registerLabel} style={{ textAlign: "left" }}>
                          Registration No. of Hospital<span className={classes.required}>*</span>
                        </Typography>
                        <TextField
                          variant="outlined"
                          data-test-id="hospitalRegistrationNumber"
                          className={this.showErrorField(hospitalErrors.registrationNumber, classes)}
                          placeholder="Enter registration no."
                          value={this.state.registrationNumber}
                          onChange={(event) => this.handleHospitalChange("registrationNumber",event.target.value.trimStart())}
                        />
                        {this.errorMessage(hospitalErrors.registrationNumber, classes.inputErrors)}
                      </Box>
                      <Box>
                        <Typography variant="h6" className={classes.registerLabel} style={{ textAlign: "left" }}>
                          Select Departments<span className={classes.required}>*</span>
                        </Typography>
                        <TextField
                          variant="outlined"
                          data-test-id="departmentsType"
                          style={{caretColor:"white !important"}}
                          className={this.showSelectErrorField(hospitalErrors.departmentType, classes)}
                          placeholder="Select department here"
                          value={this.state.departmentType}
                          onClick={this.openDeparmentsPopover}
                        />
                        {this.errorMessage(hospitalErrors.departmentType, classes.inputErrors)}
                        {this.renderOverlay(isDepartsPopoverOpen, this.closeDepartmentsPopover, classes.overlay)}
                        <Popover
                          data-test-id="departsPopover"
                          open={isDepartsPopoverOpen}
                          anchorEl={departsTypeAnchorEl}
                          className={classes.PaperPropsClass}
                          anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'center',
                          }}
                          transformOrigin={{
                            vertical: 'center',
                            horizontal: 'center',
                          }}
                        >
                          <Box className={classes.popoverContainer}>
                            <Box className={classes.selectPopoverContainer}>
                              <Typography variant="h6" className={classes.departmentsHeading}>
                                Add Department
                              </Typography>
                              <Typography variant="h6" data-test-id="closeDepartsPopover" className={classes.closePopoverBtn} onClick={this.selectDepartmentsType}>
                                <CloseIcon/>
                              </Typography>
                            </Box>
                            <Box className={classes.tablePopoverContainer}>
                              <TableContainer className={classes.tablecontainer}>
                                <Table>
                                  <TableBody>
                                    {departmentsList?.map((department: { id: React.Key | undefined; attributes: { name: string }; }, index: number) => (
                                      <TableRow key={department.id}>
                                        <TableCell style={this.getDepartRowStyles(index, false)}>
                                          <Checkbox
                                            data-test-id="selectDeparts"
                                            className={classes.checkbox}
                                            checked={this.state.selectedDeparts.includes(department.id)}
                                            onChange={() => this.handleDepartmentsCheckboxChange(department.id)}
                                            icon={<SvgIcon width="16" height="16" fill="none" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#014866"/>
                                            </SvgIcon>}
                                            checkedIcon={<SvgIcon width="16" height="16"fill="none" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#014866" />
                                            <path d="M3.75 7.99992L6.58 10.8299L12.25 5.16992" stroke="#014866" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                          </SvgIcon>}
                                          />
                                        </TableCell>
                                        <TableCell style={this.getDepartRowStyles(index, this.state.selectedDeparts.includes(department.id))}>{department?.attributes?.name}</TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Box>
                            <Box className={classes.selectPopoverContainer}>
                              <Button
                                data-test-id="saveDepartments"
                                variant="contained"
                                color="primary"
                                className={classes.selectButtons}
                                style={{ textTransform: "capitalize" }}
                                onClick={this.selectedDepartemnts}
                              >
                                Select
                              </Button>
                            </Box>
                          </Box>
                        </Popover>
                      </Box>
                      <Box>
                        <Typography variant="h6" className={classes.registerLabel} style={{caretColor:"transparent",textAlign: "left" }}>
                          Select Insurances<span className={classes.required}>*</span>
                        </Typography>
                        <TextField
                          variant="outlined"
                          data-test-id="insuranceType"
                          style={{caretColor:"white !important"}}
                          className={this.showSelectErrorField(hospitalErrors.insuranceType, classes)}
                          placeholder="Select insurance here"
                          value={this.state.insuranceType}
                          onClick={this.openInsurancesPopover}
                        />
                        {this.errorMessage(hospitalErrors.insuranceType, classes.inputErrors)}
                        {this.renderOverlay(isInsurancePopoverOpen, this.closeInsurancessPopover, classes.overlay)}
                        <Popover
                          className={classes.PaperPropsClass}
                          data-test-id="inurancesPopover"
                          open={isInsurancePopoverOpen}
                          anchorEl={insuranceTypeAnchorEl}
                          anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'center',
                          }}
                          transformOrigin={{
                            vertical: 'center',
                            horizontal: 'center',
                          }}
                        >
                          <Box className={classes.popoverContainer}>
                            <Box className={classes.selectPopoverContainer}>
                              <Typography variant="h6" className={classes.insuranceHeading}>
                                Add Insurance
                              </Typography>
                              <Typography variant="h6" data-test-id="closeInsurancePopover" className={classes.closePopoverBtn} onClick={this.selectInsurancesType}>
                                <CloseIcon/>
                              </Typography>
                            </Box>
                            <Box className={classes.selectInsurance}>
                              <TextField
                                data-test-id="searchInsurance"
                                variant="outlined"
                                placeholder="Search insurance here.."
                                className={classes.insuranceSearchField}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <img
                                        src={searchIcon}
                                        alt="search"
                                        style={{ width: "25px", height: "25px", objectFit: "cover", display: "flex", justifyContent: "center", margin: "0px auto" }}
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                                onChange={(event) => this.handleInsuranceSearch(event.target.value.trimStart())}
                              />
                            </Box>
                            <Box className={classes.tablePopoverContainer}>
                              <TableContainer className={classes.tablecontainer}>
                                <Table>
                                  <TableBody>
                                    {insuranceList?.filter((insurance: any) => insurance.attributes.name.toLowerCase().includes(this.state.insuranceSearch.toLowerCase()))
                                      .map((insurance: { id: React.Key | undefined; attributes: { name: string }; }, index: number) => (
                                        <TableRow key={index}>
                                          <TableCell style={this.getCheckboxStyles(index, false)}>
                                            <Checkbox
                                              data-test-id="selectInsurance"
                                              className={classes.checkbox}
                                              checked={this.state.selectedInsurance.includes(insurance.id)}
                                              onChange={() => this.handleInsuranceCheckboxChange(insurance.id)}
                                              icon={<SvgIcon width="16" height="16" fill="none" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                                              <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#014866"/>
                                              </SvgIcon>}
                                              checkedIcon={<SvgIcon width="16" height="16"fill="none" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                                              <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#014866" />
                                              <path d="M3.75 7.99992L6.58 10.8299L12.25 5.16992" stroke="#014866" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            </SvgIcon>}
                                            />
                                          </TableCell>
                                          <TableCell style={this.getInsuranceRowStyles(index, this.state.selectedInsurance.includes(insurance.id))}>{insurance?.attributes?.name}</TableCell>
                                        </TableRow>
                                      ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Box>
                            <Box className={classes.selectPopoverContainer}>
                              <Button
                                data-test-id="saveInsurance"
                                variant="contained"
                                color="primary"
                                className={classes.selectButtons}
                                style={{ textTransform: "capitalize" }}
                                onClick={this.selectedInsurance}
                              >
                                Select
                              </Button>
                            </Box>
                          </Box>
                        </Popover>
                      </Box>
                      <Button
                        data-test-id="submit"
                        variant="contained"
                        color="primary"
                        className={classes.registerMobileButtons}
                        style={{ textTransform: "capitalize" }}
                        onClick={this.handleHospitalDetailsSubmit}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Grid >
            </Grid >
            {this.hospitalErroMessage(classes,this.state.hospitalErrorMessage)}
          </>
        ) : (<>
            <LocationView id="" navigation="undefined" setAddress={this.setAddressFromChild} closeSetLocationPage={this.closeSetLocationPage} setAddressFieldsByMap={this.setAddressFieldsByMap} showGoogleMap={this.state.showGoogleMap} longitude={this.state.longitude} latitude={this.state.latitude} />
        </>)
        }
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(styles)(HospitalRegisterDetails);
// Customizable Area End
