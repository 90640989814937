import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment from "moment";
import { getStorageData } from "../../../framework/src/Utilities";

type DaySelection = {
  day: string;
  selected: boolean;
};

type Shift = {
  title: string;
  shift_title: string;
  start_time: string;
  end_time: string;
  days: DaySelection[];
};

export const dayNames = {
  "M": "monday",
  "T": "tuesday",
  "W": "wednesday",
  "TH": "thursday",
  "F": "friday",
  "S": "saturday",
  "SUN": "sunday",
};

export const shiftsData = [
  {
      idx: 0,
      title: 'Shift 01',
      shift_title: 'shift_1',
      start_time: '9:00 AM',
      end_time: '3:00 PM',
      checked: true,
      days: [
          { day: "S", dayFull: dayNames.SUN, selected: false },
          { day: "M", dayFull: dayNames.M, selected: true },
          { day: "T", dayFull: dayNames.T, selected: false },
          { day: "W", dayFull: dayNames.W, selected: true },
          { day: "T", dayFull: dayNames.TH, selected: false },
          { day: "F", dayFull: dayNames.F, selected: false },
          { day: "S", dayFull: dayNames.S, selected: false },

      ],
  },
  {
      idx: 1,
      title: 'Shift 02',
      shift_title: 'shift_2',
      start_time: '3:00 PM',
      end_time: '9:00 PM',
      checked: true,
      days: [
        { day: "S", dayFull: dayNames.SUN, selected: true },
        { day: "M", dayFull: dayNames.M, selected: false },
        { day: "T", dayFull: dayNames.T, selected: false },
        { day: "W", dayFull: dayNames.W, selected: true },
        { day: "T", dayFull: dayNames.TH, selected: false },
        { day: "F", dayFull: dayNames.F, selected: true },
        { day: "S", dayFull: dayNames.S, selected: true },

      ],
  },
  {
      idx: 2,
      title: 'Shift 03',
      shift_title: 'shift_3',
      start_time: '9:00 PM',
      end_time: '9:00 AM',
      checked: false,
      days: [
        { day: "S", dayFull: dayNames.SUN, selected: false },
        { day: "M", dayFull: dayNames.M, selected: true },
        { day: "T", dayFull: dayNames.T, selected: true },
        { day: "W", dayFull: dayNames.W, selected: false },
        { day: "T", dayFull: dayNames.TH, selected: false },
        { day: "F", dayFull: dayNames.F, selected: true },
        { day: "S", dayFull: dayNames.S, selected: true },
      ],
  },
];

export const ayahShiftsData = [
  {
      title: 'Shift 01',
      shift_title: 'shift_1',
      start_time: '8:00 AM',
      end_time: '8:00 PM',
      days: [
          { day: "S", dayFull: dayNames.SUN, selected: true },
          { day: "M", dayFull: dayNames.M, selected: true },
          { day: "T", dayFull: dayNames.T, selected: false },
          { day: "W", dayFull: dayNames.W, selected: false },
          { day: "T", dayFull: dayNames.TH, selected: false },
          { day: "F", dayFull: dayNames.F, selected: true },
          { day: "S", dayFull: dayNames.S, selected: true },
      ],
  },
  {
      title: 'Shift 02',
      shift_title: 'shift_2',
      start_time: '8:00 PM',
      end_time: '8:00 AM',
      days: [
          { day: "S", dayFull: dayNames.SUN, selected: true },
          { day: "M", dayFull: dayNames.M, selected: false },
          { day: "T", dayFull: dayNames.T, selected: true },
          { day: "W", dayFull: dayNames.W, selected: true },
          { day: "T", dayFull: dayNames.TH, selected: true },
          { day: "F", dayFull: dayNames.F, selected: true },
          { day: "S", dayFull: dayNames.S, selected: true },
      ],
  },
];


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?:any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedTime: number;
  selectedDate: Date | string | null;
  timeSlots: object[];
  serviceProviderId: string;
  serviceProviderSchedule: any;
  details: any;
  token: any;
  checked: any;
  leaveToggle: boolean;
  kind: string;
  isEdit: boolean;
  payloadData: unknown;
  errorMessage: string;
  successMessage: string;
  disableConfirm: boolean;
  loading: boolean;
  date: string;
  selectedSlots: string[];
  showSlot: boolean;
  showSlotCancelPopup: boolean;
  cancelSlot: string[];
  cancelAllSlot: boolean;
  removeSlot: boolean;
  showSuccessMsg: boolean;
  slot:string;
  isLeave: boolean;
  isLeaveConfirmation: boolean;
  avaibilityPopup: boolean;
  isFullDay: boolean;
  isRecurrence: boolean;
  selectedType:string;
  isOpen: boolean;
  endType: string;
  recurringType:string;
  isMonthly: boolean;
  selectedMonthType:string;
  isRepeat:boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class SchedulingController extends BlockComponent<Props, S, SS> {
  
  // Customizable Area Start
  serviceProviderDetailApiId: any;
  serviceProviderScheduleApiId: any;
  getAvailabilityList: string = "";
  UpdateShiftAvailability: string = "";
  UpdateLeave: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      selectedTime: 0,
      selectedDate: moment().format("dddd, D MMMM YYYY"),
      timeSlots: [],
      serviceProviderId: "",
      serviceProviderSchedule: "",
      details: {},
      token: null,
      successMessage:"",
      errorMessage:"",
      checked: shiftsData.map((shift: any) => ({
        idx: shift.idx,
        checked: true,
        start_time: shift.start_time,
        end_time: shift.end_time,
        shift_title: shift.shift_title,
        days: shift.days.map((day: { day: string; dayFull: string; selected: boolean }) => ({ day: day.day, dayFull: day.dayFull, selected: false }))
      })),
      kind: "nurse",
      leaveToggle: false,
      isEdit: false,
      payloadData: [],
      disableConfirm: true,
      loading: false,
      date: "",
      selectedSlots:[],
      showSlot: false,
      showSlotCancelPopup: false,
      cancelSlot:[],
      cancelAllSlot:false,
      removeSlot:false,
      showSuccessMsg: false,
      slot:"",
      isLeave: false,
      isLeaveConfirmation: false,
      avaibilityPopup:false,
      isFullDay:false,
      isRecurrence: false,
      selectedType:"Does not repeat",
      isOpen: false,
      endType: "On",
      recurringType:"Monthly",
      isMonthly:false,
      selectedMonthType:"",
      isRepeat:false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
      this.getServiceProviderDetails({
        setApiCallId: 'serviceProviderScheduleApiId',
        serviceProviderId: this.state.serviceProviderId,
        availableDate: this.state.selectedDate,
        token
      })
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if(responseJson){
        switch (apiRequestCallId) {
          case this.getAvailabilityList :
            this.setInitialAvailabilityData(responseJson);
            break;
          case this.UpdateLeave :
            this.setState({ 
              errorMessage: responseJson.errors ? responseJson.errors.message : "",
              successMessage: responseJson.meta ? responseJson.meta.message : ""
            })
            this.toggleLeave();
            this.GetAvailability();
            break;
          case this.UpdateShiftAvailability :
            this.setState({ 
              loading: false,
              errorMessage: responseJson.errors ? responseJson.errors.message : "",
              successMessage: responseJson.meta ? responseJson.meta.message : ""
            })
            this.GetAvailability();
            break;
        }
      }

      if (responseJson && responseJson.errors) {

        this.setState({ serviceProviderSchedule: [] });

        if (typeof responseJson.errors === 'string') {
          this.showAlert("", responseJson.errors)
        } else {
          this.parseApiErrorResponse(responseJson);
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
      else if (apiRequestCallId != null) {

        if (apiRequestCallId === this.serviceProviderScheduleApiId) {
          this.setState({
            serviceProviderSchedule: responseJson && responseJson.data ? responseJson.data : ''
          });
        }
        if (apiRequestCallId === this.serviceProviderDetailApiId) {
          let navigateVar = new Message(getName(MessageEnum.NavigationCalendarMessage));
          navigateVar.addData(
            getName(MessageEnum.CalendarProviderDetailsResponseMessage),
            responseJson
          );
          this.send(navigateVar);
          this.unsubscribeMessages();
          return;
        }
      }
    } else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      runEngine.debugLog("Availability Message Received", message);
      const serviceProviderIdMessage = message.getData(
        getName(MessageEnum.ServiceProviderIdMessage)
      );
      if (serviceProviderIdMessage) {
        this.setState({
          serviceProviderId: serviceProviderIdMessage
        });
      }

      const CalendarProviderDetailsApiMessage = message.getData(
        getName(MessageEnum.CalendarProviderDetailsApiMessage)
      );
      if (CalendarProviderDetailsApiMessage) {
        this.getServiceProviderDetails({ ...CalendarProviderDetailsApiMessage, setApiCallId: 'serviceProviderDetailApiId' });
        return;

      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getHpUserRole();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }

    this.GetAvailability();
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  getHpUserRole = async() => {
    const hpUserType = await getStorageData("hpUserRole");
    this.setState({ kind: hpUserType.toLowerCase(), checked: (hpUserType.toLowerCase() === "ayah" ? ayahShiftsData : shiftsData).map((shift: any) => ({
      idx: shift.idx,
      checked: true,
      start_time: shift.start_time,
      end_time: shift.end_time,
      shift_title: shift.shift_title,
      days: shift.days.map((day: any) => ({ day: day.day, dayFull: day.dayFull, selected: false }))
    })) })
  }
  GetAvailability = async () => {
    const method = "GET";
    const token = await getStorageData("authToken") as string;
    const header = {
      "Content-Type": 'application/json',
      "token": token
    };
    const getAvailabilityListUrl = "/healthcare_personnel_availabilities";
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAvailabilityList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getAvailabilityListUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  UpdateLeaveAvailability = async () => {
    const method = "POST";
    const token = await getStorageData("authToken") as string;
    const header = {
      "Content-Type": 'application/json',
      "token": token
    };
    let updateLeaveBody = {
      data: {
          attributes: {
             on_leave: !this.state.leaveToggle
          }
      }
  }
    const updateLeaveUrl = "healthcare_personnel_availabilities/edit_leave";
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.UpdateLeave = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      updateLeaveUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(updateLeaveBody)
    )
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  UpdateShiftAvailabilityApi = async () => {
    const method = "POST";
    const token = await getStorageData("authToken") as string;
    const header = {
      "Content-Type": 'application/json',
      "token": token
    };
    this.setState({
      loading: true,
    })
    let httpsBody = {
      data: {
          attributes: {
            availabilities: this.state.checked.map((elem: any) => {
              return {
                ...(this.state.isEdit ? { id: elem.idx } : {}),
                    start_time: elem.start_time,
                    end_time: elem.end_time,
                    monday: elem.days[1].selected,
                    tuesday: elem.days[2].selected,
                    wednesday: elem.days[3].selected,
                    thursday: elem.days[4].selected,
                    friday: elem.days[5].selected,
                    saturday: elem.days[6].selected,
                    sunday: elem.days[0].selected,
                    shift_title: elem.shift_title,
                    is_available: elem.checked
              }
            })
          }
      }
  }
    const updateShiftUrl = this.state.isEdit ? "/healthcare_personnel_availabilities/bulk_edit" : "/healthcare_personnel_availabilities";
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.UpdateShiftAvailability = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      updateShiftUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpsBody)
    )
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  setInitialAvailabilityData = (responseJson: { data: any, meta: any}) => {
    if (responseJson) {
      if (responseJson.data.length > 0) {
        this.setState({
          isEdit: true,
          leaveToggle: responseJson.meta.on_leave,
          disableConfirm: true,
        })
        if (responseJson.meta.on_leave) {
          this.toggleShiftsBasedOnLeave();
        } else {
          this.setState({
            checked: responseJson.data.map((shift: any) => {
              return {
                idx: shift.id,
                checked: shift.attributes.is_available,
                start_time: shift.attributes.start_time,
                end_time: shift.attributes.end_time,
                shift_title: shift.attributes.shift_title,
                days: [
                  { day: "S", dayFull: 'sunday', selected: shift.attributes.sunday },
                  { day: "M", dayFull: 'monday', selected: shift.attributes.monday },
                  { day: "T", dayFull: 'tuesday', selected: shift.attributes.tuesday },
                  { day: "W", dayFull: 'wednesday', selected: shift.attributes.wednesday },
                  { day: "T", dayFull: 'thursday', selected: shift.attributes.thursday },
                  { day: "F", dayFull: 'friday', selected: shift.attributes.friday },
                  { day: "S", dayFull: 'saturday', selected: shift.attributes.saturday },
                ]
              }
            }),
          })
        }
      } else {
        this.setState({
          isEdit: false,
        })
      }
    }
  }

  onSelectDate = (selectedDateStr: string) => {
    const { details } = this.state;
    this.setState({
      selectedDate: selectedDateStr,
      timeSlots: [],
      selectedTime: 0
    });
  };

  handleConfirm = () => {
    this.UpdateShiftAvailabilityApi();
  };

  toggleShiftsCheck = (idx: number, checked: boolean) => {
    if (!this.state.leaveToggle) {
      this.setState((prevState) => {
        const updatedChecked = [...prevState.checked];
        updatedChecked[idx] = { ...updatedChecked[idx], checked };
        return { checked: updatedChecked, disableConfirm: false };
      });
    } else {
      this.setState({ 
        errorMessage: "Please disable the Leave button to proceed with managing shift availabilities.",
      })
    }
  };

  toggleDaySelection = (shiftIndex: number, dayIndex: number) => {
    this.setState((prevState: any) => {
      const updatedChecked = [...prevState.checked];
      if (updatedChecked[shiftIndex].checked && !this.state.leaveToggle) {
        updatedChecked[shiftIndex].days[dayIndex].selected = !updatedChecked[shiftIndex].days[dayIndex].selected;
      } else if (this.state.leaveToggle) {
        this.setState({ 
          errorMessage: `You have enabled "On Leave". To proceed, please disable leave toggle.`,
        }) 
      }
      else {
        this.setState({ 
          errorMessage: `You have disabled this shift. To proceed, please enable this shift.`,
        })
      }
      return { checked: updatedChecked, ...(updatedChecked[shiftIndex].checked && {disableConfirm: false}) };
    });
  };

  toggleLeave = () => {
    if (!this.state.leaveToggle) {
      const updatedChecked = this.state.checked.map((item: any) => ({
        ...item,
        checked: false
      }));
      this.setState({
        checked: updatedChecked
      })
    }

    this.setState({
      leaveToggle: !this.state.leaveToggle,
    });
  };

  toggleShiftsBasedOnLeave = () => {
    if (this.state.leaveToggle) {
      const updatedChecked = this.state.checked.map((item: any) => ({
        ...item,
        checked: false,
        days: item.days.map((day: any) => ({ ...day, selected: false }))
      }));

      this.setState({
        checked: updatedChecked,
        disableConfirm: true,
        errorMessage: '',
      });
    }
  }

  calendarProps = {
    minDate: moment(),
    onSelectDate: (selectedDate: string) => this.onSelectDate(selectedDate)
  };

  async getServiceProviderDetails(dataObj: any) {
    const { setApiCallId, serviceProviderId, availableDate, token } = dataObj;

    const header = {
      "Content-Type": configJSON.applicationJsonApiContentType,
      token,
    };

    this.apiCall({
      setApiCallId,
      header,
      method: configJSON.getApiMethodType,
      endPoint: `${configJSON.serviceProviderAPiEndPoint}?availability_date=${availableDate}&service_provider_id=${serviceProviderId}`,
      body: null
    });
    return true;
  }

  apiCall = async (data: any) => {

    const { setApiCallId, header, endPoint, method, body } = data;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    if (setApiCallId === 'serviceProviderDetailApiId') {
      this.serviceProviderDetailApiId = requestMessage.messageId;
    } else if (setApiCallId === 'serviceProviderScheduleApiId') {
      this.serviceProviderScheduleApiId = requestMessage.messageId;
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    body && requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  unsubscribeMessages = () => {
    runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
  };

  handleDateChange = (date: any) => {
    if (date){
      const options: Intl.DateTimeFormatOptions = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      };
      const formattedDate = date.toLocaleDateString('en-GB', options);

      this.setState({ date: formattedDate });
    }
  }


  handleSlotSelect = (slot: string) => {
    if(this.state.selectedSlots.includes(slot)){
      this.handleShowSlot()
    }else{
      this.setState((prevState) => {
        const updatedSlots = [...prevState.selectedSlots, slot];
        return { selectedSlots: updatedSlots };
      });
    }
  };
  
  handleShowSlot = () => {
    this.setState({showSlot:true})
  };

  handleClose = () => {
    this.setState({showSlot:false,showSlotCancelPopup:false,cancelAllSlot:false,removeSlot:false})
  };

  handelShowSlotCancelPopup = (slot:string) => {
    this.setState({showSlotCancelPopup:true,slot})
  }

  handleCancelBooking = (slot:string) => {
    this.setState((prevState) => {
      const isSelected = prevState.cancelSlot.includes(slot);
      return {
        cancelSlot: isSelected
          ? prevState.cancelSlot.filter((selectedslot:string) => selectedslot !== slot)
          : [...prevState.cancelSlot, slot], 
      };
    });
  };
  
  handelSelectedSlot = () => {
    this.setState({cancelAllSlot:!this.state.cancelAllSlot}) 
  }

  handelCancelSlot = () => {
    this.setState({showSlotCancelPopup:false,cancelAllSlot:false,showSlot:false}) 
  }

  handelRemoveSlot = () =>{
    this.setState({removeSlot:true}) 
  }

  handelCancelAllSlot = () => {
    this.setState({showSlotCancelPopup:false,cancelAllSlot:false,showSlot:false,removeSlot:false}) 
  }

  handelShowSuccessMsg = () => {
    this.setState({showSuccessMsg:!this.state.showSuccessMsg})
  }

  closeSlotCancellation = () => {
    this.setState({showSlotCancelPopup:false,cancelAllSlot:false,showSlot:false,removeSlot:false,showSuccessMsg:false,selectedSlots:[],slot:""}) 
  }

  handelOpenEdit = () => {
    this.setState({isEdit: true}) 
  }

  handelLeave = () => {
    this.setState({isLeave: true,isLeaveConfirmation:true,showSuccessMsg:true}) 
  }

  handelPreviousPage = () => {
    if(this.state.isEdit){
      this.setState({isEdit:false})
    } else {
      this.props.navigation.goBack()
    }
  }

  handelLeaveConfirmation = () => {
    this.setState({isLeaveConfirmation: !this.state.isLeaveConfirmation,showSuccessMsg:true,isLeave:!this.state.isLeave}) 
  }

  handelOpenLeaveConfirmationPopup = () => {
    this.setState({showSuccessMsg: !this.state.showSuccessMsg}) 
  }

  closeLeaveConfirmation = () => {
    this.setState({showSuccessMsg:false,isLeaveConfirmation:false, isLeave:false})
  }

  handelAvaibilityPopup = () => {
    if(this.state.isEdit) {
       this.setState({
        avaibilityPopup: !this.state.avaibilityPopup, 
        isFullDay: false, 
        selectedMonthType: "",
        isRecurrence: false,
        selectedType: "Does not repeat",
        isOpen: false,
        endType: "On",
        recurringType: "Monthly",
        isMonthly: false,
        isRepeat:false
      })
    }
  }

  handelIsFullDay = () => {
    this.setState({isFullDay:!this.state.isFullDay})
  }

  handelIsRepeat = () => {
    this.setState({isRepeat:!this.state.isRepeat})
  }

  handelSelecteype = (selectedType:string,recurringType:string) => {
    this.setState({selectedType,isOpen:false,recurringType,isRecurrence:true,isRepeat:false})
  }

  handelUpdateSelectedType = () => {
    this.setState({selectedType:"Does not repeat",endType:"On",isRepeat:false,isRecurrence:true})
  }

  handelSelectOption = () => {
    this.setState({isOpen:!this.state.isOpen,endType:"On"})
  }

  handelEndType = (endType:string) => {
    this.setState({endType})
  }

  handelMonthlySelectOption = () =>{
    this.setState({isMonthly:!this.state.isMonthly})
  }

  handelselectedMonthType = (selectedMonthType:string) => {
    this.setState({selectedMonthType,isMonthly:!this.state.isMonthly})
  }
  // Customizable Area End
}