import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  otp: string;
  otpAuthToken: string;
  userAccountID: string;
  labelInfo: string;
  toMessage: string;
  isFromForgotPassword: boolean;
  mobileNo: string;
  isError: boolean;
  errorMessage: string;
  isLoading: boolean;
  resendCounter: number;
  isResendActive: boolean;
  phoneOtpInput: any,
  focusedInput: number,
  phoneOTP: any;
  phoneNumber: string;
  isStatus: string;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class OTPInputAuthController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  otpAuthApiCallId: any;
  btnTxtSubmitOtp: string;
  placeHolderOtp: string;
  submitButtonColor: any = configJSON.submitButtonColor;
  hospitalAuthApiCallId: any;
  checkRegistrationSuccessApiCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      otp: "",
      otpAuthToken: "",
      userAccountID: "",
      labelInfo: configJSON.labelInfo,
      toMessage: "",
      isFromForgotPassword: false,
      isError: false,
      errorMessage: "",
      isLoading: false,
      resendCounter: 0,
      isResendActive: false,
      phoneOtpInput: [],
      focusedInput: 0,
      mobileNo: "",
      phoneOTP: ["", "", "", ""],
      phoneNumber: "",
      isStatus: ""
    };

    this.btnTxtSubmitOtp = configJSON.btnTxtSubmitOtp;
    this.placeHolderOtp = configJSON.placeHolderOtp;
    // Customizable Area End
  }

  async receive(from: String, message: Message) {
    // Customizable Area Start
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.otpAuthApiCallId != null &&
      this.otpAuthApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.otpAuthResponse(responseJson)
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.hospitalAuthApiCallId != null &&
      this.hospitalAuthApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.authApiResponse(responseJson)

    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.checkRegistrationSuccessApiCallId != null &&
      this.checkRegistrationSuccessApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.redirectToregistrationPage(responseJson)

    }else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const phoneAuthToken = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );

      const phoneNumber = message.getData(
        getName(MessageEnum.AuthTokenPhoneNumberMessage)
      );

      const forgotPasswordBool = message.getData(
        getName(MessageEnum.EnterOTPAsForgotPasswordMessage)
      );

      const emailValue = message.getData(
        getName(MessageEnum.AuthTokenEmailMessage)
      );

      const userAccountID = phoneNumber ? "" + phoneNumber : "" + emailValue;

      let updatedLabel = this.state.labelInfo;
      this.setState({
        otpAuthToken:
          phoneAuthToken && phoneAuthToken.length > 0
            ? phoneAuthToken
            : this.state.otpAuthToken,
        userAccountID: userAccountID,
        labelInfo: updatedLabel,
        isFromForgotPassword:
          forgotPasswordBool === undefined
            ? this.state.isFromForgotPassword
            : forgotPasswordBool
      });
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getPhoneNumber = async () => {
    const PhoneNumber = await getStorageData("phoneNumber") as string;
    return PhoneNumber
  }

  otpAuthResponse = async(responseJson: any) => {
    if (
      responseJson &&
      (responseJson.messages ||
        (responseJson.meta))
    ) {
      if (responseJson.meta.token) {
        this.setState({
          otpAuthToken: responseJson.meta.token
        });
        setStorageData("hpUserRole", responseJson.meta.profile_type);
        setStorageData("authToken", responseJson.meta.token)
        const isSignUp = JSON.parse(await getStorageData("isSignUp") || "false");
        this.redirectToNextPage(isSignUp,responseJson)
      }
    } else {
      this.showErrorMessage(responseJson)
    }
  }

  redirectToNextPage = (isSignUp:boolean,responseJson:any) => {
    if(!isSignUp){
      if(responseJson.meta.email === null){
      this.props.navigation.navigate("EmailAccountRegistration");
      return;
      }
      this.checkRegistrationCompleted(responseJson.meta.user_type,responseJson.meta.token)
    }else{
      this.props.navigation.navigate("OTPVerified");
    }
  }

  authApiResponse = (responseJson: any) => {
    if (
      responseJson && responseJson.hospital
    ) {
      if (responseJson.hospital.hospital_status !== "activated") {
        this.props.navigation.navigate("HospitalRegistrationSuccess")
      } else {
        this.props.navigation.navigate("Dashboard")
      }
    } else {
      this.showErrorMessage(responseJson)
    }
  }
  showErrorMessage = (responseJson: any) => {
    this.setState({ errorMessage: responseJson.error.message || responseJson.errors.message || responseJson.meta.message, isError: true });
    //Check Error Response
    this.parseApiErrorResponse(responseJson);
  }

  async submitOtp() {
    if (!this.state.otp || this.state.otp.length === 0) {
      this.showAlert(configJSON.errorTitle, configJSON.errorOtpNotValid);
      return;
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const headers = {
      "Content-Type": configJSON.apiVerifyOtpContentType,
      token: this.state.otpAuthToken
    };

    this.otpAuthApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiVerifyOtpEndPoint + this.state.otp
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(JSON.stringify({}))
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiVerifyOtpMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  btnSubmitOTPProps = {
    onPress: () => this.submitOtp()
  };

  txtMobilePhoneOTPWebProps = {
    onChangeText: (text: string) => this.setState({ otp: text })
  };

  txtMobilePhoneOTPMobileProps = {
    ...this.txtMobilePhoneOTPWebProps,
    keyboardType: "numeric"
  };

  txtMobilePhoneOTPProps = this.isPlatformWeb()
    ? this.txtMobilePhoneOTPWebProps
    : this.txtMobilePhoneOTPMobileProps;

  toggleResend = (state: boolean) => {
    this.setState({
      isResendActive: state
    })
  }

  handleBackButton = async () => {
    this.props.navigation.goBack()
  };

  handleChange = (index: any, event: any) => {
    const { phoneOTP } = this.state;
    const newOTP = [...phoneOTP];
    const inputValue = event.target.value;

    // Check if the input value is a number
    if (/^\d{0,9}$/.test(inputValue)) {
      newOTP[index] = inputValue;

      if (inputValue === "" && index > 0) {
        // If the user deletes a digit, move focus to the previous input field
        const prevInput = index - 1;
        const inputElement = document.getElementById(`otpInput${prevInput}`);
        if (inputElement) {
          inputElement.focus();
        }
      } else if (index < phoneOTP.length - 1 && inputValue !== "") {
        // Move focus to the next input field
        const nextInput = index + 1;
        const inputElement = document.getElementById(`otpInput${nextInput}`);
        if (inputElement) {
          inputElement.focus();
        }
      }

      this.setState({ phoneOTP: newOTP });
    }
  };

  checkOtpValidation() {
    // if empty show error = "Please enter OTP"
    // if not 4 digit show error = "Please enter valid OTP"
    // if not equal to 1234 show error = "Please enter valid OTP"
    if (this.state.phoneOTP.every((item: string) => item === "")) {
      this.setState({
        isError: true,
        errorMessage: "Please enter OTP"
      })
      return false;
    } else if (this.state.phoneOTP.length !== 4 || this.state.phoneOTP.some((item: any) => item === "")) {
      this.setState({
        isError: true,
        errorMessage: "Please enter 4 digit OTP"
      });
      return false;
    } else if (this.state.phoneOTP.length === 4) {
      this.setState({
        isError: false,
        errorMessage: ""
      });
    }
    return true;

  }

  async otpConfirmation() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    //GO TO REQUEST STATE
    this.otpAuthApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "sms_confirmations"
    );


    let pinCode = this.state.phoneOTP.map((otp: any) => otp.toString()).join('');

    const httpBody = {
      data: {
        attributes: {
          type: "login",
          pin: pinCode
        }
      }
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiVerifyOtpMethod
    );

    const token = await getStorageData("authToken") as string;
    const header = {
      "Content-Type": configJSON.apiVerifyOtpContentType,
      "token": token
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  async signupOtpConfirmation() {

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    //GO TO REQUEST STATE
    this.otpAuthApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "sms_confirmations"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiVerifyOtpMethod
    );

    let pinCodeNo = this.state.phoneOTP.map((otp: any) => otp.toString()).join('');

    const userType = await getStorageData("userType") as string;
    const token = await getStorageData("authToken") as string;
    const header = {
      "Content-Type": configJSON.apiVerifyOtpContentType,
      "token": token
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    const httpBody = {
      "data": {
        "attributes": {
          "type": "signup",
          "user_type": userType,
          "pin": pinCodeNo
        }
      }
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  verifyOTP = async () => {
    // check otp validation
    this.checkOtpValidation();
    const isSignUp = JSON.parse(await getStorageData("isSignUp") || "false");

    if (this.checkOtpValidation()) {
      if (isSignUp) {
        await this.signupOtpConfirmation()
      }

      else {
        await this.otpConfirmation();
      }
      // end loading
    }
  }

  navigateToNextPage = async () => {
    const userType = await getStorageData("userType") as string;
    const isSignUp = JSON.parse(await getStorageData("isSignUp") || "false");
    removeStorageData("hpDetails")
    if (isSignUp) {
      this.props.navigation.navigate("EmailAccountRegistration")
    } else if (userType === "hospital") {
      await this.checkHospitalProfile();
    }else{
        this.props.navigation.navigate("Dashboard");
    }
  }

  checkHospitalProfile = async () => {
    const token = await getStorageData("authToken") as string;
    const header = {
      token: token
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.hospitalAuthApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "hospitals/current_user_hospital"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  checkRegistrationCompleted = async (usertype:string,token:string) => {
    const header = {
      token: token
    };

    let endpoint= "";
    if(usertype === "patient"){
      endpoint = "patients/current_user_patient"
    }else if(usertype === "hospital"){
      endpoint = "hospitals/current_user_hospital"
    }

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.checkRegistrationSuccessApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  
  redirectToregistrationPage = (responseJson: any) => {
    if (!responseJson) {
      return;
    }

    const { patient, errors } = responseJson;

    if (
      patient &&
      patient.message === "Please create a patient for this account"
    ) {
      this.props.navigation.navigate("PatientRegisterDetails");
    } else if (
      errors &&
      errors.message === "Please fill the hospital detail page."
    ) {
      this.props.navigation.navigate("HospitalRegisterDetails");
    } else {
      this.props.navigation.navigate("OTPVerified");
    }
  }
  // Customizable Area End
}
